import { createReducer } from "@reduxjs/toolkit";
import { IAllocationSummary } from "config/types";
import { floor, inRange } from "lodash-es";
import { getVerifiedInitialData } from "store/actions/appLoad";
import { logout } from "store/actions/authentication";
import { getDashboardData } from "store/actions/dashboard";

import { getWyshAllocationSummary } from "../actions/wyshes";

const defaultState: IAllocationSummary = {
  activeCount: 0,
  totalAmount: 0,
  percentCovered: 0,
};

const roundAllocationPercent = (percentCovered = 0) => {
  const inRangeOf100 = inRange(percentCovered, 99.9, 100);

  return inRangeOf100 ? 100 : floor(percentCovered);
};

const allocationSummary = createReducer(defaultState, builder => {
  builder
    .addCase(getVerifiedInitialData.fulfilled, (state, action) => {
      const summary = action.payload?.account?.allocationSummary;
      const percentCovered = roundAllocationPercent(summary?.percentCovered);

      state.activeCount = summary?.activeCount || 0;
      state.totalAmount = summary?.totalAmount || 0;
      state.percentCovered = percentCovered;
    })
    .addCase(getDashboardData.fulfilled, (state, action) => {
      const summary = action.payload?.allocationSummary;
      const percentCovered = roundAllocationPercent(summary?.percentCovered);

      state.activeCount = summary?.activeCount || 0;
      state.totalAmount = summary?.totalAmount || 0;
      state.percentCovered = percentCovered;
    })
    .addCase(getWyshAllocationSummary.fulfilled, (state, action) => {
      const summary = action?.payload;
      const percentCovered = roundAllocationPercent(summary?.percentCovered);

      state.activeCount = summary?.activeCount || 0;
      state.totalAmount = summary?.totalAmount || 0;
      state.percentCovered = percentCovered;
    })
    .addCase(logout, () => defaultState);
});

export default allocationSummary;
