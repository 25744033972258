import { gql } from "config/graphql";

import { RECIPIENT_FIELDS } from "./recipient";

export const WYSH_FIELDS = gql`
  fragment WyshFields on Wysh {
    id
    slug
    description
    prompt
    active
    guidance
    monitorable
    termable
    recipientType
    order
    icon100100 {
      formats {
        contentType
        url
      }
    }
    iconSelected6464 {
      formats {
        contentType
        url
      }
    }
    iconUnselected6464 {
      formats {
        contentType
        url
      }
    }
    header950400 {
      formats {
        contentType
        url
      }
    }
    header400368 {
      formats {
        contentType
        url
      }
    }
    defaultAmount
    defaultName
    amountPrompt
    amountGuidance
    amountPlaceholder
    monitoringGuidance
    termPrompt
    termGuidance
    termPlaceholder
    wyshSuggestions {
      id
      title
      amount
      enabled
      order
    }
    wyshCategory {
      id
      name
      prompt
      slug
      color
      description
    }
  }
`;

export const WYSH_ALLOCATION_FIELDS = gql`
  ${RECIPIENT_FIELDS}
  ${WYSH_FIELDS}
  fragment WyshAllocationFields on WyshAllocation {
    id
    name
    amount
    covered
    amountCovered
    percentCovered
    monitored
    status
    order
    terminatesAt
    updatedAt
    recipient {
      ...RecipientFields
    }
    wysh {
      ...WyshFields
    }
    allocationHistories {
      id
      amount
      change
      direction
      createdAt
      updatedAt
      wyshAllocation {
        name
      }
    }
  }
`;
