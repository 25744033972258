import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import React, { FC } from "react";

const EndOfMonthErrorModal: FC<IModalProps> = ({ closeModal }) => {
  const { t } = useTranslation();

  const content = {
    header: t(
      "savings.autodeposit.setup.modal.date_error.header",
      "Please pick a date before the 29th"
    ),
    subheader: t(
      "savings.autodeposit.setup.modal.date_error.subheader",
      "Be advised not all months go past the 29th (especially you, February👀!). To avoid any deposit issues, please pick any date on or before the 28th."
    ),
    button: [
      {
        text: t("savings.autodeposit.setup.modal.date_error.cta.primary", "OKAY"),
        subType: "primary",
        onClick: () => closeModal(),
      },
    ],
    icon: "BlobFee",
  };

  return <Layout as="ModalLayout" className="cancel-auto-deposit__modal" content={content} />;
};

export default EndOfMonthErrorModal;
