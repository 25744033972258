interface Options {
  top?: number;
  left?: number;
  behavior?: "smooth" | "auto";
}

export const scrollToTop = (options?: Options) => {
  const top = options?.top || 0;
  const left = options?.left || 0;
  const behavior = options?.behavior || "smooth";

  window.scrollTo({ top, left, behavior });
};
