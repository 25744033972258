import { ICountry } from "config";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store";
import { getCountries } from "store/actions/country";
import { getStates } from "store/actions/states";
import { selectCountryItems, selectCountryOptions } from "store/selectors";

// Hook functionalities:
// 1. Fetches country data from api and sets on redux store
// 2. Returns country data, selectedCountry, if called with country.id
// 3. Returns countries as array of options for select inputs
// 4. Returns country's states, if available, as array of options for select inputs

export const useCountryStates = (countryId = "") => {
  const dispatch = useAppDispatch();
  const countriesList = useSelector(selectCountryItems);
  const countryOptions = useSelector(selectCountryOptions);
  const states = useSelector((state: RootState) => state.states[countryId]);

  const [selectedCountry, setSelectedCountry] = useState<ICountry | undefined>(undefined);
  const [stateOptions, setStateOptions] = useState<{ value: string; label: string }[]>([]);

  // Fetch countries data if state is empty
  useEffect(() => {
    if (!countryOptions.length) {
      dispatch(getCountries());
    }
  }, [dispatch, countryOptions.length]);

  // Fetch country's states if available
  useEffect(() => {
    const currentCountry = countriesList.find(country => country.id === countryId);
    setSelectedCountry(currentCountry);

    if (countryId && currentCountry?.hasStates) {
      dispatch(getStates(countryId));
    }
  }, [dispatch, countryId, countriesList]);

  // Map states to select option objects for dropdown
  useEffect(() => {
    const countryStates = states || [];
    const statesMappedToOpions = countryStates.map(stateObj => ({
      value: stateObj.id,
      label: stateObj.name,
      key: stateObj.alpha2,
    }));

    setStateOptions(statesMappedToOpions);
  }, [dispatch, states]);

  return { countryOptions, stateOptions, selectedCountry };
};
