import "./FullScreenModalLayout.scss";

import classNames from "classnames";
import Button from "components/Button/Button";
import HeaderLogo from "components/HeaderLogo/HeaderLogo";
import Icon from "components/Icon/Icon";
import Text, { IProps as ITextProps } from "components/Text/Text";
import { ButtonSubType, ValueOrArray } from "config/types";
import Modal from "containers/Shared/Modal/Modal";
import { flatten, map } from "lodash-es";
import { ILayoutProps } from "modules";
import { FC } from "react";
import { useAppDispatch } from "store";
import { closeModal } from "store/actions/modal";

type ButtonType = {
  text: string;
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  isLoading?: boolean;
  subType?: ButtonSubType;
  onClick: () => void;
};

type ContentType = {
  header: string;
  subheader: ValueOrArray<ITextProps["text"]>;
  button: ButtonType | ButtonType[];
  image?: string;
  onClose?: () => void;
};

const FullScreenModalLayout: FC<ILayoutProps<ContentType>> = props => {
  const { children, className } = props;
  const dispatch = useAppDispatch();

  const content = props.content as ContentType;
  const header = content?.header as string;
  const subheader = content?.subheader || [];
  const subheaderList = flatten([subheader]);
  const button = content?.button || [];
  const buttonList = flatten([button || []]);
  const onClose = content?.onClose;
  const image = content?.image;

  const classes = classNames("full-screen-modal", className);

  const headerProps = {
    closeButton: false,
  };

  const confirmExitModal = () => {
    onClose && onClose();
    dispatch(closeModal());
  };

  return (
    <Modal className={classes} headerProps={headerProps} onClose={onClose} type="fullscreen">
      <div className="full-screen-modal-header">
        <HeaderLogo isCoBranded={false} />
      </div>

      <div className="full-screen-modal__container">
        <div className="full-screen-modal__close modal-header">
          <button
            className="full-screen-modal_close-icon modal-header_close-icon"
            onClick={confirmExitModal}>
            <Icon src="CloseBorder" size="sm" />
          </button>
        </div>
        <div className="full-screen-modal__content-wrapper">
          <div className="full-screen-modal__content">
            {image && <img alt="" src={image} className="full-screen-modal__image" />}
          </div>
          <div className="full-screen-modal__content">
            <Text className="full-screen-modal__header" tag="h4" text={header} />
            {map(subheaderList, (item: ITextProps["text"], index: number) => (
              <Text className="full-screen-modal__subheader" key={index} tag="p3" text={item} />
            ))}

            <div className="full-screen-modal__btns modal__btns">
              {map(buttonList, (item: ButtonType, index: number) => (
                <Button
                  key={index}
                  subtype={item.subType || "primary"}
                  text={item.text}
                  onClick={item.onClick}
                  isLoading={item.isLoading}
                  disabled={item.disabled}
                  className={item.className}
                  dataTestId={item.dataTestId}
                />
              ))}
            </div>
            {children}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FullScreenModalLayout;
