import Text from "components/Text/Text";
import { useTranslation } from "modules";

interface IProps {
  hasPortal: boolean;
  isExit: boolean;
  text?: string;
}

export function BackNavText({ hasPortal, isExit, text: sourceText }: IProps) {
  const { t } = useTranslation();

  const text = (function() {
    switch (true) {
      case !!sourceText:
        return sourceText;

      case isExit:
        return t("exit", "EXIT");

      default:
        return t("back", "Back");
    }
  })();
  const formattedText = hasPortal && text ? text.toLowerCase() : text;

  return (
    <Text tag={hasPortal ? "l4-label" : "c5"} text={formattedText} className="backnav__text" />
  );
}
