import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC } from "react";
import { closeModal } from "store/actions/modal";

const PendingTransferModal: FC<IModalProps> = props => {
  const { t } = useTranslation();
  const closeModalWrapper = () => props.closeModal();

  const content = {
    icon: "BlobAlert",
    header: t("savings.close_account.modal.header", "Pending transfer in progress"),
    subheader: t(
      "savings.close_account.modal.body",
      "You currently have a pending transfer that needs to be completed before we can close your account. Once the transfer is finalized you can come back and try again."
    ),
    onClose: closeModalWrapper,
    button: [
      {
        text: t("savings.close_account.modal.cta.primary", "GOT IT"),
        subType: "primary",
        onClick: closeModalWrapper,
      },
    ],
    onButtonClick: closeModal,
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default PendingTransferModal;
