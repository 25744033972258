import { isEmpty } from "lodash-es";
import { FC, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { refreshToken } from "store/actions/authentication";

import { OAuthContext } from "./OAuthProvider";

const RefreshTokenProvider: FC = props => {
  const { children } = props;
  const dispatch = useDispatch();

  const { clientId } = useContext(OAuthContext);
  const isLoading = useSelector((state: RootState) => state.authentication.refreshingToken);
  const token = useSelector((state: RootState) => state.authentication.accessToken?.access_token);

  useEffect(() => {
    if (isEmpty(token)) {
      dispatch(refreshToken(clientId));
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return isLoading ? null : <>{children}</>;
};
export default RefreshTokenProvider;
