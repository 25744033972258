export const AnalyticsIds = {
  loginRegistration: {
    createAccountLogin: "createAccountLoginPage",
    login: "loginLoginPage",
    createAccountSignUp: "signUpCreateAccountPage",
  },
  estimation: {
    makeWyshNow: "wyshNowWyshSelectPage",
    makeWyshLater: "wyshLaterWyshSelectPage",
  },
  underwriting: {
    underwritingStarted: "startStartUWPage",
  },
  quote: {
    acceptQuote: "continueQuoteAcceptPage",
    purchasePolicy: "payStartCoveragePage",
  },
  flowQuestions: {
    "prequalification-state": "nextStateSelectPage",
    "prequalification-dob": "nextAgeSelectPage",
    "prequalification-felony": "nextCriminalHistorySelectPage",
    "prequalification-income": "nextIncomeSelectPage",
    "prequalification-drug": "nextDrugAbuseSelectPage",
    "estimate-gender": "nextGenderSelectPage",
  },
};
