import { EventsMap } from "redux-beacon";
import { verifySession } from "store/actions/authentication";
import { initFormFlow } from "store/actions/flow";
import { setJourneyActive } from "store/actions/journey";
import { createWyshAllocation, updateWyshAllocation } from "store/actions/wyshes";
import * as actionType from "store/constants";

import { sessionVerified } from "./authEvents";
import { beneficiaryEventsMap } from "./beneficiaryEvents";
import { billingEventsMap } from "./billingEvents";
import { eventFired } from "./events";
import {
  flowFormCompleted,
  flowFormFieldSet,
  flowFormStarted,
  flowFormValidationError,
} from "./flowEvents";
import { fulfillmentEventsMap } from "./fulfillmentEvents";
import { setJourneyStep, startJourney } from "./journey";
import { onboardingEventsMap } from "./onboardingEvents";
import { productOfferEventsMap } from "./productOfferEvents";
import { savingsApplicationEventsMap } from "./savings/savingsApplicationEvents";
import { savingsFundingEventsMap } from "./savings/savingsFundingEvents";
import { savingsLifeBenefitEventsMap } from "./savings/savingsLifeBenefitEvents";
import { savingsManagementEventsMap } from "./savings/savingsManagementEvents";
import { savingsOnboardingEventsMap } from "./savings/savingsOnboardingEvents";
import { underwritingEventsMap } from "./underwritingEvents";
import {
  createWyshAllocationEventDefinition,
  updateWyshAllocationEventDefinition,
} from "./wyshEvents";

export const eventsMap: EventsMap = {
  EVENT_FIRED: eventFired,
  [initFormFlow.fulfilled.type]: flowFormStarted,
  [actionType.FINISH_FLOW]: flowFormCompleted,
  [actionType.FLOW_FIELD_SET]: flowFormFieldSet,
  [actionType.FLOW_VALIDATION_ERROR]: flowFormValidationError,
  [createWyshAllocation.fulfilled.type]: createWyshAllocationEventDefinition,
  [updateWyshAllocation.fulfilled.type]: updateWyshAllocationEventDefinition,
  [verifySession.fulfilled.type]: sessionVerified,
  [setJourneyActive.fulfilled.type]: startJourney,
  SET_JOURNEY_STEP: setJourneyStep,
  ...beneficiaryEventsMap,
  ...billingEventsMap,
  ...fulfillmentEventsMap,
  ...underwritingEventsMap,
  ...productOfferEventsMap,
  ...onboardingEventsMap,
  ...savingsApplicationEventsMap,
  ...savingsFundingEventsMap,
  ...savingsLifeBenefitEventsMap,
  ...savingsManagementEventsMap,
  ...savingsOnboardingEventsMap,
};
