interface IO {
  [key: string]: any;
}

const shallowDiffers = (a: IO, b: IO) => {
  for (const i in a) {
    if (!(i in b)) {
      return true;
    }
  }

  for (const i in b) {
    if (a[i] !== b[i]) {
      return true;
    }
  }

  return false;
};

export default shallowDiffers;
