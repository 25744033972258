import { createAction, createAsyncThunk, Dispatch } from "@reduxjs/toolkit";
import { OrString } from "config/types";
import { push, replace } from "connected-react-router";
import { find, first, get, isEmpty, isEqual, last, map, mapKeys } from "lodash-es";
import { batch } from "react-redux";
import { RootState } from "store";
import { mockJourney } from "store/mock/journey.mock";
import { JourneyState } from "store/reducers/journey";
import { local } from "store2";
import { getAppError } from "utils/error";
import { getJourneyExitPath } from "utils/journey";

/* ------------    ACTIONS     ------------------ */

// Journey
export const setJourneyStep = createAction("SET_JOURNEY_STEP", (id: string) => ({
  payload: { id },
}));

export const setJourneyInactive = createAction("SET_JOURNEY_INACTIVE", (id: string) => ({
  payload: { id },
}));

export const setJourneyActiveSection = createAction(
  "SET_JOURNEY_ACTIVE_SECTION",
  (section: string) => ({
    payload: { section },
  })
);

export const setShowJourneyProgress = createAction(
  "SET_SHOW_JOURNEY_PROGRESS",
  (showJourneyProgress: boolean) => ({
    payload: { showJourneyProgress },
  })
);

export const setJourneyProgressPercentOverride = createAction(
  "SET_JOURNEY_PROGRESS_PERCENT_OVERRIDE",
  (percent?: number) => ({
    payload: { percent },
  })
);

export const restoreJourney = createAction(
  "SET_JOURNEY_STATE",
  (journey: Partial<JourneyState>) => ({
    payload: { state: journey },
  })
);

export const clearJourneyActive = createAction("CLEAR_JOURNEY_ACTIVE");

/* ------------       THUNKS      ------------------ */

export const setJourneyActive = createAsyncThunk(
  "SET_JOURNEY_ACTIVE",
  (id: string, { getState }) => {
    const authenticated = get(getState(), "authentication.authenticated", false);

    return {
      authenticated,
      id,
    };
  }
);

export const startJourney = (
  journeyId: string,
  activateFirstStep = false,
  context: { data?: Record<string, unknown>; metadata?: Record<string, unknown> } = {}
) => {
  return (dispatch: Dispatch<any>, getState: () => RootState) => {
    // should only result in one combined re-render, not two
    batch(async () => {
      const response = await dispatch(
        fetchJourney({ id: journeyId, activateFirstStep, ...context })
      );
      const id = get(response, "payload.journey.id", "");

      const { router } = getState();
      const entryPath = get(response, "payload.journey.paths.entry");
      if (entryPath && !isEqual(router.location.pathname, entryPath)) {
        const locationState = router.location.state;
        const search = router.location.search;
        dispatch(push({ pathname: entryPath, state: locationState, search }));
      }

      dispatch(setJourneyActive(id));
    });
  };
};

export const nextJourneyStep = createAsyncThunk(
  "next_journey",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await mockJourney(id);
      return response;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const previousJourneyStep = createAsyncThunk(
  "previous_journey",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await mockJourney(id);
      return { journey: response };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const finishJourney = createAsyncThunk(
  "SET_JOURNEY_COMPLETE",
  async (id: string, { getState, dispatch }) => {
    const { journey, router, user, wyshes } = getState() as RootState;
    const userId = user.id;
    const activeJourney = find(journey.collection, { id });
    const storage = isEmpty(userId) ? local : local.namespace(userId as string);
    storage.remove("journeyProgress");
    storage.transact("completedJourneys", () => ({
      [id]: "completed",
    }));

    const exitPath = getJourneyExitPath(activeJourney, wyshes);

    await dispatch(clearJourneyActive());
    if (!!exitPath && !isEqual(router.location.pathname, exitPath)) {
      dispatch(replace(exitPath, { ignoreAction: true }));
    }

    return { id };
  }
);

export const fetchJourney = createAsyncThunk(
  "fetch/journey",
  async (
    {
      id,
      activateFirstStep,
      data,
      metadata,
    }: {
      id: string;
      activateFirstStep?: boolean;
      data?: Record<string, unknown>;
      metadata?: Record<string, unknown>;
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const store = getState() as any;
      const { journey } = store;
      const activeJourney: OrString = last(journey.activeJourneys);
      const response = mockJourney(id, data);
      const stepKeys = map(response.steps, "key");

      return {
        journey: { ...response, steps: stepKeys, parent: activeJourney, metadata },
        steps: {
          active: activateFirstStep ? first(stepKeys) : undefined,
          collection: mapKeys(response.steps, "key"),
          ids: stepKeys,
        },
      };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
