import { isUndefined } from "lodash-es";
import { FC, Fragment, isValidElement, ReactElement, ReactNode } from "react";

interface IProps {
  condition: boolean;
  wrapper?: FC<IProps["props"]> | ((children: ReactNode, props: IProps["props"]) => ReactNode);
  props?: any;
}
export const ConditionalWrapper: FC<IProps> = ({
  props: wrapperProps = {},
  condition = false,
  wrapper,
  children,
}): ReactElement => {
  const isElem = isValidElement(wrapper);
  const Wrapper = wrapper as any;

  if (isUndefined(wrapper)) {
    return <Fragment>{condition && children}</Fragment>;
  }

  const wrapperInstance = isElem ? (
    (wrapper as any)(children, wrapperProps)
  ) : (
    <Wrapper {...wrapperProps}>{children}</Wrapper>
  );

  return <Fragment>{condition ? wrapperInstance : children}</Fragment>;
};
