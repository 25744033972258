import {
  endsWith,
  filter,
  isEmpty,
  join,
  map,
  split,
  trimStart,
  toLower,
  upperFirst,
} from "lodash-es";

export const formatPaymentName = (name: string) => {
  const trimmedValue = trimStart(name);
  const loweredName = toLower(trimmedValue);
  const splitNames = filter(split(loweredName, " "), str => !isEmpty(str));

  const capitalizedName = join(map(splitNames, upperFirst), " ");
  return endsWith(name, " ") ? `${capitalizedName} ` : capitalizedName;
};
