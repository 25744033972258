import { createReducer } from "@reduxjs/toolkit";
import {
  IPaymentMethod,
  OrEmptyString,
  PaymentMethodSource,
  PaymentMethodStatus,
} from "config/types";
import { logout } from "store/actions/authentication";
import { createPaymentMethod, getActivePaymentMethod } from "store/actions/payment";

export interface IPaymentState extends Omit<IPaymentMethod, "source" | "status"> {
  status: OrEmptyString<PaymentMethodStatus>;
  source: OrEmptyString<PaymentMethodSource>;
}

const defaultState: IPaymentState = {
  id: "",
  name: "",
  metadata: {},
  status: "",
  source: "",
  externalId: "",
  mask: "",
  plaidAccount: {
    accountType: "",
    id: "",
    externalId: "",
    mask: "",
    name: "",
  },
  createdAt: "",
  updatedAt: "",
};

const payment = createReducer(defaultState, {
  [getActivePaymentMethod.fulfilled.type]: (state, action) => {
    const paymentMethod = action.payload;
    const plaidAccount = paymentMethod?.plaidAccount || defaultState.plaidAccount;

    return { ...state, ...paymentMethod, plaidAccount };
  },
  [createPaymentMethod.fulfilled.type]: (state, action) => {
    const paymentMethod = action.payload;
    const plaidAccount = paymentMethod.plaidAccount || defaultState.plaidAccount;

    return { ...state, ...paymentMethod, plaidAccount };
  },
  [logout.type]: () => defaultState,
});

export default payment;
