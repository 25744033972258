import { gql } from "@apollo/client";
import { ProducerManagementReferralCode } from "config";
import { getApolloClient } from "store/apollo";

interface ICreateApplicationReferralPayload {
  data: {
    createApplicationReferral: {
      response: {
        success: boolean;
        status?: string;
      };
    };
  };
}

export const createApplicationReferralMutation = (
  referralCode: ProducerManagementReferralCode
): Promise<ICreateApplicationReferralPayload> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation CreateApplicationReferral($input: CreateApplicationReferralInput!) {
      createApplicationReferral(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables: {
      input: {
        referralCode,
      },
    },
  });
};
