import { IModalProps } from "config/init/providers/ModalsProvider";
import { replace } from "connected-react-router";
import { find, isEmpty } from "lodash-es";
import { Layout, useTranslation } from "modules";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { restoreJourney } from "store/actions/journey";
import { closeModal } from "store/actions/modal";
import { local } from "store2";
import { getJourneyExitPath } from "utils/journey";

const JourneyProgress: FC<IModalProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.user.id);
  const storage = isEmpty(userId) ? local : local.namespace(userId as string);

  const handleResumeJourney = () => {
    const journeyState = storage.get("journeyProgress");
    if (!isEmpty(journeyState)) {
      dispatch(restoreJourney(journeyState));
      closeModalWrapper();
    }
  };

  const closeModalWrapper = () => {
    const journeyState = storage.get("journeyProgress");
    const activeJourney = find(journeyState.collection, { active: true });
    const exitPath = getJourneyExitPath(activeJourney);

    storage.remove("journeyProgress");
    dispatch(closeModal());

    if (exitPath) {
      dispatch(replace(exitPath, { ignoreAction: true }));
    }
  };

  const ctaButtons = [
    {
      text: t("journey.journey_resume.modal.button_confirm", "Resume Journey"),
      onClick: handleResumeJourney,
    },
    {
      text: t("journey.journey_resume.modal.button_cancel", "Cancel"),
      subType: "text-dark",
      onClick: closeModalWrapper,
    },
  ];

  const content = {
    header: t("journey.journey_resume.modal.title", "Journey In Progress"),
    subheader: t(
      "journey.journey_resume.modal.body",
      "You're in the middle of a journey. Don't abandon it! Get in there!"
    ),
    onClose: closeModalWrapper,
    button: ctaButtons,
    icon: "BlobAlert",
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default JourneyProgress;
