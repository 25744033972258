import "./PhoneGroupComponent.scss";
import { FC } from "react";

import { DynamicFormGroupComponentProps } from "..";

type IProps = DynamicFormGroupComponentProps;

export const PhoneGroupComponent: FC<IProps> = ({ children }) => {
  return <div className="dynamic-form-group form-group--input-phone">{children}</div>;
};
