import { createReducer } from "@reduxjs/toolkit";
import { IFeatureFlags } from "config/types/featureFlag.types";
import { fetchGlobalFlags } from "store/actions";

/* ------------    FEATURE FLAGS DEFAULT STATE     ------------------ */
const defaultState: IFeatureFlags[] = [];

/* ------------    REDUCER     ------------------ */
const featureFlags = createReducer(defaultState, builder => {
  builder.addCase(fetchGlobalFlags.fulfilled, (state, action) => {
    const featureFlags = action.payload || [];

    // Set Locally Managed Feature Flags
    featureFlags.push({ name: "coverage-breakdown-chart", enabled: true });

    return featureFlags;
  });
});

export default featureFlags;
