import { gql } from "config/graphql";

const PLAID_INSTITUTION_FIELDS = gql`
  fragment PlaidInstitutionFields on PlaidInstitution {
    id
    name
    logo
  }
`;

export const PLAID_ACCOUNT_FIELDS = gql`
  ${PLAID_INSTITUTION_FIELDS}
  fragment PlaidAccountFields on PlaidAccount {
    id
    externalId
    name
    mask
    fullName
    subtype
    accountType
    createdAt
    plaidItem {
      plaidInstitution {
        ...PlaidInstitutionFields
      }
    }
  }
`;

export const PLAID_ITEM_FIELDS = gql`
  ${PLAID_ACCOUNT_FIELDS}
  ${PLAID_INSTITUTION_FIELDS}
  fragment PlaidItemFields on PlaidItem {
    id
    business {
      id
      name
      slug
    }
    createdAt
    externalId
    plaidAccounts {
      ...PlaidAccountFields
    }
    plaidInstitution {
      ...PlaidInstitutionFields
    }
    status
    updatedAt
  }
`;

export const USER_PLAID_ITEMS = gql`
  ${PLAID_ITEM_FIELDS}
  fragment UserPlaidItems on User {
    activeConstellations {
      plaidItems {
        ...PlaidItemFields
      }
      financePlaidItems: plaidItems(lineOfBusiness: "wysh-financial") {
        ...PlaidItemFields
      }
    }
  }
`;
