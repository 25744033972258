import { ExperienceLocationTypes, isFormDynamicForm, ProductOfferingAttribute } from "config";
import { get, isEmpty, isFunction } from "lodash-es";
import { RootState } from "store";
import {
  getInsuranceMembership,
  getWyshADOffering,
  getWyshTermLifeOffering,
  selectActiveFlowForm,
  selectLifeBenefitData,
} from "store/selectors";

const LOCATION_ATTRS_GETTERS: Record<ExperienceLocationTypes, (state: RootState) => any> = {
  APPLICATION: getApplicationDefaultAttrs,
  DASHBOARD: getDashboardDefaultAttrs,
  FULFILLMENT: getFulfillmentDefaultAttrs,
  FUNDING: getFundingDefaultAttrs,
  MANUALUW: getManualUWDefaultAttrs,
  MYSAVINGS: getMySavingsDefaultAttrs,
  ONBOARDING: getOnboardingDefaultAttrs,
  SAVINGS: getSavingsDefaultAttrs,
  VERIFICATION: getVerificationDefaultAttrs,
};

function getApplicationDefaultAttrs() {
  return {
    experienceLocation: "APPLICATION",
  };
}

function getDashboardDefaultAttrs() {
  return {
    experienceLocation: "DASHBOARD",
  };
}

function getFulfillmentDefaultAttrs() {
  return {
    experienceLocation: "FULFILLMENT",
  };
}

function getFundingDefaultAttrs() {
  return {
    experienceLocation: "FUNDING",
  };
}

function getMySavingsDefaultAttrs(state: RootState) {
  const lifeBenefit = selectLifeBenefitData(state);

  return {
    experienceLocation: "MySavings",
    lbStatus: lifeBenefit.status,
  };
}

function getOnboardingDefaultAttrs() {
  return {
    experienceLocation: "ONBOARDING",
  };
}

function getSavingsDefaultAttrs() {
  return {
    experienceLocation: "SAVINGS",
  };
}

function getVerificationDefaultAttrs() {
  return {
    experienceLocation: "VERIFICATION",
  };
}

function getManualUWDefaultAttrs() {
  return {
    experienceLocation: "manualUW",
  };
}

function getDefaultAttrs(state: RootState, experienceLocation?: ExperienceLocationTypes) {
  const locationAttrGetter = experienceLocation && LOCATION_ATTRS_GETTERS[experienceLocation];
  const locationAttrs = isFunction(locationAttrGetter) ? locationAttrGetter(state) : {};

  const activeAccount = getInsuranceMembership(state);
  const accountId = get(activeAccount, "id", "");
  const utmAttrs = get(state, "analyticsTracker.utm", {});

  const status = get(state, "policy.status", "");
  const hasPolicy = !isEmpty(status) && status !== "terminated";

  const userId = get(state, "user.id", "");

  return {
    hasPolicy,
    userId,
    ...(!!accountId && { accountId: accountId }),
    ...locationAttrs,
    ...utmAttrs,
  };
}

// Retrieves question.externalId on current flowForm
const getExternalId = (state: RootState) => {
  const activeForm = selectActiveFlowForm(state);
  const activeQuestion = isFormDynamicForm(activeForm)
    ? activeForm?.items[0]
    : activeForm?.questions[0]?.fields[0];

  return activeQuestion?.key;
};

// Returns value for attribute.productOffering
const getProductOfferingAttr = (state: RootState): ProductOfferingAttribute => {
  const termOffer = getWyshTermLifeOffering(state);
  const adOffer = getWyshADOffering(state);

  if (termOffer && adOffer) {
    return "term-ad";
  } else if (termOffer) {
    return "term";
  } else if (adOffer) {
    return "ad";
  } else {
    return "none";
  }
};

const getProductSelectedAttr = (state: RootState) => {
  const productDetail = state.quote?.product || state.policy?.productDetail;
  const productsMap = {
    "wysh-accidental-death": "ad",
    "wysh-term-life": "term",
  };

  return !!productDetail?.slug && productsMap[productDetail?.slug];
};

export { getDefaultAttrs, getExternalId, getProductOfferingAttr, getProductSelectedAttr };
