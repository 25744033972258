import "./ListItem.scss";

import classNames from "classnames";
import Divider from "components/Divider/Divider";
import Icon, { IconColor } from "components/Icon/Icon";
import Text, { TextType } from "components/Text/Text";
import ToggleInput, { IToggleInputEvent } from "components/ToggleInput/ToggleInput";
import { ClassName } from "config/types";
import { defaultTo, get, isNil, isString, kebabCase } from "lodash-es";
import { CSSProperties, FC, HTMLProps, MouseEvent, ReactNode } from "react";
import { Link } from "react-router-dom";

export const elementTypes = {
  li: "li",
  div: "div",
  a: "a",
};

export type ElementType = keyof typeof elementTypes;

type IProps = HTMLProps<HTMLLIElement> & {
  className?: ClassName;
  style?: CSSProperties;
  header?: string;
  text?: ReactNode;
  textTag?: TextType;
  disabled?: boolean;
  isHeader?: boolean;
  isLabel?: boolean;
  textBold?: boolean;
  secondaryText?: string;
  rightText?: string;
  rightTextTag?: TextType;
  rightSecondaryText?: string;
  icon?: {
    left?: string;
    right?: string;
    colorRight?: IconColor;
  };
  toggle?: {
    checked: boolean;
    onChange: (event: IToggleInputEvent) => void;
  };
  dataTestId?: string;
  imgSrc?: string;
  tag?: ElementType;
  linkTo?: string;
  active?: boolean;
  heightAuto?: boolean;
  noBorderBottom?: boolean;
  elementRef?: React.Ref<HTMLInputElement> | null;
  onClick?: (event: MouseEvent) => void;
  onRightIconClick?: (event: MouseEvent) => void;
};

const ListItem: FC<IProps> = props => {
  const {
    children,
    className,
    elementRef,
    dataTestId: dataTestIdProp,
    style,
    text,
    disabled,
    isHeader,
    isLabel,
    textBold,
    textTag,
    secondaryText,
    rightText,
    rightTextTag,
    rightSecondaryText,
    icon,
    toggle,
    imgSrc,
    tag,
    linkTo,
    active,
    heightAuto,
    noBorderBottom,
    onClick,
    onRightIconClick,
    ...nativeProps
  } = props;

  const defaultTag = defaultTo(tag, "li");

  const textContentTag = (tag: TextType = "l3") => {
    let tagToReturn = tag;

    if (isLabel) {
      tagToReturn = "p5";
    } else if (isHeader) {
      tagToReturn = "c5";
    }

    return tagToReturn;
  };

  const Element: any = get(elementTypes, defaultTag);
  const textLabel = isString(text) ? kebabCase(text) : "";
  const dataTestIdBase = dataTestIdProp || textLabel;

  const classes = classNames("list-item", className, {
    "list-item--disabled": disabled,
    "list-item--active": active,
    "list-item--height-auto": heightAuto,
    "list-item--border-bottom": !isHeader && !noBorderBottom,
  });

  const leftContentClasses = classNames("list-item__left-container", {
    "list-item__text--header": isHeader || textBold,
    "single-line-list": !rightText && !rightSecondaryText,
  });

  const leftTextClasses = classNames("list-item__left-container_text", {
    "label-list-item": isLabel,
  });

  const rightTextClasses = classNames("list-item__right-container_text", {
    "label-list-item": isLabel,
  });

  const LinkContent = () => (
    <Link className="link list-link" to={linkTo || ""}>
      <Text tag={isHeader ? "c5" : "l3"} text={rightText} className="list-item__text" />
      <Icon
        src={icon?.right}
        className="list-item__right-icon list-item__right-icon--link"
        dataTestId={`list-item__right-icon--${dataTestIdBase}`}
      />
    </Link>
  );

  const TextContent = (props: { text?: ReactNode; textTag?: TextType }) => (
    <Text
      tag={textContentTag(props.textTag || "l3")}
      text={props.text}
      className="list-item__text"
    />
  );

  const SecondaryTextContent = (props: { secondaryText?: string }) => {
    return props.secondaryText ? (
      <Text
        tag={isLabel ? "l3" : "p5"}
        text={props.secondaryText}
        className="list-item__text--secondary"
      />
    ) : null;
  };

  return (
    <Element
      {...nativeProps}
      ref={elementRef}
      className={classes}
      style={style}
      onClick={onClick}
      data-testid={`list-item__${dataTestIdBase}`}>
      {isHeader && <Divider />}
      <div className="list-item__content flex--center-space-between">
        <div className={leftContentClasses}>
          {imgSrc && <img alt="ALT ART" src={imgSrc} className="list-item__image" />}
          {icon?.left && <Icon src={icon.left} className="list-item__left-icon" />}
          <div className={leftTextClasses}>
            <TextContent text={text} textTag={textTag} />
            <SecondaryTextContent secondaryText={secondaryText} />
          </div>
        </div>

        <div className="list-item__right-container">
          <div className={rightTextClasses}>
            {linkTo ? <LinkContent /> : null}
            {!linkTo && rightText ? <TextContent text={rightText} textTag={rightTextTag} /> : null}

            <SecondaryTextContent secondaryText={rightSecondaryText} />
          </div>
          {icon?.right && !linkTo && (
            <Icon
              onClick={onRightIconClick}
              src={icon.right}
              className="list-item__right-icon"
              dataTestId={`list-item__right-icon--${dataTestIdBase}--${icon.right}`}
              color={icon?.colorRight || "primary-dark"}
            />
          )}
          {!isNil(toggle) && <ToggleInput checked={toggle.checked} onChange={toggle.onChange} />}
        </div>
      </div>
      {children}
    </Element>
  );
};

export default ListItem;
