import { IEntityStatus, IHeaderTitle } from "config/types";
import { endsWith, isEqual, join, last, map, some, split, startsWith } from "lodash-es";

import { ClientName, env, version } from ".";
import EncryptionKey from "../encryption/pubKey.pem?raw";
import EncryptionKeyStaging from "../encryption/pubKey.staging.pem?raw";
import EncryptionKeyLocal from "../encryption/pubKey.local.pem?raw";

// Helper Methods

const isLocal = (url = window.location.origin, environment = env) => {
  if (!startsWith(url, "http")) return false;

  const parsedUrl = new URL(url);
  const { hostname } = parsedUrl;

  const isDevEnvironment = isEqual(environment, "development");
  const conditions = [isEqual(hostname, "localhost"), endsWith(hostname, ".test")];
  return some(conditions) && isDevEnvironment;
};

const localPath = () => {
  const { location } = window;
  const { hostname, protocol } = location;
  const [first, ...rest] = split(hostname, ".");

  const isLocalhost = first === "localhost" && hostname === first;
  const formattedUrl = `${protocol}//${join(["api", ...rest], ".")}`;
  const domainExtension = last(rest);

  const isTestExtension = isEqual(domainExtension, "test");
  const extensionUrl = isTestExtension ? formattedUrl : "";

  return isLocalhost ? "http://localhost:3000" : extensionUrl;
};

const createUrl = (protocol: string, origin: string) => `${protocol}//${origin}`;
const hostnameIterator = (value: string) => (isEqual(value, ClientName) ? "backend" : value);

const generatePlatformUrl = () => {
  const local = isLocal();
  if (local) return localPath();

  const { location } = window;
  const { host, protocol } = location;
  const [currentPath, ...rest] = split(host, ".");
  const pathnameMap = map([currentPath, ...rest], hostnameIterator);
  const origin = join(pathnameMap, ".");
  const platformUrl = createUrl(protocol, origin);
  return platformUrl;
};

const generateFallbackUrl = () => {
  const local = isLocal();
  if (local) return "";

  const defaultFallbackPath = "develop";

  const { location } = window;
  const { hostname, protocol } = location;
  const [, ...rest] = split(hostname, ".");
  const pathnameMap = map([defaultFallbackPath, ...rest], hostnameIterator);
  const fallbackOrigin = join(pathnameMap, ".");
  const fallbackUrl = createUrl(protocol, fallbackOrigin);
  return fallbackUrl;
};

const getClientFingerprint = () => {
  return `web_client-${env}-${version}`;
};

// End of Helper Methods

export const CLIENT_NAME = "web" as const;
export const REACT_APP_ENV = "development" as const;
export const OPENSSL_ENCRYPTION_KEY = EncryptionKey;
export const OPENSSL_ENCRYPTION_KEY_LOCAL = EncryptionKeyLocal;
export const OPENSSL_ENCRYPTION_KEY_STAGING = EncryptionKeyStaging;

export const REACT_APP_AUTH_STORAGE_KEY = "authentication" as const;
export const REACT_APP_ALLOW_TOKEN_REFRESH = true as const;
export const REACT_APP_OAUTH_CLIENT_ID = "" as const;
export const REACT_APP_OAUTH_REDIRECT_URI = "urn:ietf:wg:oauth:2.0:oob" as const;
export const REACT_APP_PLATFORM_API_URL = generatePlatformUrl;
export const REACT_APP_TOKEN_SILENT_REFRESH_MINUTES = 10 as const;
export const REACT_APP_IDLE_LOCKOUT_MINUTES = 10 as const;
export const REACT_APP_INACTIVITY_WARNING_COUNTDOWN_SECONDS = 60 as const;
export const REACT_APP_PLATFORM_API_FALLBACK_URL = generateFallbackUrl;
export const REACT_APP_PLATFORM_API_HEALTH_ENDPOINT = "/health" as const;
export const REACT_APP_STRIPE_API_KEY = "" as const;
export const REACT_APP_BRANCH_API_KEY = "" as const;
export const REACT_APP_ZENDESK_KEY = "" as const;
export const REACT_APP_JOURNEY_LOCAL_SYNC = true as const;
export const REACT_APP_X_CLIENT_FINGERPRINT = getClientFingerprint;
export const REACT_APP_DEBUG_MODE = false as const;
export const REACT_APP_ADMIN_AUTH_TOKEN = "" as const;
export const REACT_APP_GTM_KEY = "" as const;
export const REACT_APP_SHOW_JOURNEY_BANNER = false as const;
export const REACT_APP_MEDIA_ALPHA_INELIGIBLE_AGE_ID = "" as const;
export const REACT_APP_MEDIA_ALPHA_INELIGIBLE_STATE_ID = "" as const;

export const defaultOAuthSessionStorageKey = "OAuthClient";

export const defaultVersion = "0.0.1" as const;

export const defaultDevToolsConfig = {
  development: { trace: true },
  production: false,
};

export const defaultPageTitleOptions: IHeaderTitle = {
  baseTitle: process.env.REACT_APP_BASE_TITLE || "Wysh",
  baseTitleSeparator: process.env.REACT_APP_BASE_TITLE_SEPARATOR || "-",
  title: "",
};

export const defaultBatchedActions: string[] = [
  "authentication:authenticating",
  "user:fetchCurrentUser",
  "authentication:authenticationPassed",
];

export const defaultEntityStatus: IEntityStatus = {
  loading: false,
  loaded: false,
};
