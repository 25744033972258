import "./SelectInput.scss";

import classNames from "classnames";
import Icon from "components/Icon/Icon";
import { ISelectProps } from "components/SelectInput/SelectInput";
import Text from "components/Text/Text";
import { kebabCase } from "lodash-es";
import { FC } from "react";

const NativeSelect: FC<ISelectProps> = props => {
  const { id, label, className, dataTestId, options, onChange, prompt, value } = props;

  const classes = classNames(className, "native-select-input", {
    "native-select-input--empty": value === "",
  });

  if (!options.length) {
    return null;
  }

  return (
    <>
      <div className="native-select-input__wrapper">
        {prompt ? (
          <div className="native-select__prompt">
            <Text tag="l2" text={prompt} />
          </div>
        ) : null}
        <select
          className={classes}
          name={id}
          id={id}
          onChange={onChange}
          data-testid={dataTestId || `select__input-${kebabCase(label)}`}
          defaultValue={value || ""}>
          <option value="" disabled>
            {label}
          </option>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <Icon className="native-select-input__icon" src="CaratDown" size="xs" />
      </div>
    </>
  );
};

export default NativeSelect;
