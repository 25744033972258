import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { IFinanceScheduledTransfer } from "config/types/financeCustomer.types";
import { keyBy } from "lodash-es";
import { fetchFinanceScheduledTransfers } from "store/actions";
import { getVerifiedInitialData } from "store/actions/appLoad";
import { logout } from "store/actions/authentication";

interface IScheduledTransfersState {
  ids: IFinanceScheduledTransfer["id"][];
  byId: Record<IFinanceScheduledTransfer["id"], IFinanceScheduledTransfer>;
}

/* ------------    SCHEDULED_TRANSFERS STATE     ------------------ */
const defaultState: IScheduledTransfersState = {
  ids: [],
  byId: {},
};

/* ------------    MATCHERS     ------------------ */
const isScheduledTransfersFulfilled = isAnyOf(
  getVerifiedInitialData.fulfilled,
  fetchFinanceScheduledTransfers.fulfilled
);

/* ------------    REDUCER     ------------------ */
const scheduledTransfers = createReducer(defaultState, builder => {
  builder
    .addCase(logout, () => defaultState)
    .addMatcher(isScheduledTransfersFulfilled, (state, action) => {
      const financeCustomer = action.payload.me.financeCustomer;
      const financeAccounts = financeCustomer?.accounts || [];
      const financeScheduledTransfers = financeAccounts.flatMap(account =>
        account.scheduledTransfers.map(transfer => transfer)
      );

      state.ids = financeScheduledTransfers.map(transfer => transfer.id);
      state.byId = keyBy(financeScheduledTransfers, "id");
    });
});

export default scheduledTransfers;
