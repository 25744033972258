import { IProfile } from "config";
import { DateTime } from "luxon";

export const formatBirthDate = (value?: IProfile["birthdate"]) => {
  if (!value) {
    return "";
  }

  const dateTime = DateTime.fromISO(value);

  return dateTime.isValid ? dateTime.toFormat("MMM dd, yyyy") : value;
};

export const formatFullName = ({
  firstName,
  lastName,
  middleName,
  suffix,
}: {
  firstName: IProfile["firstName"];
  lastName: IProfile["lastName"];
  middleName?: IProfile["middleName"];
  suffix?: IProfile["suffix"];
}) => {
  return `${firstName} ${middleName || ""} ${lastName} ${suffix || ""}`;
};

export const formatGender = (value?: IProfile["gender"]) => {
  if (!value) {
    return "";
  }

  return value === "M" ? "Male" : "Female";
};
