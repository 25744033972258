import classNames from "classnames";
import { IDynamicForm } from "config";
import { ReactNode, useCallback, useState } from "react";
import { useImmersiveMode, usePolling } from "modules/hooks";
import { createPortal } from "react-dom";

interface INavigationWrapperProps {
  leadingNavigationButton: IDynamicForm["leadingNavigationButton"];
  hasBackNav: boolean;
  hasNextNav: boolean;
  renderBack(hasPortal: boolean): ReactNode;
  renderNext(): ReactNode;
}

export function NavigationSectionWrapper(props: INavigationWrapperProps) {
  const { hasBackNav, hasNextNav, leadingNavigationButton, renderBack, renderNext } = props;

  const [headerPortalEl, setHeaderPortalEl] = useState<HTMLElement | null>(null);
  const isImmersiveMode = useImmersiveMode();

  const findHeaderPortalEl = useCallback(() => {
    if (!isImmersiveMode) {
      // If we shouldn't be using a portal, return true to stop polling
      return Promise.resolve(true);
    }

    const portalEl = document.getElementById("header__portal");

    if (!portalEl) {
      return Promise.resolve(false);
    }

    setHeaderPortalEl(portalEl);

    return Promise.resolve(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const status = usePolling(findHeaderPortalEl, 5, 50);
  const didNotFindPortal = (status === "done" && !headerPortalEl) || status === "timed-out";
  const hasPortal = isImmersiveMode && hasBackNav && !didNotFindPortal && !!headerPortalEl;

  return (
    <div
      className={classNames("navigation-section", {
        "leading-navigation-buttons": !!leadingNavigationButton,
        "navigation-section--portal": hasPortal,
        "space-navigation-links": hasNextNav,
      })}>
      {hasPortal && headerPortalEl
        ? createPortal(renderBack(hasPortal), headerPortalEl)
        : renderBack(hasPortal)}
      {renderNext()}
    </div>
  );
}
