import "./CoverageEditorPremiumCalculation.scss";

import classNames from "classnames";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { ClassName } from "config";
import { useTranslation } from "modules";

interface ICoverageEditorPremiumCalculation {
  className?: ClassName;
  onClick(): void;
}

export function CoverageEditorPremiumCalculation(props: ICoverageEditorPremiumCalculation) {
  const { className, onClick } = props;

  const { t } = useTranslation();

  return (
    <div className={classNames("coverage-editor-premium-calculation", className)} onClick={onClick}>
      <Icon src="info" size="sm" color="cta" />
      <Text
        tag="l6-label"
        text={t("coverage.editor.info.calculation", "Learn how we calculate monthly premiums")}
      />
    </div>
  );
}
