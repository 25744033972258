import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { AddWyshFlowType, BackWyshDetailType, WyshBuilderFlowType } from "config/types";

import {
  SET_ADD_WYSH_FLOW_TYPE,
  SET_BACK_WYSH_DETAIL_TYPE,
  SET_WYSHES_ADDED,
  SET_WYSHES_BUILDER_FLOW_TYPE,
} from "./../constants";

/* ------------       THUNKS      ------------------ */

export const startAddWyshFlow = createAsyncThunk(
  "wyshes/startAddWyshFlow",
  ({ type }: { type: AddWyshFlowType }, { dispatch }) => {
    dispatch(setAddWyshFlowType(type));
  }
);

/* ------------       ACTIONS      ------------------ */

export const setAddWyshFlowType = createAction(SET_ADD_WYSH_FLOW_TYPE, (type: AddWyshFlowType) => ({
  payload: { type },
}));

export const setBackWyshDetailType = createAction(
  SET_BACK_WYSH_DETAIL_TYPE,
  (type: BackWyshDetailType) => ({
    payload: { type },
  })
);

export const setWyshBuilderFlowType = createAction(
  SET_WYSHES_BUILDER_FLOW_TYPE,
  (type: WyshBuilderFlowType) => ({
    payload: { type },
  })
);

export const setWyshesAdded = createAction(SET_WYSHES_ADDED, (toggle: boolean) => ({
  payload: toggle,
}));
