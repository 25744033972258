import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { env } from "config/constants";
import { IAccessToken, IAuthentication } from "config/types";
import jwtDecode from "jwt-decode";
import { defaultTo, get, includes, isEmpty } from "lodash-es";
import {
  exchangeLoginToken,
  login,
  logout,
  refreshToken,
  setAccessToken,
  setRedirectPath,
  updateAccessToken,
  verifySession,
} from "store/actions/authentication";
import store, { session } from "store2";

interface IAuthIdentity {
  bypassVerification: boolean;
  token: string;
}

type AuthIdentity = Partial<IAuthentication> & IAuthIdentity;

const generateInitialState = (identity: IAuthIdentity, state: Partial<IAuthentication>) => {
  const isDevOrTestingEnv = includes(["development", "testing"], env);
  const isBypassingAuth = get(identity, "bypassVerification", false);
  const bypassToken = get(identity, "token", null);
  const authenticated = !isEmpty(bypassToken);

  const initialState = {
    authenticated,
    accessToken: bypassToken,
    sessionVerified: authenticated,
  };
  const mergedState = { ...initialState, ...state };
  return isBypassingAuth && isDevOrTestingEnv ? mergedState : {};
};

const defaultState: IAuthentication = {
  authenticated: null,
  authenticating: false,
  accessToken: null,
  redirectPath: null,
  refreshingToken: true,
  sessionVerified: false,
  authLevel: "unidentified",
};

const identity: AuthIdentity = defaultTo(session.get("AuthIdentity"), {});
const { token, bypassVerification, ...bypassState } = identity;
const generatedState = generateInitialState({ token, bypassVerification }, bypassState);

const initialState = { ...defaultState, ...generatedState };

const authentication = createReducer(initialState, builder => {
  builder
    .addCase(logout, () => {
      store.remove("token");

      return {
        ...defaultState,
        authenticated: false,
        refreshingToken: false,
      };
    })
    .addCase(refreshToken.pending, state => {
      state.refreshingToken = true;
    })
    .addCase(refreshToken.rejected, state => {
      state.accessToken = null;
      state.refreshingToken = false;
    })
    .addCase(setRedirectPath, (state, action) => {
      state.redirectPath = action.payload;
    })
    .addCase(updateAccessToken, (state, action) => {
      state.accessToken = { ...state.accessToken, ...action.payload } as IAccessToken;
    })
    .addCase(verifySession.fulfilled, (state, action) => {
      const { payload } = action;
      const sessionStatus = payload.status;

      state.authLevel = "verified";
      state.sessionVerified = sessionStatus === "verified" ? true : false;
    })
    .addMatcher(isAnyOf(exchangeLoginToken.fulfilled, login.fulfilled), (state, action) => {
      state.accessToken = action.payload;
      state.authenticated = true;
      state.authenticating = false;
      state.refreshingToken = false;
    })
    .addMatcher(isAnyOf(exchangeLoginToken.pending, login.pending), state => {
      state.accessToken = null;
      state.authenticating = true;
      state.authenticated = false;
      state.refreshingToken = false;
    })
    .addMatcher(isAnyOf(exchangeLoginToken.rejected, login.rejected), state => {
      state.accessToken = null;
      state.authenticated = false;
      state.authenticating = false;
      state.refreshingToken = false;
      state.sessionVerified = false;
      state.authLevel = "unidentified";
    })
    .addMatcher(isAnyOf(refreshToken.fulfilled, setAccessToken), (state, action) => {
      const jwtDecoded = jwtDecode(action.payload.accessToken.access_token);
      const authLevel = get(jwtDecoded, "user.auth_level");

      state.accessToken = action.payload.accessToken;
      state.authLevel = (authLevel as unknown) as IAuthentication["authLevel"];
      state.authenticated = authLevel !== "identified";
      state.sessionVerified = authLevel === "verified";

      if (action.type === refreshToken.fulfilled.type) {
        state.refreshingToken = false;
      } else if (action.type === setAccessToken.type) {
        state.authenticating = false;
      }
    });
});

export default authentication;
