import { EstimateFormSlug, IFlow } from "config";
import { findKey, includes, reduce } from "lodash-es";
import { DateTime } from "luxon";
import { RootState } from "store";
import replaceAll from "utils/replaceAll";

import { getDefaultAttrs } from "./defaults";

/* ------------------------    FLOW FORM QUESTIONS     ------------------------------ */
// Flow Form questions by `nextForm.questions.fields.key`,format values
const flowFormQuestionsMap: Record<string, (response: string) => Record<string, any>> = {
  "prequalification-dob": (response: string) => {
    return generateBirthDateAndAge(response, "prequalification-dob");
  },
  "application-dob": (response: string) => {
    return generateBirthDateAndAge(response, "application-dob");
  },
  [`${EstimateFormSlug}-dob`]: (response: string) => {
    return generateBirthDateAndAge(response, `${EstimateFormSlug}-dob`);
  },
};

const generateBirthDateAndAge = (date: string, slug: string) => {
  const formattedKey = formatKeyName(slug);

  let dateTime = DateTime.fromISO(date);
  const dateTimeFromInput = DateTime.fromFormat(date, "yyyy-MM-dd");
  const dateTimeFromFormat = DateTime.fromFormat(date, "MM/dd/yyyy");

  if (dateTimeFromInput.isValid) {
    dateTime = dateTimeFromInput;
  } else if (dateTimeFromFormat.isValid) {
    dateTime = dateTimeFromFormat;
  }

  if (!dateTime.isValid) {
    return {};
  }

  const dateTimeToIso = dateTime.toISO();

  const birthDateFormatted = DateTime.fromISO(dateTimeToIso).toFormat("yyyy-MM-dd");
  const currentAge = Math.floor(
    DateTime.local().diff(DateTime.fromISO(dateTimeToIso), "years").years
  );

  return { [formattedKey]: birthDateFormatted, currentAge };
};

const whitelistedFormKeys = [
  `${EstimateFormSlug}-gender`,
  `${EstimateFormSlug}-state`,
  `${EstimateFormSlug}-dob`,
  `${EstimateFormSlug}-health`,
  `${EstimateFormSlug}-coverage`,
  "application-gender",
  "application-dob",
  "marketing-drug",
  "marketing-felony",
  "marketing-income",
];

// Util to select whitelisted responses by `field.key` and format form responses
const formatFlowResponses = (responses: Record<string, any>) => {
  const formInputResponses = reduce(
    responses,
    (result, value, key) => {
      const flowQuestionFormatter = flowFormQuestionsMap[key];
      const formattedKey = formatKeyName(key);

      if (flowQuestionFormatter) {
        return { ...result, ...flowQuestionFormatter(value) };
      } else if (includes(whitelistedFormKeys, key)) {
        return { ...result, [formattedKey]: value };
      } else {
        return { ...result, [formattedKey]: "" };
      }
    },
    {}
  );

  return formInputResponses;
};

const formatKeyName = (key: string) => replaceAll(key, "-", "");

/* ------------------------    EVENT DEFINITION - FLOW FORM LOADS    ------------------------------ */
// Trigger events when flow form questions are loaded successfully on `nextForm` action.type

export const flowFormStarted = (
  action: { [key: string]: { flowSession: unknown; flow: IFlow } },
  _: RootState,
  nextState: RootState
) => {
  const flowFormSlug = nextState.analyticsTracker.slug || "";
  const flowFormEventName = replaceAll(flowFormSlug, "-", "");
  const defaultAttrs = getDefaultAttrs(nextState);

  return {
    event: `${flowFormEventName}_form_started`,
    attribute: {
      ...defaultAttrs,
    },
  };
};

/* ------------------------    EVENT DEFINITION - FLOW FORM COMPLETED    ------------------------------ */
// Trigger events when a flow form session is completed (terminal) on `finishFlow` action.type
export const flowFormCompleted = (action: { [key: string]: any }, prevState: RootState) => {
  const flowFormSlug = prevState.analyticsTracker.slug || "";
  const flowFormEventName = replaceAll(flowFormSlug, "-", "");
  const defaultAttrs = getDefaultAttrs(prevState);

  const formatFormResponses = formatFlowResponses(prevState.analyticsTracker.responses);

  return {
    event: `${flowFormEventName}_form_completed`,
    attribute: {
      ...defaultAttrs,
      ...formatFormResponses,
    },
  };
};

/* ------------------------    EVENT DEFINITION - FLOW FORM FIELD SET    ------------------------------ */
// Trigger event when a flow form input value is set on `FLOW_FIELD_SET` action.type
export const flowFormFieldSet = (
  action: { [key: string]: Record<string, any> },
  prevState: RootState
) => {
  const flowFormSlug = prevState.analyticsTracker.slug || "";
  const flowFormEventName = replaceAll(flowFormSlug, "-", "");
  const defaultAttrs = getDefaultAttrs(prevState);
  const inputResponse = formatFlowResponses(action.payload);
  const inputKey = formatKeyName(findKey(action.payload, () => true) || "");

  return {
    event: `${flowFormEventName}_form_field_set`,
    attribute: {
      ...defaultAttrs,
    },
    field: {
      ...inputResponse,
      currentKey: inputKey,
    },
  };
};

/* ------------------------    EVENT DEFINITION - FLOW FORM VALIDATION ERROR    ------------------------------ */
// Trigger event when a flow form input validation error occurs on `FLOW_VALIDATION_ERROR` action.type
export const flowFormValidationError = (
  action: { [key: string]: Record<string, any> },
  prevState: RootState
) => {
  const flowFormSlug = prevState.analyticsTracker.slug || "";
  const flowFormEventName = replaceAll(flowFormSlug, "-", "");
  const defaultAttrs = getDefaultAttrs(prevState);
  const inputResponse = formatFlowResponses(action.payload);

  return {
    event: `${flowFormEventName}_form_validationerror`,
    attribute: {
      ...defaultAttrs,
    },
    field: {
      ...inputResponse,
    },
  };
};
