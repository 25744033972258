import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { IDisclosure, IDisclosureWithBody } from "config";
import { logout } from "store/actions/authentication";
import { getDisclosure, getDisclosureWithBody } from "store/actions/disclosure";

/* ------------    STATE     ------------------ */
const defaultState: (IDisclosure | IDisclosureWithBody)[] = [];

/* ------------    MATCHERS     ------------------ */
const getDisclosuresMatcher = isAnyOf(getDisclosure.fulfilled, getDisclosureWithBody.fulfilled);

/* ------------    REDUCER     ------------------ */
const disclosures = createReducer(defaultState, builder => {
  builder
    .addCase(logout, () => defaultState)
    .addMatcher(getDisclosuresMatcher, (state, action) => {
      const disclosure = action.payload;

      state.push(disclosure);
    });
});

export default disclosures;
