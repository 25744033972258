import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchTriviaQuery } from "graphql/queries/trivia";
import { getAppError } from "utils/error";

export const fetchTrivia = createAsyncThunk("trivia/fetch", async (_, { rejectWithValue }) => {
  try {
    const res = await fetchTriviaQuery();

    return res.data.triviaQuestions;
  } catch (err) {
    const errorCode = getAppError(err);

    return rejectWithValue(errorCode);
  }
});
