import { gql } from "config/graphql";
import { BillingFrequency, QuotesStatus, TermUnit } from "config/types";
import { getApolloClient } from "store/apollo";

export interface IUpdateQuoteInput {
  quoteId: string;
  termUnit?: TermUnit;
  termDuration?: number;
  amount?: number;
  status?: QuotesStatus;
  billingFrequency?: BillingFrequency;
}

interface IUpdateQuoteResponse {
  data: {
    response: {
      success: boolean;
    };
  };
}

export const updateQuoteMutation = (quote: IUpdateQuoteInput): Promise<IUpdateQuoteResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation updateQuote($input: UpdateQuoteInput!) {
      updateQuote(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: { input: quote },
    mutation,
  });
};
