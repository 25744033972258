import DateLabel from "components/DateLabel/DateLabel";
import Text from "components/Text/Text";
import { ISO8601Date } from "config";
import { useTranslation } from "modules";
import { FC } from "react";

interface IProps {
  expDate: ISO8601Date;
  header?: string;
}

export const HomeCardQuote: FC<IProps> = ({ header, expDate }) => {
  const { t } = useTranslation();

  const headerText = header || t("product.quote.message", "Your Quote is Ready");
  const quoteExpText = t("product.quote.expiration", "Quote Expires");

  return (
    <div>
      <Text className="color-primary-dark" tag="h4-header" text={headerText} />
      <div className="home-product-quote-date">
        <Text className="color-secondary-text" tag="p4-paragraph" text={quoteExpText} />
        <DateLabel date={expDate} textTag="l5-label" />
      </div>
    </div>
  );
};
