import "./UnlinkBankWyshErrorModal.scss";

import Button from "components/Button/Button";
import List from "components/List/List";
import WishesListItem from "components/WishesListItem/WishesListItem";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { IWyshAllocation } from "config/types";
import { push } from "connected-react-router";
import { find, get, map } from "lodash-es";
import { Layout, Link, useTranslation } from "modules";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { closeModal } from "store/actions/modal";
import { getWyshes } from "store/actions/wyshes";
import formatCommas from "utils/formatCommas";

const UnlinkBankWyshErrorModal: FC<IModalProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const closeModalWrapper = () => dispatch(closeModal());

  const modalMetadata = useSelector((state: RootState) => state.modal.data);
  const wyshes = useSelector((state: RootState) => state.wyshes.wyshesItems);

  const wyshAllocations = get(modalMetadata, "allocations", "") as IWyshAllocation[];

  const unlinkBankOnClick = async () => {
    dispatch(push("/wyshes"));
  };

  const content = {
    header: t(
      "settings.linked_accounts.unlink_modal_wyshes.title",
      "Hmm, we can’t unlink your bank just yet..."
    ),
    subheader: t(
      "settings.linked_accounts.unlink_modal_wyshes.body",
      "You currently have a Wysh tied to this bank. To unlink, turn off monitoring & try again. "
    ),
    onClose: closeModalWrapper,
    icon: "BlobBank",
  };

  useEffect(() => {
    dispatch(getWyshes());
  }, [dispatch]);

  return (
    <Layout as="ModalLayout" content={content}>
      <List className="unlink-bank__wyshes-list">
        {map(wyshAllocations, allocation => {
          const wysh = find(wyshes, wysh => wysh.id === allocation.wysh?.id);

          const wyshCoveredAmount = t("dollar.amount", "$%<amount>s", {
            amount: formatCommas(allocation.amountCovered),
          });
          const wyshAmount = t("dollar.amount", "$%<amount>s", {
            amount: formatCommas(allocation.amount),
          });
          const showCoveredAmount = allocation.amount && allocation.percentCovered < 100;
          const coveredAmount = showCoveredAmount
            ? `${wyshCoveredAmount}/${wyshAmount}`
            : wyshAmount;

          return (
            <Link to={`/wyshes/${allocation.id}/details`} key={allocation.id}>
              <WishesListItem
                className="wysh-list__item"
                text={allocation.name}
                secondaryText={coveredAmount}
                rightText={t("percentage.amount", "%<percentage>s%", {
                  percentage: allocation.percentCovered,
                })}
                icon={wysh?.icon}
                color={wysh?.wyshCategory.color}
              />
            </Link>
          );
        })}
      </List>
      <div className="modal__btns">
        <Button
          text={t("settings.linked_accounts.unlink_modal_wyshes.cta", "GO TO WYSHES")}
          onClick={unlinkBankOnClick}
        />
        <Button
          text={t("settings.linked_accounts.unlink_modal_wyshes.cancel", "NEVERMIND")}
          subtype="text-dark"
          onClick={closeModalWrapper}
        />
      </div>
    </Layout>
  );
};

export default UnlinkBankWyshErrorModal;
