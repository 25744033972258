import { createAsyncThunk } from "@reduxjs/toolkit";
import { sha256 as SHA256 } from "sha.js";
import { local } from "store2";
import { v4 as uuiv4 } from "uuid";
import { delay } from "utils/delay";

export const setDeviceFingerprint = createAsyncThunk(
  "setDeviceFingerprint",
  async (_, { rejectWithValue }) => {
    try {
      const existingFingerprint = local.get("deviceFingerprint");
      if (existingFingerprint) {
        return existingFingerprint;
      }

      // Temporary fingerprint strategy
      // Fingerprint will eventually be provided by Lovation
      await delay(); // mock delay from call to Lovation
      const fingerprint = new SHA256().update(uuiv4()).digest("base64"); // mock fingerprint

      local.set("deviceFingerprint", fingerprint);
      return fingerprint;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
