import { gql } from "config/graphql";

export const INVOICE_FIELDS = gql`
  fragment InvoiceFields on Invoice {
    amount
    balance
    dueAt
    id
    issuedAt
    pastDue
    status
  }
`;
