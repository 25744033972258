import "./TextLink.scss";

import classNames from "classnames";
import Text, { TextType, textTypes } from "components/Text/Text";
import { ClassName } from "config";
import { FC, HTMLAttributes } from "react";
import { Link } from "react-router-dom";

enum TextLinkSizes {
  sm = "l5-label",
  md = "l4-label",
  lg = "l3-label",
}

interface IProps extends Omit<HTMLAttributes<HTMLButtonElement | HTMLAnchorElement>, "className"> {
  text: string;
  className?: ClassName;
  isDisabled?: boolean;
  size?: keyof typeof TextLinkSizes;
  tag?: keyof typeof textTypes;
  to?: string;
}

export const TextLink: FC<IProps> = props => {
  const { className, isDisabled, size = "sm", tag, text, to = "", ...rest } = props;

  const classes = classNames("text-link", { "text-link--disabled": isDisabled }, className);
  const textLinkTag: TextType = tag || TextLinkSizes[size];

  if (!to) {
    return (
      <button className={classes} type="button" {...rest}>
        <Text tag={textLinkTag} className="text-link__text">
          {text}
        </Text>
      </button>
    );
  }

  return (
    <Link className={classes} to={to} {...rest}>
      <Text tag={textLinkTag} className="text-link__text">
        {text}
      </Text>
    </Link>
  );
};
