import { createReducer } from "@reduxjs/toolkit";
import { IPayoutDetails, IPlan, IPolicy, IProductDetail } from "config/types";
import { getVerifiedInitialData } from "store/actions/appLoad";
import { logout } from "store/actions/authentication";
import { getDashboardData } from "store/actions/dashboard";
import { getCurrentUserPastDueInvoices } from "store/actions/invoice";
import { getCurrentUserPolicy } from "store/actions/policy";
import { toFixed } from "utils/toFixed";

export interface IPolicyState extends IPolicy {
  activePlan: IPlan;
  label: string;
  productDetail: IProductDetail;
  payoutDetails: IPayoutDetails;
}

const defaultState: IPolicyState = {
  id: "",
  label: "",
  status: "",
  amount: 0,
  termDuration: 0,
  termUnit: "month",
  insuredsAgeAtTerm: 0,
  premiums: [],
  product: undefined,
  effectiveAt: "",
  pausedAt: "",
  terminatesAt: "",
  lapsesAt: "",
  effectiveUntil: "",
  documents: [],
  activePlan: {
    id: "",
    amount: 0,
    billingFrequency: "monthly",
    nextPayment: {
      date: "",
      estimated: false,
      invoiceId: "",
    },
  },
  classification: {
    id: "",
    name: "",
    gender: "",
  },
  productDetail: {
    id: "",
    name: "",
    slug: "",
    status: "",
    description: "",
  },
  payoutDetails: {
    autoPedestrian: 0,
    generalAccident: 0,
    publicTransportation: 0,
  },
};

const policy = createReducer(defaultState, {
  [getDashboardData.fulfilled.type]: (state, action) => {
    const accountLabel = action.payload?.label || "";
    const policyPayload = action.payload?.activePolicy || {};
    const activePlanPayload = policyPayload?.product?.activePlan || state.activePlan;
    const planAmount = toFixed(activePlanPayload?.amount || state.activePlan.amount);
    const activePlan = { ...state.activePlan, ...activePlanPayload, amount: planAmount };
    const productDetail = policyPayload?.productV2 || state.productDetail;
    const payoutDetails = policyPayload?.payoutDetails || state.payoutDetails;

    return {
      ...state,
      ...policyPayload,
      activePlan,
      label: accountLabel,
      productDetail,
      payoutDetails,
    };
  },
  [getCurrentUserPolicy.fulfilled.type]: (state, action) => {
    const policyPayload = action.payload.activePolicy || {};
    const defaultPlan = policyPayload?.product?.activePlan || state.activePlan;
    const planAmount = toFixed(defaultPlan?.amount || 0);
    const activePlan = { ...defaultPlan, amount: planAmount };
    const productDetail = policyPayload?.productV2 || state.productDetail;
    const payoutDetails = policyPayload?.payoutDetails || state.payoutDetails;

    return { ...state, ...policyPayload, activePlan, productDetail, payoutDetails };
  },
  [getCurrentUserPastDueInvoices.fulfilled.type]: (state, action) => {
    const policyPayload = action.payload.activePolicy || {};

    return {
      ...state,
      lapsesAt: policyPayload.lapsesAt,
    };
  },
  [getVerifiedInitialData.fulfilled.type]: (state, action) => {
    const account = action.payload?.account;
    const accountLabel = account?.label || "";
    const policyPayload = account?.activePolicy || {};
    const defaultPlan = policyPayload?.product?.activePlan || state.activePlan;
    const planAmount = toFixed(defaultPlan?.amount || 0);
    const activePlan = { ...defaultPlan, amount: planAmount };
    const productDetail = policyPayload?.productV2 || state.productDetail;
    const payoutDetails = policyPayload?.payoutDetails || state.payoutDetails;

    return {
      ...state,
      ...policyPayload,
      activePlan,
      label: accountLabel,
      productDetail,
      payoutDetails,
    };
  },
  [logout.type]: () => defaultState,
});

export default policy;
