import FormError from "components/FormError/FormError";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { BeneficiaryResponsibility, BusinessSlugTypes } from "config/types";
import { push } from "connected-react-router";
import { defaultTo, size } from "lodash-es";
import { Layout, useTranslation } from "modules";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { destroyBeneficiary } from "store/actions/beneficiaries";
import { closeModal } from "store/actions/modal";
import { selectBeneficiariesByLineOfBusiness, useParamSelector } from "store/selectors";
import { getThunkError } from "utils/error";

const RemoveBeneficiaryModal: FC<IModalProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const modalData = useSelector((state: RootState) => state.modal.data);

  const beneficiaryId = modalData.beneficiaryId as string;
  const responsibility = modalData.responsibility as BeneficiaryResponsibility;
  const lineOfBusiness = modalData.lineOfBusiness as BusinessSlugTypes;

  const { beneficiaries } = useParamSelector(selectBeneficiariesByLineOfBusiness, lineOfBusiness);

  const removeBeneficiary = async () => {
    try {
      setLoading(true);

      const response = await dispatch(destroyBeneficiary({ beneficiaryId, responsibility }));

      const error = getThunkError(response);
      if (error) {
        throw error;
      }

      const numberOfBeneficiaries = size(beneficiaries);
      if (responsibility === "backup") {
        dispatch(push(`/beneficiary/${lineOfBusiness}`));
      } else if (numberOfBeneficiaries - 1 > 0) {
        dispatch(push(`/beneficiary/allocation/${lineOfBusiness}`));
      } else {
        dispatch(push("/"));
      }

      closeModalWrapper();
    } catch (error) {
      setError(defaultTo(error.fullMessage, error.code));
    } finally {
      setLoading(false);
    }
  };

  const closeModalWrapper = () => {
    dispatch(closeModal());
  };

  const onClickContinue = async () => {
    try {
      await removeBeneficiary();
    } catch (error) {
      setError(defaultTo(error.fullMessage, error.code));
    }
  };

  const content = {
    header: t("beneficiary.modal.remove.header", "Sure you wanna remove your beneficiary?"),
    subheader: t(
      "beneficiary.modal.remove.body",
      "If you delete them, all of their info will be lost."
    ),
    onClose: closeModalWrapper,
    button: [
      {
        subType: "primary",
        text: t("beneficiary.modal.remove.cta.primary", "THAT'S FINE, REMOVE"),
        onClick: onClickContinue,
        disabled: loading,
        isLoading: loading,
      },
      {
        subType: "text-dark",
        text: t("beneficiary.modal.remove.cta.secondary", "NEVERMIND"),
        onClick: closeModalWrapper,
      },
    ],
  };

  return (
    <Layout as="ModalLayout" content={content}>
      <FormError error={error} />
    </Layout>
  );
};

export default RemoveBeneficiaryModal;
