import TextInput from "components/TextInput/TextInput";
import { ClassName, ValueOrArray } from "config/types";
import { ComponentProps, FC } from "react";

type ITextInputProps = Pick<
  ComponentProps<typeof TextInput>,
  "max" | "min" | "onBlur" | "onChange" | "onFocus"
>;

interface IProps extends ITextInputProps {
  id: string;
  className?: ClassName;
  dataTestId?: string;
  disableAutoComplete?: boolean;
  disableFloatingLabel?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: ValueOrArray<string>;
  iconLeft?: string;
  label?: string;
  placeholder?: string;
  precision?: number;
  unit?: string;
  value?: number | string;
}

const NumberInput: FC<IProps> = props => {
  const { disableFloatingLabel = true, onChange, precision = 0, ...rest } = props;

  const handleOnChange: ITextInputProps["onChange"] = event => {
    let cleanedValue = event.currentTarget.value.replace(/[^0-9.]/gi, "");

    const [integer, decimal] = cleanedValue.split(".");

    if (precision > 0 && decimal) {
      cleanedValue = `${integer}.${decimal.slice(0, precision)}`;
    } else {
      cleanedValue = integer;
    }

    const changedEvent = {
      ...event,
      target: { ...event.target, id: event.target.id, value: cleanedValue },
    };

    onChange && onChange(changedEvent);
  };

  return (
    <TextInput
      {...rest}
      disableFloatingLabel={disableFloatingLabel}
      inputMode={precision > 0 ? "decimal" : "numeric"}
      onChange={handleOnChange}
      // Leaving input as text for inputs without precision to ensure legacy compatibility
      type={precision > 0 ? "number" : "text"}
    />
  );
};

export default NumberInput;
