import { goBack } from "connected-react-router";
import { useJourney } from "modules";
import { fetchPreviousDynamicForm } from "store/actions/flow";
import { useAppDispatch, useAppSelector } from "store/index";
import { selectPreviousDynamicFormInputPayload } from "store/selectors";

import { TApiButtonCommandFn } from ".";

export const usePop = () => {
  const dispatch = useAppDispatch();
  const isFirstForm = useAppSelector(state => state.formFlow.formIndex <= 1);
  const previousFormInputPayload = useAppSelector(selectPreviousDynamicFormInputPayload);
  const { previousStepCallback } = useJourney();

  const popCommand: TApiButtonCommandFn = async () => {
    if (!isFirstForm) {
      /*  TODO - implement 'previous' direction on fetchDynamicForm when API ready
               - remove setFormFlowIndex/fetchPreviousDynamicForm placeholder implementation
      */
      return await dispatch(fetchPreviousDynamicForm(previousFormInputPayload)).unwrap();
    } else {
      const goBackAction = () => dispatch(goBack());
      const callback = previousStepCallback(goBackAction);
      callback();
    }
  };

  return popCommand;
};
