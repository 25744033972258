/*
..................
~~~ INTERFACES ~~~
..................
*/

export type IneligibleCoverageReasonsType =
  | "age_of_insured"
  | "state_of_residence"
  | "criminal_history";

export interface IAccountStatus {
  capabilities: Record<"billing" | "termLifeExtension", boolean>;
  caseId: string;
  eligibleForCoverage: boolean;
  eligibleForFinanceApplication: boolean;
  eligibleForMonitoring: boolean;
  eligibleForUnderwriting: boolean;
  fraudulentDevice: boolean;
  locked: LockTypeStrings | "";
  underwritingRequired: boolean;
}

/*
..................
~~~~~ ENUMS ~~~~~
..................
*/

export enum ILockType {
  SECURITY_LOCK,
  PASSWORD_ATTEMPTS,
  DEVICE_LOCKED,
  SYSTEM_ERROR,
  DECOMMISSIONED_ACCOUNT,
  BLOCKED,
}

/*
..................
~~~~~ TYPES ~~~~~
..................
*/

export type LockTypeStrings = keyof typeof ILockType;
