import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const getQuoteId = (state: RootState) => state.quote.id;
const getPendingPolicyId = (state: RootState) => state.pendingPolicy.id;

export const getHasPendingQuoteOrPolicy = createSelector(
  getQuoteId,
  getPendingPolicyId,
  (quoteId, pendingPolicyId) => !!quoteId || !!pendingPolicyId
);
