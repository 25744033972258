import "./InsuranceProfileReviewInfo.scss";

import classNames from "classnames";
import Text from "components/Text/Text";
import { ClassName } from "config";
import { useTranslation } from "modules";
import { ReactNode, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { getProfileReviewPersonalInfo } from "store/actions/profile";
import { selectInsuranceProfileReviewInfo } from "store/selectors/selectInsuranceProfileReviewInfo";

interface IBaseInsuranceProfileReviewInfo {
  children?: ReactNode;
}

interface IInsuranceProfileReviewInfo {
  className?: ClassName;
}

interface InsuranceProfileReviewInfoItem {
  label: string;
  value: string;
}

export function InsuranceProfileReviewInfo({ className }: IInsuranceProfileReviewInfo) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const profileReviewInfo = useSelector(selectInsuranceProfileReviewInfo);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getProfileReviewPersonalInfo()).unwrap();
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <div className={classNames("insurance-profile-review-info", className)}>
      <InsuranceProfileReviewInfoHeader>
        {t("uip.review.ppi.header", "Personal info")}
      </InsuranceProfileReviewInfoHeader>

      <InsuranceProfileReviewInfoItem
        label={t("uip.review.ppi.name.label", "Name")}
        value={profileReviewInfo.name}
      />
      <InsuranceProfileReviewInfoItem
        label={t("uip.review.ppi.birthdate.label", "Birthdate")}
        value={profileReviewInfo.birthDate}
      />
      <InsuranceProfileReviewInfoItem
        label={t("uip.review.ppi.phone.label", "Phone number")}
        value={profileReviewInfo.phoneNumber}
      />
      <InsuranceProfileReviewInfoItem
        label={t("uip.review.ppi.gender.label", "Gender")}
        value={profileReviewInfo.gender}
      />
      <InsuranceProfileReviewInfoItem
        label={t("uip.review.ppi.address.label", "Primary Residence")}
        value={profileReviewInfo.address}
      />
      <InsuranceProfileReviewInfoItem
        label={t("uip.review.ppi.ssn.label", "Taxpayer ID Number")}
        value={profileReviewInfo.ssn}
      />

      {profileReviewInfo.isFinanceCustomer && (
        <InsuranceProfileReviewInfoMessage>
          {t(
            "uip.review.edit.info.message",
            "To edit personal info, visit your profile in settings."
          )}
        </InsuranceProfileReviewInfoMessage>
      )}
    </div>
  );
}

function InsuranceProfileReviewInfoHeader({ children }: IBaseInsuranceProfileReviewInfo) {
  return (
    <Text className="insurance-profile-review-info__header" tag="l3">
      {children}
    </Text>
  );
}

function InsuranceProfileReviewInfoItem({ label, value }: InsuranceProfileReviewInfoItem) {
  return (
    <div
      className="insurance-profile-review-info__item insurance-profile-review-info-item"
      key={label}>
      <Text className="insurance-profile-review-info-item__label" tag="p5">
        {label}
      </Text>
      <Text className="insurance-profile-review-info-item__value" tag="l4">
        {value}
      </Text>
    </div>
  );
}

export function InsuranceProfileReviewInfoMessage({ children }: IBaseInsuranceProfileReviewInfo) {
  return (
    <Text className="insurance-profile-review-info__message" tag="p6">
      {children}
    </Text>
  );
}
