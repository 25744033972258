import { apolloClient } from "config/apollo";
import { gql } from "config/graphql";
import { IUser } from "config/types";
import { BENEFICIARY_FIELDS } from "graphql/fragments/beneficiary";

interface IBeneficiariesQueryResponse {
  me: Pick<IUser, "activeConstellations" | "financeCustomer">;
}

export const fetchBeneficiaries = () => {
  const query = gql`
    ${BENEFICIARY_FIELDS}
    query getBeneficiaries {
      me {
        activeConstellations {
          activeInsuranceMembership {
            activePolicy {
              beneficiaries {
                ...BeneficiaryFields
              }
            }
            beneficiaries {
              ...BeneficiaryFields
            }
          }
        }
        financeCustomer {
          beneficiaries {
            ...BeneficiaryFields
          }
        }
      }
    }
  `;

  return apolloClient.query<IBeneficiariesQueryResponse>({
    query,
  });
};
