import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { IFinanceCustomer } from "config/types";
import { getVerifiedInitialData } from "store/actions/appLoad";
import { logout } from "store/actions/authentication";
import {
  createAndInitiateFinanceCustomer,
  createFinanceCustomer,
  fetchFinanceCustomer,
  initiateFinanceVerification,
  predictiveDocumentVerification,
} from "store/actions/financeCustomer";
import { getProfileReviewPersonalInfo } from "store/actions/profile";

/* ------------    FINANCE_CUSTOMER STATE     ------------------ */
const defaultState: IFinanceCustomer = {
  accounts: [],
  createdAt: "",
  id: "",
  status: null,
};

/* ------------    MATCHERS     ------------------ */
const isFinanceCustomerFulfilled = isAnyOf(
  createAndInitiateFinanceCustomer.fulfilled,
  createFinanceCustomer.fulfilled,
  initiateFinanceVerification.fulfilled,
  predictiveDocumentVerification.fulfilled,
  fetchFinanceCustomer.fulfilled
);

/* ------------    REDUCER     ------------------ */
const financeCustomer = createReducer(defaultState, builder => {
  builder
    .addCase(getProfileReviewPersonalInfo.fulfilled, (state, action) => {
      const financeCustomer = action.payload.financeCustomer || defaultState;

      state.status = financeCustomer.status;
    })
    .addCase(getVerifiedInitialData.fulfilled, (state, action) => {
      const financeCustomer = action.payload.me.financeCustomer || defaultState;

      state.accounts = financeCustomer.accounts;
      state.id = financeCustomer.id;
      state.createdAt = financeCustomer.createdAt;
      state.status = financeCustomer.status;
    })
    .addCase(logout, () => defaultState)
    .addMatcher(isFinanceCustomerFulfilled, (state, action) => {
      const financeCustomer = action.payload || defaultState;

      state.accounts = financeCustomer.accounts;
      state.id = financeCustomer.id;
      state.createdAt = financeCustomer.createdAt;
      state.status = financeCustomer.status;
    });
});

export default financeCustomer;
