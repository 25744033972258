import Button from "components/Button/Button";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setTimer } from "store/actions/counter";
import { closeModal } from "store/actions/modal";
import { initiatePasswordReset } from "store/actions/user";
import { openChat } from "utils/customerSupport";

const ResetPasswordModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const timerName = "resetPassword";
  const resetPasswordTimer = useSelector((state: RootState) => state.counter.timer[timerName]);
  const email = useSelector((state: RootState) => state.modal.data?.email);
  const isPending = useSelector((state: RootState) => state.request.inFlight);
  const isTimerActive = resetPasswordTimer > 0;

  const closeModalWrapper = () => dispatch(closeModal());

  const handleResetPassword = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    dispatch(initiatePasswordReset({ email }));
    dispatch(setTimer(timerName, 60));
  };

  const onClickHelp = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    openChat();
    closeModalWrapper();
  };

  const resendLinkText = isTimerActive
    ? t("reset_password.link_sent.button", "RE-SEND LINK %<time>s SEC", {
        time: resetPasswordTimer,
      })
    : t("reset_password.link_sent.modal.primary_title", "RE-SEND EMAIL");

  const content = {
    header: t("reset_password.link_sent.modal.title", "Didn't get your link?"),
    subheader: t(
      "reset_password.link_sent.modal.body",
      "Sometimes it takes a second to hit your inbox. Other times it ends up chilling in your spam folder-so check there too."
    ),
    onClose: closeModalWrapper,
  };

  return (
    <Layout as="ModalLayout" className="reset-password__modal" content={content}>
      <Button
        text={resendLinkText}
        isLoading={isPending}
        disabled={isTimerActive || isPending}
        onClick={handleResetPassword}
        className="full-width mb-10"
      />
      <Button
        subtype="text-dark"
        text={t("reset_password.link_sent.modal.secondary_title", "GET HELP")}
        onClick={onClickHelp}
      />
    </Layout>
  );
};

export default ResetPasswordModal;
