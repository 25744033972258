import { createAsyncThunk } from "@reduxjs/toolkit";
import { IInvoice, IReinstateablePolicy, IUserAccount } from "config";
import {
  fetchCurrentUserInvoices,
  fetchCurrentUserLatestPayableInvoice,
} from "graphql/queries/invoice";
import { createReinstatementInvoiceMutation } from "graphql/mutations/invoice";
import { getActiveAccount } from "utils/account";
import { getAppError } from "utils/error";

/* ------------       THUNKS      ------------------ */

export const createReinstatementInvoice = createAsyncThunk(
  "offerings/createReinstatementInvoice",
  async (policyId: IReinstateablePolicy["id"], { rejectWithValue }) => {
    try {
      const { data } = await createReinstatementInvoiceMutation(policyId);

      return { success: data.createReinstatementInvoice.response.success };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const getCurrentUserLatestPayableInvoice = createAsyncThunk(
  "invoice/getCurrentUserInvoice",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await fetchCurrentUserLatestPayableInvoice();
      const account = getActiveAccount(data.me);

      return account?.latestPayableInvoice;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

interface IUserPastDueInvoicesMeta extends IUserAccount {
  invoicesProcessingAmount: number;
  invoicesPastDueBalance: number;
  pastDueInvoices: IInvoice[];
  processingInvoices: IInvoice[];
}

export const getCurrentUserPastDueInvoices = createAsyncThunk(
  "invoice/getCurrentUserPastDueInvoices",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await fetchCurrentUserInvoices();
      const account = getActiveAccount(data.me) as IUserPastDueInvoicesMeta;

      return {
        activePolicy: account?.activePolicy,
        invoicesProcessingAmount: account?.invoicesProcessingAmount,
        invoicesPastDueBalance: account?.invoicesPastDueBalance,
        pastDueInvoices: account?.pastDueInvoices || [],
        processingInvoices: account?.processingInvoices || [],
      };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
