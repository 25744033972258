import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const DYNAMIC_FORM_STEPS = ["insurance-profile", "aura-interview"];

const getActiveJourneySlug = (state: RootState) => state.journey.activeJourneys[0];
const getActiveStep = (state: RootState) => state.journey.steps.active;
const getCollection = (state: RootState) => state.journey.collection;
const getDynamicFormSlug = (state: RootState) => state.dynamicForm.slug;
const getShowProgress = (state: RootState) => state.journey.progress.show;
const getPercentOverride = (state: RootState) => state.journey.progress.percentOverride;

const getProgressStepKeys = createSelector(
  getActiveJourneySlug,
  getCollection,
  (activeJourneySlug, journeyCollection) =>
    journeyCollection[activeJourneySlug]?.progressStepKeys || []
);

const getProgressStep = createSelector(
  getActiveStep,
  getDynamicFormSlug,
  (activeStep, dynamicFormSlug) =>
    DYNAMIC_FORM_STEPS.includes(activeStep) ? dynamicFormSlug : activeStep
);

export const getJourneyProgress = createSelector(
  getProgressStepKeys,
  getProgressStep,
  getShowProgress,
  getPercentOverride,
  (progressStepKeys, progressStep, showProgress, percentOverride) => {
    const currentProgressIndex = progressStepKeys.indexOf(progressStep);
    const totalProgressSteps = progressStepKeys.length;
    const percentage =
      !!totalProgressSteps && currentProgressIndex > -1
        ? ((currentProgressIndex + 1) / totalProgressSteps) * 100
        : undefined;

    return {
      percentOverride,
      percentage,
      shouldDisplayProgress: showProgress && !!progressStepKeys.length,
    };
  }
);
