import c from "classnames";
import Checkbox from "components/Checkbox/Checkbox";
import Text from "components/Text/Text";
import { useContext } from "react";
import replaceWyshwareUri from "utils/replaceWyshwareUri";

import { DynamicFormContext } from "../DynamicFormContext";

import { IDynamicFormItemProps } from ".";

export function CheckboxInputItem({ item }: IDynamicFormItemProps<"inputCheckbox">) {
  const { form } = useContext(DynamicFormContext);

  const { content, key } = item;
  const { disabled, text } = content;
  const { value } = form.getFormInput(key);

  const classes = c("dynamic-form-item form-item--input-checkbox");

  return (
    <Checkbox
      className={classes}
      checked={value}
      dataTestId={key}
      disabled={disabled}
      id={key}
      label={
        <div>
          <Text
            className="form-item--input-checkbox-text"
            tag="p4"
            dangerouslySetInnerHTML={{
              __html: replaceWyshwareUri(text),
            }}
          />
        </div>
      }
      name={key}
      onChange={form.onChange}
      value={value}
    />
  );
}
