// This file will be to use in a route to override the current breadcrumb.
import { MetadataContext, RouteContext } from "config/init/providers/MetadataProvider";
import { get, has, isEmpty, isEqual, isFunction } from "lodash-es";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { RouteProps, useLocation, useRouteMatch } from "react-router";

export const useRouteRegister = (
  title: NonNullable<RouteContext["title"]>,
  routerProps: RouteProps
) => {
  const location = useLocation();
  const match = useRouteMatch(routerProps);
  const { pathname } = location;
  const computedTitle = isFunction(title) ? title({ match, location }) : title;
  const route = useMemo(() => ({ path: pathname, title: computedTitle }), [
    computedTitle,
    pathname,
  ]);

  const { routes, registerRoute } = useContext(MetadataContext);

  const register = useCallback(
    route => {
      const routeTitle = get(routes, [route.path, "title"]);
      if (
        isFunction(registerRoute) &&
        (!has(routes, route.path) || (!isEqual(routeTitle, route.title) && isEmpty(routeTitle)))
      ) {
        registerRoute(route);
      }
    },
    [registerRoute, routes]
  );

  useEffect(() => {
    register(route);
  }, [register, route]);
  // store these values with the use

  return "breadcrumbs";
};
