import { SmartyAddressKey } from "config";
import { useState, useEffect } from "react";
import * as SmartySDK from "smartystreets-javascript-sdk";
import * as sdkUtils from "smartystreets-javascript-sdk-utils";

// Defining types for the SmartyCore clients.

type StreetClient<T = SmartySDK.usStreet.Lookup> = SmartySDK.core.Client<
  T | SmartySDK.core.Batch<T>,
  SmartySDK.core.Batch<T>
>;
type USStreetClient = StreetClient<SmartySDK.usStreet.Lookup>;

type AutoCompleteClient<T = SmartySDK.usAutocompletePro.Lookup> = SmartySDK.core.Client<T, T>;

/**
 * A custom hook to initialize SmartyCore clients.
 * @returns An object containing autoCompleteClient and usStreetClient.
 */
export const useSmartyCore = () => {
  // Using the SmartyStreets website key from the config.
  const websiteKey = SmartyAddressKey;

  // State variables to hold the initialized clients.
  const [autoCompleteClient, setAutoCompleteClient] = useState<AutoCompleteClient | null>(null);
  const [usStreetClient, setUsStreetClient] = useState<USStreetClient | null>(null);

  // Effect to initialize the clients when the component mounts.
  useEffect(() => {
    // Get the SmartyCore instance.
    const SmartyCore = SmartySDK.core;

    // Create shared credentials using the website key.
    const smartySharedCredentials = new SmartyCore.SharedCredentials(websiteKey);

    // Build AutoComplete client with the appropriate licenses.
    const autoCompleteClientBuilder = new SmartyCore.ClientBuilder(
      smartySharedCredentials
    ).withLicenses(["us-autocomplete-pro-cloud"]);

    // Build US Street client.
    const usStreetClientBuilder = new SmartyCore.ClientBuilder(smartySharedCredentials);

    // Set the initialized clients in the state.
    setAutoCompleteClient(autoCompleteClientBuilder.buildUsAutocompleteProClient());
    setUsStreetClient(usStreetClientBuilder.buildUsStreetApiClient());
  }, [websiteKey]);

  // Return the initialized clients.
  return {
    utils: sdkUtils,
    sdk: SmartySDK,
    autocomplete: {
      client: autoCompleteClient,
      lookup: SmartySDK.usAutocompletePro.Lookup,
    },
    street: {
      client: usStreetClient,
      lookup: SmartySDK.usStreet.Lookup,
    },
  };
};
