import { RootState } from "store";

import { getDefaultAttrs } from "./defaults";

export const sessionVerified = (
  action: { [key: string]: any },
  prevState: RootState,
  nextState: RootState
) => {
  const isInFulfillment = nextState.journey.activeJourneys.includes("fulfillment");
  if (isInFulfillment) {
    return {
      event: "registration_2fa_verified",
      attribute: {
        ...getDefaultAttrs(nextState, "ONBOARDING"),
      },
    };
  }
};
