import classNames from "classnames";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { FC, FocusEvent, MouseEvent } from "react";
import { NavLink } from "react-router-dom";

type IProps = {
  text: string;
  isSubMenu?: boolean;
  icon?: string;
  linkTo?: string;
  active?: boolean;
  highlighted?: boolean;
  dataTestId?: string;
  itemRef?: React.RefObject<any>;
  onClick?(event: MouseEvent): void;
  onMouseDown?(event: MouseEvent): void;
  onFocus?(event: FocusEvent): void;
};

const MenuListItem: FC<IProps> = props => {
  const {
    isSubMenu,
    text,
    icon,
    linkTo,
    active,
    highlighted,
    itemRef,
    onClick,
    onMouseDown,
    onFocus,
    dataTestId,
    ...rest
  } = props;
  const menuClass = classNames("menu-item flex--center", {
    "sub-menu": isSubMenu,
    "main-menu": !isSubMenu,
    "menu-item--active": active,
    "menu-item--highlighted": highlighted,
  });

  return linkTo ? (
    <div className="menu-item__border--bottom" ref={itemRef}>
      <NavLink
        {...rest}
        to={linkTo}
        data-testid={dataTestId}
        onClick={onClick}
        className="menu__link"
        activeClassName="menu-item--active"
        onFocus={onFocus}>
        <div className={menuClass}>
          <Icon className="menu-item__icon" src={icon} size="sm" />
          <Text text={text} tag="p3" />
        </div>
      </NavLink>
    </div>
  ) : (
    <div
      className={classNames("menu-item__border--bottom", menuClass)}
      onFocus={onFocus}
      onClick={onClick}
      onMouseDown={onMouseDown}
      data-testid={dataTestId}
      ref={itemRef}>
      <button className="menu-item__btn">
        <Icon className="menu-item__icon" src={icon} size="sm" />
        <Text text={text} tag="p3" />
      </button>
    </div>
  );
};

export default MenuListItem;
