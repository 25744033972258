import { createReducer } from "@reduxjs/toolkit";
import { FlowTypes } from "config";
import { forEach } from "lodash-es";
import queryString from "query-string";
import { logout } from "store/actions/authentication";
import { createFlowResponse, initFormFlow } from "store/actions/flow";
import { locationChange } from "store/actions/router";

export interface IAnalyticsTracker {
  slug: FlowTypes | "";
  responses: Record<string, any>;
  utm: Record<string, any>;
}

const defaultState: IAnalyticsTracker = {
  slug: "",
  responses: {},
  utm: {
    utm_campaign: "",
    utm_content: "",
    utm_medium: "",
    utm_source: "",
    utm_term: "",
  },
};

const analyticsTracker = createReducer(defaultState, builder => {
  builder
    .addCase(initFormFlow.fulfilled, (state, action) => {
      state.slug = action.payload.flow?.slug || "";
      state.responses = {};
    })
    .addCase(createFlowResponse.fulfilled, (state, action) => {
      const responses = action.payload;
      const formInputs = action.meta.arg.formInputs;

      forEach(formInputs, input => {
        const key = input?.key || "";
        const value = input?.value || "";
        state.responses[key] = value;
      });

      responses.forEach(payload => {
        const key = payload.field?.key || "";
        const value = payload.response?.value || "";

        if (key) {
          state.responses[key] = value;
        }
      });
    })
    .addCase(locationChange, (state, action) => {
      const searchString = action.payload.location.search;

      const query = queryString.parse(searchString);

      Object.keys(query).forEach(key => {
        // Only set `utm` prefixed search queries if there is a value as we want
        // to store the original value from intial page load
        if (key.indexOf("utm_") === 0 && query[key]) {
          state.utm[key] = query[key];
        }
      });
    })
    .addCase(logout, () => defaultState);
});

export default analyticsTracker;
