import { createAction } from "@reduxjs/toolkit";
import { ProductSelectedAttribute } from "config";
import { EventDefinition } from "redux-beacon";
import { RootState } from "store";
import { IEventFiredPayload } from "store/actions/analytics";
import * as actionType from "store/constants";
import {
  getLatestInsuranceApplication,
  getWyshADOffering,
  getWyshTermLifeOffering,
} from "store/selectors";

import { getDefaultAttrs, getProductOfferingAttr } from "./defaults";

/* ------------------------    EVENT ACTION     ------------------------------ */

// This should appear when screen 5.0 About AD is displayed
export const aboutADDisplayedEvent = createAction(actionType.ABOUT_AD_DISPLAYED);

// This appears on the PayoutDetailsModal for AD
export const adQuoteModalDisplayedEvent = createAction(actionType.AD_QUOTE_MODAL_DISPLAYED);

// This occurs when the user chooses to edit their offering from the Quote Screen or the About AD screen
export const productOfferEditEvent = createAction<ProductSelectedAttribute>(
  actionType.PRODUCT_OFFER_EDIT
);

// This appears after fulfillment UW and user lands on a product offer or waitlist screen
export const productOfferReviewEvent = createAction(actionType.PRODUCT_OFFER_REVIEW);

// The initial screen telling the user they fell within manual UW
export const dashboardDisplayedEvent = createAction(actionType.DASHBOARD_DISPLAYED);

// The user lands on either bifurcated AD/Term screen
export const bifurcatedProductDisplayedEvent = createAction<ProductSelectedAttribute>(
  actionType.BIFURCATED_PRODUCT_PAGEVIEW
);

// The user on either bifurcated AD/Term clicks CTA to proceed to next screen
export const bifurcatedProductNextEvent = createAction<ProductSelectedAttribute>(
  actionType.BIFURCATED_PRODUCT_NEXT
);

/* ------------------------    EVENT DEFINITION     ------------------------------ */

const aboutADDisplayedEventDefinition: EventDefinition<
  IEventFiredPayload,
  { [key: string]: any },
  RootState
> = (_, prevState: RootState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");
  const productOfferAttr = getProductOfferingAttr(prevState);

  return {
    event: "fulfillment_quote_about_ad_displayed",
    attribute: {
      ...defaultAttrs,
      productOffering: productOfferAttr,
    },
  };
};

const adQuoteModalDisplayedEventDefinition: EventDefinition<
  IEventFiredPayload,
  { [key: string]: any },
  RootState
> = (_, prevState: RootState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");
  const productOfferAttr = getProductOfferingAttr(prevState);

  return {
    event: "fulfillment_quote_ad_modal",
    attribute: {
      ...defaultAttrs,
      productOffering: productOfferAttr,
    },
  };
};

const productOfferReviewEventDefinition: EventDefinition<
  IEventFiredPayload | null,
  { [key: string]: any },
  RootState
> = (_, prevState: RootState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");
  const productOfferAttr = getProductOfferingAttr(prevState);

  const termOffer = getWyshTermLifeOffering(prevState);
  const quoteTerm = termOffer?.termDuration ? termOffer.termDuration * 12 : null;
  const quoteAmount = termOffer?.amount || null;
  const quotePremium = termOffer?.monthlyPremium || null;
  const premiumClass = termOffer?.classification?.name || null;
  const applicationId = prevState.insuranceApplication.id;

  const adOffer = getWyshADOffering(prevState);
  // AD offers do not have a "term" value, default to 12 "months"
  const adquoteTerm = adOffer ? 12 : null;
  const adquoteAmount = adOffer?.amount || null;
  const adquotePremium = adOffer?.monthlyPremium || null;

  const eventPayload = {
    event: "fulfillment_quote_displayed",
    attribute: {
      ...defaultAttrs,
    },
  };

  const quoteAttributes = {
    quotePremium,
    quoteAmount,
    quoteTerm,
    premiumClass,
    applicationId,
  };

  const adAttributes = {
    adquoteTerm,
    adquoteAmount,
    adquotePremium,
  };

  return {
    ...eventPayload,
    attribute: {
      ...eventPayload.attribute,
      productOffering: productOfferAttr,
      ...quoteAttributes,
      ...adAttributes,
    },
  };
};

const productOfferEditEventDefinition: EventDefinition<
  IEventFiredPayload,
  { [key: string]: ProductSelectedAttribute },
  RootState
> = ({ payload }, prevState: RootState) => {
  const adOffering = getWyshADOffering(prevState);
  const termOffering = getWyshTermLifeOffering(prevState);

  return {
    event: "fulfillment_quote_edit",
    attribute: {
      ...getDefaultAttrs(prevState, "ONBOARDING"),
      adquoteAmount: adOffering?.amount,
      adquotePremium: adOffering?.monthlyPremium,
      adquotePremiumClass: adOffering?.classification.name,
      adquoteTerm: adOffering?.termDuration ? adOffering.termDuration * 12 : undefined,
      applicationId: prevState.insuranceApplication.id,
      productOffering: getProductOfferingAttr(prevState),
      productSelected: payload,
      quoteAmount: termOffering?.amount,
      quotePremium: termOffering?.monthlyPremium,
      quotePremiumClass: termOffering?.classification.name,
      quoteTerm: termOffering?.termDuration ? termOffering.termDuration * 12 : undefined,
    },
  };
};

const dashboardDisplayedDefinition: EventDefinition<
  IEventFiredPayload,
  { [key: string]: any },
  RootState
> = (_, _prevState: RootState, nextState: RootState) => {
  const defaultAttrs = getDefaultAttrs(nextState, "DASHBOARD");
  const productOfferAttr = getProductOfferingAttr(nextState);
  const latestApplication = getLatestInsuranceApplication(nextState);

  return {
    event: "dashboard_displayed",
    attribute: {
      ...defaultAttrs,
      productOffering: productOfferAttr,
      underwritingDecision: latestApplication.result,
    },
  };
};

const bifurcatedProductDisplayedDefinition: EventDefinition<
  IEventFiredPayload,
  { [key: string]: ProductSelectedAttribute },
  RootState
> = (action, _prevState: RootState, nextState: RootState) => {
  const defaultAttrs = getDefaultAttrs(nextState, "FULFILLMENT");
  const productOffer = action.payload;
  const eventName =
    productOffer === "ad"
      ? "fulfillment_ad_term_bifurcation_pageview"
      : "fulfillment_term_bifurcation_pageview";

  return {
    event: eventName,
    attribute: {
      ...defaultAttrs,
    },
  };
};

const bifurcatedProductNextDefinition: EventDefinition<
  IEventFiredPayload,
  { [key: string]: ProductSelectedAttribute },
  RootState
> = (action, _prevState: RootState, nextState: RootState) => {
  const defaultAttrs = getDefaultAttrs(nextState, "FULFILLMENT");
  const productOffer = action.payload;
  const eventName =
    productOffer === "ad"
      ? "fulfillment_ad_term_bifurcation_next"
      : "fulfillment_term_bifurcation_next";

  return {
    event: eventName,
    attribute: {
      ...defaultAttrs,
    },
  };
};

/* ------------------------    EVENT MAP    ------------------------------ */

export const productOfferEventsMap = {
  [actionType.ABOUT_AD_DISPLAYED]: aboutADDisplayedEventDefinition,
  [actionType.AD_QUOTE_MODAL_DISPLAYED]: adQuoteModalDisplayedEventDefinition,
  [actionType.PRODUCT_OFFER_EDIT]: productOfferEditEventDefinition,
  [actionType.PRODUCT_OFFER_REVIEW]: productOfferReviewEventDefinition,
  [actionType.DASHBOARD_DISPLAYED]: dashboardDisplayedDefinition,
  [actionType.BIFURCATED_PRODUCT_PAGEVIEW]: bifurcatedProductDisplayedDefinition,
  [actionType.BIFURCATED_PRODUCT_NEXT]: bifurcatedProductNextDefinition,
};
