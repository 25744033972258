import { createAction } from "@reduxjs/toolkit";
import { EstimateFormSlug } from "config";
import { EventDefinition } from "redux-beacon";
import { RootState } from "store";
import { IEventFiredPayload } from "store/actions/analytics";
import * as actionType from "store/constants";
import { getIsIdentified } from "store/selectors";
import replaceAll from "utils/replaceAll";

import { getDefaultAttrs } from "./defaults";

/* ------------------------    EVENT ACTION     ------------------------------ */

// User clicks LOGIN cta on Welcome screen
export const welcomeLoginCtaEvent = createAction(actionType.WELCOME_LOGIN_CLICK);

// User clicks HELP cta on Welcome screen
export const welcomeHelpCtaEvent = createAction(actionType.WELCOME_HELP_CLICK);

// User submits estimate form on Welcome screen
export const welcomeFormSubmittedEvent = createAction(actionType.WELCOME_FORM_SUBMITTED);

// User clicks back on Wysh Builder
export const wyshBuilderBackEvent = createAction(actionType.WYSH_BUILDER_BACK_NAV);

// User lands on Wysh Builder
export const wyshBuilderStartedEvent = createAction(actionType.WYSH_BUILDER_STARTED);

/* ------------------------    EVENT DEFINITION     ------------------------------ */
const estimateEventPrefix = replaceAll(EstimateFormSlug, "-", "");

const welcomeLoginCta: EventDefinition<
  IEventFiredPayload | null,
  { [key: string]: any },
  RootState
> = (_, prevState: RootState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");

  return {
    event: `${estimateEventPrefix}_login`,
    attribute: {
      ...defaultAttrs,
    },
  };
};

const welcomeHelpCta: EventDefinition<
  IEventFiredPayload | null,
  { [key: string]: any },
  RootState
> = (_, prevState: RootState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");

  return {
    event: `${estimateEventPrefix}_help`,
    attribute: {
      ...defaultAttrs,
    },
  };
};

const welcomeFormSubmitted: EventDefinition<
  IEventFiredPayload | null,
  { [key: string]: any },
  RootState
> = (_, prevState: RootState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");

  return {
    event: `${estimateEventPrefix}_form_submitted`,
    attribute: {
      ...defaultAttrs,
    },
  };
};

const wyshBuilderBack: EventDefinition<
  IEventFiredPayload | null,
  { [key: string]: any },
  RootState
> = (_, prevState: RootState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");

  return {
    event: "wysh_builder_back_clicked",
    attribute: {
      ...defaultAttrs,
    },
  };
};

const wyshBuilderStarted: EventDefinition<
  IEventFiredPayload | null,
  { [key: string]: any },
  RootState
> = (_, prevState: RootState) => {
  const experienceLocation = getIsIdentified(prevState) ? "ONBOARDING" : "DASHBOARD";
  const defaultAttrs = getDefaultAttrs(prevState, experienceLocation);

  return {
    event: "wysh_builder_started",
    attribute: {
      ...defaultAttrs,
    },
  };
};

/* ------------------------    EVENT MAP    ------------------------------ */

export const onboardingEventsMap = {
  [actionType.WELCOME_LOGIN_CLICK]: welcomeLoginCta,
  [actionType.WELCOME_HELP_CLICK]: welcomeHelpCta,
  [actionType.WELCOME_FORM_SUBMITTED]: welcomeFormSubmitted,
  [actionType.WYSH_BUILDER_BACK_NAV]: wyshBuilderBack,
  [actionType.WYSH_BUILDER_STARTED]: wyshBuilderStarted,
};
