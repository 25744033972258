import "./WishesListItem.scss";

import classNames from "classnames";
import Divider from "components/Divider/Divider";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { ClassName } from "config/types";
import { isFunction } from "lodash-es";
import { ConditionalWrapper } from "modules";
import { CSSProperties, FC, ReactNode } from "react";

type IProps = {
  className?: ClassName;
  style?: CSSProperties;
  header?: string;
  text?: string;
  color?: string;
  secondaryText?: ReactNode;
  rightText?: string;
  icon?: string;
  draggable?: boolean;
  tabIndex?: number;
  onRemoveClick?(): void;
};

const WishesListItem: FC<IProps> = props => {
  const {
    className,
    draggable,
    style,
    text,
    secondaryText,
    rightText,
    icon,
    color,
    tabIndex,
    onRemoveClick,
  } = props;

  const classes = classNames("wishes-list-item", className);
  const hasRemoveCallback = isFunction(onRemoveClick);
  return (
    <div className={classes} style={style}>
      <div className="wishes-list-item__content flex--center-space-between">
        <ConditionalWrapper condition={!!draggable}>
          <div className="wishes-list-item__drag-icon-wrapper">
            <Icon src="Drag" />
          </div>
        </ConditionalWrapper>
        <div className="wishes-list-item__content-left">
          <div className="wishes-list-item__icon-wrapper" style={{ background: color }}>
            <Icon src={icon} className="wishes-list-item__icon" size="lg" />
          </div>
          <div className="wishes-list-item__text-wrapper">
            <Text tag="l3" text={text} className="wishes-list-item__text" />
            {secondaryText && (
              <Text tag="p5" className="wishes-list-item__text--secondary">
                {secondaryText}
              </Text>
            )}
          </div>
        </div>

        <div className="flex-column--end wishes-list-item__content-right">
          <div className="flex--center">
            {rightText && !hasRemoveCallback && (
              <Text tag="l3" text={rightText} className="wishes-list-item__text" />
            )}
            {hasRemoveCallback && (
              <button
                onClick={onRemoveClick}
                className="wishes-list-item__remove-icon"
                tabIndex={tabIndex ?? 0}>
                <Icon src="Close" />
              </button>
            )}
          </div>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default WishesListItem;
