import { gql } from "config/graphql";
import { IUser } from "config/types";
import { CONSTELLATION_FIELDS } from "graphql/fragments";
import { getApolloClient } from "store/apollo";

enum NotificationsChannel {
  email = "email",
  push = "push",
  sms = "sms",
}

interface IUserOtpNotification {
  channel: NotificationsChannel;
  metadata: Record<string, unknown>;
  createdAt: string;
}

interface IUserOtpNotifications {
  data: {
    user: {
      notifications: IUserOtpNotification[];
    };
  };
}

interface IFetchCurrentUserResponse {
  data: {
    me: IUser;
  };
}

export const fetchCurrentUserQuery = (): Promise<IFetchCurrentUserResponse> => {
  const client = getApolloClient();

  const query = gql`
    ${CONSTELLATION_FIELDS}
    query currentUser {
      me {
        id
        age
        birthdate
        age
        status
        createdAt
        activeConstellations {
          ...ConstellationFields
        }
      }
    }
  `;

  return client.query({ query });
};

export const fetchUserOtpNotificationsQuery = (
  userId: string,
  authorizationToken: string
): Promise<IUserOtpNotifications> => {
  const client = getApolloClient();

  const query = gql`
    query UserOtpNotifications($userId: ID!) {
      user(id: $userId) {
        notifications {
          channel
          metadata
          createdAt
        }
      }
    }
  `;

  return client.query({
    context: {
      headers: {
        authorization: `Bearer ${authorizationToken}`,
      },
    },
    variables: { userId },
    query,
  });
};
