import { gql } from "config/graphql";
import { getApolloClient } from "store/apollo";

interface IUpdateNotification {
  data: {
    updateNotification: {
      response: {
        success: boolean;
      };
    };
  };
}

export const updateNotificationMutation = (
  notificationId: string
): Promise<IUpdateNotification> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation UpdateNotification($input: UpdateNotificationInput!) {
      updateNotification(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: { input: { notificationId } },
    mutation,
  });
};
