import { IUser } from "config";
import { gql } from "config/graphql";
import { FINANCE_CUSTOMER_FIELDS } from "graphql/fragments";
import { getApolloClient } from "store/apollo";

interface IFinanceCustomerResponse {
  data: {
    me: Pick<IUser, "financeCustomer">;
  };
}

export const financeCustomerQuery = (): Promise<IFinanceCustomerResponse> => {
  const client = getApolloClient();

  const query = gql`
    ${FINANCE_CUSTOMER_FIELDS}
    query FinanceCustomer {
      me {
        financeCustomer {
          ...FinanceCustomerFields
        }
      }
    }
  `;

  return client.query({ query });
};
