import Loader from "components/Loader/Loader";
import { IDisclosureWithBody, TitleCallback } from "config";
import NotFound from "containers/NotFound/NotFound";
import { get, startCase } from "lodash-es";
import { Layout } from "modules";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getDisclosureWithBody } from "store/actions/disclosure";
import { getThunkError } from "utils/error";

export const buildDisclosureTitle: TitleCallback<{ slug: string }> = ({ match }) => {
  const formattedPathname = startCase(match?.params.slug);
  return formattedPathname;
};

const Disclosure: FC = () => {
  const dispatch = useDispatch();
  const { slug } = useParams<{ slug: string }>();

  const [pending, setPending] = useState(false);
  const [error, setError] = useState(false);
  const [disclosure, setDisclosure] = useState<IDisclosureWithBody>({
    id: "",
    name: "",
    body: "",
    description: "",
    cta: "",
    slug: "",
    version: null,
    createdAt: "",
  });

  const getDisclosureCallback = useCallback(async () => {
    setPending(true);
    setError(false);
    const response = await dispatch(getDisclosureWithBody({ slug }));
    setPending(false);
    const error = getThunkError(response);
    if (error) {
      setError(true);
      return;
    }
    setDisclosure(get(response, "payload"));
  }, [dispatch]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getDisclosureCallback();
  }, [getDisclosureCallback]);

  return (
    <Layout
      as="DisclosureLayout"
      renderFallback={error}
      fallback={<NotFound />}
      content={{ ...disclosure }}>
      {pending && <Loader size="md" />}
    </Layout>
  );
};

export default Disclosure;
