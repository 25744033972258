import { gql } from "config/graphql";
import { IMembership } from "config/types";
import {
  CreateOrInitiateExternalAccountInput,
  ExternalAccount,
  RemoveExternalAccountInput,
  RemoveExternalAccountResponse,
  VerifyExternalAccountInput,
} from "config/types/externalAccount.type";
import { getApolloClient } from "store/apollo";

interface VerifyExternalAccountResponse {
  data: {
    verifyExternalAccount: {
      response: {
        success: boolean;
      };
    };
  };
}

export const verifyExternalAccountMutation = (
  input: VerifyExternalAccountInput
): Promise<VerifyExternalAccountResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation verifyExternalAccountMutation($input: VerifyExternalAccountInput!) {
      verifyExternalAccount(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: { input },
    mutation,
  });
};

interface ExternalAccountsResponse {
  data: {
    externalAccounts: ExternalAccount[];
  };
}

export const fetchExternalAccountsQuery = (
  membershipId: IMembership["id"]
): Promise<ExternalAccountsResponse> => {
  const client = getApolloClient();

  const query = gql`
    query externalAccounts($membershipId: ID!) {
      externalAccounts(membershipId: $membershipId) {
        accountNumber
        accountType
        balance
        customerExternalId
        externalAccountId
        institutionLogo
        institutionName
        mask
        routingNumber
        status
      }
    }
  `;

  return client.query({
    query,
    variables: { membershipId },
  });
};

export const createOrInitiateExternalAccountMutation = (
  input: CreateOrInitiateExternalAccountInput
): Promise<ExternalAccountsResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation CreateOrInitiateExternalAccount($input: CreateOrInitiateExternalAccountInput!) {
      createOrInitiateExternalAccount(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: { input },
    mutation,
  });
};

export const removeExternalAccountMutation = (
  input: RemoveExternalAccountInput
): Promise<RemoveExternalAccountResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation RemoveExternalAccount($input: RemoveExternalAccountInput!) {
      removeExternalAccount(input: $input) {
        response {
          status
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: { input },
    mutation,
  });
};
