import Checkbox from "components/Checkbox/Checkbox";
import Text from "components/Text/Text";
import { useTranslation } from "modules";

interface IPolicyAcknowledgements {
  adCheckbox: boolean;
  isQuoteAccidentalDeath: boolean;
  setADCheckbox(value: boolean): void;
}

function PolicyAcknowledgements(props: IPolicyAcknowledgements) {
  const { adCheckbox, isQuoteAccidentalDeath, setADCheckbox } = props;
  const { t } = useTranslation();

  if (!isQuoteAccidentalDeath) {
    return null;
  }

  return (
    <Checkbox
      id="ad-acknowledgement-checkbox"
      checked={adCheckbox}
      className="ad-disclosure__checkbox"
      name="ad-acknowledgement-checkbox"
      label={
        <Text
          tag="p5"
          text={t(
            "review_document.checkbox.ad_replacement",
            "Check this box only if as a result of this purchase, any other accidental death policy will be replaced, discontinued, or changed in any way."
          )}
        />
      }
      onChange={() => setADCheckbox(!adCheckbox)}
      value={adCheckbox}
    />
  );
}

export default PolicyAcknowledgements;
