import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { IInsuranceApplication } from "config";
import { getVerifiedInitialData } from "store/actions/appLoad";
import { logout } from "store/actions/authentication";
import { getDashboardData } from "store/actions/dashboard";
import { getQuote } from "store/actions/quote";
import { offeringsReviewPolling } from "store/actions/product-offerings";
import {
  getLatestInsuranceApplication,
  getLatestInsuranceApplicationDocuments,
} from "store/actions/insuranceApplications";

const defaultState: IInsuranceApplication = {
  documents: [],
  id: "",
  result: "",
  version: 0,
};

const caseResultMatcher = isAnyOf(getQuote.fulfilled, offeringsReviewPolling.fulfilled);

/* ------------    REDUCER     ------------------ */
const insuranceApplication = createReducer(defaultState, builder => {
  builder
    .addCase(getDashboardData.fulfilled, (state, action) => {
      const accountPayload = action.payload;
      const application = accountPayload?.latestInsuranceApplication || defaultState;

      state.id = application.id;
      state.result = application.result;
      state.version = application.version;
    })
    .addCase(getLatestInsuranceApplication.fulfilled, (state, action) => {
      state.id = action.payload?.id || defaultState.id;
      state.result = action.payload?.result || defaultState.result;
      state.version = action.payload?.version || defaultState.version;
    })
    .addCase(getLatestInsuranceApplicationDocuments.fulfilled, (state, action) => {
      state.documents = action.payload || [];
    })
    .addCase(getVerifiedInitialData.fulfilled, (state, action) => {
      const accountPayload = action.payload.account;
      const application = accountPayload?.latestInsuranceApplication || defaultState;

      state.id = application.id;
      state.result = application.result;
      state.version = application.version;
    })
    .addCase(logout, () => defaultState)
    .addMatcher(caseResultMatcher, (state, action) => {
      const caseResult = action.payload.caseResult;
      const caseId = action.payload.caseId;

      state.id = caseId;
      state.result = caseResult;
    });
});

export default insuranceApplication;
