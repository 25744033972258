import { createReducer } from "@reduxjs/toolkit";
import { IRequest } from "config/types";
import { get, filter } from "lodash-es";

const defaultState: IRequest = {
  requests: [],
  inFlight: false,
  pendingRequests: 0,
  error: {},
};

const user = createReducer(defaultState, {
  START_REQUEST: (state, action) => {
    const resourceName = get(action, "payload.resource");

    return {
      ...defaultState,
      requests: [...state.requests, resourceName],
      pendingRequests: state.pendingRequests + 1,
      inFlight: true,
    };
  },
  END_REQUEST: (state, action) => {
    const resourceName = get(action, "payload.resource");
    const filteredRequests = filter(state.requests, (request: string) => request !== resourceName);

    return {
      ...defaultState,
      requests: filteredRequests,
      pendingRequests: state.pendingRequests - 1,
      inFlight: false,
    };
  },
});

export default user;
