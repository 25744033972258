import { gql } from "config/graphql";

import { BUSINESS_FIELDS } from "./business";
import { PLAN_FIELDS } from "./plan";

export const FINANCE_MEMBERSHIP_FIELDS = gql`
  ${BUSINESS_FIELDS}
  ${PLAN_FIELDS}
  fragment MembershipFields on Membership {
    activePlan {
      ...PlanFields
    }
    business {
      ...BusinessFields
    }
    createdAt
    custom
    id
    metadata
    pendingPlan {
      ...PlanFields
    }
  }
`;
