import { gql } from "config/graphql";
import { getApolloClient } from "store/apollo";

export const fetchTriviaQuery = () => {
  const client = getApolloClient();

  const query = gql`
    query TriviaQuestions {
      triviaQuestions {
        explanation
        id
        question
        slug
        answers {
          correct
          id
          name
          slug
        }
      }
    }
  `;

  return client.query({
    fetchPolicy: "cache-first",
    query,
  });
};
