import { gql } from "config/graphql";
import { getApolloClient } from "store/apollo";
import { TermUnit } from "config";

interface AcceptProductOfferingInput {
  offeringId: string;
}

interface AcceptProductOfferingPayload {
  data: {
    acceptProductOffering: {
      response: {
        success: boolean;
      };
    };
  };
}

export const acceptProductOfferingMutation = (
  input: AcceptProductOfferingInput
): Promise<AcceptProductOfferingPayload> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation AcceptProductOffering($input: AcceptProductOfferingInput!) {
      acceptProductOffering(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: {
      input,
    },
    mutation,
  });
};

export interface IUpdateProductOfferingInput {
  offeringId: string;
  amount: number;
  termUnit?: TermUnit;
  termDuration?: number;
}

interface IUpdateProductOfferingResponse {
  data: {
    updateProductOffering: {
      response: {
        success: boolean;
      };
    };
  };
}

export const updateProductOfferingMutation = (
  input: IUpdateProductOfferingInput
): Promise<IUpdateProductOfferingResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation updateProductOffering($input: UpdateProductOfferingInput!) {
      updateProductOffering(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: {
      input,
    },
    mutation,
  });
};
