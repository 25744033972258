import { gql } from "config/graphql";
import { IDisclosure, IDisclosureWithBody, IUser } from "config/types";
import { getApolloClient } from "store/apollo";

interface IDisclosureResponse {
  data: {
    disclosure: IDisclosure;
  };
}

interface IDisclosureWithBodyResponse {
  data: {
    disclosure: IDisclosureWithBody;
  };
}

interface IBusinessDisclosuresResponse {
  data: {
    businessDisclosures: IDisclosure[];
  };
}

const CORE_DISCLOSURE_FIELDS = gql`
  fragment CoreDisclosureFields on Disclosure {
    createdAt
    cta
    description
    id
    name
    slug
    version
    downloadUrl
    important
  }
`;

export const disclosureQuery = (slug: string): Promise<IDisclosureResponse> => {
  const client = getApolloClient();

  const query = gql`
    ${CORE_DISCLOSURE_FIELDS}

    query disclosure($slug: String!) {
      disclosure(slug: $slug) {
        ...CoreDisclosureFields
      }
    }
  `;

  return client.query({
    variables: { slug },
    query,
  });
};

export const disclosureWithBodyQuery = (slug: string): Promise<IDisclosureWithBodyResponse> => {
  const client = getApolloClient();

  const query = gql`
    ${CORE_DISCLOSURE_FIELDS}

    query disclosure($slug: String!) {
      disclosure(slug: $slug) {
        ...CoreDisclosureFields
        body
      }
    }
  `;

  return client.query({
    query,
    variables: { slug },
  });
};

export const businessDisclosuresQuery = (
  businessSlug: string
): Promise<IBusinessDisclosuresResponse> => {
  const client = getApolloClient();

  const query = gql`
    ${CORE_DISCLOSURE_FIELDS}

    query BusinessDisclosures($businessSlug: String!) {
      businessDisclosures(businessSlug: $businessSlug) {
        ...CoreDisclosureFields
      }
    }
  `;
  return client.query({
    query,
    variables: { businessSlug },
  });
};

interface IDocumentsResponse {
  data: {
    me: IUser;
  };
}

export const documentsQuery = (): Promise<IDocumentsResponse> => {
  const client = getApolloClient();

  const query = gql`
    query userDocuments {
      me {
        activeConstellations {
          activeInsuranceMembership {
            id
            activePolicy {
              documents {
                id
                name
                description
                cta
                slug
                html {
                  url
                  fileName
                }
                pdf {
                  url
                  fileName
                }
                version
              }
            }
            documents {
              id
              name
              description
              cta
              slug
              html {
                url
                fileName
              }
              pdf {
                url
                fileName
              }
              version
            }
          }
        }
      }
    }
  `;

  return client.query({ query });
};
