import "./FAQ.scss";

import Accordion from "components/Accordion/Accordion";
import Text from "components/Text/Text";
import { FC } from "react";

interface IProps {
  fireAnalyticsEvent?: boolean;
  title: string;
  questions: { id: string; question: string; answer: string }[];
}

const FAQ: FC<IProps> = props => {
  const { fireAnalyticsEvent = false, title, questions } = props;

  const panels = questions.map(question => {
    return {
      id: question.id,
      label: question.question,
      content: question.answer,
    };
  });

  return (
    <div className="faq">
      <div className="faq__wrapper">
        <Text className="faq__title" tag="h4" text={title} />

        <div className="faq__list">
          <Accordion fireAnalyticsEvent={fireAnalyticsEvent} panelData={panels} splitColumn />
        </div>
      </div>
    </div>
  );
};

export default FAQ;
