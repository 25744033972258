import { gql } from "config/graphql";
import { IState } from "config/types";
import { getApolloClient } from "store/apollo";

interface IStatesResponse {
  data: {
    states: IState[];
  };
}

export const statesQuery = (countryId: string): Promise<IStatesResponse> => {
  const client = getApolloClient();

  const query = gql`
    query States($countryId: ID!) {
      states(countryId: $countryId) {
        id
        name
        alpha2
      }
    }
  `;

  return client.query({ variables: { countryId }, query });
};
