import classnames from "classnames";
import Text from "components/Text/Text";
import { ClassName, OR, OrString, ValueOrArray } from "config/types";
import { find, flatten, get, isEmpty, isNil, isPlainObject, map, partition } from "lodash-es";
import { ConditionalWrapper } from "modules";
import { ComponentProps, FC, Fragment, ReactElement, ReactNode } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";

type Item<C = unknown> = {
  id: string;
  content: Record<string, C>;
};

interface ItemProps extends Record<string, unknown> {
  className?: ClassName;
  dragging?: Record<string, unknown>;
}

type TitleProp = OR<OrString<ComponentProps<typeof Text>>, ReactNode>;

type ListState = {
  collection: Item<ComponentProps<ListState["ListItem"]>>[];
  ListItem: FC<any>;
  itemProps?: ItemProps;
  title?: ValueOrArray<TitleProp>;
};

interface IProps {
  type: string;
  list: ListState;
  className?: ClassName;
  partition?: Record<string, unknown>;
  onDragEnd?(dragEvent: DropResult): void;
}

export const DraggableList: FC<IProps> = props => {
  const { className, type, list, onDragEnd, partition: partitionCondition } = props;
  const { collection, itemProps = {}, ListItem, title: listTitle } = list;
  const listTitles = flatten([listTitle]);

  const handleDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;
    if (!destination) return;

    const differentDestinationBucket = destination.droppableId !== source.droppableId;
    const [c] = partition(collection, partitionCondition as any);
    const resultExistsInFirstBucket = find(c, { id: draggableId });
    if (
      !isEmpty(partitionCondition) &&
      differentDestinationBucket &&
      isEmpty(resultExistsInFirstBucket)
    )
      return;

    onDragEnd && onDragEnd(result);
  };

  const baseListClass = "dragdrop-list__container";
  const { dragging: draggingItemProps = {}, className: itemClasses, ...restItemProps } = itemProps;
  const { className: draggingItemClass, ...restDragItemProps } = draggingItemProps;

  const buildTextElement = (
    text: TitleProp,
    baseClassName?: ClassName
  ): ReactElement<ComponentProps<typeof Text>, typeof Text> => {
    const defaultTag: ComponentProps<typeof Text>["tag"] = "c5";
    const propsFromText = isPlainObject(text)
      ? (text as ComponentProps<typeof Text>)
      : { className: "" };
    const { className: classNameFromProps, ...restProps } = propsFromText;
    const className = classnames(baseClassName, get(text, "className", classNameFromProps));
    const textProps: ComponentProps<typeof Text> = {
      className,
      tag: defaultTag,
      ...restProps,
    };

    return <Text {...textProps}>{text}</Text>;
  };

  const collectionList = isEmpty(partitionCondition)
    ? [collection]
    : partition(collection, partitionCondition as any);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {map(collectionList, (collection, index) => (
        <Droppable
          droppableId={`droppable-${index + 1}`}
          type={type}
          direction="vertical"
          key={index}>
          {(provided, snapshot) => {
            const { isDraggingOver, draggingFromThisWith } = snapshot;
            return (
              <Fragment key={index}>
                <ConditionalWrapper
                  condition={!isEmpty(listTitles[index]) || !isNil(listTitles[index])}>
                  {buildTextElement(listTitles[index])}
                </ConditionalWrapper>
                <div
                  ref={provided.innerRef}
                  className={classnames(baseListClass, className, {
                    "is-dragging": !!draggingFromThisWith,
                    "is-dragging-over": isDraggingOver,
                  })}
                  {...provided.droppableProps}>
                  <div className="dragdrop-list__wrapper">
                    {map(collection, (item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided, snapshot) => {
                          return (
                            <div
                              ref={provided.innerRef}
                              className="dragdrop-list__item-container"
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}>
                              <ListItem
                                {...restItemProps}
                                {...(snapshot.isDragging ? restDragItemProps : {})}
                                {...item.content}
                                className={classnames(
                                  itemClasses,
                                  "dragdrop-list__item-wrapper",
                                  snapshot.isDragging ? (draggingItemClass as ClassName) : ""
                                )}
                              />
                            </div>
                          );
                        }}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                </div>
              </Fragment>
            );
          }}
        </Droppable>
      ))}
    </DragDropContext>
  );
};
