import { gql } from "config/graphql";

export const PRODUCT_DETAIL_FIELDS = gql`
  fragment ProductFields on Product {
    id
    name
    status
    slug
    description
  }
`;
