import { OAuthAuthorizeRoute, OAuthClientId, OAuthTokenRoute } from "config";
import AuthRequest from "config/AuthRequest";
import { IAccessTokenRequestData, IAuthApi, IAuthorizeRequestData } from "config/types";
import { getRefreshToken } from "utils/refreshToken";

const authApi: IAuthApi = {
  refreshAccessToken: (clientId = OAuthClientId) => {
    return AuthRequest.getInstance().request({
      data: {
        client_id: clientId,
        grant_type: "refresh_token",
        refresh_token: getRefreshToken().token,
      },
      method: "POST",
      url: OAuthTokenRoute,
    });
  },
  authorize: (authorizeRequestData: IAuthorizeRequestData) => {
    return AuthRequest.getInstance().request({
      data: authorizeRequestData,
      method: "POST",
      url: OAuthAuthorizeRoute,
    });
  },
  fetchToken: (accessTokenRequestData: IAccessTokenRequestData) => {
    return AuthRequest.getInstance().request({
      data: accessTokenRequestData,
      method: "POST",
      url: OAuthTokenRoute,
    });
  },
};

export default authApi;
