import Trivia from "components/Trivia/Trivia";
import { ITriviaNextQuestion, TriviaSectionType } from "config/types";
import { useTrivia } from "modules/hooks";
import { PollingStatus } from "modules/hooks/usePolling";
import { FC, MouseEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { eventFired } from "store/actions/analytics";

interface IProps {
  isFailedQuote: boolean;
  isQuoteReady: boolean;
  requestStatus?: PollingStatus;
  onComplete(): void;
}

const TriviaContainer: FC<IProps> = ({
  isFailedQuote,
  isQuoteReady,
  onComplete,
  requestStatus,
}) => {
  const {
    answerDuration,
    answerScreenDuration,
    delayBeforeBanner,
    minBannerDisplayDuration,
    nextQuestion,
    questionScreenDuration,
  } = useTrivia();

  const addDelayBeforeBanner = useRef<boolean>(false);
  const delayBeforeBannerTimeout = useRef<number | undefined>(undefined);
  const isReady = useRef<boolean>(isQuoteReady);
  const minBannerDisplayDurationStartTime = useRef<number | undefined>(undefined);
  const triviaSectionTimeout = useRef<NodeJS.Timeout | undefined>(undefined);

  const [triviaSection, setTriviaSection] = useState<TriviaSectionType>("intro");
  const [questionInfo, setQuestionInfo] = useState<ITriviaNextQuestion | undefined>(undefined);
  const [selectedAnswerSlug, setSelectedAnswerSlug] = useState<string | undefined>(undefined);
  const [questionWasTimeout, setQuestionWasTimeout] = useState<boolean>(false);

  const dispatch = useDispatch();

  const clearTriviaSectionTimeout = () => {
    if (triviaSectionTimeout.current) {
      clearTimeout(triviaSectionTimeout.current);
    }
  };

  const handleComplete = (event?: MouseEvent<HTMLDivElement>) => {
    dispatch(
      eventFired({
        event: event ? "fulfillment_trivia_quote_click" : "fulfillment_trivia_completed",
        experienceLocation: "ONBOARDING",
      })
    );

    onComplete();
  };

  const handleNext = () => {
    if (isReady.current) {
      const currentTime = new Date().getTime();

      const timeoutLength = minBannerDisplayDurationStartTime.current
        ? minBannerDisplayDuration * 1000 -
          (currentTime - minBannerDisplayDurationStartTime.current)
        : 0;

      setTriviaSectionTimeout(handleComplete, timeoutLength);
    } else {
      setTriviaSection("question");
    }
  };

  const handleSelectAnswer = (
    slug: string,
    metadata?: { question?: ITriviaNextQuestion; timeout?: boolean }
  ) => {
    dispatch(
      eventFired({
        event: metadata?.timeout
          ? "fulfillment_trivia_question_timeout"
          : "fulfillment_trivia_question_answered",
        experienceLocation: "ONBOARDING",
        attribute: {
          triviaNumber: metadata?.timeout
            ? metadata?.question?.questionNumber
            : questionInfo?.questionNumber,
          triviaQuestion: metadata?.timeout
            ? metadata?.question?.question.slug
            : questionInfo?.question.slug,
          triviaAnswer: slug,
        },
      })
    );
    setQuestionWasTimeout(metadata?.timeout ? true : false);
    setSelectedAnswerSlug(slug);
    setTriviaSectionTimeout(() => setTriviaSection("answer"), answerDuration * 1000);
  };

  const handleQuestionTimeout = (question?: ITriviaNextQuestion) => {
    const correctAnswerSlug = question?.question.answers.find(({ correct }) => correct)?.slug;

    if (correctAnswerSlug) {
      handleSelectAnswer(correctAnswerSlug, { question, timeout: true });
    } else {
      // This condition should not happen, but protects
      // against malformed questions making into the API
      setTriviaSection("answer");
    }
  };

  const handleTransitionToNextQuestion = () => {
    const question = nextQuestion();

    // If there are no more questions left, or there was an API issue, go back to the quote loader
    if (!question) {
      onComplete();
      return;
    }

    setQuestionInfo(question);
    setSelectedAnswerSlug(undefined);

    if (question.questionNumber === 1) {
      dispatch(
        eventFired({
          event: "fulfillment_trivia_question_start",
          experienceLocation: "ONBOARDING",
          attribute: {
            triviaNumber: question.questionNumber,
            triviaQuestion: question.question.slug,
          },
        })
      );
    }

    return question;
  };

  const setTriviaSectionTimeout = (cb: () => void, ms: number) => {
    clearTriviaSectionTimeout();

    triviaSectionTimeout.current = setTimeout(cb, ms);
  };

  useEffect(() => {
    dispatch(
      eventFired({
        event: "fulfillment_trivia_start",
        experienceLocation: "ONBOARDING",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (triviaSection === "intro") {
      setTriviaSectionTimeout(() => setTriviaSection("question"), answerScreenDuration * 1000);
    } else if (triviaSection === "question") {
      const question = handleTransitionToNextQuestion();

      setTriviaSectionTimeout(() => handleQuestionTimeout(question), questionScreenDuration * 1000);
    } else if (triviaSection === "answer") {
      setTriviaSectionTimeout(handleNext, answerScreenDuration * 1000);
    }

    return clearTriviaSectionTimeout;
  }, [answerScreenDuration, triviaSection, questionScreenDuration]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isQuoteReady && triviaSection !== "intro" && !minBannerDisplayDurationStartTime.current) {
      minBannerDisplayDurationStartTime.current = new Date().getTime();
    }
  }, [isQuoteReady, minBannerDisplayDuration, triviaSection]);

  useEffect(() => {
    switch (true) {
      case addDelayBeforeBanner.current && triviaSection === "answer":
        delayBeforeBannerTimeout.current = setTimeout(handleComplete, delayBeforeBanner * 1000);
        break;

      case isFailedQuote && triviaSection !== "answer":
        addDelayBeforeBanner.current = true;
        break;

      case isFailedQuote && triviaSection === "answer":
        return handleComplete();
    }

    return () => {
      if (delayBeforeBannerTimeout.current) {
        clearTimeout(delayBeforeBannerTimeout.current);
      }
    };
  }, [isFailedQuote, requestStatus, triviaSection]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isQuoteReady) {
      dispatch(
        eventFired({
          event: "fulfillment_trivia_quote_ready",
          experienceLocation: "ONBOARDING",
        })
      );
    }
  }, [dispatch, isQuoteReady]);

  useEffect(() => {
    isReady.current = isQuoteReady;
  }, [isQuoteReady]);

  return (
    <Trivia
      answerScreenDuration={answerScreenDuration}
      isQuoteReady={isQuoteReady && !isFailedQuote}
      onComplete={handleComplete}
      onNext={handleNext}
      onSelectAnswer={handleSelectAnswer}
      questionInfo={questionInfo}
      questionWasTimeout={questionWasTimeout}
      questionScreenDuration={questionScreenDuration}
      section={triviaSection}
      selectedAnswerSlug={selectedAnswerSlug}
    />
  );
};

export default TriviaContainer;
