import { env } from "config";
import { FC } from "react";

export const DevPartial: FC = ({ children }) => {
  const isDev = env === "development" || env === "staging";

  return isDev ? <>{children}</> : null;
};

export default DevPartial;
