import "./HeaderHelpButton.scss";

import { welcomeHelpCtaEvent } from "analytics/onboardingEvents";
import classNames from "classnames";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { ClassName } from "config";
import { MouseEvent } from "react";
import { matchPath, useLocation } from "react-router";
import { useAppDispatch, useAppSelector } from "store";
import { eventFired } from "store/actions/analytics";
import { setModal } from "store/actions/modal";
import { isOnboarding } from "utils/journey";

interface IProps {
  ctaText: string;
  className?: ClassName;
  isCoBranded?: boolean;
}

function HeaderHelpButton({ className, ctaText, isCoBranded }: IProps) {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const journey = useAppSelector(state => state.journey);

  const handleClickHelp = (event: MouseEvent) => {
    event.preventDefault();

    dispatch(
      eventFired({
        event: "navigation_header_help_main_clicked",
        experienceLocation: isOnboarding(journey) ? "ONBOARDING" : "DASHBOARD",
      })
    );

    if (matchPath(location.pathname, { path: "/products/insurance/welcome", exact: true })) {
      dispatch(welcomeHelpCtaEvent());
    }

    dispatch(setModal("CustomerSupportModal"));
  };

  const buttonClasses = classNames("header__help", "flex--center", "pointer", className, {
    "header__help--co-branded": isCoBranded,
  });

  return (
    <button className={buttonClasses} onClick={handleClickHelp}>
      <Icon className="header__help-icon" src="ChatAgent" size="sm" />
      <Text className="header__help-text" tag="l4-label" text={ctaText} />
    </button>
  );
}

export default HeaderHelpButton;
