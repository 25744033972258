import "./CreatePhoneNumber.scss";

import CreatePhoneImg from "assets/images/registration/CreatePhone.svg";
import Button from "components/Button/Button";
import FormError from "components/FormError/FormError";
import USPhoneNumberInput, {
  IUSPhoneNumberInputEvent,
} from "components/PhoneNumberInput/USPhoneNumberInput";
import Text from "components/Text/Text";
import { push } from "connected-react-router";
import { get } from "lodash-es";
import { Layout, useJourney } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC, FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { eventFired } from "store/actions/analytics";
import { createPhoneNumber } from "store/actions/user";
import { getThunkError } from "utils/error";

const CreatePhoneNumber: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { nextStepCallback } = useJourney();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formError, setFormError] = useState("");
  const [loading, setLoading] = useState(false);

  const content = {
    header: t(
      "create_phone_number.form.header",
      "Welcome aboard! Let’s verify your mobile phone number."
    ),
    subheader: t(
      "create_phone_number.subheader",
      "Verifying your phone helps us confirm that your number isn’t being used fraudulently. We take protecting your data very seriously."
    ),
    imgSrc: CreatePhoneImg,
  };

  const onChange = (event: IUSPhoneNumberInputEvent) => {
    const { value } = event.target;
    const newNumber = value;

    if (newNumber !== phoneNumber) {
      setFormError("");
    }

    setPhoneNumber(newNumber);
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    setLoading(true);

    const response = await dispatch(
      createPhoneNumber({
        phoneNumber,
        countryCode: "1",
      })
    );

    setLoading(false);

    const errorMessage = get(getThunkError(response), "fullMessage", "");

    if (!errorMessage) {
      dispatch(
        eventFired({
          event: "phone_number_created",
          experienceLocation: "ONBOARDING",
        })
      );

      const callback = () => dispatch(push("/"));
      nextStepCallback(callback, false)();
      return;
    }

    setFormError(errorMessage);
  };

  return (
    <Layout as="TwoColumnLayout" className="create-phone-number" content={content}>
      <form className="flex-column--center flex-stretch" onSubmit={onSubmit}>
        <USPhoneNumberInput
          id="phone"
          dataTestId="verify-phone__phone"
          onChange={onChange}
          value={phoneNumber}
        />
        {formError && <FormError error={formError} />}
        <Text
          tag="p6"
          text={t(
            "create_phone_number.legal",
            "By tapping send, you are giving us permission to send you a text message."
          )}
          className="create-phone-number__permission"
        />
        <Button
          text={t("create_phone_number.form.button", "SEND")}
          subtype="primary"
          disabled={!phoneNumber || loading}
          isLoading={loading}
          type="submit"
          dataTestId="create-phone__submit"
        />
      </form>
    </Layout>
  );
};

export default CreatePhoneNumber;
