import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";

import account from "./account";
import allocationHistory from "./allocationHistory";
import allocationSummary from "./allocationSummary";
import analyticsTracker from "./analyticsTracker";
import authentication from "./authentication";
import bank from "./bank";
import beneficiaries from "./beneficiaries";
import cases from "./cases";
import constellation from "./constellation";
import counter from "./counter";
import country from "./country";
import customer from "./customer";
import deviceFingerprint from "./device-fingerprint";
import disclosures from "./disclosures";
import documents from "./documents";
import dynamicForm from "./dynamicForm";
import estimate from "./estimate";
import externalAccounts from "./externalAccounts";
import featureFlags from "./featureFlags";
import financeBankAccount from "./financeBankAccount";
import financeBankTransactions from "./financeBankTransactions";
import financeCustomer from "./financeCustomer";
import financeMembership from "./financeMembership";
import financeProgramDetails from "./financeProgramDetails";
import formFlow from "./formFlow";
import header from "./header";
import insuranceApplication from "./insuranceApplication";
import insuranceMembership from "./insuranceMembership";
import invoice from "./invoice";
import journey from "./journey";
import modal from "./modal";
import notifications from "./notifications";
import payment from "./payment";
import pendingPolicy from "./pendingPolicy";
import pendingAutoTransfers from "./pendingScheduledTransfers";
import plaidAccounts from "./plaidAccounts";
import plaidItems from "./plaidItems";
import policy from "./policy";
import policyRiders from "./policyRiders";
import productOffering from "./productOffering";
import profile from "./profile";
import quote from "./quote";
import reinstateablePolicies from "./reinstateablePolicies";
import request from "./request";
import scheduledTransfers from "./scheduledTransfers";
import states from "./states";
import transferSchedules from "./transferSchedules";
import translations from "./translations";
import trivia from "./trivia";
import user from "./user";
import wyshAllocations from "./wyshAllocations";
import wyshCategories from "./wyshCategories";
import wyshes from "./wyshes";
import wyshesFlow from "./wyshesFlow";

const createRootReducer = (history: History) =>
  combineReducers({
    account,
    allocationHistory,
    allocationSummary,
    authentication,
    analyticsTracker,
    bank,
    beneficiaries,
    cases,
    constellation,
    counter,
    country,
    customer,
    deviceFingerprint,
    disclosures,
    documents,
    dynamicForm,
    estimate,
    externalAccounts,
    featureFlags,
    financeBankAccount,
    financeBankTransactions,
    financeCustomer,
    financeMembership,
    financeProgramDetails,
    formFlow,
    header,
    insuranceApplication,
    insuranceMembership,
    invoice,
    journey,
    modal,
    notifications,
    payment,
    pendingPolicy,
    pendingAutoTransfers,
    plaidAccounts,
    plaidItems,
    policy,
    policyRiders,
    productOffering,
    profile,
    quote,
    reinstateablePolicies,
    request,
    router: connectRouter(history),
    scheduledTransfers,
    states,
    transferSchedules,
    translations,
    trivia,
    user,
    wyshAllocations,
    wyshCategories,
    wyshes,
    wyshesFlow,
  });

export default createRootReducer;
