import { divideEvenly } from "utils/divideEvenly";
import { DynamicFormContext } from "../DynamicFormContext";
import { useContext } from "react";
import { filter } from "lodash-es";

export const useDivideEvenly = () => {
  const { form, inputs } = useContext(DynamicFormContext);

  const divideEvenlyCommand = () => {
    const allocationInputs = filter(inputs, ({ key }) => key.startsWith("bene-allocation-"));

    const evenlyDividedValues = divideEvenly(allocationInputs.length);

    allocationInputs.forEach((input, index) => {
      form.onChange({
        target: {
          id: input.key,
          value: `${evenlyDividedValues[index]}`,
        },
      });
    });
  };

  return divideEvenlyCommand;
};
