import { gql } from "config/graphql";

export const ACCOUNTS_PRODUCT_FIELDS = gql`
  fragment AccountsProductFields on AccountsProduct {
    active
    category
    createdAt
    custom
    description
    id
    name
  }
`;
