import { gql } from "config/graphql";
import {
  IFinanceAccount,
  IFinanceBankAccountPayload,
  IFinanceBankTransactionsInput,
  IFinanceBankTransactionsPayload,
} from "config/types";
import { FINANCE_BANK_ACCOUNT, FINANCE_BANK_TRANSACTION } from "graphql/fragments";
import { getApolloClient } from "store/apollo";

export const financeBankAccountQuery = (
  financeAccountId: IFinanceAccount["id"]
): Promise<IFinanceBankAccountPayload> => {
  const client = getApolloClient();

  const query = gql`
    ${FINANCE_BANK_ACCOUNT}
    query FinanceBankAccount($financeAccountId: ID!) {
      bankAccount(financeAccountId: $financeAccountId) {
        ...FinanceBankAccountFields
      }
    }
  `;

  return client.query({
    query,
    variables: { financeAccountId },
  });
};

export const financeBankTransactionsQuery = (
  input: IFinanceBankTransactionsInput
): Promise<IFinanceBankTransactionsPayload> => {
  const client = getApolloClient();

  const query = gql`
    ${FINANCE_BANK_TRANSACTION}
    query FinanceBankTransactions(
      $beginDate: ISO8601Date!
      $endDate: ISO8601Date!
      $financeAccountId: ID!
    ) {
      financeBankTransactions(
        beginDate: $beginDate
        endDate: $endDate
        financeAccountId: $financeAccountId
      ) {
        ...FinanceBankTransactionFields
      }
    }
  `;

  return client.query({
    query,
    variables: { ...input },
  });
};
