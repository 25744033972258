import { IDynamicForm } from "config";
import React from "react";

import { MapGroupComponentToItems } from "../DynamicFormGroups";
import { DynamicFormItemComponent } from "../DynamicFormItems/index";

/* ------------------    TYPES    ------------------ */
interface IProps {
  groups: IDynamicForm["groups"];
  items: IDynamicForm["items"];
}

export function VerticalLayout({ groups, items }: IProps) {
  return (
    <div className="dynamic-form-layout__vertical">
      <MapGroupComponentToItems items={items} groups={groups}>
        {items.map(item => (
          <DynamicFormItemComponent item={item} key={item.key} />
        ))}
      </MapGroupComponentToItems>
    </div>
  );
}
