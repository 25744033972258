import "./ScrollingTabsLayout.scss";

import classNames from "classnames";
import Text from "components/Text/Text";
import { findIndex, throttle } from "lodash-es";
import { ILayoutProps } from "modules";
import { FC, useEffect, useState } from "react";

import { ITab } from "../tabs-layouts.types";
import { getActiveIdOnScroll, getOrCreateRef, scrollTo, SectionRefs } from "../tabs-utils";

type ContentType = {
  header: string;
  subheader: string;
  tabs: ITab[];
  onTabClick?(id: string): void;
};

const sectionRefs: SectionRefs = {};

const ScrollingTabsLayout: FC<ILayoutProps<ContentType>> = props => {
  const [scrollStyle, setScrollStyle] = useState({ left: "0px", width: "0px" });
  const [activeId, setActiveId] = useState("");
  const { className, children } = props;

  const tabsContent = props.content as ContentType;
  const header = tabsContent?.header;
  const subheader = tabsContent?.subheader;
  const tabs = tabsContent?.tabs;
  const onTabClick = tabsContent?.onTabClick;

  const classes = classNames("scrolling-tabs__container", className);

  useEffect(() => {
    const tabIndex = activeId ? findIndex(tabs, tab => tab.id === activeId) : 0;
    const tabsLength = tabs.length;

    const left = `${(tabIndex * 100) / tabsLength}%`;
    const width = `${100 / tabsLength}%`;

    setScrollStyle({ left, width });
  }, [activeId, tabs]);

  const handleScroll = throttle(() => {
    const bottomOfPage =
      Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
    const id = getActiveIdOnScroll(sectionRefs, 0, bottomOfPage);
    if (id) {
      setActiveId(id);
    }
  }, 100);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabClick = (id: string) => {
    onTabClick && onTabClick(id);
    scrollTo(sectionRefs, id);
  };

  return (
    <div className={classes}>
      <div className="scrolling-tabs__header-container">
        <Text tag="h3" text={header} className="scrolling-tabs__header" />
        <Text tag="p3" text={subheader} className="scrolling-tabs__subheader" />
      </div>
      <div className="scrolling-tabs__tabs-container">
        <div className="scrolling-tabs__tabs">
          {tabs.map(({ id, list }, index) => {
            const active = (index === 0 && !activeId) || activeId === id;
            const tabClasses = classNames("scrolling-tabs__tab", {
              "scrolling-tabs__tab--active": active,
            });
            return (
              <div className={tabClasses} key={id} onClick={() => handleTabClick(id)}>
                <Text tag="l6" text={list.label} />
              </div>
            );
          })}
          <div className="scroll-bar" style={scrollStyle} />
        </div>
      </div>

      <div className="scrolling-tabs__content-container">
        {tabs.map(({ id, content }) => (
          <section
            className="scrolling-tabs__content"
            key={id}
            id={id}
            ref={getOrCreateRef(sectionRefs, id)}>
            <Text tag="h4" className="scrolling-tabs__content-header">
              {content.header}
            </Text>
            {content.component}
          </section>
        ))}
        {children}
      </div>
    </div>
  );
};

export default ScrollingTabsLayout;
