import { WyshesCardSlider } from "components/Carousels";
import { MouseEvent } from "react";
import { useAppSelector } from "store";

export const HomeCardWyshesOwned = () => {
  const wyshes = useAppSelector(state => state.wyshAllocations.allocated);

  // Prevent HomeProductCard redirect when dragging slider
  const preventRedirectOnDrag = (event: MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <div className="home-product-wyshes-card" onClick={preventRedirectOnDrag}>
      <WyshesCardSlider wyshes={wyshes} />
    </div>
  );
};
