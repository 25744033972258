import classNames from "classnames";
import { PlatformContext } from "config/init/providers";
import { ClassName, DisclosureSlug } from "config/types";
import { CSSProperties, FC, useContext } from "react";
import { Link } from "react-router-dom";

interface IProps {
  slug: DisclosureSlug;
  style?: CSSProperties;
  className?: ClassName;
  downloadUrl?: string;
}

const EXTERNAL_LINKS: Partial<Record<DisclosureSlug, string>> = {
  "privacy-policy": "https://www.wysh.com/online-privacy",
  "terms-conditions": "https://www.wysh.com/terms",
};

const DisclosureLink: FC<IProps> = props => {
  const { children, slug, style, className, downloadUrl } = props;
  const { endpoint } = useContext(PlatformContext);

  const linkProps = {
    style,
    className: classNames("disclosure-link", className),
    target: "_blank",
  };

  const getPathname = () => {
    if (!!downloadUrl) {
      return `${endpoint}/${downloadUrl}`;
    }
    return `/disclosure/${slug}`;
  };

  return EXTERNAL_LINKS[slug] ? (
    <a {...linkProps} href={EXTERNAL_LINKS[slug]}>
      {children}
    </a>
  ) : (
    <Link {...linkProps} to={{ pathname: getPathname() }}>
      {children}
    </Link>
  );
};

export default DisclosureLink;
