import "./FullWidthLayout.scss";

import classNames from "classnames";
import BackNav from "components/BackNav/BackNav";
import { OrString } from "config/types";
import Container from "containers/Container/Container";
import { isString } from "lodash-es";
import { ILayoutProps } from "modules";
import { ComponentProps, FC } from "react";

type ContentType = {
  backNav: OrString<ComponentProps<typeof BackNav>>;
};

const FullWidthLayout: FC<ILayoutProps<ContentType>> = ({
  children,
  className,
  content = {} as ContentType,
}) => {
  const backNav = content?.backNav;
  const backNavProps = isString(backNav) ? { pathname: backNav } : backNav;

  const classes = classNames("full-width-layout__container", className);

  return (
    <Container className={classes} maxWidth="full">
      {backNav && <BackNav {...(backNavProps as ComponentProps<typeof BackNav>)} />}
      {children}
    </Container>
  );
};

export default FullWidthLayout;
