import "focus-visible";
import "./App.scss";

import AppProvider from "config/init/providers";
import Footer from "containers/Footer/Footer";
import Header from "containers/Header/Header";
import { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useStore } from "react-redux";
import Routes from "router/Routes/Routes";
import smoothscroll from "smoothscroll-polyfill";
import { history } from "store";
import { closeChat } from "utils/customerSupport";
import { initTransUnion } from "utils/fraud";

smoothscroll.polyfill();

const App: FC = () => {
  const store = useStore();

  useEffect(() => {
    initTransUnion();
  }, []);

  const handleClick = () => {
    // Close Zendesk when user clicks off the chat window
    closeChat();
  };

  return (
    <AppProvider store={store} history={history}>
      <div className="App" onClick={handleClick}>
        <Helmet>
          <meta
            name="description"
            content="Term life insurance tailored to you because you choose what's in it. With flexible coverage, 100% online process, instant coverage, and over-the-top customer support, we make Wyshes come true. Starting at $9/mo."
          />
        </Helmet>
        <Header />
        <div className="App-content__container">
          <Routes />
        </div>
        <Footer />
      </div>
    </AppProvider>
  );
};

export default App;
