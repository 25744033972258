import Text from "components/Text/Text";
import { useTranslation } from "modules";
import formatCommas from "utils/formatCommas";

export interface IPaymentAmountData {
  dueToday: number;
  monthlyPremium: number;
}

function ReinstatablePolicyCoverages({ dueToday, monthlyPremium }: IPaymentAmountData) {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <Text
          tag="n3"
          text={t("dollar.amount", "$%<amount>s", { amount: formatCommas(dueToday) })}
        />
        <Text tag="c6" text={t("purchase_policy.due_today.label", "DUE TODAY")} />
      </div>
      <div>
        <Text
          tag="n3"
          text={t("dollar.amount", "$%<amount>s", {
            amount: formatCommas(monthlyPremium),
          })}
        />
        <Text tag="c6" text={t("purchase_policy.monthly_premium.label", "MONTHLY PREMIUM")} />
      </div>
    </>
  );
}

export default ReinstatablePolicyCoverages;
