import { FetchResult, HttpLink, Observer, ServerError } from "@apollo/client";
import { GraphQLErrors } from "@apollo/client/errors";
import { ErrorResponse, onError } from "@apollo/client/link/error";
import { globalErrors } from "config/graphql/globalErrors";
import { ErrorCodes, IAppError } from "config/types";
import { get } from "lodash-es";
import { Store } from "store";
import { logout } from "store/actions/authentication";
import { buildGraphQlError, getAppError } from "utils/error";

import { LinkContext } from ".";

export const errorHandler = (
  error: ErrorResponse,
  context: {
    store: Store;
    errors: Partial<Record<ErrorCodes, any>>;
    observer: Observer<FetchResult>;
  }
) => {
  const { store, errors } = context;
  const { graphQLErrors, networkError } = error;
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);

  const graphQlError = getAppError(error);
  const errorCode = get(graphQlError, "code", "") as ErrorCodes;
  if (errorCode && errors[errorCode]) {
    return errors[errorCode](store);
  } else if ((networkError as ServerError).statusCode === 401) {
    store.dispatch(logout());
  }
};

interface INoticeErrorsOnNewRelic {
  graphQLErrors?: GraphQLErrors;
  networkError?: ServerError;
  store: Store;
}

const noticeErrorsOnNewRelic = ({
  graphQLErrors = [],
  networkError,
  store,
}: INoticeErrorsOnNewRelic) => {
  if (!window.newrelic) {
    return;
  }

  const {
    analyticsTracker: { utm: utmParams },
    user: { id: userId },
  } = store.getState();

  if (userId) {
    window.newrelic.setUserId(userId);
  }

  if (networkError) {
    // Wrapped in a try/catch to get the stack trace on Safari/IE
    try {
      throw networkError;
    } catch (err) {
      window.newrelic.noticeError(networkError.message, {
        statusCode: networkError.statusCode,
        utmParams: utmParams && JSON.stringify(utmParams),
      });
    }
  }

  graphQLErrors.forEach(graphQLError => {
    // Wrapped in a try/catch to get the stack trace on Safari/IE
    try {
      throw graphQLError;
    } catch (err) {
      window.newrelic.noticeError(graphQLError.message, {
        field: graphQLError.extensions?.field,
        full_message: graphQLError.extensions?.full_message,
        path: graphQLError.path?.join("/") || "",
        utmParams: utmParams && JSON.stringify(utmParams),
      });
    }
  });
};

const unauthorizedError = (networkError: ServerError, graphQlError: IAppError) => {
  const statusCode = networkError?.statusCode;
  const errorCode = graphQlError?.code || "";
  const graphQLUnauthorized = errorCode.toLowerCase() === "graph_api_unauthorized";
  return statusCode === 401 || graphQLUnauthorized;
};

export const errorLink = ({ store }: LinkContext) => {
  return onError(({ graphQLErrors, networkError }) => {
    const graphQlError = buildGraphQlError({ graphQLErrors });
    const errorCode = get(graphQlError, "code", "") as ErrorCodes;

    const globalErrorCallback = globalErrors[errorCode];
    const unauthorized = unauthorizedError(networkError as ServerError, graphQlError);

    noticeErrorsOnNewRelic({
      graphQLErrors,
      networkError: networkError as ServerError,
      store,
    });

    if (errorCode && globalErrorCallback) {
      return globalErrorCallback(store);
    } else if (unauthorized) {
      store.dispatch(logout());
    }
  });
};

export const endpointLink = ({ endpoint }: LinkContext) =>
  new HttpLink({ uri: `${endpoint}/graphql` || "/graphql" });
