import { settingsUnlinkAccountEvent } from "analytics/billingEvents";
import FormError from "components/FormError/FormError";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { IPlaidItem } from "config/types";
import { get } from "lodash-es";
import { Layout, useTranslation } from "modules";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { unlinkAccount } from "store/actions/bank";
import { closeModal } from "store/actions/modal";
import { getThunkError } from "utils/error";

const UnlinkBankModal: FC<IModalProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const closeModalWrapper = () => dispatch(closeModal());
  const modalMetadata = useSelector((state: RootState) => state.modal.data);

  // TODO: Set this value somewhere?
  const [error] = useState("");
  const [loading, setLoading] = useState(false);

  const bank = get(modalMetadata, "bank", "") as IPlaidItem;

  const unlinkBankOnClick = async () => {
    try {
      setLoading(true);

      const response = await dispatch(unlinkAccount(bank.id));
      const error = getThunkError(response);
      if (error) {
        throw error;
      }

      dispatch(settingsUnlinkAccountEvent());

      closeModalWrapper();
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
      closeModalWrapper();
    }
  };

  const ctaButtons = [
    {
      text: t("settings.billing.unlink_bank.modal.button_cancel", "I'm sure"),
      isLoading: loading,
      disabled: loading || !!error,
      onClick: unlinkBankOnClick,
    },
    {
      text: t("settings.billing.unlink_bank.modal.button_confirm", "Cancel"),
      subType: "text-dark",
      disabled: loading,
      onClick: closeModalWrapper,
    },
  ];

  const content = {
    header: t(
      "settings.billing.unlink_bank.modal.title",
      "Sure you want to unlink from %<institutionName>s?",
      { institutionName: bank.plaidInstitution.name }
    ),
    onClose: closeModalWrapper,
    button: ctaButtons,
    icon: "BlobBank",
  };

  return (
    <Layout as="ModalLayout" content={content}>
      <div>{error && <FormError error={error} />}</div>
    </Layout>
  );
};

export default UnlinkBankModal;
