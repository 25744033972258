import { useEffect, useState } from "react";
import { useAppDispatch } from "store";
import { getProductOfferings } from "store/actions/product-offerings";

export const useFetchOffersData = () => {
  const dispatch = useAppDispatch();
  const [offersFetched, setOffersFetched] = useState(false);

  useEffect(() => {
    const fetchOffersData = async () => {
      try {
        await dispatch(getProductOfferings());
        setOffersFetched(true);
      } catch (err) {
        return err;
      } finally {
        setOffersFetched(true);
      }
    };

    fetchOffersData();
  }, [dispatch]);

  return { offersFetched };
};
