import "./TriviaIntro.scss";

import blobBottomSvg from "assets/images/trivia/blob-bottom.svg";
import blobTopSvg from "assets/images/trivia/blob-top.svg";
import rocketship from "assets/images/trivia/rocketship.svg";
import classNames from "classnames";
import Text from "components/Text/Text";
import { TriviaIntroType } from "config/types";
import { useTranslation } from "modules";
import { FC } from "react";
import { Helmet } from "react-helmet";

interface IProps {
  introSection: TriviaIntroType;
}

const TriviaIntro: FC<IProps> = ({ introSection }) => {
  const { t } = useTranslation();

  const classes = classNames("trivia-intro__container", {
    "trivia-intro__container--in-progress": introSection === "inProgress",
    "trivia-intro__container--lets-play": introSection === "letsPlay",
    "trivia-intro__container--rocketship": introSection === "rocketship",
  });

  return (
    <div className={classes}>
      <Helmet>
        <link rel="preload" as="image" href={rocketship} />
      </Helmet>
      <img className="trivia-intro__blob trivia-intro__blob--top" src={blobTopSvg} />

      <Text className="trivia-intro__text trivia-intro__text--in-progress" tag="h2">
        {t("trivia.intro.quote_in_progress", "Your quote is in progress")}
      </Text>

      <Text
        className="trivia-intro__loading-indicator trivia-intro__loading-indicator--in-progress"
        tag="h2">
        ...
      </Text>

      <Text className="trivia-intro__text trivia-intro__text--lets-play" tag="h2">
        {t("trivia.intro.rando", "While we wait, let's play!")}
      </Text>

      <img className="trivia-intro__blob trivia-intro__blob--bottom" src={blobBottomSvg} />

      {introSection === "rocketship" ? (
        <img className="trivia-intro__rocketship" src={rocketship} />
      ) : null}
    </div>
  );
};

export default TriviaIntro;
