import React from "react";
import SuggestionsItem from "./AddressInputSuggestionItem";
import List from "components/List/List";
import { map } from "lodash-es";
import classNames from "utils/classNames";

interface SuggestionsProps {
  isOpen: boolean;
  highlightedIndex: number;
  getItemProps: any;
  getMenuProps: any;
  suggestions: any;
  selectSuggestion?: (suggestion: any) => void;
}

const Suggestions: React.FC<SuggestionsProps> = ({
  isOpen,
  getItemProps,
  getMenuProps,
  highlightedIndex,
  suggestions,
}) => {
  const search = suggestions?.search || "";
  const { ref, ...menuProps } = getMenuProps();

  const suggestionItems = suggestions?.result || [];
  const emptySuggestions = suggestionItems.length === 0;
  const classes = classNames("address-verifications-suggestions__wrapper", {
    "appear-hidden": emptySuggestions || !isOpen,
  });

  return (
    <div
      className={classNames("address_input__suggestions_container", {
        address_input__suggestions_container__opened: isOpen,
      })}>
      <div ref={ref} className={classes}>
        <List
          className={["address-verifications-suggestions__list", { open: isOpen }]}
          {...menuProps}>
          {isOpen &&
            map(suggestionItems, (item: any, index: number) => (
              <SuggestionsItem
                key={index}
                item={item}
                index={index}
                search={search}
                highlighted={highlightedIndex === index}
                getItemProps={getItemProps}
              />
            ))}
        </List>
      </div>
    </div>
  );
};

export default Suggestions;
