import { createReducer } from "@reduxjs/toolkit";
import { ExternalAccount } from "config/types/externalAccount.type";
import { keyBy } from "lodash-es";
import { logout } from "store/actions/authentication";
import { fetchExternalAccounts } from "store/actions/externalAccounts";

interface IExternalAccountsState {
  ids: ExternalAccount["externalAccountId"][];
  byId: Record<ExternalAccount["externalAccountId"], ExternalAccount>;
  hasLoaded: boolean;
}

/* ------------    EXTERNAL ACCOUNTS STATE     ------------------ */
const defaultState: IExternalAccountsState = {
  ids: [],
  byId: {},
  hasLoaded: false,
};

/* ------------    REDUCER     ------------------ */
const externalAccounts = createReducer(defaultState, builder => {
  builder
    .addCase(fetchExternalAccounts.fulfilled, (state, action) => {
      const externalAccounts = action.payload || [];

      state.ids = externalAccounts.map(externalAccount => externalAccount.externalAccountId);
      state.byId = keyBy(externalAccounts, "externalAccountId");
      state.hasLoaded = true;
    })
    .addCase(fetchExternalAccounts.rejected, state => {
      state.hasLoaded = true;
    })
    .addCase(logout, () => defaultState);
});

export default externalAccounts;
