import { gql } from "config/graphql";
import {
  BusinessSlugTypes,
  GQLUserResponse,
  ICoverageEditorPremium,
  ProductDetailSlug,
  TermUnit,
} from "config/types";
import {
  CLASSIFICATION_FIELDS,
  INSURANCE_APPLICATION_FIELDS,
  PAYOUT_DETAILS_FIELDS,
  PRODUCT_DETAIL_FIELDS,
} from "graphql/fragments";
import { IAdjustCoverageInput } from "graphql/queries/estimate";
import { getApolloClient } from "store/apollo";

export const fetchProductOfferings = async (): Promise<GQLUserResponse<"activeConstellations">> => {
  const client = getApolloClient();

  const query = gql`
    ${CLASSIFICATION_FIELDS}
    ${INSURANCE_APPLICATION_FIELDS}
    ${PRODUCT_DETAIL_FIELDS}
    ${PAYOUT_DETAILS_FIELDS}
    query ProductOfferings {
      me {
        activeConstellations {
          activeInsuranceMembership {
            latestInsuranceApplication {
              ...InsuranceApplicationFields
            }
            productsOfferings {
              id
              amount
              status
              product {
                ...ProductFields
              }
              payoutDetails {
                ...PayoutDetailsFields
              }
              premiums {
                amount
                billingFrequency
              }
              expiresAt
              extensionAvailable
              createdAt
              insuredsAgeAtTerm
              allocationCoverage
              termUnit
              termDuration
              classification {
                ...ClassificationFields
              }
            }
          }
        }
      }
    }
  `;

  return client.query({
    query,
  });
};

export interface IAdjustProductOfferingInput {
  id: string;
  amount: number;
  termUnit?: TermUnit;
  termDuration?: number;
}

interface IAdjustProductOfferingResponse {
  data: {
    adjustProductOffering: ICoverageEditorPremium;
  };
}

export const adjustProductOfferingQuery = (
  input: IAdjustCoverageInput
): Promise<IAdjustProductOfferingResponse> => {
  const client = getApolloClient();

  const query = gql`
    query adjustProductOffering(
      $id: ID!
      $termUnit: SharedTermUnit
      $termDuration: Int
      $amount: Int
    ) {
      adjustProductOffering(
        id: $id
        termUnit: $termUnit
        termDuration: $termDuration
        amount: $amount
      ) {
        annualPremium
        monthlyPremium
        allocationsCoveredPercentage
      }
    }
  `;

  return client.query({
    variables: {
      ...input,
    },
    query,
  });
};

interface IProductAvailableInput {
  businessSlug: BusinessSlugTypes;
  productSlug: ProductDetailSlug;
  stateId: string;
}

interface IProductAvailableResponse {
  data: {
    productAvailableInState: boolean;
  };
}

export const productAvailableInStateQuery = (
  input: IProductAvailableInput
): Promise<IProductAvailableResponse> => {
  const client = getApolloClient();

  const query = gql`
    query ProductAvailableInState($businessSlug: String!, $productSlug: String!, $stateId: ID!) {
      productAvailableInState(
        businessSlug: $businessSlug
        productSlug: $productSlug
        stateId: $stateId
      )
    }
  `;

  return client.query({
    variables: {
      ...input,
    },
    query,
  });
};
