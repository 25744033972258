import c from "classnames";
import UnitizedInput from "components/UnitizedInput/UnitizedInput";
import { useContext } from "react";

import { DynamicFormContext } from "../DynamicFormContext";

import { IDynamicFormItemProps } from ".";

export function UnitizedInputItem({ item }: IDynamicFormItemProps<"inputUnitized">) {
  const { form } = useContext(DynamicFormContext);

  const { content, key } = item;
  const { options } = content;
  const { value } = form.getFormInput(key);

  const classes = c("dynamic-form-item form-item--input-unitized", `form-item--${key}`);

  return (
    <UnitizedInput
      className={classes}
      error={form.getInputError(key)}
      errorMessage={form.getInputErrorMessage(key)}
      id={key}
      onChange={form.onChange}
      options={options}
      value={value || []}
    />
  );
}
