import Text from "components/Text/Text";
import { FC } from "react";

interface IProps {
  bodyText: string;
}

export const HomeCardMarketingRecapture: FC<IProps> = ({ bodyText }) => {
  return <Text className="color-secondary-text" tag="l5-label" text={bodyText} />;
};
