import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ICase } from "config/types";
import { getCasesQuery } from "graphql/queries/case";
import { SET_CASES } from "store/constants";
import { getAppError } from "utils/error";

/* ------------       ACTIONS     ------------------ */

export const setCases = createAction(SET_CASES, (cases: ICase[]) => {
  return {
    type: SET_CASES,
    payload: {
      cases,
    },
  };
});

/* ------------       THUNKS      ------------------ */

export const getCases = createAsyncThunk("cases/getCases", async (_, { rejectWithValue }) => {
  try {
    const response = await getCasesQuery();

    return response.data.cases;
  } catch (err) {
    const errorCode = getAppError(err);

    return rejectWithValue(errorCode);
  }
});
