const isUrl = (str: string) => {
  const pattern = /^(http[s]?:\/\/www\.|http[s]?:\/\/)+[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  if (!pattern.test(str)) {
    return false;
  } else {
    return true;
  }
};

export default isUrl;
