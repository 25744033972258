import { IMembership } from "config";
import { gql } from "config/graphql";
import { FINANCE_MEMBERSHIP_FIELDS } from "graphql/fragments";
import { getApolloClient } from "store/apollo";

export const membershipQuery = (
  membershipId: IMembership["id"]
): Promise<{ data: { membership: IMembership } }> => {
  const client = getApolloClient();

  const query = gql`
    ${FINANCE_MEMBERSHIP_FIELDS}
    query FinanceMembership($id: ID!) {
      membership(id: $id) {
        ...MembershipFields
      }
    }
  `;

  return client.query({ query, variables: { id: membershipId } });
};
