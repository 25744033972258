import "./DecorationItem.scss";

import { IDynamicFormItemProps } from "../";

import { useDecorationItemBySlug } from "./useDecorationItemBySlug";

export function DecorationItem({ item }: IDynamicFormItemProps<"decoration">) {
  const { slug } = item.content;

  const DecoratorItem = useDecorationItemBySlug(slug);

  return DecoratorItem;
}
