import "./CustomLink.scss";

import classNames from "classnames";
import Icon from "components/Icon/Icon";
import Text, { TextType } from "components/Text/Text";
import { ClassName, IDataProps } from "config/types";
import { defaultTo, isEmpty } from "lodash-es";
import { ConditionalWrapper, Link } from "modules";
import { ComponentProps, FC } from "react";
import { NavLink } from "react-router-dom";

interface IProps extends IDataProps, Omit<ComponentProps<typeof Link>, "className"> {
  text?: string;
  tag?: TextType;
  type?: "light" | "primary" | "secondary";
  className?: ClassName;
  activeClassName?: string;
  exact?: boolean;
  disabled?: boolean;
  newTab?: boolean;
  icon?: { left?: string; right?: string };
  border?: boolean;
  bold?: boolean;
}

const CustomLink: FC<IProps> = props => {
  const {
    className,
    activeClassName,
    exact,
    disabled,
    icon,
    newTab,
    text,
    dataTestId,
    dataAnalyticsId,
    children,
    type,
    tag,
    border,
    bold = true,
    ...linkProps
  } = props;

  const textTag = defaultTo(tag, "l4");
  const linkClass = classNames(
    "custom-link link",
    { disabled, border, "custom-link--bold": bold },
    className,
    type
  );
  const TextContent = isEmpty(text) ? null : (
    <Text className="custom-link--text" tag={textTag}>
      {text}
    </Text>
  );

  const content = defaultTo(TextContent, children);

  const itemProps = {
    ...linkProps,
    className: linkClass,
    "data-testid": dataTestId,
    "data-analyticsid": dataAnalyticsId,
    target: newTab ? "_blank" : undefined,
    rel: "noopener noreferrer",
  };

  const isNavLink = activeClassName !== undefined;
  const navLinkProps = {
    exact,
    activeClassName: activeClassName || "",
  };

  return (
    <ConditionalWrapper
      condition={true}
      wrapper={isNavLink ? NavLink : Link}
      props={isNavLink ? { ...itemProps, ...navLinkProps } : itemProps}>
      {icon?.left && <Icon src={icon.left} className="left-icon" />}
      {content}
      {icon?.right && <Icon src={icon.right} className="right-icon" />}
    </ConditionalWrapper>
  );
};

export default CustomLink;
