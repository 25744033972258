import { createAsyncThunk } from "@reduxjs/toolkit";
import { IInitiateFinanceOnboardingInput, IUpdateDepositInput } from "config";
import {
  initiateFinanceOnboardingMutation,
  removeOnboardingAutoDepositMutation,
  updateDepositMutation,
} from "graphql/mutations/membership";
import { membershipQuery } from "graphql/queries/membership";
import { RootState } from "store";
import { getFinanceMembershipId, selectExternalAccount } from "store/selectors";
import { getAppError } from "utils/error";

import { fetchFinanceCustomer } from "./financeCustomer";

/* ------------       THUNKS      ------------------ */

export const initiateFinanceOnboarding = createAsyncThunk(
  "membership/initiateFinanceOnboarding",
  async (
    {
      accountNumber = null,
      accountType = null,
      routingNumber = null,
      initialFundingAmount = 0,
      plaidAccountId = null,
    }: Partial<IInitiateFinanceOnboardingInput>,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const membershipId = getFinanceMembershipId(state);

      if (!membershipId) {
        throw Error("Missing input values. Please try again later");
      }

      const input = {
        initialFundingAmount,
        membershipId,
        plaidAccountId,
        accountNumber,
        accountType,
        routingNumber,
      };

      const { data } = await initiateFinanceOnboardingMutation(input);
      dispatch(fetchFinanceCustomer());
      await dispatch(fetchMembership());

      return data.initiateFinanceOnboarding.membership;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const updateAutoDeposit = createAsyncThunk(
  "membership/updateAutoDeposit",
  async (
    {
      accountNumber = null,
      accountType = null,
      routingNumber = null,
      firstTransferDate = null,
      initialAutoDepositAmount = null,
      initialFundingAmount = null,
      plaidAccountId = null,
    }: Partial<IUpdateDepositInput>,
    { getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const membershipId = getFinanceMembershipId(state);
      const externalAccount = selectExternalAccount(state);

      if (!membershipId) {
        throw Error("Missing input values. Please try again later");
      }

      const input = externalAccount
        ? {
            accountNumber: externalAccount.accountNumber,
            accountType: externalAccount.accountType,
            firstTransferDate,
            initialAutoDepositAmount,
            initialFundingAmount,
            membershipId,
            plaidAccountId: null,
            routingNumber: externalAccount.routingNumber,
          }
        : {
            accountNumber: accountNumber,
            accountType: accountType,
            firstTransferDate,
            initialAutoDepositAmount: initialAutoDepositAmount,
            initialFundingAmount: initialFundingAmount,
            membershipId,
            plaidAccountId: plaidAccountId,
            routingNumber: routingNumber,
          };

      const { data } = await updateDepositMutation(input);

      return data.updateDeposit.membership;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const cancelOnboardingAutoDeposit = createAsyncThunk(
  "cancelOnboardingAutoDeposit",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const membershipId = getFinanceMembershipId(state);
      const { data } = await removeOnboardingAutoDepositMutation({ membershipId });
      await dispatch(fetchMembership());

      return data.removeOnboardingAutoDeposit.response.success;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const fetchMembership = createAsyncThunk(
  "fetchMembership",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const membershipId = getFinanceMembershipId(state);

      const { data } = await membershipQuery(membershipId);

      return data.membership;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState;
      const membershipId = getFinanceMembershipId(state);

      if (!membershipId) {
        return false;
      }
    },
  }
);
