import { isFunction } from "lodash-es";

export const initTransUnion = () => {
  const windowWithTU = window as TransUnion.WindowWithTU;

  if (isFunction(windowWithTU.TrustevV2?.InitIovation)) {
    try {
      windowWithTU.TrustevV2?.InitIovation();
    } catch (error) {
      console.error(error);
    }
  }
};

export const getBlackbox = () => {
  const windowWithTU = window as TransUnion.WindowWithTU;

  if (isFunction(windowWithTU.IGLOO?.getBlackbox)) {
    const blackbox = windowWithTU.IGLOO?.getBlackbox();
    return blackbox;
  } else {
    return null;
  }
};
