import { useContext } from "react";

import { DynamicFormContext } from "../DynamicFormContext";

export const useValidateForm = () => {
  const { form } = useContext(DynamicFormContext);

  const validateFormCommand = async () => {
    const isFormValid = form.validateAllFields();

    if (!isFormValid) {
      throw Error("Invalid form value");
    }
  };

  return validateFormCommand;
};
