import ScreenLoader from "components/ScreenLoader/ScreenLoader";
import CreatePhoneNumber from "containers/CreatePhoneNumber/CreatePhoneNumber";
import VerifySession from "containers/VerifySession/VerifySession";
import { isEmpty, some } from "lodash-es";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { getIsIdentified } from "store/selectors";

const VerifySessionRoute: FC = () => {
  // const { sessionVerificationChecked } = useSelector((state: RootState) => state.authentication);
  const profile = useSelector((state: RootState) => state.profile);
  const { phoneNumbers, preVerifiedDataFetched } = profile;
  const hasPhone = useSelector((state: RootState) => !isEmpty(state.profile.phoneNumbers));
  const hasActivePhoneNumber = some(phoneNumbers, ["status", "active"]);
  const isIdentified = useSelector(getIsIdentified);

  if (!preVerifiedDataFetched) {
    return <ScreenLoader />;
  }

  if (!hasPhone && !isIdentified) {
    return <CreatePhoneNumber />;
  }

  return <VerifySession type={hasActivePhoneNumber ? "session" : "phone"} />;
};

export default VerifySessionRoute;
