import { IDocument } from "config";
import { fetchDocumentById, fetchDocumentCategories } from "graphql/queries/documents";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAppError } from "utils/error";

export const getDocumentById = createAsyncThunk(
  "documents/getDocumentById",
  async (documentId: IDocument["id"], { rejectWithValue }) => {
    try {
      const { data } = await fetchDocumentById(documentId);

      return data.document;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const getDocumentsCategories = createAsyncThunk(
  "documents/getCategories",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await fetchDocumentCategories();
      return data.documentCategories;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
