/* istanbul ignore file */
import "./index.scss";

import App from "App";
import { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "store";

import * as serviceWorker from "./config/serviceWorker";

const Root = (
  <Suspense fallback={null}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>
);

ReactDOM.render(Root, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
