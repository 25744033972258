import "./CustomerSupport.scss";

import {
  CustomerSupportCall,
  CustomerSupportChat,
  CustomerSupportFAQ,
} from "components/CustomerSupportTiles/CustomerSupportTiles";
import Text from "components/Text/Text";
import { Layout, useTranslation } from "modules";
import { FC } from "react";

import GirlOnCloudSrc from "assets/images/illustrations/girl_on_cloud.svg";

const CustomerSupport: FC = () => {
  const { t } = useTranslation();

  return (
    <Layout
      as="FullWidthLayout"
      className="account-customer-support__container"
      content={{}}
      backNav={{ hasBackNav: true }}>
      <div className="account-customer-support__column account-customer-support__content">
        <Text
          className="account-customer-support__header"
          tag="h4"
          text={t("customer_support.header", "Need Help?")}
        />

        <div className="account-customer-support__tiles">
          <CustomerSupportChat />
          <CustomerSupportCall />
          <CustomerSupportFAQ />
        </div>
      </div>

      <div className="account-customer-support__column account-customer-support__image-container">
        <img alt="" src={GirlOnCloudSrc} />
      </div>
    </Layout>
  );
};

export default CustomerSupport;
