import { createSelector } from "@reduxjs/toolkit";
import { find } from "lodash-es";
import { RootState } from "store";

import { getLatestInsuranceApplication } from "./getHasRUWCase";

const getHasMUWNotification = (state: RootState) => {
  const findMUWNotification = find(
    state.notifications.notifications,
    notification => notification?.metadata?.type === "manual_underwriting_decision"
  );

  return !!findMUWNotification;
};

export const getHasMUWResult = createSelector(
  getHasMUWNotification,
  getLatestInsuranceApplication,
  (hasMUWNotification, latestApplication) =>
    hasMUWNotification &&
    (latestApplication.result === "accepted" || latestApplication.result === "rejected")
);

export const getMUWNotificationId = (state: RootState) => {
  const findMUWNotification = find(
    state.notifications.notifications,
    notification => notification?.metadata?.type === "manual_underwriting_decision"
  );

  return findMUWNotification?.id;
};

export const getHasReadMUWResult = (state: RootState) => {
  const findMUWNotification = find(
    state.notifications.readNotifications,
    notification => notification?.metadata?.type === "manual_underwriting_decision"
  );

  return !!findMUWNotification;
};
