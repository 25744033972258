import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { StripeAPIKey } from "config";
import { FC } from "react";

const StripeProvider: FC = props => {
  const { children } = props;

  const stripePromise = loadStripe(StripeAPIKey);

  return <Elements stripe={stripePromise}>{children}</Elements>;
};
export default StripeProvider;
