import Icon from "components/Icon/Icon";

interface IProps {
  hasPortal: boolean;
  isExit: boolean;
  iconSrc?: string;
}

export function BackNavIcon({ hasPortal, iconSrc, isExit }: IProps) {
  const src = (function() {
    switch (true) {
      case !!iconSrc:
        return iconSrc;

      case hasPortal && isExit:
        return "Close";

      case hasPortal && !isExit:
        return "BackCarat";

      case !hasPortal && isExit:
        return "Exit";

      default:
        return "BackArrow";
    }
  })();

  return <Icon src={src} size="sm" />;
}
