import "./List.scss";

import { FC, CSSProperties, HTMLProps } from "react";
import classNames from "classnames";
import { get, defaultTo } from "lodash-es";
import { ClassName } from "config/types";

export const elementTypes = {
  ul: "ul",
  ol: "ol",
  nav: "nav",
};

export type ElementType = keyof typeof elementTypes;

type IProps = HTMLProps<HTMLElement> & {
  tag?: ElementType;
  className?: ClassName;
  style?: CSSProperties;
};

const List: FC<IProps> = props => {
  const { children, tag, className, style, ...nativeProps } = props;

  const defaultTag = defaultTo(tag, "ul");

  const Element: any = get(elementTypes, defaultTag);

  const classes = classNames("list", className);

  return (
    <Element style={style} className={classes} {...nativeProps}>
      {children}
    </Element>
  );
};

export default List;
