import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";

const ReinstatementActivePolicyModal: FC<IModalProps> = ({ closeModal, context }) => {
  const { t } = useTranslation();
  const { callbacks } = context;

  const isStartingReinstatement = useSelector(
    (state: RootState) => state.reinstateablePolicies.isRequestingToStartReinstatement
  );

  const content = {
    icon: "BlobAlert",
    header: t("modal.reinstatement.active_policy.title", "You have an active policy"),
    subheader: t(
      "modal.reinstatement.active_policy.body",
      "As a heads up, because you have an active term life or accidental death policy with Wysh, your reinstatement application will be rejected."
    ),
    button: [
      {
        disabled: isStartingReinstatement,
        onClick: closeModal,
        text: t("modal.reinstatement.active_policy.cta.cancel", "NEVER MIND"),
      },
      {
        disabled: isStartingReinstatement,
        isLoading: isStartingReinstatement,
        onClick: async () => {
          if (callbacks?.onContinue) {
            await callbacks.onContinue();
          }

          await closeModal();
        },
        subType: "text-dark-no-border",
        text: t("modal.reinstatement.active_policy.cta.apply", "APPLY ANYWAY"),
      },
    ],
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default ReinstatementActivePolicyModal;
