import Text from "components/Text/Text";
import { useTranslation } from "modules";

interface IPolicyCoverages {
  amount: string;
  isQuoteAccidentalDeath: boolean;
  term: number;
}

function PolicyCoverages({ amount, isQuoteAccidentalDeath, term }: IPolicyCoverages) {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <Text tag="n3" text={t("dollar.amount", "$%<amount>s", { amount: amount })} />
        <Text tag="c6" text={t("purchase_policy.amount.label", "TOTAL COVERAGE")} />
      </div>
      <div>
        <Text tag="n3" text={term} />
        <Text
          tag="c6"
          text={
            isQuoteAccidentalDeath
              ? t("purchase_policy.ad.duration.label", "UNTIL AGE")
              : t("purchase_policy.term.duration.label", "YEAR TERM")
          }
        />
      </div>
    </>
  );
}

export default PolicyCoverages;
