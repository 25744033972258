import { gql } from "config/graphql";
import { getApolloClient } from "store/apollo";

interface IResponse {
  data: {
    translations: {
      key: string;
      value: string;
    }[];
  };
}

export const translationsQuery = async (): Promise<IResponse> => {
  const client = getApolloClient();

  const query = gql`
    query translations {
      translations(slug: "web") {
        key
        value
      }
    }
  `;

  return client.query({
    fetchPolicy: "cache-first",
    query,
  });
};
