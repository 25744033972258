import { createSelector } from "@reduxjs/toolkit";
import { find } from "lodash-es";
import { RootState } from "store";

import { getIsFinancePreBetaFlag } from "./getFeatureFlags";

export const selectConstellation = (state: RootState) => state.constellation;

export const getIsUserWyshFinanceEnabled = createSelector(
  selectConstellation,
  getIsFinancePreBetaFlag,
  (constellation, isFinancePreBeta) => {
    const isFinanceEnabled = find(constellation.featureFlags, {
      name: "wysh-financial",
      enabled: true,
    });

    return isFinanceEnabled && !isFinancePreBeta;
  }
);
