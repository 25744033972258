import { gql } from "config/graphql";
import { IUser } from "config/types";
import { getApolloClient } from "store/apollo";

export interface IEligibleResponse {
  data: {
    me: Pick<IUser, "activeConstellations">;
  };
}

export const fetchEligibleForUnderwriting = (): Promise<IEligibleResponse> => {
  const client = getApolloClient();

  const query = gql`
    query fetchEligibleForUnderwriting {
      me {
        activeConstellations {
          activeInsuranceMembership {
            eligibleForCoverage
            eligibleForUnderwriting
            activePolicy {
              id
              status
            }
          }
        }
      }
    }
  `;

  return client.query({ query });
};
