import { createAsyncThunk } from "@reduxjs/toolkit";
import { BusinessSlugTypes } from "config";
import { initiatePasswordResetMutation, passwordMutation } from "graphql/mutations/password";
import {
  createPhoneNumberMutation,
  decommissionUserMutation,
  ICreatePhoneNumerInput,
  IRegisterUserInput,
  registerUserMutation,
  setUpUserForNewLineOfBusinessMutation,
} from "graphql/mutations/user";
import { fetchCurrentUserQuery } from "graphql/queries/user";
import { get } from "lodash-es";
import { RootState } from "store";
import { eventFired } from "store/actions/analytics";
import { endRequest, startRequest } from "store/actions/request";
import { getActiveMembershipBusinesses, getIsRequestPendingByResource } from "store/selectors";
import { logBranchRegisterEvent } from "utils/branch";
import { getAppError } from "utils/error";

/* ------------       THUNKS      ------------------ */

export const getCurrentUser = createAsyncThunk(
  "user/getCurrentUser",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await fetchCurrentUserQuery();

      return data;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const initiatePasswordReset = createAsyncThunk(
  "user/initiateResetPassword",
  async ({ email }: any, { rejectWithValue, dispatch }) => {
    dispatch(startRequest("resetPassword"));
    try {
      const { data } = await initiatePasswordResetMutation(email);

      const success = get(data, "initiatePasswordReset.response.success");
      if (!success) {
        throw success;
      }
      return data.initiatePasswordReset.response.success;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    } finally {
      dispatch(endRequest("resetPassword"));
    }
  }
);

export const updatePassword = createAsyncThunk(
  "updatePassword",
  async ({ token, password }: any, { dispatch, rejectWithValue }) => {
    dispatch(startRequest("createNewPassword"));
    try {
      const response = await passwordMutation(token, password);
      return response;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    } finally {
      dispatch(endRequest("createNewPassword"));
    }
  }
);

export const registerUser = createAsyncThunk(
  "account/registerUser",
  async (user: IRegisterUserInput, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await registerUserMutation(user);

      const userData = get(data, ["registerUser", "user"]);

      logBranchRegisterEvent(userData?.id);
      dispatch(
        eventFired({
          event: "registration_login_created",
          experienceLocation: "ONBOARDING",
          attribute: {
            marketingOptin: get(user, "attributes.newsletterOptIn"),
          },
        })
      );

      return userData;
    } catch (err) {
      const errorCode = getAppError(err);
      return rejectWithValue(errorCode);
    }
  }
);

export const createPhoneNumber = createAsyncThunk(
  "user/createPhoneNumber",
  async (phoneNumber: ICreatePhoneNumerInput, { rejectWithValue }) => {
    try {
      const { data } = await createPhoneNumberMutation(phoneNumber);

      return { ...data.createPhoneNumber.phoneNumber, ...phoneNumber };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const decommissionUser = createAsyncThunk(
  "user/decommissionUser",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await decommissionUserMutation();

      return { ...data };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const createIdentifiedUser = createAsyncThunk(
  "user/createIdentfiedUser",
  async (businessSlug: BusinessSlugTypes, { dispatch, rejectWithValue }) => {
    try {
      dispatch(startRequest("user/createIdentfiedUser"));
      const { data } = await setUpUserForNewLineOfBusinessMutation({ businessSlug });

      return data.setupUserForNewLineOfBusiness.user;
    } catch (err) {
      const errorCode = getAppError(err);
      return rejectWithValue(errorCode);
    } finally {
      dispatch(endRequest("user/createIdentfiedUser"));
    }
  },
  {
    condition: (businessSlug: BusinessSlugTypes, { getState }) => {
      const state = getState() as RootState;
      const userMemberships = getActiveMembershipBusinesses(state);
      const userHasAlreadyHasLineOfBusiness = userMemberships[businessSlug];
      const isRequestInFlight = getIsRequestPendingByResource(state, "user/createIdentfiedUser");

      // Cancel thunk user already has LOB
      if (userHasAlreadyHasLineOfBusiness || isRequestInFlight) {
        return false;
      }
    },
  }
);

export const setupUserForNewLineOfBusiness = createAsyncThunk(
  "setupUserForNewLineOfBusiness",
  async (businessSlug: BusinessSlugTypes, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(startRequest("setupUserForNewLineOfBusiness"));
      const { constellation, user } = getState() as RootState;

      const { data } = await setUpUserForNewLineOfBusinessMutation({
        businessSlug,
        constellationId: constellation.id,
        userId: user.id,
      });

      return data.setupUserForNewLineOfBusiness.user;
    } catch (err) {
      const errorCode = getAppError(err);
      return rejectWithValue(errorCode);
    } finally {
      dispatch(endRequest("setupUserForNewLineOfBusiness"));
    }
  },
  {
    condition: (businessSlug: BusinessSlugTypes, { getState }) => {
      const state = getState() as RootState;
      const userMemberships = getActiveMembershipBusinesses(state);
      const userHasAlreadyHasLineOfBusiness = userMemberships[businessSlug];
      const isRequestInFlight = getIsRequestPendingByResource(
        state,
        "setupUserForNewLineOfBusiness"
      );

      const { constellation, user } = getState() as RootState;

      // Cancel thunk if user/constellation data does not exist or user already has LOB
      if (!constellation.id || !user.id || userHasAlreadyHasLineOfBusiness || isRequestInFlight) {
        return false;
      }
    },
  }
);
