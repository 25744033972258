import { createAsyncThunk } from "@reduxjs/toolkit";
import { IFinanceScheduledTransfer } from "config/types";
import { cancelScheduledTransferMutation } from "graphql/mutations";
import {
  financeAutoTransfersQuery,
  financeScheduledTransfersQuery,
} from "graphql/queries/financeScheduledTransfers";
import { RootState } from "store";
import { hasOpenedFinanceAccount } from "store/selectors";
import { getAppError } from "utils/error";

/* ------------       THUNKS      ------------------ */

export const fetchFinanceScheduledTransfers = createAsyncThunk(
  "scheduledTransfers/fetchFinanceScheduledTransfers",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await financeScheduledTransfersQuery();
      return data;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const fetchFinanceAutoDeposits = createAsyncThunk(
  "fetchFinanceAutoDeposits",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await financeAutoTransfersQuery();
      return data.pendingAutoTransfers;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState;
      const hasFinanceAccount = hasOpenedFinanceAccount(state);

      if (!hasFinanceAccount) {
        return false;
      }
    },
  }
);

export const cancelScheduledTransfer = createAsyncThunk(
  "scheduledTransfers/cancelScheduledTransfer",
  async (scheduledTransferId: IFinanceScheduledTransfer["id"], { dispatch, rejectWithValue }) => {
    try {
      const { data } = await cancelScheduledTransferMutation(scheduledTransferId);
      await dispatch(fetchFinanceScheduledTransfers());

      return data.createFinanceTransfer.response;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
