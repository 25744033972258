import "./HeaderMenu.scss";

import classNames from "classnames";
import Icon from "components/Icon/Icon";
import Menu from "components/Menu/Menu";
import { ClassName } from "config";
import { useMenu } from "modules";
import { MouseEvent, useState } from "react";

interface IProps {
  className?: ClassName;
}

function HeaderMenu({ className }: IProps) {
  const { headerLinksMenu, headerUnregisteredMenu } = useMenu();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuButtonClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setIsMenuOpen(prev => !prev);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleMenuFocus = () => {
    setIsMenuOpen(true);
  };

  return (
    <div className={classNames("header-menu", className, { "header-menu--open": isMenuOpen })}>
      <button className="header-menu__toggle" onClick={handleMenuButtonClick}>
        <Icon
          className={classNames("header-menu__toggle-icon", {
            "header-menu__toggle-icon--active": isMenuOpen,
          })}
          src="CloseBorder"
        />
        <Icon
          className={classNames("header-menu__toggle-icon", {
            "header-menu__toggle-icon--active": !isMenuOpen,
          })}
          src="Menu"
        />
      </button>

      <Menu
        active={isMenuOpen}
        className="header-menu__menu"
        handleCloseMenu={handleMenuClose}
        mainSiteNav
        menuItems={headerLinksMenu}
        menuItems2={headerUnregisteredMenu}
        onMenuListFocus={handleMenuFocus}
      />
    </div>
  );
}

export default HeaderMenu;
