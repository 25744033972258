import { IAdjustCoverageInput } from "graphql/queries/estimate";
import { debounce } from "lodash-es";
import { useCallback, useState } from "react";
import { CoverageType } from "router/CoverageEditorRoute/CoverageEditorRoute";
import { useAppDispatch } from "store";
import { adjustEstimate } from "store/actions/estimate";
import { adjustProductOffering } from "store/actions/product-offerings";
import { adjustQuote } from "store/actions/quote";

type StatusTypes = "inactive" | "active" | "in-flight";

export const useAdjustCoverage = (type: CoverageType) => {
  const [status, setStatus] = useState<StatusTypes>("inactive");
  const dispatch = useAppDispatch();

  const adjustCoverage = (payload: IAdjustCoverageInput) => {
    setStatus("active");

    return debounceAdjustCoverage(payload);
  };

  const adjustCoverageAmount = async (payload: IAdjustCoverageInput) => {
    setStatus("in-flight");

    let adjustAction;
    switch (type) {
      case "estimate":
        adjustAction = adjustEstimate(payload);
        break;
      case "termLifeOffering":
        adjustAction = adjustProductOffering({
          ...payload,
        });
        break;
      case "adOffering":
        adjustAction = adjustProductOffering(payload);
        break;
      default:
        adjustAction = adjustQuote(payload);
        break;
    }

    const coverageQuery = await dispatch(adjustAction);

    setStatus("inactive");

    return coverageQuery;
  };

  const debounceAdjustCoverage = useCallback(debounce(adjustCoverageAmount, 500), []); //eslint-disable-line react-hooks/exhaustive-deps

  return { status, adjustCoverage };
};
