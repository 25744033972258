import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { IBusiness } from "config";
import { IMembership } from "config/types";
import { fetchMembership, initiateFinanceOnboarding, updateAutoDeposit } from "store/actions";
import { getPreVerifiedInitialData, getVerifiedInitialData } from "store/actions/appLoad";
import { logout } from "store/actions/authentication";
import { createIdentifiedUser, setupUserForNewLineOfBusiness } from "store/actions/user";
import { getConstellationMembership } from "utils/constellation";

interface IMembershipsState extends Omit<IMembership, "business"> {
  business: IBusiness | null;
}

/* ------------    FINANCE MEMBERSHIPS STATE     ------------------ */
const defaultState: IMembershipsState = {
  id: "",
  active: false,
  activePlan: null,
  business: null,
  createdAt: "",
  custom: false,
  metadata: {},
  pendingPlan: null,
  plans: [],
};

/* ------------------    ADD MATCHERS     ------------------ */
const isUpdateActionPending = isAnyOf(
  initiateFinanceOnboarding.fulfilled,
  updateAutoDeposit.fulfilled
);

const isUpdateActionFulfilled = isAnyOf(
  initiateFinanceOnboarding.fulfilled,
  updateAutoDeposit.fulfilled,
  fetchMembership.fulfilled
);

const isNewMemberActionFulfilled = isAnyOf(
  createIdentifiedUser.fulfilled,
  setupUserForNewLineOfBusiness.fulfilled
);

/* ------------    REDUCER     ------------------ */
const financeMembership = createReducer(defaultState, builder => {
  builder
    .addCase(getPreVerifiedInitialData.fulfilled, (state, action) => {
      const financeMembership = getConstellationMembership(
        action.payload.me?.activeConstellations,
        "wysh-financial"
      );

      state.id = financeMembership?.id || defaultState.id;
      state.business = financeMembership?.business || defaultState.business;
      state.metadata = financeMembership?.metadata || defaultState.metadata;
    })
    .addCase(getVerifiedInitialData.fulfilled, (state, action) => {
      const financeMembership = getConstellationMembership(
        action.payload.me?.activeConstellations,
        "wysh-financial"
      );

      state.id = financeMembership?.id || state.id;
      state.active = financeMembership?.active || state.active;
      state.activePlan = financeMembership?.activePlan || state.activePlan;
      state.business = financeMembership?.business || state.business;
      state.createdAt = financeMembership?.createdAt || state.createdAt;
      state.custom = financeMembership?.custom || state.custom;
      state.metadata = financeMembership?.metadata || state.metadata;
      state.pendingPlan = financeMembership?.pendingPlan || state.pendingPlan;
      state.plans = financeMembership?.plans || state.plans;
    })
    .addCase(logout, () => defaultState)
    .addMatcher(isNewMemberActionFulfilled, (state, action) => {
      const financeMembership = getConstellationMembership(
        action.payload.activeConstellations,
        "wysh-financial"
      );

      state.id = financeMembership?.id || "";
    })
    .addMatcher(isUpdateActionPending, (state, action) => {
      const accountNumber = action.meta.arg?.accountNumber;
      const accountNumberMask = accountNumber?.slice(-4) || state.metadata?.account_last_four;

      state.metadata["account_last_four"] = accountNumberMask;
    })
    .addMatcher(isUpdateActionFulfilled, (state, action) => {
      const membership = action.payload;
      const accountNumberMask = state.metadata?.account_last_four;

      state.id = membership?.id || state.id;
      state.active = membership?.active || state.active;
      state.activePlan = membership?.activePlan || state.activePlan;
      state.business = membership?.business || state.business;
      state.createdAt = membership?.createdAt || state.createdAt;
      state.custom = membership?.custom || state.custom;
      state.metadata = membership?.metadata || state.metadata;
      state.pendingPlan = membership?.pendingPlan || state.pendingPlan;
      state.plans = membership?.plans || state.plans;
      state.metadata.account_last_four = accountNumberMask;
    });
});

export default financeMembership;
