import { OrString } from "config/types";
import { useTranslation } from "modules";
import { FC } from "react";
import formatCommas from "utils/formatCommas";
import { toFixed } from "utils/toFixed";

interface IProps {
  amount: OrString<number>;
  withCommas?: boolean;
  fixedPoints?: number;
}

const Currency: FC<IProps> = ({ amount, withCommas = true, fixedPoints = 2 }) => {
  const { t } = useTranslation();
  const fixedAmount = toFixed(amount, fixedPoints);
  const formattedAmount = withCommas ? formatCommas(fixedAmount) : fixedAmount;
  const template = t("dollar.amount", "$%<amount>s", { amount: formattedAmount });
  return <>{template}</>;
};

export default Currency;
