import { createSelector } from "@reduxjs/toolkit";
import { sortBy } from "lodash-es";
import { RootState } from "store";

import { getMembershipMetadata } from "./selectMemberships";

const selectPlaidItemIds = (state: RootState) => state.plaidItems.ids;
const selectPlaidItemsById = (state: RootState) => state.plaidItems.byId;

export const selectPlaidItems = createSelector(
  selectPlaidItemIds,
  selectPlaidItemsById,
  (plaidIds, plaidHash) => {
    return plaidIds.map(id => plaidHash[id]);
  }
);

const selectFinancalPlaidAccountsById = (state: RootState) => {
  return Object.keys(state.plaidAccounts.byId).reduce((memo, key) => {
    const plaidAccount = state.plaidAccounts.byId[key];

    if (plaidAccount.businessSlug === "wysh-financial") {
      memo[key] = plaidAccount;
    }

    return memo;
  }, {} as typeof state.plaidAccounts.byId);
};

export const selectLatestFinancialPlaidAccount = createSelector(
  getMembershipMetadata,
  selectFinancalPlaidAccountsById,
  (metadata, plaidAccounts) => {
    const plaidAccountId = metadata?.plaid_account_id || "";

    if (plaidAccounts[plaidAccountId]) {
      return plaidAccounts[plaidAccountId];
    }

    return sortBy(plaidAccounts, "createdAt").reverse()[0];
  }
);

// Get plaid account data by id
export const getLinkedFinancalAccountById = (id: string) => (state: RootState) =>
  selectFinancalPlaidAccountsById(state)[id];
