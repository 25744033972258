import { IUser } from "./store.types";

/*
..................
~~~ INTERFACES ~~~
..................
*/

export interface IApi {
  auth: IAuthApi;
}

export interface IAuthApi {
  // TODO: Add return types for all of these functions.
  refreshAccessToken(clientId?: string): Promise<any>;
  authorize(authorizeRequestData: IAuthorizeRequestData): Promise<any>;
  fetchToken(accessTokenRequestData: IAccessTokenRequestData): Promise<any>;
}

export interface IAuthRequestErrorConstructor {
  code: number;
  message: string;
  request: IAuthRequestPayload;
  response: IAuthRequestErrorResponsePayload;
}

export interface IAuthRequestErrorResponsePayload {
  data: {
    error: string;
    error_description: string;
  };
  headers: Headers;
  status: number;
}

export interface IAuthRequestPayload {
  body: string;
  headers: Headers;
  method: string;
  url: string;
}

export interface IAuthorizeRequestData {
  client_id: string;
  redirect_uri: string;
  response_type: "code";
  scope: string;
  email: string;
  password: string;
  user_id: string;
  code_challenge_method: "S256";
  code_challenge: string;
}

export interface IAccessTokenRequestData {
  grant_type: "authorization_code";
  client_id: string;
  code: string;
  redirect_uri: string;
  code_verifier: string;
}

export interface ICredential {
  email: string;
  password: string;
}

export interface IDecodedAccessToken {
  iat: number;
  iss: string;
  jti: string;
  user: IUser;
}

export interface IRefreshTokenRequestData {
  client_id: string;
  grant_type: "refresh_token";
  refresh_token: string;
}

/*
..................
~~~~~ ENUMS ~~~~~
..................
*/

export enum IResendCodeType {
  resetPassword,
  resetPasswordExpired,
}

export enum ISessionStatusesEnums {
  pending,
  verified,
  transient,
}

/*
..................
~~~~~ TYPES ~~~~~
..................
*/

export type ResendCodeTypeStrings = keyof typeof IResendCodeType;
export type SessionStatusesStrings = keyof typeof ISessionStatusesEnums;

export type PhoneVerificationMode = "sms" | "voice";
