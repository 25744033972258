import "./PaymentCardInput.scss";

import { CardElement } from "@stripe/react-stripe-js";
import Text from "components/Text/Text";
import { ClassName } from "config/types";
import { get, includes, kebabCase } from "lodash-es";
import { FC, useState } from "react";
import classNames from "utils/classNames";
import { useTranslation } from "modules/hooks";

const withLabel = (label: string, e: { props: { id: string; className: string } }) => {
  const { id, className } = e.props;
  const activeClass = "payment-card-input--active";
  const elementHasActiveClass = includes(className, activeClass);
  const classes = classNames("payment-card-input__label", {
    "p5-tag__text": elementHasActiveClass,
  });

  return (
    <>
      {e}
      <label {...(id && { htmlFor: id })} className="payment-card-input__label-wrapper">
        <Text tag="p2" className={classes} text={label} />
      </label>
    </>
  );
};

export type PaymentInputState = {
  empty?: boolean;
  active?: boolean;
  complete?: boolean;
  error?: {
    type: "validation_error";
    code: string;
    message: string;
  };
};

interface IProps {
  className?: ClassName;
  onChange?(state: PaymentInputState): void;
}

const PaymentCardInput: FC<IProps> = props => {
  const { className, onChange } = props;
  const [inputState, setInputState] = useState<PaymentInputState>({});
  const { t } = useTranslation();

  const stripeStyles = {
    base: {
      fontFamily: '"Henderson Sans", sans-serif',
      fontSize: "16px",
      color: "#1C1C1C",
      "::placeholder": {
        color: "#B0B0A8",
      },
    },
  };

  const defaultOptions = { style: stripeStyles };

  const isInputActive = (inputName: string) => {
    const input = get(inputState, inputName, {});
    const isInputEmpty = get(input, "empty", true);
    const isInputFocused = get(input, "active", false);

    return !isInputEmpty || isInputFocused;
  };

  const setInputItemState = (value: PaymentInputState) => {
    const newState = {
      ...inputState,
      ...value,
    };

    setInputState(newState);
  };

  const setInputActiveState = (active: boolean) => {
    const payload = { active };
    setInputItemState(payload);
  };

  // const formatValue = (inputName: string, value: string) => {};

  const handleFocus = () => {
    setInputActiveState(true);
  };
  const handleBlur = () => {
    setInputActiveState(true);
  };

  const handleChange = (event: any) => {
    const { complete, empty, error } = event;
    const payload = { complete, empty, error };
    setInputItemState(payload);
    const state = { ...inputState, ...payload };
    onChange && onChange(state);
  };

  const buildClasses = (inputName: string): string => {
    const baseClass = "payment-card-input";
    const inputClass = `payment-card-input__${kebabCase(inputName)}`;
    const activeClass = {
      "payment-card-input--active": isInputActive(inputName),
    };
    return classNames(baseClass, inputClass, activeClass);
  };

  return (
    <div className={classNames(className, "payment-card-input__container")}>
      <div className="payment-card-input__wrapper">
        <div className="payment-card-input__card-row">
          <div
            className={classNames(
              "payment-card-input__card-row-item",
              "payment-card-input__card-number-wrapper",
              { "payment-card-input__card-row-item--active": isInputActive("card") }
            )}>
            {withLabel(
              t("card.entry.number.placeholder", "Card Number"),
              <CardElement
                options={{
                  ...defaultOptions,
                }}
                id="payment-card-input"
                className={buildClasses("card")}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCardInput;
