import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "store/actions/modal";
import { destroyWyshAllocation } from "store/actions/wyshes";
import { getThunkError } from "utils/error";

const RemoveWyshModal: FC<IModalProps> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const wyshAllocationId = props.data.allocationId as string;

  const [loading, setLoading] = useState(false);

  const closeModalWrapper = () => {
    dispatch(closeModal());
  };

  const handleDeleteWysh = async () => {
    try {
      setLoading(true);
      const response = await dispatch(destroyWyshAllocation(wyshAllocationId));
      const error = getThunkError(response);
      if (error) {
        throw error;
      }
      props.context?.callbacks?.onSuccess();
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
      closeModalWrapper();
    }
  };

  const ctaButtons = [
    {
      text: t("wysh.modal.remove_wysh.button_confirm", "YES, REMOVE"),
      isLoading: loading,
      disabled: loading,
      onClick: handleDeleteWysh,
      dataTestId: "remove-wysh-modal__submit",
    },
    {
      text: t("wysh.modal.remove_wysh.button_cancel", "NEVERMIND"),
      subType: "text-dark",
      disabled: loading,
      onClick: closeModalWrapper,
    },
  ];

  const content = {
    header: t("wysh.modal.remove_wysh.title", "Are you sure you want to remove this Wysh?"),
    onClose: closeModalWrapper,
    button: ctaButtons,
    icon: "BlobAlert",
  };

  return <Layout as="ModalLayout" content={content} className="remove-wysh-modal__container" />;
};

export default RemoveWyshModal;
