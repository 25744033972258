import { StatusLabel } from "components/StatusLabel/StatusLabel";
import Text from "components/Text/Text";
import { useTranslation } from "modules/hooks";
import { FC } from "react";

interface IProps {
  text: string;
  labelText?: string;
}

export const HomeCardNotice: FC<IProps> = ({ labelText, text }) => {
  const { t } = useTranslation();

  return (
    <div className="home-product-card__notice-labels">
      <Text
        className="color-secondary-text"
        tag="l5-label"
        text={labelText || t("product.notice.message", "Application Status")}
      />
      <StatusLabel text={text} />
    </div>
  );
};
