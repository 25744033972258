import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC } from "react";
import { useAppDispatch } from "store";
import { closeModal } from "store/actions/modal";

const CancelPendingUpdatesModal: FC<IModalProps> = props => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const closeModalWrapper = () => dispatch(closeModal());

  const continueOnClick = () => {
    closeModalWrapper();
  };

  const cancelOnClick = () => {
    props.context?.callbacks?.closeDrawer && props.context.callbacks.closeDrawer();
    closeModalWrapper();
  };

  const modalHeader = t("modal.cancel_pending.title", "Cancel pending change?");

  const modalSubheader = t(
    "modal.cancel_pending.body",
    "Looks like you already have a policy change in the works. If you continue with this update, your pending request will be canceled and won't be applied at your next payment."
  );

  const primaryCtaText = t("modal.cancel_pending.cta.primary", "YES, CONTINUE");

  const secondaryCtaText = t("modal.cancel_pending.cta.secondary", "NEVER MIND");

  const content = {
    header: modalHeader,
    subheader: modalSubheader,
    onClose: closeModalWrapper,
    button: [
      {
        text: primaryCtaText,
        subType: "primary",
        onClick: continueOnClick,
      },
      {
        text: secondaryCtaText,
        subType: "text-dark-border",
        onClick: cancelOnClick,
      },
    ],
    onButtonClick: closeModal,
    icon: "BlobPolicy",
    alwaysShowIcon: true,
  };

  return <Layout as="ModalLayout" content={content} className="left-align-sm" />;
};

export default CancelPendingUpdatesModal;
