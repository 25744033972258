type PartialPath = {
  pathname?: string;
  search?: string;
  hash?: string;
};

export const parsePath = (path: string) => {
  const partialPath: PartialPath = {};

  if (path) {
    const hashIndex = path.indexOf("#");
    if (hashIndex >= 0) {
      partialPath.hash = path.substr(hashIndex);
      path = path.substr(0, hashIndex);
    }

    const searchIndex = path.indexOf("?");
    if (searchIndex >= 0) {
      partialPath.search = path.substr(searchIndex);
      path = path.substr(0, searchIndex);
    }

    if (path) {
      partialPath.pathname = path;
    }
  }

  return partialPath;
};
