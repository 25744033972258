import { createAsyncThunk } from "@reduxjs/toolkit";
import { IAppError, IWysh, IWyshAllocationRecipient, WyshAllocationRecipientType } from "config";
import {
  createAllocationRecipientMutation,
  createWyshAllocationMutation,
  destroyWyshAllocationMutation,
  ICreateWyshAllocationInput,
  IUpdateWyshAllocationInput,
  removeAllocationRecipientMutation,
  updateAllocationRecipientMutation,
  updateWyshAllocationMutation,
} from "graphql/mutations/wyshes";
import {
  fetchWyshAllocationsQuery,
  fetchWyshAllocationsSummaryQuery,
  fetchWyshCategoriesQuery,
  fetchWyshItemQuery,
  fetchWyshItemsQuery,
} from "graphql/queries/wyshes";
import { get } from "lodash-es";
import { RootState } from "store";
import { getActiveAccount } from "utils/account";
import { getAppError } from "utils/error";

interface IWyshesMetadata {
  isAccountEligibleForMonitoring: boolean;
}

interface ICreateAndUpdateWyshInput extends ICreateWyshAllocationInput {
  amount: number;
}

/* ------------       THUNKS      ------------------ */
export const getWyshes = createAsyncThunk<
  IWysh[],
  void,
  { fulfilledMeta: IWyshesMetadata; rejectValue: IAppError; state: RootState }
>("wyshes/getWyshes", async (_, { fulfillWithValue, getState, rejectWithValue }) => {
  try {
    const response = await fetchWyshItemsQuery();
    const meta = {
      isAccountEligibleForMonitoring: getState().account.eligibleForMonitoring,
    };

    return fulfillWithValue(response.data.wyshes || [], meta);
  } catch (err) {
    const errorCode = getAppError(err);

    return rejectWithValue(errorCode);
  }
});

export const getWysh = createAsyncThunk<
  IWysh,
  string,
  { fulfilledMeta: IWyshesMetadata; rejectValue: IAppError; state: RootState }
>("wyshes/getWysh", async (slug: string, { fulfillWithValue, getState, rejectWithValue }) => {
  try {
    const response = await fetchWyshItemQuery(slug);
    const meta = {
      isAccountEligibleForMonitoring: getState().account.eligibleForMonitoring,
    };

    return fulfillWithValue(response.data.wysh, meta);
  } catch (err) {
    const errorCode = getAppError(err);

    return rejectWithValue(errorCode);
  }
});

export const getWyshesCategories = createAsyncThunk(
  "wyshes/categories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchWyshCategoriesQuery();

      return response.data.wyshCategories || [];
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const getWyshAllocations = createAsyncThunk(
  "wyshes/allocations",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchWyshAllocationsQuery();
      const activeAccount = getActiveAccount(response.data.me);

      return activeAccount?.wyshAllocations || [];
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const destroyWyshAllocation = createAsyncThunk(
  "wyshes/destroyWyshAllocation",
  async (wyshAllocationId: string, { dispatch, rejectWithValue }) => {
    try {
      await destroyWyshAllocationMutation(wyshAllocationId);
      await dispatch(getWyshAllocationSummary());

      return wyshAllocationId;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const createWyshAllocation = createAsyncThunk(
  "wyshes/createWyshAllocation",
  async (input: ICreateWyshAllocationInput, { dispatch, rejectWithValue }) => {
    try {
      const response = await createWyshAllocationMutation(input);
      await dispatch(getWyshAllocationSummary());

      return response.data.createWyshAllocation.wyshAllocation;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const updateWyshAllocation = createAsyncThunk(
  "wyshes/updateWyshAllocation",
  async (input: IUpdateWyshAllocationInput, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateWyshAllocationMutation(input);
      await dispatch(getWyshAllocationSummary());

      return response.data.updateWyshAllocation.wyshAllocation;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const createAndUpdateWyshAllocation = createAsyncThunk(
  "wyshes/createUpdateWyshAllocation",
  async ({ wyshId, amount }: ICreateAndUpdateWyshInput, { dispatch, rejectWithValue }) => {
    try {
      const newWysh = await dispatch(createWyshAllocation({ wyshId: wyshId })).unwrap();
      const allocationId = newWysh.id;

      const updatedWysh = await dispatch(
        updateWyshAllocation({
          wyshAllocationId: allocationId,
          amount: amount,
        })
      ).unwrap();

      await dispatch(getWyshAllocationSummary());

      return updatedWysh;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const updateWyshAllocationOrder = createAsyncThunk(
  "wyshes/updateWyshAllocationOrder",
  async ({ id, order }: { id: string; order: number }, { rejectWithValue }) => {
    try {
      const requestPayload = { wyshAllocationId: id, order };
      await updateWyshAllocationMutation(requestPayload);

      return;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const getWyshAllocationSummary = createAsyncThunk(
  "wyshes/allocationSummary",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchWyshAllocationsSummaryQuery();
      const activeAccount = getActiveAccount(response.data.me);

      return activeAccount?.allocationSummary;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

// Recipient
export const createWyshAllocationRecipient = createAsyncThunk(
  "wyshes/createWyshAllocationRecipient",
  async (
    input: {
      type: WyshAllocationRecipientType;
      wyshAllocationId: string;
      recipient: IWyshAllocationRecipient;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await createAllocationRecipientMutation(input);
      const { wyshAllocationId } = input;

      return {
        recipient: get(response, "data.createAllocationRecipient.wyshAllocation.recipient"),
        wyshAllocationId,
      };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const updateWyshAllocationRecipient = createAsyncThunk(
  "wyshes/updateWyshAllocationRecipient",
  async (
    input: {
      type: WyshAllocationRecipientType;
      wyshAllocationId: string;
      recipient: IWyshAllocationRecipient;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateAllocationRecipientMutation(input);
      const { wyshAllocationId } = input;

      return {
        recipient: response.data.updateAllocationRecipient.wyshAllocation.recipient,
        wyshAllocationId,
      };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const removeWyshAllocationRecipient = createAsyncThunk(
  "wyshes/removeWyshAllocationRecipient",
  async (
    input: {
      wyshAllocationId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      await removeAllocationRecipientMutation(input);
      const { wyshAllocationId } = input;

      return {
        wyshAllocationId,
      };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
