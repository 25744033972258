import "./StatusLabel.scss";

import Text from "components/Text/Text";
import { FC } from "react";

interface IProps {
  text: string;
  type?: "default" | "mamey";
}

export const StatusLabel: FC<IProps> = ({ text, type = "default" }) => {
  return (
    <div className={`status-label status-label--${type}`}>
      <Text tag="c4-caption" text={text} />
    </div>
  );
};
