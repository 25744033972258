import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchPendingPolicy } from "graphql/queries/pendingPolicy";
import { getActiveAccount } from "utils/account";
import { getAppError } from "utils/error";

/* ------------       THUNKS      ------------------ */

export const getPendingPolicy = createAsyncThunk(
  "policy/getPendingPolicy",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await fetchPendingPolicy();
      const account = getActiveAccount(data.me);

      return account?.pendingPolicy;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
