import { toInteger } from "lodash-es";

export const divideEvenly = (count: number) => {
  const returnValues: number[] = [];

  const basePercent = toInteger(100 / count);
  // how much did we lose in decimal truncation?
  // we will distribute this value
  // across count to reach 100% allocation
  const remainder = 100 - count * basePercent;

  // loop the count
  // if the index is less than the remainder
  // add 1 to the basePercent
  for (let i = 0; i < count; i++) {
    let amount = basePercent;
    if (i < remainder) {
      amount += 1;
    }
    returnValues.push(amount);
  }

  return returnValues;
};
