import { gql } from "config/graphql";
import { IAddress } from "config/types";
import { getApolloClient } from "store/apollo";

interface ICreateAddressResponse {
  data: {
    createAddress: {
      response: {
        success: boolean;
      };
    };
  };
}

export const createAddressMutation = (input: IAddress): Promise<ICreateAddressResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation createAddress($input: CreateAddressInput!) {
      createAddress(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: { input },
    mutation,
  });
};

interface ICreateEmailResponse {
  data: {
    newEmail: {
      response: {
        success: boolean;
      };
    };
  };
}

interface ICreateEmailInput {
  email: string;
}

export const createEmailMutation = (input: ICreateEmailInput): Promise<ICreateEmailResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation newEmail($input: NewEmailInput!) {
      newEmail(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: { input },
    mutation,
  });
};

interface IRequestEmailVerificationResendResponse {
  data: {
    requestEmailVerificationResend: {
      response: {
        success: boolean;
      };
    };
  };
}

export const requestEmailVerificationResendMutation = (
  emailId: string
): Promise<IRequestEmailVerificationResendResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation requestEmailVerificationResend($input: RequestEmailVerificationResendInput!) {
      requestEmailVerificationResend(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: {
      input: { emailId },
    },
    mutation,
  });
};

interface IUpdateUserInput {
  birthdate?: string;
  firstName?: string;
  gender?: string;
  lastName?: string;
  middleName?: string;
  newsletterOptIn?: boolean;
  suffix?: string;
}

interface IUpdateUserResponse {
  data: {
    updateUser: {
      response: {
        success: boolean;
      };
    };
  };
}

export const updateUserMutation = (input: IUpdateUserInput): Promise<IUpdateUserResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation updateUser($input: UpdateUserInput!) {
      updateUser(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: {
      input: { attributes: input },
    },
    mutation,
  });
};

interface IVerifyEmailResponse {
  data: {
    verifyEmail: {
      success: boolean;
    };
  };
}

export const verifyEmailMutation = (token: string): Promise<IVerifyEmailResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation verifyEmail($input: VerifyEmailInput!) {
      verifyEmail(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: {
      input: { token },
    },
    mutation,
  });
};
