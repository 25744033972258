import { createReducer } from "@reduxjs/toolkit";
import { ICustomer } from "config/types";
import { getVerifiedInitialData } from "store/actions/appLoad";
import { logout } from "store/actions/authentication";

/* ------------    CUSTOMER STATE     ------------------ */
const defaultState: ICustomer = {
  createdAt: "",
  externalId: "",
  id: "",
  metadata: {},
  processor: null,
};

/* ------------    REDUCER     ------------------ */
const customer = createReducer(defaultState, builder => {
  builder
    .addCase(getVerifiedInitialData.fulfilled, (state, action) => {
      const customer = action.payload.me.customer || defaultState;

      state.createdAt = customer.createdAt;
      state.id = customer.id;
      state.metadata = customer.metadata;
      state.processor = customer.processor;
    })
    .addCase(logout, () => defaultState);
});

export default customer;
