import { createSelector } from "@reduxjs/toolkit";
import { IExternalAccountItem } from "config";
import { useTranslation } from "modules/hooks/useTranslation";
import { RootState } from "store";

import { getMembershipMetadata } from "./selectMemberships";
import { getLinkedFinancalAccountById } from "./selectPlaidItems";

const selectExternalAccountIds = (state: RootState) => state.externalAccounts.ids;
const selectExternalAccountsById = (state: RootState) => state.externalAccounts.byId;
export const getHasExternalAccountsLoaded = (state: RootState) => state.externalAccounts.hasLoaded;

export const selectExternalAccount = createSelector(
  selectExternalAccountIds,
  selectExternalAccountsById,
  (externalAccountId, externalAccountsHash) => {
    return externalAccountsHash[externalAccountId[0]];
  }
);

export const selectIsExternalAccountUnverified = createSelector(
  selectExternalAccountIds,
  selectExternalAccountsById,
  getHasExternalAccountsLoaded,
  (externalAccountId, externalAccountsHash, hasExternalAccountsLoaded) => {
    const externalAccountStatus = externalAccountsHash[externalAccountId[0]]?.status;
    return (
      hasExternalAccountsLoaded && !!externalAccountStatus && externalAccountStatus !== "Verified"
    );
  }
);

export const getExternalAccountById = createSelector(
  [selectExternalAccountsById, (_state: RootState, externalAccountId: string) => externalAccountId],
  (externalAccounts, externalAccountId) => {
    return externalAccounts[externalAccountId];
  }
);

export const getExternalAccountItemDataById = (id: string) =>
  createSelector(
    selectExternalAccountsById,
    getLinkedFinancalAccountById(id),
    getMembershipMetadata,
    (externalAccountHash, plaidAccount, metadata): IExternalAccountItem | undefined => {
      const externalAccount = externalAccountHash[id];
      const { t } = useTranslation();

      if (externalAccount) {
        return {
          accountType: externalAccount.accountType,
          balance: externalAccount.balance,
          institutionLogo: externalAccount.institutionLogo,
          institutionName:
            externalAccount.institutionName || t("savings.my_linked_bank", "My Linked Bank"),
          mask: externalAccount.mask,
        };
      } else if (id === metadata?.account_number) {
        return {
          accountType: metadata?.account_type || "",
          balance: undefined,
          institutionLogo: "",
          institutionName: t("savings.my_linked_bank", "My Linked Bank"),
          mask: metadata?.account_last_four || "****",
        };
      } else if (plaidAccount) {
        return {
          accountType: plaidAccount.subtype,
          balance: undefined,
          institutionLogo: plaidAccount.plaidInstitution.logo,
          institutionName: plaidAccount.plaidInstitution.name,
          mask: plaidAccount.mask,
        };
      }
    }
  );
