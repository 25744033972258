import { createReducer } from "@reduxjs/toolkit";
import { IState } from "config/types";
import { logout } from "store/actions/authentication";
import { getStates } from "store/actions/states";

const defaultState: Record<string, IState[]> = {};

const states = createReducer(defaultState, builder => {
  builder
    .addCase(getStates.fulfilled, (state, action) => {
      const countryId = action.meta.arg;

      state[countryId] = action.payload;
    })
    .addCase(logout, () => defaultState);
});

export default states;
