import { find } from "lodash-es";
import { useContext } from "react";
import { fetchDynamicForm } from "store/actions/flow";
import { useAppDispatch, useAppSelector } from "store/index";
import { selectDynamicForm } from "store/selectors";

import { DynamicFormContext } from "../DynamicFormContext";

import { TApiButtonCommandFn } from ".";

export const useNextForm = () => {
  const dispatch = useAppDispatch();
  const flowSessionId = useAppSelector(state => state.formFlow.flowSessionId);
  const dynamicForm = useAppSelector(selectDynamicForm);
  const { inputs } = useContext(DynamicFormContext);

  const nextFormCommand: TApiButtonCommandFn = async (commandArgs, { formAction, formScope }) => {
    const actionArgumentSource = find(commandArgs, ["name", "action"])?.source;
    const actionArgumentSourceType = find(commandArgs, ["name", "action"])?.sourceType;
    const actionValue =
      actionArgumentSourceType === "static"
        ? actionArgumentSource
        : find(inputs, ["key", actionArgumentSource])?.value;
    const criteriaAction = actionValue || formAction;

    const criteriaAttributes = {
      ...(!!criteriaAction && { action: criteriaAction }),
      ...(!!formScope && { formScope }),
    };

    return await dispatch(
      fetchDynamicForm({
        criteria: criteriaAttributes,
        currentFormIdentifier: dynamicForm.id,
        direction: "next",
        flowSessionId,
      })
    ).unwrap();
  };

  return nextFormCommand;
};
