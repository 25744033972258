import { manualUWModalDisplayEvent } from "analytics/underwritingEvents";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store";
import { closeModal } from "store/actions/modal";
import { openChat } from "utils/customerSupport";

const ManualUWModal: FC<IModalProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isPolicyEditBlocked = useSelector(
    (state: RootState) => state.modal.id === "policy-edit-block"
  );

  const closeModalWrapper = () => dispatch(closeModal());

  const onClickConfirm = () => {
    closeModalWrapper();
  };

  const onClickCustomerSupport = () => {
    openChat();
    closeModalWrapper();
  };

  useEffect(() => {
    dispatch(manualUWModalDisplayEvent("underwriting-info-modal"));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const modalHeader = isPolicyEditBlocked
    ? t("modal.policy_edit.blocked.title", "Manual review in progress")
    : t("modal.dashboard.ruw.title", "Manual underwriting");

  const modalSubheader = isPolicyEditBlocked
    ? t(
        "modal.policy_edit.blocked.body",
        "Our team is currently reviewing your recent coverage increase application. Until this review is complete you will not be able ot make additional edits to your coverage amount. It generally takes less than three business days."
      )
    : t(
        "modal.dashboard.ruw.body",
        "Our underwriting team needs to do a manual review of your application, which means that we can’t give you an instant quote just yet. We’ll reach out to you within 48 hrs with a decision. Thanks for your patience!"
      );

  const primaryCtaText = isPolicyEditBlocked
    ? t("modal.policy_edit.blocked.cta.primary", "SOUNDS GOOD!")
    : t("modal.dashboard.ruw.cta.primary", "SOUNDS GOOD!");

  const secondaryCtaText = isPolicyEditBlocked
    ? t("modal.policy_edit.blocked.cta.secondary", "CONTACT SUPPORT")
    : t("modal.dashboard.ruw.cta.secondary", "CONTACT SUPPORT");

  const content = {
    header: modalHeader,
    subheader: modalSubheader,
    onClose: closeModalWrapper,
    button: [
      {
        text: primaryCtaText,
        subType: "primary",
        onClick: onClickConfirm,
      },
      {
        text: secondaryCtaText,
        subType: "text-dark-border",
        onClick: onClickCustomerSupport,
      },
    ],
    onButtonClick: closeModal,
    icon: "BlobPolicy",
    alwaysShowIcon: true,
  };

  return <Layout as="ModalLayout" content={content} className="manual-uw__modal left-align-sm" />;
};

export default ManualUWModal;
