import { gql } from "config/graphql";
import { ICase } from "config/types";
import { getApolloClient } from "store/apollo";

export interface ICasesResponse {
  data: {
    cases: ICase[];
  };
}

export const getCasesQuery = (): Promise<ICasesResponse> => {
  const client = getApolloClient();

  const query = gql`
    query Cases {
      cases {
        id
        result
        decision
        externalId
        source
        createdAt
      }
    }
  `;

  return client.query({ query });
};
