import classNames from "classnames";
import Modal from "containers/Shared/Modal/Modal";
import { ILayoutProps } from "modules";
import { ComponentProps, FC, useEffect, useState } from "react";

type ContentType = {
  headerProps?: ComponentProps<typeof Modal>["headerProps"];
  shouldCloseOverlay?: boolean;
  onClose?: () => void;
  onOverlayClickAndEsc?: () => void;
};

const OverlayLayout: FC<ILayoutProps<ContentType>> = props => {
  const { children, className } = props;
  const content = props.content as ContentType;
  const shouldCloseOverlay = content?.shouldCloseOverlay;
  const classes = classNames("overlay-layout", className);

  // LOCAL STATE
  const [isOpen, setIsOpen] = useState(true);

  // METHODS
  const handleOnClose = () => {
    setIsOpen(false);
    content?.onClose && content.onClose();
  };

  const onOverlayClickAndEsc = () => {
    content?.onOverlayClickAndEsc && content.onOverlayClickAndEsc();
    handleOnClose();
  };

  // EFFECTS
  useEffect(() => {
    if (shouldCloseOverlay === true) {
      handleOnClose();
    }
  }, [shouldCloseOverlay]); //eslint-disable-line react-hooks/exhaustive-deps

  // RENDER
  return (
    <Modal
      className={classes}
      headerProps={content?.headerProps}
      isOpen={isOpen}
      onClose={onOverlayClickAndEsc}
      type="default">
      <div className="modal__container">{children}</div>
    </Modal>
  );
};

export default OverlayLayout;
