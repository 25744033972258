import { createReducer } from "@reduxjs/toolkit";
import { IConstellation } from "config/types";
import { fetchCoverageBreakdown } from "store/actions";
import { getPreVerifiedInitialData, getVerifiedInitialData } from "store/actions/appLoad";
import { logout } from "store/actions/authentication";
import { getDashboardData } from "store/actions/dashboard";
import { createIdentifiedUser } from "store/actions/user";
import { getActiveConstellation } from "utils/constellation";

/* ------------    CONSTELLATION STATE     ------------------ */
const defaultState: Omit<
  IConstellation,
  "allocationSummary" | "allocationHistories" | "wyshAllocations"
> = {
  id: "",
  coverageBreakdown: undefined,
  coverageGap: 500000,
  coverageSum: 0,
  createdAt: "",
  desiredCoverage: 500000,
  label: "",
  featureFlags: [],
  plaidItems: [],
  status: "",
};

/* ------------    REDUCER     ------------------ */
const constellation = createReducer(defaultState, builder => {
  builder
    .addCase(getPreVerifiedInitialData.fulfilled, (state, action) => {
      const constellation = getActiveConstellation(action.payload.me.activeConstellations) || state;

      state.id = constellation.id;
      state.createdAt = constellation.createdAt;
      state.featureFlags = constellation.featureFlags;
      state.label = constellation.label;
    })
    .addCase(getVerifiedInitialData.fulfilled, (state, action) => {
      const constellation = getActiveConstellation(action.payload.me.activeConstellations) || state;

      state.id = constellation.id;
      state.createdAt = constellation.createdAt;
      state.label = constellation.label;
      state.featureFlags = constellation.featureFlags || state.featureFlags;
      state.plaidItems = constellation.plaidItems;
      state.status = constellation.status;
    })
    .addCase(createIdentifiedUser.fulfilled, (state, action) => {
      const constellations = action.payload.activeConstellations;
      const latestConstellation = getActiveConstellation(constellations) || defaultState;

      state.id = latestConstellation.id;
      state.featureFlags = latestConstellation.featureFlags;
    })
    .addCase(getDashboardData.fulfilled, (state, action) => {
      const constellation = action.payload;

      state.featureFlags = constellation?.featureFlags || state.featureFlags;
      state.label = constellation.label || state.label;
    })
    .addCase(fetchCoverageBreakdown.fulfilled, (state, action) => {
      const constellations = action.payload;
      const latestConstellation = getActiveConstellation(constellations) || defaultState;

      state.coverageBreakdown = latestConstellation.coverageBreakdown;
      state.coverageGap = latestConstellation.coverageGap;
      state.coverageSum = latestConstellation.coverageSum;
      state.desiredCoverage = latestConstellation.desiredCoverage;
    })
    .addCase(logout, () => defaultState);
});

export default constellation;
