import { gql } from "config/graphql";
import { IPaymentMethod, IUserPlaidItems } from "config/types";
import { USER_PLAID_ITEMS } from "graphql/fragments";
import { getApolloClient } from "store/apollo";

interface IActivePaymentMethodResponse {
  data: {
    activePaymentMethod: IPaymentMethod;
  };
}

export const activePaymentMethodQuery = (): Promise<IActivePaymentMethodResponse> => {
  const client = getApolloClient();

  const query = gql`
    query activePaymentMethod {
      activePaymentMethod {
        id
        source
        status
        name
        metadata
        externalId
        mask
        plaidAccount {
          accountType
          id
          externalId
          mask
          name
          plaidItem {
            plaidInstitution {
              name
            }
            status
          }
        }
        createdAt
        updatedAt
      }
    }
  `;

  return client.query({ query });
};

interface IPlaidItemsResponse {
  data: {
    me: {
      activeConstellations: IUserPlaidItems[];
    };
  };
}

export const plaidItemsQuery = (): Promise<IPlaidItemsResponse> => {
  const client = getApolloClient();

  const query = gql`
    ${USER_PLAID_ITEMS}
    query PlaidItems {
      me {
        ...UserPlaidItems
      }
    }
  `;

  return client.query({ query });
};
