import { IModalProps } from "config/init/providers/ModalsProvider";
import { push } from "connected-react-router";
import { Layout, useJourney, useTranslation } from "modules";
import { FC } from "react";
import { useAppDispatch } from "store";

const UnsupportedStateModal: FC<IModalProps> = ({ closeModal }) => {
  const { t } = useTranslation();
  const { exitJourney } = useJourney();
  const dispatch = useAppDispatch();

  const handleExit = async () => {
    await exitJourney();
    dispatch(push("/"));
  };

  const content = {
    icon: "BlobSorry",
    header: t("modals.unsupported_state.header", "Sorry, we aren't in your state yet"),
    subheader: t(
      "modals.unsupported_state.subheader",
      "But we're working to expand Wysh's coverage every day."
    ),
    button: [
      {
        onClick: closeModal,
        text: t("modals.unsupported_state.button.resume.label", "Okay"),
      },
      {
        onClick: handleExit,
        subType: "text-dark",
        text: t("modals.unsupported_state.button.cancel_application.label", "Cancel Application"),
      },
    ],
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default UnsupportedStateModal;
