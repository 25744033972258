import { OrString } from "config/types";
import { escapeRegExp, flatten, isArray, isEmpty, isRegExp, isString, map } from "lodash-es";

type Callback = (content: string, index: number, position: number) => any;

const stringReplace = (str: string, match: OrString<RegExp>, fn: Callback) => {
  let curCharStart = 0;
  let curCharLen = 0;

  if (isEmpty(str)) return str;

  let re = match;

  if (!isRegExp(re)) {
    re = new RegExp("(" + escapeRegExp(re) + ")", "gi");
  }

  const result = str.split(re);

  for (let i = 1, length = result.length; i < length; i += 2) {
    curCharLen = result[i].length;
    curCharStart += result[i - 1].length;
    result[i] = fn(result[i], i, curCharStart);
    curCharStart += curCharLen;
  }

  return result;
};

const replaceString = (source: string | string[], match: OrString<RegExp>, fn: Callback) => {
  if (!isArray(source)) source = [source];

  return flatten(
    map(source, function(x) {
      return isString(x) ? stringReplace(x, match, fn) : x;
    })
  );
};

export default replaceString;
