import ArroSrc from "assets/images/cobrands/arro.svg";
import BestMoneySrc from "assets/images/cobrands/best-money.svg";
import CappsoolSrc from "assets/images/cobrands/cappsool.png";
import UnestSrc from "assets/images/cobrands/unest.svg";
import { useSelector } from "react-redux";
import { matchPath } from "react-router";
import { RootState } from "store";

export type CoBrandSlug = "arro" | "best-money" | "cappsool" | "unest";

interface ICoBrandMetadata {
  logoSrc: string;
  name: string;
}

export const CO_BRAND_SLUGS_BY_CAMPAIGN_ID: Record<number, CoBrandSlug> = {
  5363833: "arro",
  34020: "best-money",
  1985270: "cappsool",
  2235149: "unest",
};

export const CO_BRAND_METADATA_BY_SLUG: Record<CoBrandSlug, ICoBrandMetadata> = {
  arro: {
    logoSrc: ArroSrc,
    name: "Arro",
  },
  "best-money": {
    logoSrc: BestMoneySrc,
    name: "Best Money",
  },
  cappsool: {
    logoSrc: CappsoolSrc,
    name: "Best Life Insurance Hub",
  },
  unest: {
    logoSrc: UnestSrc,
    name: "Unest",
  },
};

const CO_BRANDED_PATHS = [
  "/coverage-preview",
  "/products/insurance/welcome",
  "/wysh-builder/list",
  "/products/insurance/wysh-builder/list",
];

export const useCoBranding = () => {
  const currentPath = useSelector((state: RootState) => state.router.location.pathname);
  const utmCampaign = useSelector((state: RootState) => state.analyticsTracker.utm.utm_campaign);

  const coBrandSlug = CO_BRAND_SLUGS_BY_CAMPAIGN_ID[utmCampaign];
  const coBrandMetadata = CO_BRAND_METADATA_BY_SLUG[coBrandSlug] || undefined;

  const isCoBrandedRoute = CO_BRANDED_PATHS.some(path =>
    matchPath(currentPath, { path, exact: true })
  );

  return {
    coBrandMetadata,
    isCoBranded: !!coBrandMetadata && isCoBrandedRoute,
  };
};
