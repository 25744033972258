import "./ContactService.scss";

import {
  CustomerSupportCall,
  CustomerSupportChat,
  CustomerSupportFAQ,
} from "components/CustomerSupportTiles/CustomerSupportTiles";
import Text from "components/Text/Text";
import { WyshAppStoreUrl, WyshGooglePlayUrl } from "config";
import { useTranslation } from "modules";

import MobilePhoneApp from "assets/images/illustrations/mobile_phone_app.svg";
import AppStoreLogo from "assets/logos/appstore.svg";
import GooglePlayLogo from "assets/logos/googleplay.svg";

export function ContactService() {
  const { t } = useTranslation();

  return (
    <div className="contact-service content-container">
      <div className="contact-service__column content-wrapper contact-service__customer-support">
        <Text tag="h4" text={t("home.tab.customer_service.header", "Need Help?")} />

        <div className="contact-service__tiles content-wrapper">
          <CustomerSupportChat />
          <CustomerSupportCall />
          <CustomerSupportFAQ />
        </div>
      </div>
      <div className="contact-service__column content-wrapper contact-service__mobile-app">
        <div>
          <Text tag="h4" text={t("home.tab.app_download.header", "Download our App")} />
          <div className="contact-service__app-store-btns">
            <a href={WyshAppStoreUrl} target="_blank" rel="noreferrer">
              <img alt="Apple App Store Logo" src={AppStoreLogo} />
            </a>
            <a href={WyshGooglePlayUrl} target="_blank" rel="noreferrer">
              <img alt="Google Play Store Logo" src={GooglePlayLogo} />
            </a>
          </div>
        </div>
        <div>
          <img
            alt="Wysh App on Mobile"
            className="contact-service__mobile-app-image"
            src={MobilePhoneApp}
          />
        </div>
      </div>
    </div>
  );
}
