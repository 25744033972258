import "./HeroImageHeader.scss";

import c from "classnames";
import { ClassName } from "config/types";
import { FC } from "react";

interface IProps {
  image: string;
  className?: ClassName;
}

const HeroImageHeader: FC<IProps> = ({ image, className }) => {
  const classes = c("hero-image-header", className);
  return <div className={classes} style={{ backgroundImage: `url(${image})` }} />;
};

export default HeroImageHeader;
