import { ComponentFormGroup, IDynamicForm, isDFGroupComponent } from "config";
import { findIndex, remove } from "lodash-es";
import { FC } from "react";

import { DynamicFormItemComponent } from "../DynamicFormItems";

import { AddressGroupComponent } from "./AddressGroupComponent";
import { PhoneGroupComponent } from "./PhoneGroupComponent/PhoneGroupComponent";

export type DynamicFormGroupComponentProps = {
  group: ComponentFormGroup;
  items: IDynamicForm["items"];
};

const formGroupsComponentMap: Record<ComponentFormGroup["detail"]["type"], FC<any>> = {
  address: AddressGroupComponent,
  phone: PhoneGroupComponent,
};

// Renders DynamicFormItemComponent(s) in ComponentGroupDetail.type
export const MapGroupComponentToItems: FC<GroupComponentProps> = ({ children, groups, items }) => {
  const groupComponents = (groups || []).filter(isDFGroupComponent);

  const mappedGroupComponentToItems = groupComponents.reduce((mappedFormItems, group) => {
    const FormGroupComponent = formGroupsComponentMap[group.detail.type];
    const indexOfItemToReplace = findIndex(mappedFormItems, [
      "props.item.key",
      group.detail.replacementKey,
    ]);

    const removedDynamicFormItems = remove(mappedFormItems, item => {
      return group.detail.itemKeys.includes(item.props.item?.key);
    });

    mappedFormItems[indexOfItemToReplace] = (
      <FormGroupComponent items={items} group={group} key={group.detail.replacementKey}>
        {removedDynamicFormItems}
      </FormGroupComponent>
    );

    return mappedFormItems;
  }, children);

  return <>{mappedGroupComponentToItems}</>;
};

interface GroupComponentProps {
  children: ReturnType<typeof DynamicFormItemComponent>[];
  groups: IDynamicForm["groups"];
  items: IDynamicForm["items"];
}

export { formGroupsComponentMap };
