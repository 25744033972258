import { env, OAuthClientId, OAuthSessionStorageKey } from "config";
import { defaultTo, get, includes, isEmpty, isEqual } from "lodash-es";
import { createContext, FC, useCallback, useContext, useEffect, useState } from "react";
import { session } from "store2";

import { PlatformContext } from "./PlatformProvider";

interface IOAuthContext {
  clientId: string;
}

const initialContextValue: IOAuthContext = {
  clientId: OAuthClientId,
};

const defaultContextValue = defaultTo(session.get(OAuthSessionStorageKey), initialContextValue);

export const OAuthContext = createContext<IOAuthContext>(defaultContextValue);

export const OAuthProvider: FC = ({ children }) => {
  // TODO: REMOVE PRODUCTION AND TESTING FROM LIST
  const isDevEnvironment = includes(["development", "staging", "testing", "production"], env);
  const { endpoint } = useContext(PlatformContext);
  const [context, setContextState] = useState<IOAuthContext>(defaultContextValue);

  const isFromSession = isEqual(session.get(OAuthSessionStorageKey), context);
  const resolvedStatus = !isDevEnvironment || isFromSession;
  const [resolved, setResolved] = useState(resolvedStatus);

  const setContext = (value: IOAuthContext) => {
    if (isEmpty(value.clientId)) throw new Error("Invalid Client ID");
    console.log("Client Context has been updated: ", value);
    session.set(OAuthSessionStorageKey, value);
    setContextState(value);
  };

  const fetchOauthId = useCallback(async () => {
    if (resolved) return;
    try {
      const url = new URL("/oauth_client_id", endpoint);
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const response = await fetch(url.href, { headers });
      const payload = await response.json();
      const clientId = get(payload, "client_id", "");
      setContext({ clientId });
    } catch (error) {
      console.log("Unable to fetch OAuth Client ID");
    } finally {
      setResolved(true);
    }
  }, [endpoint, resolved]);

  useEffect(() => {
    fetchOauthId();
  }, [fetchOauthId]);

  return (
    <OAuthContext.Provider value={context}>{resolved ? children : null}</OAuthContext.Provider>
  );
};
