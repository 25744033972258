import { findIndex } from "lodash-es";
import { useModal } from "modules";
import { useEffect, useRef } from "react";
import { useAppSelector } from "store";

import { BREAK_ACTIONS } from "../useButtonAction";

import { TApiButtonCommandFn } from ".";

export const useFormUnsavedDiffWarning = () => {
  const isFlowFormDirtied = useAppSelector(state => state.formFlow.isFormDirtied);
  const isFormDirtiedRef = useRef<boolean>(isFlowFormDirtied);
  const openModal = useModal();

  useEffect(() => {
    isFormDirtiedRef.current = isFlowFormDirtied;
  }, [isFlowFormDirtied]);

  const formUnsavedDiffWarningCommand: TApiButtonCommandFn = (_, { actions = [] }) => {
    if (isFormDirtiedRef.current) {
      const indexOfCurrentAction = findIndex(
        actions,
        action => action.command.destination === "formUnsavedDiffWarning"
      );
      const remainingButtonActions = actions.slice(indexOfCurrentAction + 1);

      openModal("ExitWithoutSavingModal", "", { actions: remainingButtonActions });
      return BREAK_ACTIONS;
    }
  };

  return formUnsavedDiffWarningCommand;
};
