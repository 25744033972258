import { BackNavIcon } from "./BackNavIcon";
import { BackNavText } from "./BackNavText";

interface IProps {
  hasPortal: boolean;
  iconSrc?: string;
  isExit: boolean;
  text?: string;
}

export function BackNavContent({ hasPortal, iconSrc, isExit, text }: IProps) {
  return (
    <div className="backnav__content-wrapper">
      <BackNavIcon hasPortal={hasPortal} iconSrc={iconSrc} isExit={isExit} />
      <BackNavText hasPortal={hasPortal} isExit={isExit} text={text} />
    </div>
  );
}
