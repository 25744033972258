import { createSelector } from "@reduxjs/toolkit";
import { IReinstateablePolicy } from "config";
import { RootState } from "store";

export const reinstateablePoliciesById = (state: RootState) => state.reinstateablePolicies.byId;
const reinstateablePoliciesHasLoaded = (state: RootState) => state.reinstateablePolicies.hasLoaded;
const reinstateablePoliciesIsLoading = (state: RootState) => state.reinstateablePolicies.isLoading;
const reinstateablePolicyIds = (state: RootState) => state.reinstateablePolicies.ids;

export const selectReinstateablePolicies = createSelector(
  reinstateablePolicyIds,
  reinstateablePoliciesById,
  reinstateablePoliciesHasLoaded,
  reinstateablePoliciesIsLoading,
  (policyIds, policiesById, hasLoaded, isLoading) => {
    return {
      hasLoaded,
      isLoading,
      reinstateablePolicies: policyIds.map(id => policiesById[id]),
    };
  }
);

export const selectReinstateablePolicyById = createSelector(
  reinstateablePoliciesById,
  (_: RootState, policyId: IReinstateablePolicy["id"]) => policyId,
  (policiesById, policyId) => policiesById[policyId]
);
