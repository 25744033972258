import { gql } from "config/graphql";
import { getApolloClient } from "store/apollo";

export interface IExchangeBlackboxInput {
  blackbox: string;
}

interface IExchangeBlackboxResponse {
  data: {
    exchangeBlackbox: {
      blocked: boolean;
      caseId: string;
      deviceId: string;
    };
  };
}

export const exchangeBlackboxMutation = (
  input: IExchangeBlackboxInput
): Promise<IExchangeBlackboxResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation exchangeBlackboxMutation($input: ExchangeBlackboxInput!) {
      exchangeBlackbox(input: $input) {
        blocked
        caseId
        deviceId
      }
    }
  `;

  return client.mutate({
    variables: {
      input: {
        blackbox: input.blackbox,
        clientFamily: "web",
      },
    },
    mutation,
  });
};
