import FlowReviewField from "components/FlowReview/FlowReviewField";
import { ComponentDynamicFormItem } from "containers/DynamicForm/DynamicFormItems";

interface IProps {
  isRequestingEdit: boolean;
  item: ComponentDynamicFormItem;
  onEdit(itemId: string): Promise<void>;
}

export function DynamicFormReviewItemComponent({ isRequestingEdit, item, onEdit }: IProps) {
  switch (item.type) {
    case "inputMultiSelect":
      return (
        <FlowReviewField
          inputType="multi_select"
          isEditDisabled={isRequestingEdit}
          label={item.content.label}
          onEdit={() => onEdit(item.content.id || "")}
          options={item.content.options.map(({ label, value }) => ({ label, value }))}
          value={item.content.value as string[]}
        />
      );

    case "inputSingleSelect":
      return (
        <FlowReviewField
          inputType="blobs"
          isEditDisabled={isRequestingEdit}
          label={item.content.label}
          onEdit={() => onEdit(item.content.id || "")}
          options={item.content.options.map(({ label, value }) => ({ label, value }))}
          value={item.content.value as string}
        />
      );

    case "inputText":
      return (
        <FlowReviewField
          inputType="long_text"
          isEditDisabled={isRequestingEdit}
          label={item.content.label}
          onEdit={() => onEdit(item.content.id || "")}
          value={item.content.value as string}
        />
      );

    default:
      return null;
  }
}
