import { Dispatch } from "@reduxjs/toolkit";
import {
  IAppError,
  IFlowField,
  IForm,
  IFormInput,
  IFormInputError,
  IFormInputs,
  IValidationData,
} from "config/types";
import {
  assign,
  flatten,
  forOwn,
  get,
  isArray,
  isEmpty,
  isEqual,
  isNumber,
  isString,
  some,
} from "lodash-es";
import { DateTime } from "luxon";
import IFormValidations from "modules/hooks/form/Validations";
import { useTranslation } from "modules/hooks/useTranslation";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { eventFired } from "store/actions/analytics";
import { setFormFlowDirty } from "store/actions/flow";
import { processValidationErrors } from "utils/error";
import { buildFormInputs, IResetValues, resetFormInputs } from "utils/form";

export interface IOptions {
  showInputErrorMessages: boolean;
  allValidInputsRequired?: boolean;
}

interface IReturnValue {
  form: IForm;
  inputs: IFormInputs;
}

const VALIDATION_ANALYTICS_EVENTS = {
  "errors.invalid_date_range": ({
    dispatch,
    key,
    value,
  }: {
    dispatch: Dispatch<any>;
    key: string;
    value: any;
  }) => {
    if (key === "prequalification-dob") {
      const birthdate = DateTime.fromISO(value);

      dispatch(
        eventFired({
          event: "prequal_birthday_validationerror",
          experienceLocation: "ONBOARDING",
          attribute: {
            birthdate: birthdate.isValid ? birthdate.toFormat("yyyy-MM-dd") : "",
          },
        })
      );
    }
  },
};

export const useForm = (
  fieldData: Partial<IFormInput>[],
  { showInputErrorMessages, allValidInputsRequired = false }: IOptions
): IReturnValue => {
  const dispatch = useDispatch();
  const formInputs = buildFormInputs(fieldData);
  const [inputs, setInputs] = useState(formInputs);
  const [loading, setLoading] = useState(false);
  const [allRequiredFieldsFilled, setAllRequiredFieldsFilled] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [formvalid, setFormValid] = useState(false);
  const [isFormDirtied, setIsFormDirtied] = useState(false);
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    initializeFormState(inputs);
  }, []); //eslint-disable-line

  /*
  ..................
  ~~~~~ START INITIIALIZE STATE ~~~~~
  ..................
  */
  const initializeFormState = (formInputs: IFormInputs | Partial<IFormInput>[]) => {
    const inputs = isArray(formInputs) ? buildFormInputs(fieldData) : formInputs;
    setInputs(inputs);
    setFormValidity(inputs);
    setRequiredFieldsFilled(inputs);
    setIsFormInitialized(true);
  };

  /*
  ..................
  ~~~~~ END INITIIALIZE STATE ~~~~~
  ..................
  */

  /*
  ..................
  ~~~~~ START VALIDATIONS ~~~~~
  ..................
  */
  const setRequiredFieldsFilled = (formInputs = inputs) => {
    let allFilled = true;

    forOwn(formInputs, input => {
      let value = input.value;

      // HACK: For Beneficiary Phone Numbers, we have a half international/half
      // US-only set up where the countryCode and phoneNumber are stored
      // separately in the `input.value`. This raises the `phoneNumber` up to
      // the top level. If we pivot back to allowing more countries, this will
      // need to be refactored.
      if (input.id === "phoneNumber" && input.value.hasOwnProperty("phoneNumber")) {
        value = input.value.phoneNumber;
      }

      if (input.inputType === "inputCheckbox") {
        value = input.value === true ? "true" : "";
      }

      if (input.required && !isNumber(value) && isEmpty(value)) {
        allFilled = false;
      }
    });

    setAllRequiredFieldsFilled(allFilled);
  };

  const validateField = (id: IFlowField["id"]) => {
    const errors: IFormInputError[] = [];

    const requiredValidation = IFormValidations["required"];

    const input = get(inputs, id);
    const value = get(input, ["value"], "");

    if (!input) {
      return;
    }

    if (input.required && !requiredValidation.validation(value)) {
      errors.push({
        code: requiredValidation.errorCode,
        message: t("errors.required", "Required"),
      });
    }

    if (!!value) {
      input.validations.forEach((validation: any) => {
        const validationName = isString(validation) ? validation : validation.name;
        const formValidation = IFormValidations[validationName];

        const validationData: IValidationData = {
          inputs,
          validation,
          inputId: input.id,
        };

        if (!formValidation.validation(value, validationData)) {
          const analyticsEvent = get(VALIDATION_ANALYTICS_EVENTS, formValidation.errorCode);

          if (analyticsEvent) {
            analyticsEvent({
              dispatch,
              key: input.key,
              value: input.value,
            });
          }

          errors.push({
            code: formValidation.errorCode,
            message: t(formValidation.errorCode, formValidation.defaultError),
          });
        }
      });
    }

    updateInput(id, { touched: true, error: errors });
  };

  const validateAllFields = () => {
    forOwn(inputs, input => {
      validateField(input.id);
    });

    const isFormValid = setFormValidity();

    return isFormValid;
  };

  const setFormValidity = (formInputs = inputs) => {
    let valid = true;

    forOwn(formInputs, input => {
      const errors = flatten([input.error]);
      if (errors.length) {
        valid = false;
      }
    });

    setFormValid(valid);

    return valid;
  };

  const setFormDirtied = () => {
    const areInputValuesDirtied = some(inputs, input => !isEqual(input.value, input.pristineValue));

    setIsFormDirtied(areInputValuesDirtied);
    dispatch(setFormFlowDirty(areInputValuesDirtied));

    return areInputValuesDirtied;
  };
  /*
  ..................
  ~~~~~ END VALIDATIONS ~~~~~
  ..................
  */

  /*
  ..................
  ~~~~~ START HELPERS ~~~~~
  ..................
  */
  const updateInput = (id: IFormInput["id"], data: { [key: string]: any }) => {
    const newInput: IFormInput = assign(inputs[id], data);
    const newInputs = { ...inputs, ...{ [id]: newInput } };
    setInputs(newInputs);
    return newInputs;
  };

  const getFormInput = (id: IFormInput["id"]) => {
    return inputs[id] || {};
  };

  const getInputError = (id: IFormInput["id"]) => {
    const inputErrors = getFormInput(id)?.error || [];
    return !!inputErrors.length;
  };

  const getInputErrorMessage = (id: IFormInput["id"]) => {
    const inputErrors = getFormInput(id)?.error || [];
    return showInputErrorMessages ? inputErrors.map(inputError => inputError.message) : [];
  };

  const getSubmitBtnDisabled = () => {
    const isFormInvalid = allValidInputsRequired ? !formvalid : false;

    return !form.allRequiredFieldsFilled || loading || !!form.formErrorMessage || isFormInvalid;
  };

  const reset = (resetValues: IResetValues) => {
    const newInputs = resetFormInputs(inputs, resetValues);
    setInputs(newInputs);
    setRequiredFieldsFilled(newInputs);
    setLoading(false);
  };

  const processServerValidationErrors = (thunkError: IAppError | null) => {
    const newInputs = processValidationErrors(inputs, thunkError?.validationErrors);
    setInputs(newInputs);
    return newInputs;
  };
  /*
  ..................
  ~~~~~ END HELPERS ~~~~~
  ..................
  */

  /*
  ..................
  ~~~~~ START EVENT HANDLERS ~~~~~
  ..................
  */

  const onBlur = (event: any) => {
    const { id } = event.target;
    validateField(id);
    setFormValidity();
    setFormDirtied();
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    const input = get(inputs, id);
    const isNumericField = input.metadata.flowInputType === "numeric";
    const newValue = isNumericField && value ? parseInt(value) : value;

    const newInputs = updateInput(id, {
      value: newValue,
      dirty: !isEqual(value, input.pristineValue),
      touched: true,
      error: [],
    });

    setFormErrorMessage("");
    setRequiredFieldsFilled(newInputs);
  };

  /*
  ..................
  ~~~~~ END EVENT HANDLERS ~~~~~
  ..................
  */
  const form = {
    onChange,
    onBlur,
    getFormInput,
    getInputError,
    getInputErrorMessage,
    validateAllFields,
    getSubmitBtnDisabled,
    setLoading,
    setFormErrorMessage,
    loading,
    formErrorMessage,
    allRequiredFieldsFilled,
    valid: formvalid,
    reset,
    processServerValidationErrors,
    setInputs,
    initializeFormState,
    isFormDirtied,
    isFormInitialized,
  };

  return { form, inputs };
};
