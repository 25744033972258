import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const policyRiderIds = (state: RootState) => state.policyRiders.ids;
const policyRidersById = (state: RootState) => state.policyRiders.byId;

export const selectPolicyRiders = createSelector(
  policyRiderIds,
  policyRidersById,
  (riderIds, ridersById) => {
    return riderIds.map(id => {
      const { label, rider } = ridersById[id];
      return {
        cta: rider.cta,
        ctaDestination: rider.ctaDestination,
        description: rider.description,
        id,
        label,
        name: rider.name,
      };
    });
  }
);
