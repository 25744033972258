import TextInput from "components/TextInput/TextInput";
import { ValueOrArray } from "config/types";
import { get, isEmpty, replace } from "lodash-es";
import { DateTime } from "luxon";
import { ChangeEvent, FC, FocusEvent } from "react";
import InputMask, { BeforeMaskedStateChangeStates, InputState } from "react-input-mask";

interface IProps {
  id: string;
  dataTestId?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: ValueOrArray<string>;
  label?: string;
  placeholder?: string;
  shouldDisplayUtcAsLocal?: boolean;
  value?: string; // iso string
  onBlur?(event: FocusEvent<HTMLInputElement>): void;
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
  onFocus?(event: FocusEvent<HTMLInputElement>): void;
}

const dateMask = [/[0-1]/, /[0-9]/, "/", /[0-3]/, /[0-9]/, "/", /[1-2]/, /[0,9]/, /[0-9]/, /[0-9]/];

const DateInput: FC<IProps> = props => {
  const {
    dataTestId,
    disabled = false,
    error,
    errorMessage,
    id,
    label,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    shouldDisplayUtcAsLocal,
    value,
  } = props;

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const dateTime = DateTime.fromFormat(event.target.value, "MM/dd/yyyy");
    if (dateTime.isValid) {
      event.target.value = dateTime.toISO();
    }
    onChange && onChange(event);
  };

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(event);
  };

  const handleOnFocus = (event: FocusEvent<HTMLInputElement>) => {
    onFocus && onFocus(event);
  };

  const handleMaskedStateChange = ({
    currentState,
    nextState,
  }: BeforeMaskedStateChangeStates): InputState => {
    const currentValue = replace(currentState?.value, /[^A-Za-z0-9]/, "");
    const nextValue = nextState?.value;
    const enteredString = get(nextState, "enteredString", "");
    const value =
      isEmpty(enteredString) &&
      (isEmpty(currentValue) || currentValue === "/__/____") &&
      (isEmpty(nextValue) || nextValue === "__/__/____")
        ? ""
        : nextValue;

    return { ...nextState, value };
  };

  const formatDate = () => {
    if (!value) {
      return "";
    }

    let dateTime;

    if (shouldDisplayUtcAsLocal && (value.endsWith("+00:00") || value.endsWith("Z"))) {
      dateTime = DateTime.fromISO(value, { zone: "Etc/UTC" });
    } else {
      dateTime = DateTime.fromISO(value);
    }

    return dateTime.isValid ? dateTime.toFormat("MM/dd/yyyy") : value;
  };

  const labelText = placeholder || label;

  return (
    <InputMask
      id={id}
      mask={dateMask}
      disabled={disabled}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      beforeMaskedStateChange={handleMaskedStateChange}
      value={formatDate()}>
      <TextInput
        type="text"
        label={labelText ? labelText : "mm/dd/yyyy"}
        id={id}
        error={error}
        errorMessage={errorMessage}
        dataTestId={dataTestId || "text-input__date"}
        inputMode="numeric"
      />
    </InputMask>
  );
};

export default DateInput;
