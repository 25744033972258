import { IUser, IUserAccount, OrUndefined } from "config/types";

import { getActiveConstellation, getConstellationMembership } from "./constellation";

export const getActiveAccount = (user: Partial<IUser>) => {
  const activeInsuranceMembership = getConstellationMembership(
    user?.activeConstellations || [],
    "wysh-insurance"
  );

  const activeConstellation = getActiveConstellation(user.activeConstellations);

  return { ...user, ...activeConstellation, ...activeInsuranceMembership };
};

// Temporary way to generate membership numbers
// There is an open discussion about where this value with ultimately come from
export const getMembershipNumber = (account: OrUndefined<IUserAccount>) => {
  return account?.id ? account.id.substring(0, 10) : "";
};
