import { gql } from "config/graphql";
import { IUser } from "config/types";
import { COVERAGE_BREAKDOWN_FIELDS } from "graphql/fragments";
import { getApolloClient } from "store/apollo";

interface ICoverageBreakdownResponse {
  data: {
    me: Pick<IUser, "activeConstellations">;
  };
}

export const coverageBreakdownQuery = (): Promise<ICoverageBreakdownResponse> => {
  const client = getApolloClient();

  const query = gql`
    ${COVERAGE_BREAKDOWN_FIELDS}
    query CoverageBreakdown {
      me {
        activeConstellations {
          ...CoverageBreakdownFields
        }
      }
    }
  `;

  return client.query({
    fetchPolicy: "no-cache",
    query,
  });
};
