import { gql } from "config/graphql";
import { getApolloClient } from "store/apollo";
import { IReinstateablePolicy } from "config";

export const createReinstatementInvoiceMutation = (policyId: IReinstateablePolicy["id"]) => {
  const client = getApolloClient();

  const mutation = gql`
    mutation createReinstatementInvoice($input: CreateReinstatementInvoiceInput!) {
      createReinstatementInvoice(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables: {
      input: {
        policyId,
      },
    },
  });
};
