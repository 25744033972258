import { changeUser, getUser as getBrazeUser, initialize, openSession } from "@braze/web-sdk";
import { BrazeAPIKey, BrazeCustomEndpoint, BrazeEnableLogging } from "config/constants";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { BrazeIAMProvider } from "./BrazeIAMProvider";
import { useAppSelector } from "store";
import { getUser } from "store/selectors";

interface IBrazeProvider {
  children: ReactNode;
}

function BrazeProvider({ children }: IBrazeProvider) {
  const user = useAppSelector(getUser);

  const [isInitialized, setIsInitialized] = useState(false);
  const initializeBraze = useCallback(async () => {
    initialize(BrazeAPIKey, {
      baseUrl: BrazeCustomEndpoint,
      enableLogging: !!BrazeEnableLogging,
      allowUserSuppliedJavascript: true,
    });
    openSession();
  }, []);

  useEffect(() => {
    async function init() {
      await initializeBraze();
      setIsInitialized(true);
    }
    init();
  }, [initializeBraze]);

  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    const brazeUserId = getBrazeUser()?.getUserId();
    if (user && user.id !== brazeUserId) {
      changeUser(user.id);
    }
  }, [isInitialized, user]);

  return (
    <>
      {isInitialized && <BrazeIAMProvider />}
      {children}
    </>
  );
}

export default BrazeProvider;
