import "./SplashMessage.scss";

import classNames from "classnames";
import BackNav from "components/BackNav/BackNav";
import Button from "components/Button/Button";
import Text from "components/Text/Text";
import { OrString, ValueOrArray } from "config/types";
import Container from "containers/Container/Container";
import { motion, usePresence } from "framer-motion";
import { flatten, isEmpty, isString, map, noop } from "lodash-es";
import { ConditionalWrapper, ILayoutProps } from "modules";
import { cloneElement, ComponentProps, FC, HTMLAttributes, isValidElement, useEffect } from "react";

type ContentType = {
  content: ValueOrArray<OrString<FC>>;
  image: string;
  backgroundImage: string;
  title: OrString<ComponentProps<typeof Text>>;
  subtitle: OrString<ComponentProps<typeof Text>>;
  button: OrString<ComponentProps<typeof Button>>;
  backNav: OrString<ComponentProps<typeof BackNav>>;
};

const SplashMessage: FC<ILayoutProps<ContentType>> = props => {
  const { children, className } = props;
  const [isPresent, safeToRemove] = usePresence();

  useEffect(() => {
    !isPresent && setTimeout(safeToRemove || noop, 1000);
  }, [isPresent, safeToRemove]);

  const content = props.content as ContentType;
  const bodyContent = flatten([content?.content]);
  const backgroundImage = content?.backgroundImage;
  const title = content?.title;
  const subtitle = content?.subtitle;
  const titleProps = isString(title) ? { tag: "h2", text: title } : title;
  const subtitleProps = isString(subtitle) ? { tag: "h4", text: subtitle } : subtitle;

  const button = content?.button;
  const buttonProps = isString(button) ? { subtype: "primary", text: button } : button;

  const backNav = content?.backNav;
  const backNavProps = isString(backNav) ? { pathname: backNav } : backNav;

  const classes = classNames("splash-message__container", className);

  const contentContainerAnimations = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delay: 1.4,
        delayChildren: 1.4,
        staggerChildren: 0.5,
      },
    },
  };

  const contentAnimations = {
    hidden: { opacity: 0, scale: 0.7, y: "-100%" },
    show: { opacity: 1, scale: 1, y: 0 },
    exit: { opacity: 0, scale: 0.7, y: "-100%" },
  };

  return (
    <Container className={classes}>
      {backNav && <BackNav {...(backNavProps as ComponentProps<typeof BackNav>)} />}
      <div className="splash-message__wrapper flex-column">
        <div className="splash-message__background-image_wrapper">
          <motion.img
            src={backgroundImage}
            alt="splash-message-image"
            className="splash-message__background-image"
            initial={{ scale: 0, opacity: 0, y: 20 }}
            animate={{ scale: [1.2, 0.5, 1], opacity: [1, 1, 1], y: [20, 20, 0] }}
            transition={{ duration: 0.5, delay: 1 }}
          />
        </div>

        <motion.div
          className="splash-message__content-wrapper"
          variants={contentContainerAnimations}
          initial="hidden"
          animate="show">
          {!isEmpty(subtitleProps) && (
            <motion.div
              key="contentSubtitle"
              className="splash-message__content-subtitle-wrapper"
              variants={contentAnimations}
              animate="show"
              initial="hidden"
              exit="exit">
              <Text
                {...(subtitleProps as ComponentProps<typeof Text>)}
                className="splash-message__content-subtitle"
              />
            </motion.div>
          )}
          {!isEmpty(titleProps) && (
            <motion.div
              key="contentTitle"
              className="splash-message__content-title-wrapper"
              variants={contentAnimations}
              animate="show"
              initial="hidden">
              <Text
                {...(titleProps as ComponentProps<typeof Text>)}
                className="splash-message__content-title"
              />
            </motion.div>
          )}
          <ConditionalWrapper condition={!isEmpty(bodyContent)}>
            <div className="splash-message__content-text-wrapper color-secondary-text">
              {map(bodyContent, (text, key) =>
                isValidElement(text) ? (
                  cloneElement(text, {
                    key,
                    className: "p4-tag__text splash-message__content-text",
                  } as HTMLAttributes<HTMLParagraphElement>)
                ) : (
                  <Text
                    key={key}
                    tag="p4"
                    text={text as string}
                    className="splash-message__content-text"
                    style={{ marginBottom: 20 }}
                  />
                )
              )}
            </div>
          </ConditionalWrapper>
          {buttonProps && (
            <div className="splash-message__content-button__wrapper flex-column--end">
              <Button
                {...(buttonProps as ComponentProps<typeof Button>)}
                className="splash-message__content-button"
              />
            </div>
          )}
          {children}
        </motion.div>
      </div>
    </Container>
  );
};

export default SplashMessage;
