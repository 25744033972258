import c from "classnames";
import Text from "components/Text/Text";
import { kebabCase } from "lodash-es";
import replaceWyshwareUri from "utils/replaceWyshwareUri";
import { translateTextTag } from "utils/translateTextTag";

import { IDynamicFormItemProps } from ".";

export function TextItem({ item }: IDynamicFormItemProps<"text">) {
  const { color, html, text, typography } = item.content;
  const translatatedTypography = translateTextTag(typography);

  const colorClassName = `color-${kebabCase(color)}`;
  const textTagClassName = `${typography}-dynamic-form__text`;

  const classes = c("dynamic-form-item form-item--text", colorClassName, textTagClassName);

  if (html) {
    return (
      <Text
        className={classes}
        dangerouslySetInnerHTML={{ __html: replaceWyshwareUri(text) }}
        tag={translatatedTypography}
      />
    );
  }

  return (
    <Text className={classes} tag={translatatedTypography}>
      {text}
    </Text>
  );
}
