import { createReducer } from "@reduxjs/toolkit";
import { IModal } from "config/types";
import { LOCATION_CHANGE } from "connected-react-router";
import { defaultTo } from "lodash-es";

import * as t from "../constants";

const defaultState: IModal = {
  currentModal: "",
  id: "",
  data: {},
};

const modal = createReducer(defaultState, {
  [t.SET_MODAL]: (state, action) => {
    state.currentModal = action.payload.modal;
    state.id = action.payload.id;
    state.data = defaultTo(action.payload.data, {});
  },
  [t.RESET_MODAL]: () => defaultState,
  [LOCATION_CHANGE]: () => defaultState,
});

export default modal;
