import { createAsyncThunk } from "@reduxjs/toolkit";
import { IDisclosure, IDocument } from "config";
import {
  createAcknowledgementMutation,
  ICreatAcknowledgementInput,
} from "graphql/mutations/acknowledgement";
import { documentsQuery } from "graphql/queries/disclosure";
import { includes, map } from "lodash-es";
import { RootState } from "store";
import { getAppError, getThunkError } from "utils/error";

/* ------------       THUNKS      ------------------ */

export const createAcknowledgement = createAsyncThunk(
  "acknowledgement/createAcknowledgement",
  async (createAcknowledgementInput: ICreatAcknowledgementInput, { rejectWithValue }) => {
    try {
      const { data } = await createAcknowledgementMutation(createAcknowledgementInput);
      const acknowledgement = data.createAcknowledgement.acknowledgement;

      return acknowledgement;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const getDocuments = createAsyncThunk(
  "documents/getDocuments",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await documentsQuery();
      const response = data.me;

      return response;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const createPurchaseAcknowledgements = createAsyncThunk(
  "acknowledgement/createPurchaseAcknowledgements",
  async (
    createAcknowledgementInputs: (IDocument | IDisclosure)[],
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { insuranceMembership, quote } = getState() as RootState;

      const mutations = map(createAcknowledgementInputs, record => {
        const isDocumentRecord = includes(["quote"], record.slug);
        const recordType = isDocumentRecord ? "document" : "disclosure";

        if (record.slug === "replace-accidental-death") {
          const contextId = quote.insuranceApplication.id;
          const contextType = "InsuranceApplication";

          return dispatch(
            createAcknowledgement({ recordId: record.id, recordType, contextId, contextType })
          );
        } else {
          const contextId = insuranceMembership.id;
          const contextType = "InsuranceMembership";

          return dispatch(
            createAcknowledgement({ recordId: record.id, recordType, contextId, contextType })
          );
        }
      });

      const responses = await Promise.all(mutations);
      responses.forEach(response => {
        const error = getThunkError(response);
        if (error) {
          throw error;
        }
      });

      return responses;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
