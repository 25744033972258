import { gql } from "config/graphql";
import {
  Camelize,
  IAccessToken,
  PhoneVerificationMode,
  SessionStatusesStrings,
} from "config/types";
import { getApolloClient } from "store/apollo";

export const exchangeLoginTokenMutation = (input: {
  loginToken: string;
}): Promise<IExchangeLoginTokenResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation exchangeLoginToken($input: ExchangeLoginTokenInput!) {
      exchangeLoginToken(input: $input) {
        token {
          accessToken
          createdAt
          expiresIn
          refreshToken
          scope
          tokenType
        }
      }
    }
  `;

  return client.mutate({
    variables: { input },
    mutation,
  });
};

export const verifySessionMutation = (input: {
  code: string;
  phoneNumberId?: string;
}): Promise<IVerifySessionResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation verifySession($input: VerifySessionInput!) {
      verifySession(input: $input) {
        session {
          id
          status
        }
      }
    }
  `;

  return client.mutate({
    variables: { input },
    mutation,
  });
};

export const requestOtpMutation = (input: {
  sessionId: string;
  phoneNumberId?: string;
  channel?: PhoneVerificationMode;
}): Promise<IRequestOtpResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation requestOtp($input: RequestOtpInput!) {
      requestOtp(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: { input },
    mutation,
  });
};

/* ------------       INTERFACE      ------------------ */

interface IExchangeLoginTokenResponse {
  data: {
    exchangeLoginToken: {
      token: Camelize<IAccessToken>;
    };
  };
}

interface IVerifySessionResponse {
  data: {
    verifySession: {
      session: {
        status: SessionStatusesStrings;
      };
    };
  };
}

interface IRequestOtpResponse {
  data: {
    requestOtp: {
      response: {
        success: boolean;
      };
    };
  };
}
