import {
  IFinanceScheduledTransfersPayload,
  IFinanceTransferSchedule,
  IPendingAutoTransfersResponse,
} from "config";
import { gql } from "config/graphql";
import { FINANCE_CUSTOMER_FIELDS, FINANCE_SCHEDULED_TRANSFERS_FIELDS } from "graphql/fragments";
import { getApolloClient } from "store/apollo";

export const financeScheduledTransfersQuery = (): Promise<IFinanceScheduledTransfersPayload> => {
  const client = getApolloClient();

  const query = gql`
    ${FINANCE_CUSTOMER_FIELDS}
    query FinanceScheduledTransfers {
      me {
        financeCustomer {
          ...FinanceCustomerFields
        }
      }
    }
  `;

  return client.query({ query });
};

export const financeAutoTransfersQuery = (
  input?: IFinanceTransferSchedule["id"][]
): Promise<IPendingAutoTransfersResponse> => {
  const client = getApolloClient();

  const query = gql`
    ${FINANCE_SCHEDULED_TRANSFERS_FIELDS}
    query PendingAutoTransfers($financeTransferScheduleIds: [ID!]) {
      pendingAutoTransfers(financeTransferScheduleIds: $financeTransferScheduleIds) {
        ...FinanceScheduledTransferFields
      }
    }
  `;

  return client.query({
    query,
    variables: {
      ...(!!input && { financeTransferScheduleIds: input }),
    },
  });
};
