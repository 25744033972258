import { buildConditions, ConditionKey } from "config/conditions";
import { Condition, OR, ValueOrArray } from "config/types";
import {
  every,
  flatten,
  get,
  isEmpty,
  isPlainObject,
  mapKeys,
  mapValues,
  pickBy,
  split,
} from "lodash-es";
import { useStore } from "react-redux";
import { RootState } from "store";

type Conditions<V = string> = OR<Record<string, V>, ValueOrArray<V>>;

const formatConditions = (collection: Conditions) => {
  if (isPlainObject(collection)) return collection as Conditions<ConditionKey>;

  const conditionList = flatten([collection as ValueOrArray<ConditionKey>]);
  const mappedList = mapKeys(conditionList, value => value);
  return mappedList as Record<ConditionKey, ConditionKey>;
};

export const useCondition = (collection?: Conditions<ConditionKey>): Condition<boolean> => {
  const { getState } = useStore<RootState>();
  const store = getState();

  if (collection === undefined) return {};

  const conditionCases = buildConditions(store);
  const conditions = formatConditions(collection);
  const filteredConditions = pickBy(conditions, (value, key) => !isEmpty(value) && !isEmpty(key));
  if (isEmpty(filteredConditions)) return conditionCases;

  const conditionIterator = (value: string): boolean => {
    const conditionSeparator = ":";
    const splitConditionList = split(value, conditionSeparator);

    const evaluatedCondition = every(splitConditionList, conditionKey =>
      get(conditionCases, conditionKey, false)
    );

    return evaluatedCondition;
  };

  const reducedConditions = mapValues(filteredConditions, conditionIterator);
  return reducedConditions as Condition;
};
