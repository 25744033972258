import "./Button.scss";

import classNames from "classnames";
import Icon from "components/Icon/Icon";
import Loader from "components/Loader/Loader";
import Text, { TextType } from "components/Text/Text";
import { ButtonSubType, ClassName, IDataProps } from "config/types";
import { defaultTo, isEmpty } from "lodash-es";
import { ButtonHTMLAttributes, FC, Fragment, Ref } from "react";

interface IProps extends IDataProps, Omit<ButtonHTMLAttributes<HTMLButtonElement>, "className"> {
  buttonRef?: Ref<HTMLButtonElement> | null;
  color?: string;
  subtype?: ButtonSubType;
  disabled?: boolean;
  text?: string;
  icon?: string;
  className?: ClassName;
  align?: "left" | "right" | "none";
  type?: "button" | "reset" | "submit" | undefined;
  tag?: TextType;
  isLoading?: boolean;
  isRounded?: boolean;
  size?: "md" | "sm";
}

const Button: FC<IProps> = props => {
  const {
    align,
    className,
    color,
    children,
    disabled,
    icon,
    isRounded,
    size = "md",
    subtype = "primary",
    text,
    type,
    tag,
    isLoading,
    dataTestId,
    dataAnalyticsId,
    buttonRef,
    ...rest
  } = props;
  const style = {
    ...props.style,
    color,
  };

  const buttonClass = classNames("btn", className, subtype, `btn--${size}`, {
    disabled,
    "btn--is-rounded": isRounded,
    loading: isLoading,
  });
  const TextContent = isEmpty(text) ? null : (
    <Text tag={tag || "c5"} className="btn-text">
      {text}
    </Text>
  );

  const content = defaultTo(TextContent, children);
  return (
    <button
      style={style}
      {...rest}
      className={buttonClass}
      disabled={disabled}
      ref={buttonRef}
      type={type}
      data-testid={dataTestId}
      data-analyticsid={dataAnalyticsId}>
      {isLoading ? <Loader /> : null}
      <Fragment>
        {icon && align === "left" ? <Icon src={icon} className="left-icon" /> : null}
        {content}
        {icon && align === "right" ? <Icon src={icon} className="right-icon" /> : null}
      </Fragment>
    </button>
  );
};

export default Button;
