import "./Accordion.scss";

import c from "classnames";
import AccordionPanel from "components/AccordionPanel/AccordionPanel";
import { chunk } from "lodash-es";
import { FC, useMemo, useState } from "react";

import { useAppDispatch } from "store";
import { eventFired } from "store/actions/analytics";

interface IProps {
  fireAnalyticsEvent?: boolean;
  panelData: { id: string; label: string; content: string }[];
  splitColumn?: boolean;
}

const Accordion: FC<IProps> = props => {
  const { fireAnalyticsEvent, panelData, splitColumn } = props;
  const dispatch = useAppDispatch();

  const initialState = useMemo(() => {
    return panelData.map(panelDatum => {
      return {
        expanded: false,
        ...panelDatum,
      };
    });
  }, [panelData]);

  const [panels, setPanels] = useState(initialState);

  const onClick = (id: string) => {
    setPanels(
      panels.map(panel => ({
        ...panel,
        expanded: panel.id === id && !panel.expanded,
      }))
    );

    if (fireAnalyticsEvent && panels.find(panel => panel.id === id)?.expanded === false) {
      dispatch(
        eventFired({
          event: "fulfillment_quote_faq_click",
          experienceLocation: "ONBOARDING",
          attribute: {
            faqname: panels.find(panel => panel.id === id)?.label,
          },
        })
      );
    }
  };

  const panelChunk = chunk(panels, Math.round(panels.length / 2));
  const leftColumn = panelChunk[0] || [];
  const rightColumn = panelChunk[1] || [];

  const className = c("accordion__wrapper", {
    "two-column": !!splitColumn,
  });

  return (
    <div className="accordion">
      <div className={className}>
        <div className="accordion__panels">
          {leftColumn.map((panel, index) => {
            return (
              <AccordionPanel
                key={index}
                id={panel.id}
                label={panel.label}
                content={panel.content}
                expanded={panel.expanded}
                onClick={onClick}
              />
            );
          })}
        </div>
        <div className="accordion__panels">
          {rightColumn.map((panel, index) => {
            return (
              <AccordionPanel
                key={index}
                id={panel.id}
                label={panel.label}
                content={panel.content}
                expanded={panel.expanded}
                onClick={onClick}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
