import "./Menu.scss";

import { IMenuItem } from "config/types";
import { defaultTo, kebabCase, map } from "lodash-es";
import { FC, MouseEvent, FocusEvent } from "react";

import MenuListItem from "./MenuListItem";

type IProps = {
  menuItems: IMenuItem[];
  handleCloseMenu?: (event: MouseEvent) => void;
  isSubMenu?: boolean;
  dataTestId?: string;
  onFocus?(event: FocusEvent): void;
};

const MenuList: FC<IProps> = ({ menuItems, isSubMenu, children, handleCloseMenu, onFocus }) => {
  const MenuItems = map(menuItems, (item: IMenuItem, index) => {
    const { component, props, ...rest } = item;
    const Element = defaultTo(component, MenuListItem);

    const onClickHandler = (event: MouseEvent) => {
      item.onClick && item.onClick(event);
      handleCloseMenu && handleCloseMenu(event);
    };

    return (
      <Element
        key={index}
        isSubMenu={isSubMenu}
        onFocus={onFocus}
        {...rest}
        {...props}
        onClick={onClickHandler}
        dataTestId={`menu-item__${kebabCase(item.text)}`}>
        {children}
      </Element>
    );
  });

  return <>{MenuItems}</>;
};

export default MenuList;
