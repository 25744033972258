import { IFinanceProgramDetails } from "config";
import { gql } from "config/graphql";
import { getApolloClient } from "store/apollo";

interface IFinanceProgramDetailsResponse {
  data: {
    financeProgramDetails: IFinanceProgramDetails;
  };
}

export const financeProgramDetailsQuery = (): Promise<IFinanceProgramDetailsResponse> => {
  const client = getApolloClient();

  const query = gql`
    query financeProgramDetails {
      financeProgramDetails(productSlug: "wysh-savings") {
        apy
        apyLastUpdatedAt
        lifeBenefitPercentage
        lifeBenefitMaximumCoverage
        nationalAverageMultiplier
        nationalAverageMultiplierLastUpdatedAt
        updatedAt
      }
    }
  `;

  return client.query({ fetchPolicy: "cache-first", query });
};
