import {
  beneficiaryPacketModalDisplayedEvent,
  beneficiaryPacketModalExit,
  beneficiaryPacketSentEvent,
} from "analytics/beneficiaryEvents";
import VariableText from "components/VariableText/VariableText";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout, useTranslation } from "modules";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { sendBeneficiaryPacket } from "store/actions/beneficiaries";

const SendBeneficiaryPacketModal: FC<IModalProps> = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(beneficiaryPacketModalDisplayedEvent());
  }, [dispatch]);

  const beneficiaryFirstName = props.data.beneficiaryFirstName as string;
  const beneficiaryId = props.data.beneficiaryId as string;
  const onModalClose = props.context.callbacks?.onClose;

  const closeModalWrapper = async () => {
    dispatch(beneficiaryPacketModalExit());
    await props.closeModal(onModalClose);
  };

  const content = {
    onClose: closeModalWrapper,
    icon: "BlobMail",
    header: t(
      "beneficiary.modal.beneficiary_packet.header",
      "Want to let %<beneficiaryFirstName>s know?",
      { beneficiaryFirstName }
    ),
    subheader: (
      <VariableText
        target="<strong>not</strong>"
        text={t(
          "beneficiary.modal.beneficiary_packet.body.html",
          "If so, we’ll send them an email with your name, policy number, and basic claims instructions (just in case). They will <strong>not</strong> be told your policy amount, term, or their allocation."
        )}
        replacementValue={<strong>not</strong>}
      />
    ),
    button: [
      {
        onClick: async () => {
          dispatch(sendBeneficiaryPacket({ beneficiaryId }));

          dispatch(beneficiaryPacketSentEvent());
          props.closeModal(onModalClose);
        },
        text: t("beneficiary.modal.beneficiary_packet.cta_1", "YES, SEND THE EMAIL"),
      },
      {
        onClick: closeModalWrapper,
        subType: "text-dark",
        text: t("beneficiary.modal.beneficiary_packet.cta_2", "NO THANKS"),
      },
    ],
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default SendBeneficiaryPacketModal;
