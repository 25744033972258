import "./Tile.scss";

import classNames from "classnames";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { ClassName } from "config";
import { MouseEvent, ReactNode } from "react";
import { Link } from "react-router-dom";

export interface ITile {
  body: ReactNode | string;
  iconSrc: string;
  title: string;
  className?: ClassName;
  src?: string;
  showAsExternal?: boolean;
  target?: "_blank" | "_parent" | "_self" | "_top";
  to?: string;
  onClick?(event: MouseEvent<HTMLAnchorElement | HTMLDivElement>): void;
}

function TileBody(props: Omit<ITile, "onClick" | "src" | "to">) {
  const { body, iconSrc, showAsExternal, title } = props;

  return (
    <>
      <img className="tile__icon" src={iconSrc} />

      <div className="tile__content">
        <Text className="tile__title" tag="l5" text={title} />
        <Text className="tile__body" tag="p5-paragraph" text={body} />
      </div>

      {showAsExternal && <Icon src="NewTab" size="sm" />}
    </>
  );
}

function Tile(props: ITile) {
  const { className, onClick, showAsExternal, src, target, to, ...bodyProps } = props;

  const classes = classNames("tile", className, { "tile--clickable": !!onClick });

  if (to && !src) {
    return (
      <Link className={classes} onClick={onClick} target={target} to={to}>
        <TileBody showAsExternal={showAsExternal} {...bodyProps} />
      </Link>
    );
  }

  if (src && !to) {
    return (
      <a className={classes} href={src} onClick={onClick} target={target}>
        <TileBody showAsExternal={showAsExternal} {...bodyProps} />
      </a>
    );
  }

  return (
    <div className={classes} onClick={onClick}>
      <TileBody showAsExternal={showAsExternal} {...bodyProps} />
    </div>
  );
}

export default Tile;
