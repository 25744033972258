import "./Text.scss";

import classNames from "classnames";
import { ClassName, OR } from "config/types";
import { defaultTo, get, isEmpty, isString } from "lodash-es";
import { CSSProperties, FC, HTMLProps, ReactNode } from "react";

export type TextType = keyof typeof textTypes;

type ElementType = OR<HTMLSpanElement, HTMLParagraphElement>;
type ElementProps = HTMLProps<ElementType>;

export interface IProps extends Omit<ElementProps, "className"> {
  tag: TextType;
  as?: TextType;
  text?: ReactNode;
  style?: CSSProperties;
  className?: ClassName;
  dataTestId?: string;
}

const Text: FC<IProps> = props => {
  const { as = "", style, tag, text, className, children, dataTestId, ...rest } = props;
  const defaultClassName = `text-tag ${tag}-tag__text`;

  const classes = classNames({ [`as:${as}`]: !isEmpty(as) }, defaultClassName, className);

  const Element: any = get(textTypes, as, get(textTypes, tag, null));
  const content: ReactNode | string = defaultTo(text, children);

  if (isString(content)) {
    return (
      <Element data-testid={dataTestId} style={style} className={classes} {...rest}>
        {`${content}`}
      </Element>
    );
  }

  const Render = (
    <Element data-testid={dataTestId} style={style} className={classes} {...rest}>
      {content}
    </Element>
  );
  return isEmpty(Element) ? null : Render;
};

export const textTypes = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  p1: "p",
  p2: "p",
  p3: "p",
  p4: "p",
  p5: "p",
  p6: "p",
  b6: "p",
  c1: "p",
  c2: "p",
  c3: "p",
  c4: "p",
  c5: "p",
  c6: "p",
  l1: "p",
  l2: "p",
  l3: "p",
  l4: "p",
  l5: "p",
  l6: "p",
  n1: "p",
  n2: "p",
  n3: "p",
  n4: "p",
  n5: "p",
  n6: "p",
  wl: "p",
  span: "span",
  "d1-display": "h1",
  "d2-display": "h2",
  "h1-header": "h1",
  "h2-header": "h2",
  "h3-header": "h3",
  "h4-header": "h4",
  "h5-header": "h5",
  "p1-paragraph": "p",
  "p2-paragraph": "p",
  "p3-paragraph": "p",
  "p4-paragraph": "p",
  "p5-paragraph": "p",
  "c1-caption": "p",
  "c2-caption": "p",
  "c3-caption": "p",
  "c4-caption": "p",
  "c5-caption": "p",
  "l1-label": "p",
  "l2-label": "p",
  "l3-label": "p",
  "l4-label": "p",
  "l5-label": "p",
  "l6-label": "p",
  "n1-number": "p",
  "n2-number": "p",
  "n3-number": "p",
  "n4-number": "p",
  "n5-number": "p",
  "n6-number": "p",
  d1: "h1",
  d2: "h2",
  b1: "p",
  b2: "p",
  b3: "p",
  b4: "p",
  b5: "p",
};

export default Text;
