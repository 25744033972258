import { createSelector } from "@reduxjs/toolkit";
import { IFinanceBankTransaction } from "config";
import { filter } from "lodash-es";
import { RootState } from "store";

export const selectFinanceBankTransactionsIds = (state: RootState) =>
  state.financeBankTransactions.ids;

export const selectFinanceBankTransactionsByIds = (state: RootState) =>
  state.financeBankTransactions.byId;

export const selectFinanceBankTransactions = createSelector(
  selectFinanceBankTransactionsIds,
  selectFinanceBankTransactionsByIds,
  (transactionIds, transactionsObject) => {
    return transactionIds.map(id => transactionsObject[id]);
  }
);

export const selectRecentFinanceBankTransactions = createSelector(
  selectFinanceBankTransactionsIds,
  selectFinanceBankTransactionsByIds,
  (transactionIds, transactionsObject) => {
    return transactionIds.slice(0, 10).map(id => transactionsObject[id]);
  }
);

export const selectFinanceBankTransactionsById = createSelector(
  [
    selectFinanceBankTransactionsByIds,
    (_state: RootState, externalId: IFinanceBankTransaction["externalId"]) => externalId,
  ],
  (transactions, externalId) => {
    return transactions[externalId];
  }
);

export const selectRecentTransactions = createSelector(
  selectFinanceBankTransactionsByIds,
  transactionsObject =>
    filter(transactionsObject, transaction => isTransactionRecentAndPending(transaction))
);

const isTransactionRecentAndPending = (transaction: IFinanceBankTransaction) => {
  const currentTime = new Date();
  const transactionCreatedAt = new Date(transaction.createdDate);
  const timeDifferenceInMinutes = Math.floor(
    (currentTime.getTime() - transactionCreatedAt.getTime()) / 60000
  );

  return timeDifferenceInMinutes <= 5 && transaction.status === "Pending";
};
