import { useContext } from "react";
import { createFlowResponse } from "store/actions/flow";
import { useAppDispatch, useAppSelector } from "store/index";

import { DynamicFormContext } from "../DynamicFormContext";

export const useFormDefaultOld = () => {
  const dispatch = useAppDispatch();
  const { inputs } = useContext(DynamicFormContext);
  const flowSessionId = useAppSelector(state => state.formFlow.flowSessionId);

  const formDefaultOldCommand = async () => {
    await dispatch(createFlowResponse({ formInputs: inputs, flowSessionId })).unwrap();
  };

  return formDefaultOldCommand;
};
