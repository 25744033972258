import { nextDynamicForm } from "store/actions/flow";
import { useAppDispatch, useAppSelector } from "store/index";
import { selectDynamicForm } from "store/selectors/selectDynamicForm";

export const useFormDefault = () => {
  const dispatch = useAppDispatch();
  const dynamicForm = useAppSelector(selectDynamicForm);
  const flowSessionId = useAppSelector(state => state.formFlow.flowSessionId);

  const formDefaultCommand = async () =>
    await dispatch(nextDynamicForm({ dynamicFormId: dynamicForm.id, flowSessionId })).unwrap();

  return formDefaultCommand;
};
