import { gql } from "config/graphql";
import { ICountry } from "config/types";
import { getApolloClient } from "store/apollo";

interface ICountriesResponse {
  data: {
    countries: ICountry[];
  };
}

export const countriesQuery = (): Promise<ICountriesResponse> => {
  const client = getApolloClient();

  const query = gql`
    query Countries {
      countries {
        id
        name
        alpha3
        postalCodeFormat
        hasStates
        hasPostalCode
      }
    }
  `;

  return client.query({ query });
};
