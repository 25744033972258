import "./FlowReviewAnswers.scss";

import classNames from "classnames";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { flatten, isArray, noop, trim } from "lodash-es";

interface IListAnswer {
  options: {
    label: string;
    value: string;
  }[];
  type: "boolean" | "list";
  value: string | string[];
}

export const FlowReviewAnswerList = ({ options, type, value }: IListAnswer) => {
  // `boolean` always gets a single value whereas `list` can have multiple,
  // so any value type to an array
  const transformedValue = flatten([value]);

  return (
    <>
      {options.map(({ label, value: optionValue }) => {
        const isSelected = transformedValue.includes(optionValue);

        return (
          <FlowReviewAnswer
            answerText={label}
            isSelected={transformedValue.includes(optionValue)}
            key={label}
            renderIcon={() => (
              <Icon
                className="flow-review-field-answer__icon"
                size="sm"
                src={isSelected ? "checkmark" : "close"}
              />
            )}
            type={type}
          />
        );
      })}
    </>
  );
};

interface ITextAnswer {
  type: "long_text" | "numeric" | "unitized";
  value: string | string[];
  isSelected?: boolean;
  options?: {
    label: string;
    value: string;
  }[];
  unit?: string;
}

export const FlowReviewAnswerText = (props: ITextAnswer) => {
  const { isSelected, options, type, unit = "", value } = props;
  let answerText = isArray(value) ? value.join(" ") : value;

  if (type === "numeric" && !!unit) {
    answerText = trim(`${value} ${unit}`);
  } else if (type === "unitized" && isArray(value) && isArray(options)) {
    answerText = value.reduce((memo, val, index) => {
      return trim(`${memo} ${val} ${options[index].label || ""}`);
    }, "");
  }

  return <FlowReviewAnswer answerText={answerText} isSelected={isSelected} type={type} />;
};

interface IAnswer {
  answerText: string;
  type: IListAnswer["type"] | ITextAnswer["type"];
  isSelected?: boolean;
  renderIcon?(): unknown;
}

const FlowReviewAnswer = ({ answerText, isSelected = false, renderIcon = noop, type }: IAnswer) => {
  const classes = classNames("flow-review-field-answer", `flow-review-field-answer--${type}`, {
    "flow-review-field-answer--selected": isSelected,
  });

  return (
    <div className={classes}>
      {renderIcon()}
      <Text as="span" className="flow-review-field-answer__text" tag="p6">
        {answerText}
      </Text>
    </div>
  );
};
