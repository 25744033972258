import { createReducer } from "@reduxjs/toolkit";
import { IDocumentCategory, IDocument } from "config/types";
import { logout } from "store/actions/authentication";
import { getDocumentsCategories } from "store/actions/documents";
import { DateTime } from "luxon";

export interface IDocuments {
  categoryItems: string[];
  categories: Record<string, IDocumentCategory>;
  documents: Record<string, IDocument>;
}

const defaultState: IDocuments = {
  categoryItems: [],
  categories: {},
  documents: {},
};

const documents = createReducer(defaultState, builder => {
  builder
    .addCase(getDocumentsCategories.fulfilled, (state, action) => {
      const categories = action.payload;

      state.categoryItems = categories
        .map(category => category.slug)
        .sort((a, b) => (a < b ? -1 : 1));

      state.categories = categories.reduce(
        (accum: Record<string, IDocumentCategory>, currentValue) => {
          accum[currentValue.slug] = {
            ...currentValue,
            documents: currentValue.documents
              .sort((a, b) => {
                return DateTime.fromISO(a.createdAt) < DateTime.fromISO(b.createdAt) ? 1 : -1;
              })
              .map(doc => doc.id),
          };
          return accum;
        },
        {}
      );

      state.documents = categories
        .flatMap(({ documents }) => documents)
        .reduce((accum: Record<string, IDocument>, currentValue) => {
          accum[currentValue.id] = currentValue;
          return accum;
        }, {});
    })
    .addCase(logout, () => defaultState);
});

export default documents;
