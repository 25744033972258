import { createAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import * as actionType from "store/constants";

import { getDefaultAttrs, getProductSelectedAttr } from "../defaults";

// Analytic events for Savings+ onboarding

/* ------------------------    EVENT ACTION     ------------------------------ */

// User lands on Product Select page
export const productSelectPageEvent = createAction(actionType.OB_PRODUCTSELECTION_PAGEVIEW);

// User selects Savings+ on Product Select page
export const savingsProductSelectedEvent = createAction(
  actionType.OB_PRODUCTSELECTION_SAVINGS_SELECTED
);

// User selects Insurance on Product Select page
export const insuranceProductSelectedEvent = createAction(
  actionType.OB_PRODUCTSELECTION_TERM_SELECTED
);

// User lands on SavingsValueProps page
export const savingsValuePropPageEvent = createAction(actionType.OB_WELCOME_SAVINGS_PAGEVIEW);

/* ------------------------    EVENT DEFINITION     ------------------------------ */

const getSavingsOnboardingEventDefinition = (eventName: string) => {
  return (action: { [key: string]: any }, prevState: RootState) => {
    const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");
    const productSelected = getProductSelectedAttr(action.type);

    return {
      event: eventName,
      attribute: {
        ...defaultAttrs,
        ...(!!productSelected && { productSelected: productSelected }),
      },
    };
  };
};

/* ------------------------    EVENT MAP    ------------------------------ */

export const savingsOnboardingEventsMap = {
  [actionType.OB_PRODUCTSELECTION_PAGEVIEW]: getSavingsOnboardingEventDefinition(
    "ob_productselection_pageview"
  ),
  [actionType.OB_PRODUCTSELECTION_SAVINGS_SELECTED]: getSavingsOnboardingEventDefinition(
    "ob_productselection_savings_selected"
  ),
  [actionType.OB_PRODUCTSELECTION_TERM_SELECTED]: getSavingsOnboardingEventDefinition(
    "ob_productselection_term_selected"
  ),
  [actionType.OB_WELCOME_SAVINGS_PAGEVIEW]: getSavingsOnboardingEventDefinition(
    "ob_welcome_savings_pageview"
  ),
};
