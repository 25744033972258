import { gql } from "config/graphql";
import { IProfile, IUser } from "config/types";
import {
  BUSINESS_FIELDS,
  CONSTELLATION_FIELDS,
  CUSTOMER_FIELDS,
  FEATURE_FLAG_FIELDS,
  FINANCE_CUSTOMER_FIELDS,
} from "graphql/fragments";
import { getApolloClient } from "store/apollo";

interface IFetchPreVerifiedReseponse {
  data: {
    me: IVerifiedInitialData;
  };
}

export const fetchPreVerifiedInitialData = (): Promise<IFetchPreVerifiedReseponse> => {
  const client = getApolloClient();

  const query = gql`
    ${BUSINESS_FIELDS}
    ${FEATURE_FLAG_FIELDS}
    query fetchPreVerifiedInitialData {
      me {
        id
        status
        phoneNumbers {
          id
          mask
          status
          createdAt
        }
        state {
          id
          name
          alpha2
        }
        activeConstellations {
          activeFinanceMembership {
            id
            business {
              ...BusinessFields
            }
            metadata
          }
          activeInsuranceMembership {
            id
            latestEstimateDatum {
              id
              termDuration
              termUnit
              amount
              health
              tobacco
              insuredsAgeAtTerm
              premiums {
                amount
                billingFrequency
              }
            }
          }
          createdAt
          featureFlags {
            ...FeatureFlagFields
          }
          id
          label
        }
      }
    }
  `;

  return client.query({
    fetchPolicy: "no-cache",
    query,
  });
};

interface IVerifiedInitialData extends IUser, IProfile {}

interface IFetchVerifiedIntialResponse {
  data: {
    me: IVerifiedInitialData;
  };
}

export const fetchVerifiedInitialData = (): Promise<IFetchVerifiedIntialResponse> => {
  const client = getApolloClient();

  const query = gql`
    ${CONSTELLATION_FIELDS}
    ${CUSTOMER_FIELDS}
    ${FINANCE_CUSTOMER_FIELDS}
    query fetchVerifiedInitialData {
      me {
        activeConstellations {
          ...ConstellationFields
        }
        id
        age
        birthdate
        age
        status
        createdAt
        firstName
        lastName
        middleName
        suffix
        emails {
          email
          status
        }
        customer {
          ...CustomerFields
        }
        financeCustomer {
          ...FinanceCustomerFields
        }
        phoneNumbers {
          id
          phoneNumber
          createdAt
          mask
          status
        }
        activeAddress {
          formattedDisplay
          street1
          street2
          city
          state
          postalCode
          country {
            id
            name
            alpha3
            hasStates
            hasPostalCode
            postalCodeFormat
          }
          stateObject {
            id
            name
            alpha2
          }
        }
      }
    }
  `;

  return client.query({ query });
};
