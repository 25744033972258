import "./CloseAccountModal.scss";

import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { logout } from "store/actions/authentication";
import { closeModal, setModal } from "store/actions/modal";
import { decommissionUser } from "store/actions/user";
import { getThunkError } from "utils/error";

const CloseAccountModal: FC<IModalProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const closeModalWrapper = () => dispatch(closeModal());

  const onCloseAccount = async () => {
    try {
      const response = await dispatch(decommissionUser());

      const error = getThunkError(response);

      if (error) {
        throw error;
      }

      dispatch(logout());
    } catch {
      dispatch(setModal("CloseAccountFailedModal"));
    }
  };

  const content = {
    alwaysShowIcon: true,
    button: [
      {
        text: t("settings.profile.view.close.account.modal.primary_cta", "YES, CLOSE ACCOUNT"),
        subType: "primary",
        onClick: onCloseAccount,
        dataTestId: "close-account-modal__confirm-btn",
      },
      {
        text: t("settings.profile.view.close.account.modal.secondary_cta", "NEVERMIND"),
        subType: "text-dark-border",
        onClick: closeModalWrapper,
      },
    ],
    header: t(
      "settings.profile.view.close.account.modal.title",
      "Are you sure you want to close your account?"
    ),
    icon: "BlobAlert",
    onClose: closeModalWrapper,
    subheader: t(
      "settings.profile.view.close.account.modal.body",
      "Once you close your account your login credentials will no longer work. If you need to access your policy documents in the future you will need to contact customer support."
    ),
  };

  return <Layout as="ModalLayout" className="account-close-modal" content={content} />;
};

export default CloseAccountModal;
