import "./CustomerSupportModal.scss";

import Text from "components/Text/Text";
import parsePhoneNumber from "libphonenumber-js";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { eventFired } from "store/actions/analytics";
import { closeModal } from "store/actions/modal";
import { openChat, openFaqLink } from "utils/customerSupport";
import { isOnboarding } from "utils/journey";

const CustomerSupportModal: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const journey = useSelector((state: RootState) => state.journey);

  const closeModalWrapper = () => {
    dispatch(closeModal());
  };

  const onClickChat = () => {
    dispatch(
      eventFired({
        event: "navigation_header_help_chat_clicked",
        experienceLocation: isOnboarding(journey) ? "ONBOARDING" : "DASHBOARD",
      })
    );

    openChat();
    closeModalWrapper();
  };

  const onClickFaq = () => {
    dispatch(
      eventFired({
        event: "navigation_header_help_faqs_clicked",
        experienceLocation: isOnboarding(journey) ? "ONBOARDING" : "DASHBOARD",
      })
    );

    openFaqLink();
    closeModalWrapper();
  };

  const customerSupportNumber = t("customer_support.modal.body.inline_cta", "+1 (844) 997-4457");

  const content = {
    icon: "BlobChatAgent",
    header: t("customer_support.modal.title", "Contact Support"),
    subheader: (
      <>
        {t("customer_support.modal.body.text_1", "Have questions? Chat with us or call")}{" "}
        <a
          className="modal__subheader-inline-cta customer-support-modal__subheader-inline-cta"
          href={parsePhoneNumber(customerSupportNumber)?.getURI()}>
          {customerSupportNumber}
        </a>
        <Text
          className="customer-support-modal__subheader-subscript"
          tag="p6"
          text={t(
            "customer_support.modal.body.subscript",
            "Live support is available Monday-Friday during business hours (9am-5pm EST)."
          )}
        />
      </>
    ),

    onClose: closeModalWrapper,
    button: [
      {
        text: t("customer_support.modal.primary", "GET HELP NOW"),
        subType: "primary",
        onClick: onClickChat,
      },
      {
        text: t("customer_support.modal.secondary", "Go to FAQs"),
        subType: "text-dark",
        onClick: onClickFaq,
      },
    ],
  };

  return <Layout as="ModalLayout" content={content} className="customer-support-modal" />;
};

export default CustomerSupportModal;
