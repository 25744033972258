import { createReducer } from "@reduxjs/toolkit";
import { IFinanceBankAccount } from "config/types";
import { logout } from "store/actions/authentication";
import { getFinanceBankAccount } from "store/actions/financeBankAccount";

interface IFinanceBankAccountState
  extends Omit<IFinanceBankAccount, "lockReasonTypeCode" | "status"> {
  hasLoaded: boolean;
  lockReasonTypeCode: IFinanceBankAccount["lockReasonTypeCode"] | "";
  status: IFinanceBankAccount["status"] | "";
}

/* ------------    FINANCE_BANK_ACCOUNT STATE     ------------------ */
const defaultState: IFinanceBankAccountState = {
  accountBalance: 0,
  accountNumber: "",
  apy: 0.0,
  availableBalance: 0,
  balanceLastModifiedDate: "",
  closedDate: "",
  createdDate: "",
  deathPayout: 0,
  fundsOnHold: 0,
  hasLoaded: false,
  isLocked: false,
  lastModifiedDate: "",
  lifeBenefit: {
    coverageAmount: 0,
    effectiveAt: null,
    eligible: true,
    expiresAt: null,
    maximumCoverage: 0,
    projectedCoverageAmount: 0,
    rate: 0,
    status: "pre_census",
    updatedAt: "",
  },
  lifetimeInterest: 0,
  lockReasonTypeCode: "",
  pendingBalance: 0,
  routingNumber: "",
  status: "",
};

/* ------------    REDUCER     ------------------ */
const financeBankAccount = createReducer(defaultState, builder => {
  builder
    .addCase(getFinanceBankAccount.pending, state => {
      state.hasLoaded = false;
    })
    .addCase(getFinanceBankAccount.fulfilled, (state, action) => {
      const bankAccount = action.payload;

      state.accountBalance = bankAccount.accountBalance;
      state.accountNumber = bankAccount.accountNumber;
      state.apy = bankAccount.apy * 100;
      state.availableBalance = bankAccount.availableBalance;
      state.balanceLastModifiedDate = bankAccount.balanceLastModifiedDate;
      state.closedDate = bankAccount.closedDate;
      state.createdDate = bankAccount.createdDate;
      state.deathPayout = bankAccount.deathPayout;
      state.fundsOnHold = bankAccount.fundsOnHold;
      state.hasLoaded = true;
      state.isLocked = bankAccount.isLocked;
      state.lastModifiedDate = bankAccount.lastModifiedDate;
      state.lifeBenefit = bankAccount.lifeBenefit;
      state.lifetimeInterest = bankAccount.lifetimeInterest;
      state.lockReasonTypeCode = bankAccount.lockReasonTypeCode;
      state.pendingBalance = bankAccount.pendingBalance;
      state.routingNumber = bankAccount.routingNumber;
      state.status = bankAccount.status;
    })
    .addCase(getFinanceBankAccount.rejected, state => {
      state.hasLoaded = true;
    })
    .addCase(logout, () => defaultState);
});

export default financeBankAccount;
