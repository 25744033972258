import { useLayoutEffect } from "react";

export const useBodyLock = () => {
  const newOverflowStyle = "hidden";

  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = newOverflowStyle;
    return () => {
      if (originalStyle !== newOverflowStyle) document.body.style.overflow = originalStyle;
    };
  }, []);
};
