import { IModalProps } from "config/init/providers/ModalsProvider";
import { isFunction } from "lodash-es";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "store/actions/modal";

const PaymentFailed: FC<IModalProps> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const closeModalWrapper = () => dispatch(closeModal());

  const onResubmit = async () => {
    if (
      props.context?.callbacks?.handleResubmit &&
      isFunction(props.context.callbacks.handleResubmit)
    ) {
      await props.context.callbacks.handleResubmit();
    }
    closeModalWrapper();
  };

  const content = {
    icon: "BlobAlert",
    header: t("errors.payment_failed.modal.title", "Uh-oh, something went wrong..."),
    subheader: t(
      "errors.payment_failed.modal.text",
      "We’re having a little trouble processing your payment, Wanna give it another go?"
    ),
    onClose: closeModalWrapper,
    button: [
      {
        text: t("errors.payment_failed.modal.button", "Try again"),
        subType: "primary",
        onClick: onResubmit,
      },
    ],
    onButtonClick: closeModal,
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default PaymentFailed;
