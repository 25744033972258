import { IModalProps } from "config/init/providers/ModalsProvider";
import { push } from "connected-react-router";
import { delay } from "lodash-es";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "store/actions/modal";

const BankAccountVerificationSuccess: FC<IModalProps> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onInitialRender = () => {
    const callback = () => {
      dispatch(closeModal());
      props.context?.callbacks?.onClose && props.context?.callbacks?.onClose();
      return dispatch(push("/products/savings"));
    };
    const closeAfterTimer = delay(callback, 2000);
    return () => clearTimeout(closeAfterTimer);
  };

  const content = {
    header: t("savings.microdeposit_verify.bank.success.modal.text", "ACCOUNT VERIFIED!"),
    icon: "BlobCheck",
    onInitialRender,
  };

  return <Layout as="ModalStatusLayout" content={content} />;
};

export default BankAccountVerificationSuccess;
