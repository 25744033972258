import { gql } from "config/graphql";

import { CLASSIFICATION_FIELDS } from "./classification";
import { INSURANCE_APPLICATION_FIELDS } from "./insuranceApplication";
import { PRODUCT_DETAIL_FIELDS } from "./product";

export const INSURANCE_MEMBERSHIP_FIELDS = gql`
  ${CLASSIFICATION_FIELDS}
  ${INSURANCE_APPLICATION_FIELDS}
  ${PRODUCT_DETAIL_FIELDS}
  fragment InsuranceMembershipFields on InsuranceMembership {
    id
    label
    eligibleForMonitoring
    underwritingRequired
    latestEstimateDatum {
      id
      termDuration
      termUnit
      amount
      health
      tobacco
      insuredsAgeAtTerm
      premiums {
        amount
        billingFrequency
      }
    }
    latestInsuranceApplication {
      ...InsuranceApplicationFields
    }
    activePolicy {
      id
      amount
      status
      termDuration
      termUnit
      insuredsAgeAtTerm
      effectiveAt
      pausedAt
      terminatesAt
      effectiveUntil
      classification {
        ...ClassificationFields
      }
      product {
        activePlan {
          amount
          billingFrequency
          nextPayment {
            date
            invoiceId
            estimated
          }
        }
      }
      productV2 {
        ...ProductFields
      }
    }
    pendingQuote {
      id
    }
  }
`;
