import { createAction } from "@reduxjs/toolkit";
import { ModalKey } from "containers/Modals/modals";

import * as t from "../constants";

export type ModalMetaInput = {
  id?: string;
  header?: string;
  text?: string;
  callback?(args?: any): void;
};

export const closeModal = createAction(t.RESET_MODAL);

export const setModal = createAction(t.SET_MODAL, (modal: ModalKey, id = "", data = {}) => ({
  payload: {
    modal,
    id,
    data,
  },
}));
