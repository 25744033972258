import { gql } from "config/graphql";

export const COVERAGE_BREAKDOWN_FIELDS = gql`
  fragment CoverageBreakdownFields on Constellation {
    coverageBreakdown {
      backgroundColor
      label
      labelColor
      value
      valueDisplay
      width
    }
    coverageGap
    coverageSum
    desiredCoverage
  }
`;
