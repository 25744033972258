import { from } from "@apollo/client";
import { isFunction, map } from "lodash-es";
import { Store } from "store";

import { authenticationLink } from "./authentication";
import { endpointLink, errorLink } from "./endpoint";
import { fingerprintLink } from "./fingerprint";
import { mutationIdLink } from "./mutationId";

// Apollo-link `onError` link is recommended as first step on chain, as per documention
export const links = [errorLink, authenticationLink, fingerprintLink, mutationIdLink, endpointLink];

export type LinkContext = {
  endpoint: string;
  store: Store;
};

export const createApolloLinks = (context: LinkContext) => {
  const mappedLinks = map(links, link => (isFunction(link) ? link(context) : link));
  return from(mappedLinks);
};

export default links;
