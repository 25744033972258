import "../AdCard.scss";

import classNames from "classnames";
import CustomLink from "components/CustomLink/CustomLink";
import Text from "components/Text/Text";
import { ICardProps } from "modules/partials/Card";
import { FC } from "react";
import Slider from "react-slick";

export type AdItem = {
  title: string;
  description: string;
  imgSrc: string;
  descriptionSize: "p1" | "p2" | "p3" | "p4" | "p5" | "p6";
};

type Content = {
  title: string;
  headline: string;
  items: AdItem[];
  linkText: string;
  description: string;
};

const sliderSettings = {
  dots: true,
  infinite: false,
  arrows: false,
};

const Ad: FC<AdItem> = ({ title, description, imgSrc, descriptionSize = "p4" }) => {
  return (
    <div className="card-content__multiple-ad">
      <img className="card-content__multiple-ad-img" alt="" src={imgSrc} />
      <Text className="card-content__multiple-ad-title" tag="c3" text={title} />
      <Text
        className="card-content__multiple-ad-description"
        tag={descriptionSize}
        text={description}
      />
    </div>
  );
};

export const MultipleAdCard: FC<ICardProps> = props => {
  const content = props.content as Content;
  const { title, headline, linkText, items, description } = content;
  const { to, conditions, className = "" } = props;
  const hideCarousel = conditions?.hideCarousel;

  const ads = items.map((item, index) => (
    <Ad
      key={index}
      title={item.title}
      description={item.description}
      descriptionSize={item.descriptionSize}
      imgSrc={item.imgSrc}
    />
  ));

  const classes = classNames(
    "card-content__ad-container card-content__multiple-ad-container",
    className,
    {
      "hide-carousel": hideCarousel,
    }
  );

  return (
    <div className={classes}>
      <Text className="card-content__ad-title" tag="c5" text={title} />
      <Text className="card-content__ad-headline" tag="h4" text={headline} />
      <Text className="card-content__ad-description" tag="p2" text={description} />
      <div className="card-content__multiple-ad-grid">{ads}</div>
      <div className="card-content__multiple-ad-carousel">
        <Slider dotsClass="slick-dots card-content__multiple-ad-carousel-dots" {...sliderSettings}>
          {ads}
        </Slider>
      </div>
      {to && (
        <CustomLink
          className="card-content__multiple-ad-link"
          type="primary"
          to={to as string}
          text={linkText}
        />
      )}
    </div>
  );
};
