import DateInput from "components/DateInput/DateInput";
import { useContext } from "react";

import { DynamicFormContext } from "../DynamicFormContext";

import { IDynamicFormItemProps } from ".";

export function DateInputItem({ item }: IDynamicFormItemProps<"inputDate">) {
  const { form } = useContext(DynamicFormContext);

  const { content, key } = item;
  const { disabled } = content;
  const { label, value } = form.getFormInput(key);

  return (
    <DateInput
      dataTestId={key}
      disabled={disabled}
      error={form.getInputError(key)}
      errorMessage={form.getInputErrorMessage(key)}
      id={key}
      label={label}
      onBlur={form.onBlur}
      onChange={form.onChange}
      shouldDisplayUtcAsLocal
      value={value}
    />
  );
}
