import "./Menu.scss";

import classNames from "classnames";
import Icon from "components/Icon/Icon";
import { ClassName, IMenuItem } from "config/types";
import { get, size } from "lodash-es";
import { CSSProperties, FC, MouseEvent, useEffect, useRef, FocusEvent } from "react";

import MenuList from "./MenuList";
import SubMenu from "./SubMenu";

type IProps = {
  menuItems: IMenuItem[];
  menuItems2?: IMenuItem[];
  subMenuText?: string;
  subMenuItems?: IMenuItem[];
  active: boolean;
  className?: ClassName;
  wrapperClassName?: ClassName;
  style?: CSSProperties;
  closeButton?: boolean;
  handleCloseMenu?: (event: MouseEvent | globalThis.MouseEvent) => void;
  mainSiteNav?: boolean;
  onMenuListFocus?(event: FocusEvent): void;
  onCloseBtnFocus?(event: FocusEvent): void;
};

const Menu: FC<IProps> = (props: IProps) => {
  const {
    active,
    className,
    wrapperClassName,
    closeButton,
    handleCloseMenu,
    menuItems,
    menuItems2,
    subMenuItems,
    subMenuText,
    mainSiteNav,
    onMenuListFocus,
    onCloseBtnFocus,
  } = props;
  const wrapperRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

  const closeMenu = (event: globalThis.MouseEvent) => {
    const eventTarget = get(event, "target") as Node;
    const currentRef = get(wrapperRef, "current");

    if (currentRef && !currentRef.contains(eventTarget)) {
      handleCloseMenu && handleCloseMenu(event);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeMenu, false);

    return () => {
      document.removeEventListener("click", closeMenu, false);
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const classes = classNames("menu-container", className, {
    active: active,
  });

  const menuWrapperClasses = classNames("menu-wrapper", wrapperClassName, {
    "main-site-nav-menu": mainSiteNav,
  });

  return (
    <div className={classes} ref={wrapperRef}>
      <div className={menuWrapperClasses}>
        {closeButton ? (
          <button className="menu__close-wrapper flex--center" onFocus={onCloseBtnFocus}>
            <div onClick={handleCloseMenu}>
              <Icon className="pointer" src="Close" />
            </div>
          </button>
        ) : null}
        <MenuList
          menuItems={menuItems}
          handleCloseMenu={handleCloseMenu}
          onFocus={onMenuListFocus}
        />
        {menuItems2 && (
          <>
            <div className="sub-menu--border" />
            <MenuList
              menuItems={menuItems2}
              handleCloseMenu={handleCloseMenu}
              onFocus={onMenuListFocus}
            />
          </>
        )}

        {subMenuItems && size(subMenuItems) ? (
          <SubMenu
            subMenuText={subMenuText}
            subMenuItems={subMenuItems}
            handleCloseMenu={handleCloseMenu}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Menu;
