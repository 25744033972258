import { gql } from "config/graphql";

export const INSURANCE_APPLICATION_FIELDS = gql`
  fragment InsuranceApplicationFields on InsuranceApplication {
    createdAt
    id
    result
    version
  }
`;
