import { first, get, last, reduce, split, trim } from "lodash-es";

const parseIconName = (filePath: string) => {
  const basename = last(split(filePath, "/"));
  const fileName = first(split(basename as string, "."));
  const trimmedName = trim(fileName);
  return trimmedName;
};

const fetchIcons = () => {
  const req = require.context("../../assets/icons/", true, /\.svg$/);
  const keys = req.keys();

  const iconIterator = (result: Record<string, unknown>, value: string) => {
    const element = req(value);
    const iconName = parseIconName(value);
    const iconPath = get(element, "default", element);
    const payload = {
      ...result,
      [iconName]: iconPath,
    };
    return payload;
  };
  const collection = reduce(keys, iconIterator, {});
  return collection;
};

const icons = fetchIcons();

export default icons;
