import { createAsyncThunk } from "@reduxjs/toolkit";
import { statesQuery } from "graphql/queries/states";
import { getAppError } from "utils/error";

/* ------------       THUNKS      ------------------ */

export const getStates = createAsyncThunk(
  "states/getStates",
  async (countryId: string, { rejectWithValue }) => {
    try {
      const response = await statesQuery(countryId);

      return response.data?.states || [];
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
