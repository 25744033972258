import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  savingsApplicationSetDOBEvent,
  savingsApplicationSetGenderEvent,
  savingsApplicationSetStateEvent,
} from "analytics/savings";
import {
  DynamicFormItemType,
  FlowInputTypes,
  FlowTypes,
  IDynamicForm,
  IFlow,
  IFlowSession,
  IFormInputs,
} from "config/types";
import { dynamicFormGroupMutationBodyMutation } from "graphql/mutations";
import { createFlowResponseMutation, createFlowSessionMutation } from "graphql/mutations/flow";
import { dynamicFormQuery, flowQuery, nextFormOldQuery, nextFormQuery } from "graphql/queries/flow";
import { compact, includes, keys, map } from "lodash-es";
import { RootState } from "store";
import {
  FINISH_FLOW,
  FLOW_FIELD_SET,
  FLOW_VALIDATION_ERROR,
  SET_FORM_FLOW_DIRTY,
  SET_FORM_FLOW_INDEX,
} from "store/constants";
import { IAnalyticsTracker } from "store/reducers/analyticsTracker";
import { selectPreviousDynamicForm } from "store/selectors";
import { encrypt } from "utils/encrypt";
import { getAppError } from "utils/error";

/* ------------------------       ACTIONS      ------------------------------ */

export const finishFlow = createAction<{
  flowTracker: IAnalyticsTracker;
}>(FINISH_FLOW);

export const flowFieldSet = createAction<Record<string, any>>(FLOW_FIELD_SET);

export const flowFieldValidationError = createAction<Record<string, any>>(FLOW_VALIDATION_ERROR);

export const setFormFlowIndex = createAction<"back" | "next">(SET_FORM_FLOW_INDEX);

export const setFormFlowDirty = createAction<boolean>(SET_FORM_FLOW_DIRTY);

/* ------------------------       THUNKS      ------------------------------ */

export const initFormFlow = createAsyncThunk(
  "flow/initFormFlow",
  async (
    { slug, sessionFromStart = false }: { slug: FlowTypes; sessionFromStart?: boolean },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const flow = await dispatch(fetchFlow(slug)).unwrap();

      const flowSession = await dispatch(
        createFlowSession({ flowId: flow.id, fromStart: sessionFromStart })
      ).unwrap();

      return { flowSession, flow };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const fetchFlow = createAsyncThunk(
  "flow/fetchFlow",
  async (slug: FlowTypes, { rejectWithValue }) => {
    try {
      const { data } = await flowQuery(slug);

      return data.flow;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const createFlowSession = createAsyncThunk(
  "flow/createFlowSession",
  async (
    { flowId, fromStart }: { flowId: IFlow["id"]; fromStart: boolean },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await createFlowSessionMutation(flowId, fromStart);

      return data.createFlowSession?.flowSession;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const nextForm = createAsyncThunk(
  "flow/nextForm",
  async (flowSessionId: IFlowSession["id"], { rejectWithValue }) => {
    try {
      const { data } = await nextFormOldQuery(flowSessionId);
      const nextForm = data.nextForm;

      return nextForm;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const nextDynamicForm = createAsyncThunk(
  "flow/nextForm",
  async (
    {
      dynamicFormId,
      flowSessionId,
    }: { flowSessionId: IFlowSession["id"]; dynamicFormId?: IDynamicForm["id"] },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await nextFormQuery(flowSessionId, dynamicFormId);
      const nextForm = data.nextForm;

      return nextForm;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const fetchDynamicForm = createAsyncThunk(
  "flow/nextForm",
  async (inputPayload: Parameters<typeof dynamicFormQuery>[0], { rejectWithValue }) => {
    try {
      const { data } = await dynamicFormQuery(inputPayload);
      return data.dynamicForm;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

// TODO - remove when 'previous' direction API ready
export const fetchPreviousDynamicForm = createAsyncThunk(
  "flow/nextForm",
  async (inputPayload: Parameters<typeof dynamicFormQuery>[0], { dispatch, rejectWithValue }) => {
    try {
      const { data } = await dynamicFormQuery(inputPayload);

      // removes 1. current form in stack
      //         2. previous stale form, to replace with updated form from query
      dispatch(setFormFlowIndex("back"));
      dispatch(setFormFlowIndex("back"));

      return data.dynamicForm;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const dismissDynamicFormModal = createAsyncThunk(
  "dismissDynamicFormModal",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const previousDynamicForm = selectPreviousDynamicForm(state);

      return previousDynamicForm;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const groupDetailMutationBody = createAsyncThunk(
  "dynamicForm/groupDetailMutationBody",
  async (
    { inputPayload, mutationBody }: Parameters<typeof dynamicFormGroupMutationBodyMutation>[0],
    { rejectWithValue }
  ) => {
    try {
      const { data } = await dynamicFormGroupMutationBodyMutation({
        inputPayload,
        mutationBody,
      });

      return data;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const createFlowResponse = createAsyncThunk(
  "flow/createFlowResponse",
  async (
    { formInputs, flowSessionId }: { formInputs: IFormInputs; flowSessionId: IFlowSession["id"] },
    { rejectWithValue }
  ) => {
    try {
      const fieldResponses = compact(
        map(formInputs, input => {
          // Need to see if we are sending a masked SSN/TIN to the server
          const isTaxIdAndIsUnChangedMask =
            /^savings-(existing-phone|active-phone|default)-application-ssn-tin$/.test(input.key) &&
            input.pristineValue === input.value &&
            /^\*{3}-\*{2}-[0-9]{4}/.test(input.value); // RegEx test for ***-**-0000 thru ***-**-9999

          if (isTaxIdAndIsUnChangedMask) {
            return null;
          }

          let value = input.value;

          if (input.metadata.flowInputType === "tax_id") {
            value = encrypt(value);
          }

          return {
            fieldId: input.metadata?.flowQuestionId || input.id,
            response: {
              inputType: input.metadata.flowInputType as FlowInputTypes | DynamicFormItemType,
              required: input.required,
              value,
            },
          };
        })
      );

      const { data } = await createFlowResponseMutation(flowSessionId, fieldResponses);

      return data?.createResponse || [];
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const flowFieldByKeySetEvent = createAsyncThunk(
  "flowFieldSetEvent",
  async (fieldInput: Record<string, any>, { dispatch, rejectWithValue }) => {
    try {
      const key = keys(fieldInput)[0];
      dispatch(flowFieldSet(fieldInput));

      if (
        includes(
          [
            "savings-active-phone-application-dob",
            "savings-default-application-dob",
            "savings-active-phone-application-v2-dob",
            "savings-default-application-v2-dob",
          ],
          key
        )
      ) {
        dispatch(savingsApplicationSetDOBEvent());
      }

      if (
        includes(
          [
            "savings-active-phone-application-gender",
            "savings-default-application-gender",
            "savings-active-phone-application-v2-gender",
            "savings-default-application-v2-gender",
          ],
          key
        )
      ) {
        dispatch(savingsApplicationSetGenderEvent());
      }
      if (
        includes(
          [
            "savings-active-phone-application-state",
            "savings-default-application-state",
            "savings-active-phone-application-v2-state",
            "savings-default-application-v2-state",
          ],
          key
        )
      ) {
        dispatch(savingsApplicationSetStateEvent());
      }

      return fieldInput;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
