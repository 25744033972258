import { TextType } from "components/Text/Text";

const OLD_TO_NEW_TRANSLATION_MAP: Partial<Record<TextType, TextType>> = {
  b1: "p1-paragraph",
  b2: "p2-paragraph",
  b3: "p3-paragraph",
  b4: "p4-paragraph",
  b5: "p5-paragraph",
  c1: "c1-caption",
  c2: "c2-caption",
  c3: "c3-caption",
  c4: "c4-caption",
  c5: "c5-caption",
  d1: "d1-display",
  d2: "d2-display",
  h1: "h1-header",
  h2: "h2-header",
  h3: "h3-header",
  h4: "h4-header",
  h5: "h5-header",
  l1: "l1-label",
  l2: "l2-label",
  l3: "l3-label",
  l4: "l4-label",
  l5: "l5-label",
  p1: "p1-paragraph",
  p2: "p2-paragraph",
  p3: "p3-paragraph",
  p4: "p4-paragraph",
  p5: "p5-paragraph",
  n1: "n1-number",
  n2: "n2-number",
  n3: "n3-number",
  n4: "n4-number",
  n5: "n5-number",
  n6: "n6-number",
};

export function translateTextTag(tag: TextType): TextType {
  return OLD_TO_NEW_TRANSLATION_MAP[tag] || tag;
}
