import { defaultTo } from "lodash-es";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { getThunkError } from "utils/error";
import { getCurrentUserPolicy } from "store/actions/policy";

export const usePolicyData = (refresh = false) => {
  const dispatch = useDispatch();
  const [pending, setPending] = useState(refresh ? true : false);
  const [error, setError] = useState("");
  const policy = useSelector((state: RootState) => state.policy);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getCurrentUserPolicy());
        setPending(false);
        const error = getThunkError(response);
        if (error) {
          throw error;
        }
      } catch (error) {
        setError(defaultTo(error.fullMessage, error.code));
      }
    };

    if (refresh) {
      fetchData();
    }
  }, [dispatch, refresh]);

  return { policy, policyDataPending: pending, policyDataError: error };
};
