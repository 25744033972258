import { gql } from "config/graphql";
import { GQLUserResponse, ICoverageEditorPremium, TermUnit } from "config/types";
import { PRODUCT_DETAIL_FIELDS } from "graphql/fragments";
import { getApolloClient } from "store/apollo";
import { createClientMutationId } from "utils/clientMutationId";

export interface IAdjustCoverageInput {
  id: string;
  termUnit: TermUnit;
  termDuration?: number;
  amount?: number;
}

interface IAdjustEstimateResponse {
  data: {
    adjustEstimate: ICoverageEditorPremium;
  };
}

export const adjustEstimateQuery = (
  estimate: IAdjustCoverageInput
): Promise<IAdjustEstimateResponse> => {
  const client = getApolloClient();

  const query = gql`
    query adjustEstimate($id: ID!, $termUnit: SharedTermUnit!, $termDuration: Int, $amount: Int) {
      adjustEstimate(id: $id, termUnit: $termUnit, termDuration: $termDuration, amount: $amount) {
        annualPremium
        monthlyPremium
        allocationsCoveredPercentage
      }
    }
  `;

  return client.query({
    variables: {
      clientMutationId: createClientMutationId(),
      ...estimate,
    },
    query,
  });
};

export const fetchCurrentUserEstimate = (): Promise<GQLUserResponse<"activeConstellations">> => {
  const client = getApolloClient();

  const query = gql`
    ${PRODUCT_DETAIL_FIELDS}
    query latestEstimate {
      me {
        activeConstellations {
          activeInsuranceMembership {
            id
            latestEstimateDatum {
              id
              termDuration
              termUnit
              amount
              health
              tobacco
              insuredsAgeAtTerm
              premiums {
                amount
                billingFrequency
              }
              product {
                ...ProductFields
              }
            }
          }
        }
      }
    }
  `;

  return client.query({ query });
};
