import { gql } from "config/graphql";
import { getApolloClient } from "store/apollo";

export interface ICreateCredentialsInput {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}

export interface ICreateCredentialResponse {
  data: {
    createCredential: {
      credential: {
        status: "pending" | "confirmed" | "rejected" | "decommissioned" | "transient";
        token: {
          accessToken: string;
          createdAt: string;
          refreshToken: string;
        };
      };
    };
  };
}

/* ------------     MUTATIONS    ------------------ */

export const initiatePasswordResetMutation = (email: string): Promise<any> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation initiatePasswordReset($input: InitiatePasswordResetInput!) {
      initiatePasswordReset(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: {
      input: { email },
    },
    mutation,
  });
};

export const passwordMutation = (token: string, password: string): Promise<any> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation resetForgottenPassword($token: String!, $password: String!) {
      resetForgottenPassword(
        input: { token: $token, password: $password, passwordConfirmation: $password }
      ) {
        clientMutationId
      }
    }
  `;

  return client.mutate({
    variables: { token, password },
    mutation,
  });
};

export const createCredential = (
  credentials: ICreateCredentialsInput & { reauthenticate: boolean }
): Promise<ICreateCredentialResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation createCredential($input: CreateCredentialInput!) {
      createCredential(input: $input) {
        credential {
          status
          token {
            createdAt
            accessToken
            refreshToken
          }
        }
      }
    }
  `;

  return client.mutate({
    variables: { input: credentials },
    mutation,
  });
};
