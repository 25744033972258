import "./HomeProducts.scss";

import PolicyUpdatesBanner from "components/PolicyUpdatesBanner/PolicyUpdatesBanner";
import {
  InsuranceProductBlock,
  SavingsProductBlock,
  WyshesProductBlock,
} from "containers/Dashboard/ProductBlocks";
import { useAppSelector } from "store";
import { getIsIdentified } from "store/selectors";

export function HomeProducts() {
  const isIdentified = useAppSelector(getIsIdentified);

  const AlertCardsPlaceholder = () => null;

  return (
    <div className="home-products content-container">
      <div className="home-products__col content-wrapper">
        <InsuranceProductBlock />
        <PolicyUpdatesBanner />
        <SavingsProductBlock />
        {!isIdentified && <WyshesProductBlock />}
      </div>
      <div className="home-products__col content-wrapper">
        <AlertCardsPlaceholder />
      </div>
    </div>
  );
}
