import "./BeneficiariesCard.scss";

import Button from "components/Button/Button";
import Text from "components/Text/Text";
import { ICardProps } from "modules/partials/Card";
import { FC } from "react";

type Content = {
  ctaText: string;
  hasPolicy?: boolean;
  hasBeneficiaries?: boolean;
  text?: string;
};

export const BeneficiariesCard: FC<ICardProps> = props => {
  const content = props.content as Content;
  const { ctaText, hasPolicy, hasBeneficiaries, text } = content;

  const btnSubtype = hasPolicy ? "primary" : "text-dark-border";

  return (
    <div className="card-content__body-container beneficiaries__card-content">
      <div className="card-content">
        {text && hasBeneficiaries && <Text tag="h4" text={text} />}
        {!hasBeneficiaries && (
          <div className="card-content__body-footer__wrapper">
            <Button
              subtype={btnSubtype}
              className="card-content__action card-content__body-footer__button"
              onClick={props.actions?.onClick}
              text={ctaText}
            />
          </div>
        )}
      </div>
    </div>
  );
};
