import "./WyshesAddedDrawer.scss";

import Currency from "components/Currency/Currency";
import Text from "components/Text/Text";
import WyshesByCategoryList from "components/WyshesByCategoryList/WyshesByCategoryList";
import WyshBuilderLiteCta from "containers/WyshBuilderLite/WyshBuilderLiteCta";
import { size, sumBy } from "lodash-es";
import { Drawer, useModal, useTranslation } from "modules";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { eventFired } from "store/actions/analytics";
import { setWyshesAdded } from "store/actions/wyshesFlow";
import { IWyshesAllocationState } from "store/reducers/wyshes";
import { v4 } from "uuid";

interface IProps {
  allocations: IWyshesAllocationState[];
}

const WyshAllocationsDrawer: FC<IProps> = ({ allocations }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { wyshesAdded } = useSelector((state: RootState) => state.wyshesFlow);
  const [drawerId, setDrawerId] = useState("");
  const allocationsCount = size(allocations);
  const allocationsTotal = sumBy(allocations, "amount");
  const allocationsTotalCurrency = <Currency amount={allocationsTotal} fixedPoints={0} />;

  const indicatorText = <Text tag="c4" text={allocationsCount.toString()} />;
  const indicator = <div className="wysh-number__indicator">{indicatorText}</div>;

  const openModal = useModal({});

  const handleOpenDrawer = () => {
    dispatch(
      eventFired({
        event: "navigation_wyshbuilder_drawer_opened",
        experienceLocation: "ONBOARDING",
      })
    );
  };

  const handleDeleteWysh = (allocationId: string) => {
    openModal("RemoveWyshModal", "", { allocationId });
  };

  const knobText = (
    <div className="wysh-allocation__knob-text__wrapper">
      <Text
        tag="c4"
        className="wysh-allocation__knob-text__title"
        text={t("wysh.wyshes_added_drawer.knob.title", "Wysh List")}
      />
      <Text tag="p3" className="wysh-allocation__knob-text__amount">
        {allocationsTotalCurrency}
      </Text>
    </div>
  );

  // Trigger "ping" animation on drawer if wysh was added, then set wyshesAdded state to false
  useEffect(() => {
    if (wyshesAdded) {
      setDrawerId(v4());

      setTimeout(() => dispatch(setWyshesAdded(false)), 100);
    }
  }, [wyshesAdded, dispatch]);

  // TAB NAVIGATION:
  // set tabIndex so drawer children can have a starting point to flow from
  const drawerKnobTabIndex = 1;

  return (
    <Drawer
      indicator={indicator}
      knobText={knobText}
      onOpen={handleOpenDrawer}
      options={{ animateKnob: wyshesAdded ? "ping" : "slide-in", tabIndex: drawerKnobTabIndex }}
      key={drawerId}>
      <div className="wysh-allocation__container">
        <div className="wysh-allocation__wrapper">
          <div className="wysh-allocation__content wysh-allocation__summary">
            <div className="wysh-allocation__summary-text">
              <Text
                tag="h4"
                className="wysh-allocation__summary-title"
                text={t("wysh.wyshes_added_drawer.summary.title", "Your Wysh List")}
              />
              <Text
                tag="p3"
                className="wysh-allocation__summary-subtitle"
                text={t(
                  "wysh.wyshes_added_drawer.summary.subtitle",
                  "The average person covers about $450,000 worth of Wyshes."
                )}
              />
            </div>
            <div className="wysh-allocation__summary-content">
              <div className="wysh-allocation__summary-content__wyshes">
                <Text
                  tag="l3"
                  className="wysh-allocation__summary-subtitle"
                  text={t("wysh.wyshes_added_drawer.summary.wysh_count", "%<count>s wyshes", {
                    count: allocationsCount,
                  })}
                />
              </div>
              <div className="wysh-allocation__summary-content__amount">
                <Text tag="l3" dataTestId="wysh-drawer__total">
                  {allocationsTotalCurrency}
                </Text>
              </div>
            </div>
          </div>
          <div className="wysh-allocation__content">
            <WyshesByCategoryList
              allocations={allocations}
              onWyshRemove={handleDeleteWysh}
              flowTabIndicesStart={drawerKnobTabIndex + 1}
            />
            <div className="wysh-allocation__cta-wrapper">
              <WyshBuilderLiteCta
                type="drawer"
                dataTestId="wysh-builder-lite__drawer-cta"
                className="wysh-builder__cta--drawer full-width appear-hidden@medium"
              />
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default WyshAllocationsDrawer;
