import "./ProductOfferingPremiumInfo.scss";

import classNames from "classnames";
import Button from "components/Button/Button";
import Currency from "components/Currency/Currency";
import Text from "components/Text/Text";
import { ClassName } from "config";
import { DateTime } from "luxon";
import { useTranslation } from "modules";

interface IProductOfferingPremiumInfo {
  cta: {
    text: string;
    onClick(): void;
  };
  isLoading: boolean;
  className?: ClassName;
  expiresAt?: string;
  premiumAmount?: number;
}

export function ProductOfferingPremiumInfo(props: IProductOfferingPremiumInfo) {
  const { className, cta, expiresAt, isLoading, premiumAmount } = props;

  const { t } = useTranslation();

  return (
    <div className={classNames("product-offering-premium-info", className)}>
      <Text className="product-offering-premium-info__amount" tag="n1-number">
        {premiumAmount ? (
          <Currency amount={premiumAmount} />
        ) : (
          t("dollar.amount", "$%<amount>s", { amount: "--.--" })
        )}
      </Text>
      <Text className="product-offering-premium-info__amount-label" tag="c4-caption">
        {t("review_quotev2.term.offer.billing_frequency", "PER MONTH")}
      </Text>

      <Button
        className="product-offering-premium-info__cta"
        dataTestId="tl-offering__cta"
        disabled={isLoading}
        isLoading={isLoading}
        isRounded
        onClick={cta.onClick}
        size="sm"
        subtype="primary"
        tag="c5-caption"
        text={cta.text}
      />

      <Text className="product-offering-premium-info__validity-text" tag="p5-paragraph">
        {t("review_quotev2.term.offer.valid_until", "Quote valid until %<date>s", {
          date: expiresAt ? DateTime.fromISO(expiresAt).toFormat("MM/dd/yy") : "--/--/--",
        })}
      </Text>
    </div>
  );
}
