import { IReinstateablePolicy } from "config";
import { gql } from "config/graphql";
import { getApolloClient } from "store/apollo";

export interface ICoverageAdjustmentInput {
  policyId: string;
  amount: number;
}

interface IRequestCoverageAdjustmentResponse {
  data: {
    requestCoverageAdjustment: {
      response: {
        success: boolean;
        status: string;
      };
    };
  };
}

export const requestCoverageAdjustment = (
  adjustment: ICoverageAdjustmentInput
): Promise<IRequestCoverageAdjustmentResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation RequestCoverageAdjustment($input: RequestCoverageAdjustmentInput!) {
      requestCoverageAdjustment(input: $input) {
        response {
          success
          status
        }
      }
    }
  `;

  return client.mutate({
    variables: {
      input: adjustment,
    },
    mutation,
  });
};

export const requestReinstatement = (policyId: IReinstateablePolicy["id"]) => {
  const client = getApolloClient();

  const mutation = gql`
    mutation requestReinstatement($input: RequestReinstatementInput!) {
      requestReinstatement(input: $input) {
        response {
          status
          success
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables: {
      input: {
        policyId,
      },
    },
  });
};
