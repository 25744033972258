import "./Drawer.scss";

import classnames from "classnames";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { ClassName } from "config/types";
import { AnimatePresence, motion } from "framer-motion";
import { merge, noop } from "lodash-es";
import { useBodyLock, useImmersiveMode, useTranslation } from "modules";
import {
  FC,
  ForwardedRef,
  forwardRef,
  Fragment,
  ReactNode,
  useImperativeHandle,
  useState,
} from "react";
import { PortalFunctionParams, PortalWithState } from "react-portal";

interface IDrawerOptions {
  closeOnEsc: boolean;
  closeOnOutsideClick: boolean;
  defaultOpen: boolean;
  animateKnob: KnobAnimations;
  tabIndex?: number;
}

export type KnobAnimations = "slide-in" | "ping" | undefined;

interface IProps {
  className?: ClassName;
  knobText?: ReactNode;
  indicator?: ReactNode;
  options?: Partial<IDrawerOptions>;
  ref?: ForwardedRef<Partial<PortalFunctionParams>>;
  onOpen?(): void;
  onClose?(): void;
}

interface IKnobProps {
  isOpen: boolean;
  indicator: IProps["indicator"];
  text: IProps["knobText"];
  className?: ClassName;
  animateKnob?: KnobAnimations;
  tabIndex: number;
  onClick(): void;
}

export interface IDrawerProps {
  defaultOpen?: boolean;
}

const DrawerKnob: FC<IKnobProps> = ({
  isOpen,
  text,
  indicator,
  onClick,
  className,
  animateKnob,
  tabIndex,
}) => {
  const { t } = useTranslation();
  const immersiveMode = useImmersiveMode();
  const [animationType] = useState<KnobAnimations>(animateKnob);
  const closeText = t("Close", "Close");
  const classes = classnames(className, "drawer-knob__container", {
    open: isOpen,
    "drawer-knob__container__immersive-mode": immersiveMode,
    "slide-in-knob": animationType === "slide-in",
    "ping-knob": animationType === "ping",
  });

  const knobOpenContent = (
    <motion.div className="drawer-knob__content-wrapper">
      <div className="drawer-knob__content__indicator__wrapper">
        <div className="drawer-knob__content__indicator">{indicator}</div>
      </div>
      <div className="drawer-knob__content__text-wrapper text-rotated-right appear-hidden appear-initial@medium">
        {text}
      </div>
    </motion.div>
  );

  const knobCloseContent = (
    <motion.div className="drawer-knob__content-close">
      <Icon src="CloseBorder" className="drawer-knob__content-close__icon fill-white!" />
      <Text tag="c4" className="text-rotated-left appear-hidden appear-initial@medium uppercase">
        {closeText}
      </Text>
    </motion.div>
  );

  const knobContent = isOpen ? knobCloseContent : knobOpenContent;

  return (
    <motion.div className={classes}>
      <button
        className="drawer-knob__wrapper"
        onClick={onClick}
        data-testid="drawer-knob"
        tabIndex={tabIndex}>
        <div className="drawer-knob__content">
          <AnimatePresence>{knobContent}</AnimatePresence>
        </div>
      </button>
    </motion.div>
  );
};

const DrawerBody: FC<{ className?: ClassName; isOpen: boolean; onOverlayClick?(): void }> = ({
  children,
  className,
  isOpen,
  onOverlayClick,
}) => {
  useBodyLock();

  const classes = classnames(
    "drawer__drawer-container",
    { "drawer-body__open": isOpen },
    className
  );

  return (
    <motion.div
      transition={{ duration: 0.2 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key="drawer__drawer-container"
      className={classes}>
      <div className="drawer-body__container">
        <div className="drawer-overlay__container" onClick={onOverlayClick} />
        <motion.div
          transition={{ duration: 0.35, ease: "easeInOut" }}
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          exit={{ x: "100%" }}
          key="drawer-body__wrapper"
          className="drawer-body__wrapper">
          {children}
        </motion.div>
      </div>
    </motion.div>
  );
};

export const Drawer: FC<IProps> = forwardRef(
  (props, ref: ForwardedRef<Partial<PortalFunctionParams>>) => {
    const { className = "", children, knobText, indicator, onClose, onOpen, options = {} } = props;

    const callbacks = { onClose, onOpen };
    let portalRefs = {};

    useImperativeHandle(ref, () => portalRefs);
    const defaultPortalOptions: IDrawerOptions = {
      closeOnEsc: true,
      closeOnOutsideClick: true,
      defaultOpen: false,
      animateKnob: undefined,
    };
    const portalOptions = merge(defaultPortalOptions, options);

    return (
      <PortalWithState {...portalOptions} {...callbacks}>
        {({ openPortal, closePortal, isOpen, portal }) => {
          const knobClickHandler = isOpen ? closePortal : openPortal;
          const overlayClickHandler = portalOptions.closeOnOutsideClick ? closePortal : noop;

          portalRefs = { isOpen, closePortal, openPortal };

          return (
            <Fragment>
              <DrawerKnob
                text={knobText}
                isOpen={isOpen}
                onClick={knobClickHandler}
                indicator={indicator}
                className={className}
                animateKnob={portalOptions.animateKnob}
                tabIndex={options.tabIndex ?? 0}
              />
              <AnimatePresence>
                {portal(
                  <DrawerBody
                    className={className}
                    isOpen={isOpen}
                    onOverlayClick={overlayClickHandler}>
                    {children}
                  </DrawerBody>
                )}
              </AnimatePresence>
            </Fragment>
          );
        }}
      </PortalWithState>
    );
  }
);

Drawer.displayName = "Drawer";
