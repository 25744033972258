import { gql } from "config/graphql";

export const RECIPIENT_FIELDS = gql`
  fragment RecipientFields on WyshAllocationsRecipient {
    __typename
    ... on WyshAllocationsInstitution {
      id
      name
      countryCode
      accountNumber
      phoneNumber
    }
    ... on WyshAllocationsCompany {
      id
      name
      websiteUrl
    }
    ... on WyshAllocationsIndividual {
      id
      name
      email
      countryCode
      phoneNumber
      relationship
    }
  }
`;
