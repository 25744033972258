import { IReinstateablePolicy } from "config";
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { logout } from "store/actions/authentication";
import { getReinstateablePolicies, requestPolicyReinstatement } from "store/actions/policy";

interface IReinstateablePoliciesState {
  byId: Record<IReinstateablePolicy["id"], IReinstateablePolicy>;
  hasLoaded: boolean;
  ids: IReinstateablePolicy["id"][];
  isLoading: boolean;
  isRequestingToStartReinstatement: boolean;
}

const defaultState: IReinstateablePoliciesState = {
  byId: {},
  hasLoaded: false,
  ids: [],
  isLoading: false,
  isRequestingToStartReinstatement: false,
};

const requestReinstatementFinishedMatcher = isAnyOf(
  requestPolicyReinstatement.fulfilled,
  requestPolicyReinstatement.rejected
);

const reinstateablePolicies = createReducer(defaultState, builder => {
  builder
    .addCase(logout, () => defaultState)
    .addCase(getReinstateablePolicies.pending, state => {
      state.byId = {};
      state.ids = [];

      state.hasLoaded = false;
      state.isLoading = true;
    })
    .addCase(getReinstateablePolicies.fulfilled, (state, action) => {
      action.payload.forEach((policy: IReinstateablePolicy) => {
        if (!state.ids.includes(policy.id)) {
          state.ids.push(policy.id);
        }

        state.byId[policy.id] = policy;
      });

      state.hasLoaded = true;
      state.isLoading = false;
    })
    .addCase(getReinstateablePolicies.rejected, state => {
      state.isLoading = false;
    })
    .addCase(requestPolicyReinstatement.pending, state => {
      state.isRequestingToStartReinstatement = true;
    })
    .addMatcher(requestReinstatementFinishedMatcher, state => {
      state.isRequestingToStartReinstatement = false;
    });
});

export default reinstateablePolicies;
