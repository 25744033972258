import { gql } from "config/graphql";
import {
  InputBeneficiaryAssignedEntityType,
  BeneficiaryResponsibility,
  ContactType,
  IAddress,
  IBeneficiary,
  IBeneficiaryAssignedEntity,
  IContactEntity,
  IContactIndividual,
} from "config/types";
import { BENEFICIARY_FIELDS } from "graphql/fragments/beneficiary";
import { getApolloClient } from "store/apollo";
import { createClientMutationId } from "utils/clientMutationId";

export interface ICreateBeneficiaryInput {
  type: ContactType;
  contact: Partial<IContactEntity> | Partial<IContactIndividual>;
  address: IAddress;
  responsibility: BeneficiaryResponsibility;
  assignedEntity: Omit<IBeneficiaryAssignedEntity, "id" | "entityType">;
  entityType: InputBeneficiaryAssignedEntityType;
}

interface ICreateBeneficiaryResponse {
  data: {
    createBeneficiary: {
      beneficiary: IBeneficiary;
    };
  };
}

export const createBeneficiaryMutation = (
  input: ICreateBeneficiaryInput
): Promise<ICreateBeneficiaryResponse> => {
  const client = getApolloClient();
  const { type, contact, address, responsibility, assignedEntity, entityType } = input;

  const mutation = gql`
    ${BENEFICIARY_FIELDS}
    mutation createBeneficiary($input: CreateBeneficiaryInput!) {
      createBeneficiary(input: $input) {
        beneficiary {
          ...BeneficiaryFields
        }
      }
    }
  `;

  return client.mutate({
    variables: {
      input: {
        responsibility,
        contact: {
          address,
          [type]: contact,
        },
        assignedEntity: {
          [entityType]: assignedEntity,
        },
        clientMutationId: createClientMutationId(),
      },
    },
    mutation,
  });
};

export interface IUpdateBeneficiaryInput {
  type: ContactType;
  contact: Partial<IContactEntity> | Partial<IContactIndividual>;
  address: IAddress;
  beneficiaryId: string;
}

interface IUpdateBeneficiaryResponse {
  data: {
    updateBeneficiary: {
      beneficiary: IBeneficiary;
    };
  };
}

export const updateBeneficiaryMutation = (
  input: IUpdateBeneficiaryInput
): Promise<IUpdateBeneficiaryResponse> => {
  const client = getApolloClient();
  const { type, contact, address, beneficiaryId } = input;

  const mutation = gql`
    ${BENEFICIARY_FIELDS}
    mutation updateBeneficiary($input: UpdateBeneficiaryInput!) {
      updateBeneficiary(input: $input) {
        beneficiary {
          ...BeneficiaryFields
        }
      }
    }
  `;

  return client.mutate({
    variables: {
      input: {
        beneficiaryId,
        contact: {
          address,
          [type]: contact,
        },
      },
    },
    mutation,
  });
};

export interface ISendBeneficiaryPacketInput {
  beneficiaryId: string;
}

export const sendBeneficiaryPacketMutation = (input: ISendBeneficiaryPacketInput) => {
  const client = getApolloClient();

  const mutation = gql`
    mutation sendBeneficiaryPacket($input: SendBeneficiaryPacketInput!) {
      sendBeneficiaryPacket(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables: { input },
  });
};

export interface IDestroyBeneficiaryInput {
  beneficiaryId: string;
  promoteBackup?: boolean;
  destroyBackup?: boolean;
}

interface IDestoryBeneficiaryResponse {
  data: {
    destroyBeneficiary: {
      response: {
        success: boolean;
      };
    };
  };
}

export const destroyBeneficiaryMutation = (
  input: IDestroyBeneficiaryInput
): Promise<IDestoryBeneficiaryResponse> => {
  const client = getApolloClient();
  const { beneficiaryId, promoteBackup = false, destroyBackup = false } = input;

  const mutation = gql`
    mutation destroyBeneficiary($input: DestroyBeneficiaryInput!) {
      destroyBeneficiary(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: {
      input: {
        beneficiaryId,
        promoteBackup,
        destroyBackup,
        clientMutationId: createClientMutationId(),
      },
    },
    mutation,
  });
};

export interface BeneficiaryInputAttributes {
  beneficiaryId: string;
  allocation: number;
}
interface IUpdateBeneficiaryAllocationResponse {
  data: {
    updateBeneficiaryAllocation: {
      response: {
        status: string;
        success: boolean;
      };
    };
  };
}

export const updateBeneficiaryAllocationMutation = (
  beneficiaryAllocations: BeneficiaryInputAttributes[]
): Promise<IUpdateBeneficiaryAllocationResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation UpdateBeneficiaryAllocation($input: UpdateBeneficiaryAllocationInput!) {
      updateBeneficiaryAllocation(input: $input) {
        response {
          status
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: {
      input: {
        beneficiaryAllocations,
        clientMutationId: createClientMutationId(),
      },
    },
    mutation,
  });
};
