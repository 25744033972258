import { gql } from "config/graphql";

export const FINANCE_LIFE_BENEFIT = gql`
  fragment FinanceLifeBenefitFields on FinanceLifeBenefit {
    coverageAmount
    effectiveAt
    eligible
    expiresAt
    maximumCoverage
    projectedCoverageAmount
    rate
    status
    updatedAt
  }
`;

export const FINANCE_BANK_ACCOUNT = gql`
  ${FINANCE_LIFE_BENEFIT}
  fragment FinanceBankAccountFields on FinanceBankAccount {
    accountBalance
    accountNumber
    apy
    availableBalance
    balanceLastModifiedDate
    closedDate
    createdDate
    deathPayout
    fundsOnHold
    isLocked
    lastModifiedDate
    lifeBenefit {
      ...FinanceLifeBenefitFields
    }
    lifetimeInterest
    lockReasonTypeCode
    pendingBalance
    routingNumber
    status
  }
`;

export const FINANCE_BANK_TRANSACTION = gql`
  fragment FinanceBankTransactionFields on FinanceBankTransaction {
    amount
    availableDate
    createdDate
    dateDisplay
    externalId
    friendlyDescription
    runningAccountBalance
    settledDate
    statementDate
    status
    statusDisplay
    typeCode
    typeDisplay
  }
`;
