import { OrNull, OrString, PlatformApiHealthEndpoint } from "config";
import { DateTime } from "luxon";

export type HealthCheckResponse = {
  url: URL;
  response: Response;
  healthy: boolean;
  timestamp: DateTime;
};

/**
 * Formats the health check response.
 * @param response - The response object from the fetch call.
 * @returns A formatted health check response with additional information.
 */
export const formatHealthResponse = (response: Response): HealthCheckResponse => {
  const healthy = response.ok;
  const url = new URL(response.url);
  const timestamp = DateTime.local();
  return { url, response, healthy, timestamp };
};

/**
 * Performs a health check on a given endpoint.
 * @param baseUrl - The base URL of the platform endpoint.
 * @param healthPath - The path to the health check endpoint. Defaults to '/health'.
 * @returns A promise that resolves to a formatted health check response or an error.
 */
export const checkHealth = async (
  baseUrl: OrString<OrNull>,
  healthPath = PlatformApiHealthEndpoint
): Promise<HealthCheckResponse | Error> => {
  try {
    if (!baseUrl) {
      throw new Error("Base URL is required.");
    }
    const endpoint = new URL(healthPath, baseUrl);
    const response = await fetch(endpoint.href);
    const formattedResponse = formatHealthResponse(response);
    if (!formattedResponse.healthy) {
      throw new Error("Current Endpoint could not be reached.");
    }
    return formattedResponse;
  } catch (error) {
    return error as Error;
  }
};
