import { ISliderMetaData } from "components/SliderInput/SliderInput";
import { useEffect, useState } from "react";
import { CoverageType } from "router/CoverageEditorRoute/CoverageEditorRoute";
import { useAppDispatch } from "store";
import { getCoverageEditorState } from "store/actions/policy";

const defaultMetadata = {
  value: 0,
  leftTextLabel: "",
  rightTextLabel: "",
  increment: 1,
  valueBase: 0,
  minErrorMessage: "",
  maxErrorMessage: "",
  textEditable: true,
  ticks: [],
};

export const useCoverageMetadata = (coverage: any, type: CoverageType) => {
  const dispatch = useAppDispatch();
  const [metadataAmount, setMetadataAmount] = useState<ISliderMetaData>(defaultMetadata);
  const [metadataDuration, setMetadataDuration] = useState<ISliderMetaData>(defaultMetadata);

  useEffect(() => {
    const fetchCoverageMetadata = async () => {
      try {
        const inputKeys = {
          estimate: "estimateId",
          quote: "quoteId",
          policy: "policyId",
          adOffering: "productsOfferingId",
          termLifeOffering: "productsOfferingId",
        };
        const inputKey = inputKeys[type];
        const inputType = { [inputKey]: coverage.id };

        const metadata = await dispatch(getCoverageEditorState(inputType)).unwrap();
        const metadataCoverageAmount = metadata[0]?.metadata;
        const metadataCoverageDuration = metadata[1]?.metadata;

        setMetadataAmount(metadataCoverageAmount);
        setMetadataDuration(metadataCoverageDuration);
      } catch (err) {
        return err;
      }
    };

    fetchCoverageMetadata();
  }, [coverage.id, dispatch, type]);

  return { metadataAmount, metadataDuration };
};
