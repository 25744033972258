import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { IEmail, IPhoneNumber, IProfile } from "config/types";
import { findLast, last } from "lodash-es";
import { getPreVerifiedInitialData, getVerifiedInitialData } from "store/actions/appLoad";
import { logout } from "store/actions/authentication";
import { getDashboardData } from "store/actions/dashboard";
import { getProfile, getProfileReviewPersonalInfo } from "store/actions/profile";
import { createPhoneNumber } from "store/actions/user";

/* ------------    PROFILE - DEFAULT STATE     ------------------ */
export interface IProfileState extends IProfile {
  email: IEmail | null;
  activeEmail: IEmail | null;
  pendingEmail: IEmail | null;
  activePhoneNumber: IPhoneNumber;
  lastEmailIsPending: boolean;
  preVerifiedDataFetched: boolean;
  isLoadingProfile: boolean;
}

const defaultState: IProfileState = {
  birthdate: "",
  firstName: "",
  lastName: "",
  gender: "",
  middleName: "",
  suffix: "",
  phoneNumbers: [],
  emails: [],
  activeAddress: {
    street1: "",
    street2: "",
    city: "",
    state: "",
    postalCode: "",
    country: {
      id: "",
      name: "",
      alpha3: "",
      hasStates: false,
      hasPostalCode: false,
      postalCodeFormat: "",
    },
    stateObject: {
      id: "",
      name: "",
      alpha2: "",
    },
    formattedDisplay: "",
  },
  activePhoneNumber: {
    id: "",
    createdAt: "",
    phoneNumber: "",
    status: "pending",
  },
  email: null,
  activeEmail: null,
  pendingEmail: null,
  lastEmailIsPending: false,
  preVerifiedDataFetched: false,
  isLoadingProfile: false,
  taxIdSet: false,
};

/* ------------    MATCHERS     ------------------ */

const isProfileActionFailed = isAnyOf(getDashboardData.rejected, getProfile.rejected);
const isProfileActionFulfilled = isAnyOf(getDashboardData.fulfilled, getProfile.fulfilled);
const isProfileActionPending = isAnyOf(getDashboardData.pending, getProfile.pending);

/* ------------    REDUCER     ------------------ */
const profile = createReducer(defaultState, builder => {
  builder
    .addCase(getProfileReviewPersonalInfo.fulfilled, (state, action) => {
      const {
        activeAddress,
        birthdate,
        firstName,
        gender,
        lastName,
        middleName,
        phoneNumbers,
        suffix,
        taxIdSet,
      } = action.payload;

      state.activeAddress = activeAddress;
      state.activePhoneNumber = getActivePhoneNumber({ phoneNumbers, state });
      state.birthdate = birthdate;
      state.firstName = firstName;
      state.gender = gender;
      state.lastName = lastName;
      state.middleName = middleName;
      state.phoneNumbers = phoneNumbers;
      state.suffix = suffix;
      state.taxIdSet = taxIdSet;
    })
    .addCase(getPreVerifiedInitialData.fulfilled, (state, action) => {
      state.activePhoneNumber = getActivePhoneNumber({
        state,
        phoneNumbers: action.payload.me.phoneNumbers,
      });
      state.phoneNumbers = action.payload.me.phoneNumbers;
      state.preVerifiedDataFetched = true;
    })
    .addCase(getVerifiedInitialData.fulfilled, (state, action) => {
      const { firstName, phoneNumbers, emails, activeAddress } = action.payload.me;
      const lastEmailIsPending = last(emails)?.status === "pending";

      state.activeEmail = findLast(emails, ["status", "active"]) || null;
      state.pendingEmail = findLast(emails, ["status", "pending"]) || null;
      state.email = state.activeEmail || state.pendingEmail;
      state.firstName = firstName || state.firstName;
      state.activePhoneNumber = getActivePhoneNumber({ phoneNumbers, state });
      state.activeAddress = activeAddress || state.activeAddress;
      state.lastEmailIsPending = lastEmailIsPending;
    })
    .addCase(createPhoneNumber.fulfilled, (state, action) => {
      state.phoneNumbers = state.phoneNumbers.concat([action.payload]);
    })
    .addCase(logout, () => defaultState)
    .addMatcher(isProfileActionFailed, state => {
      state.isLoadingProfile = false;
    })
    .addMatcher(isProfileActionFulfilled, (state, action) => {
      const {
        birthdate,
        firstName,
        gender,
        lastName,
        middleName,
        suffix,
        phoneNumbers,
        emails,
        activeAddress,
      } = action.payload as Partial<IProfile>;

      state.isLoadingProfile = false;
      state.firstName = firstName || state.firstName;
      state.lastName = lastName || state.lastName;
      state.middleName = middleName || state.middleName;
      state.suffix = suffix || state.suffix;
      state.birthdate = birthdate || state.birthdate;
      state.gender = gender || state.gender;
      state.activePhoneNumber = getActivePhoneNumber({ phoneNumbers, state });
      state.phoneNumbers = phoneNumbers || state.phoneNumbers;
      state.emails = emails || state.emails;
      state.activeEmail = findLast(state.emails, ["status", "active"]) || null;
      state.pendingEmail = findLast(state.emails, ["status", "pending"]) || null;
      state.email = state.activeEmail || state.pendingEmail;
      state.activeAddress = activeAddress || state.activeAddress;
    })
    .addMatcher(isProfileActionPending, state => {
      state.isLoadingProfile = true;
    });
});

export default profile;

function getActivePhoneNumber({
  phoneNumbers,
  state,
}: {
  state: IProfileState;
  phoneNumbers?: IPhoneNumber[];
}) {
  const activeNumber = phoneNumbers?.find(number => number.status === "active");
  const pendingNumber = phoneNumbers?.find(number => number.status === "pending");

  return activeNumber || pendingNumber || state.activePhoneNumber;
}
