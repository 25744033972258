import { createSelector } from "@reduxjs/toolkit";
import { ApiFormGroup, CheckboxInputFormItem } from "config";
import { RootState } from "store";

import { selectPreviousDynamicForm } from ".";

export const selectDynamicForm = (state: RootState) => state.dynamicForm;
export const selectDynamicFormGroups = (state: RootState) => state.dynamicForm.groups || [];
export const selectDynamicFormItems = (state: RootState) => state.dynamicForm.items;

export const selectDynamicFormApiGroups = createSelector(
  selectDynamicFormGroups,
  groups => groups.filter(group => group.type === "api") as ApiFormGroup[]
);

export const selectDynamicFormCheckboxItems = createSelector(
  selectDynamicFormItems,
  item => item.filter(item => item.type === "inputCheckbox") as CheckboxInputFormItem[]
);

export const selectDynamicFormsByLayout = createSelector(
  selectDynamicForm,
  selectPreviousDynamicForm,
  (dynamicForm, previousDynamicForm) => {
    const currentDynamicFormScreen =
      dynamicForm.layout === "modal" ? previousDynamicForm : dynamicForm;
    const dynamicFormModal = dynamicForm.layout === "modal" ? dynamicForm : null;

    return {
      dynamicForm: currentDynamicFormScreen as typeof dynamicForm,
      modalForm: dynamicFormModal as typeof dynamicForm,
    };
  }
);
