import c from "classnames";
import MultipleCheckboxInput from "components/MultipleCheckboxInput/MultipleCheckboxInput";
import { useContext } from "react";

import { DynamicFormContext } from "../DynamicFormContext";

import { IDynamicFormItemProps } from ".";

export function MultiSelectInputItem({ item }: IDynamicFormItemProps<"inputMultiSelect">) {
  const { form } = useContext(DynamicFormContext);

  const { content, key } = item;
  const { options } = content;
  const { value } = form.getFormInput(key);

  const classes = c("dynamic-form-item form-item--input-multi-select");

  const inputOptions = options.map(option => {
    return {
      id: option.key || option.label,
      label: option.label,
      value: option.value,
    };
  });

  return (
    <MultipleCheckboxInput
      className={classes}
      dataTestId={true}
      dividers
      id={key}
      onChange={form.onChange}
      options={inputOptions}
      value={value}
    />
  );
}
