import { createSelector } from "@reduxjs/toolkit";
import { selectFinanceCustomer } from "store/selectors/selectFinanceCustomer";
import { selectUserProfile } from "store/selectors/selectUser";
import { formatBirthDate, formatFullName, formatGender } from "utils/profile";
import { formatPhoneNumber } from "utils/phoneNumber";

export const selectInsuranceProfileReviewInfo = createSelector(
  selectFinanceCustomer,
  selectUserProfile,
  (financeCustomer, profile) => {
    return {
      address: profile.activeAddress.formattedDisplay || "",
      birthDate: formatBirthDate(profile.birthdate),
      gender: formatGender(profile.gender),
      isFinanceCustomer: ["automated_reviewed", "manual_reviewed", "verified"].includes(
        financeCustomer?.status as string
      ),
      name: formatFullName({
        firstName: profile.firstName,
        middleName: profile.middleName,
        lastName: profile.lastName,
        suffix: profile.suffix,
      }),
      phoneNumber: formatPhoneNumber(profile.activePhoneNumber),
      ssn: profile.taxIdSet ? "***-**-****" : "",
    };
  }
);
