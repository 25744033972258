import { IForm, IFormInputs } from "config/types";
import { createContext, FC, useState } from "react";

interface IDynamicFormContext {
  form: IForm;
  inputs: IFormInputs;
  modalOverlay?: {
    shouldCloseModalOverlay: boolean;
    closeModalOverlay?(): void;
  };
}

const defaultContextValue: IDynamicFormContext = {
  form: {
    allRequiredFieldsFilled: false,
    formErrorMessage: "",
    getFormInput: () => ({}),
    getInputError: () => false,
    getInputErrorMessage: () => [],
    getSubmitBtnDisabled: () => false,
    initializeFormState: () => ({}),
    isFormDirtied: false,
    isFormInitialized: false,
    loading: false,
    onBlur: () => ({}),
    onChange: () => ({}),
    processServerValidationErrors: () => ({}),
    reset: () => ({}),
    setFormErrorMessage: () => ({}),
    setLoading: () => ({}),
    setInputs: () => ({}),
    valid: false,
    validateAllFields: () => false,
  },
  inputs: {},
  modalOverlay: {
    shouldCloseModalOverlay: false,
    closeModalOverlay: () => ({}),
  },
};

export const DynamicFormContext = createContext<IDynamicFormContext>(defaultContextValue);

export const DynamicFormProvider: FC<IDynamicFormContext> = ({ children, form, inputs }) => {
  const [shouldCloseModalOverlay, setShouldCloseModalOverlay] = useState(false);

  const closeModalOverlay = () => {
    setShouldCloseModalOverlay(true);
  };

  return (
    <DynamicFormContext.Provider
      value={{
        form,
        inputs,
        modalOverlay: { shouldCloseModalOverlay, closeModalOverlay },
      }}>
      {children}
    </DynamicFormContext.Provider>
  );
};
