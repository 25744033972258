import "./HomeHeader.scss";

import Text from "components/Text/Text";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC } from "react";
import { useSelector } from "react-redux";
import { selectUserProfile } from "store/selectors";

const HomeHeader: FC = () => {
  const { t } = useTranslation();

  const { firstName } = useSelector(selectUserProfile);

  const headerText = firstName
    ? t("home.tab.header", "Welcome,\n %<firstName>s!", { firstName })
    : t("home.tab.header.no_name", "Welcome");

  return (
    <div className="home-header">
      <div className="home-header__content">
        <div className="home-header__text">
          <Text tag="h3" text={headerText} />
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;
