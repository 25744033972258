import { createSelector } from "@reduxjs/toolkit";
import { reinstateablePoliciesById } from "./selectReinstateablePolicies";
import { IReinstateablePolicy } from "config";
import { RootState } from "store";

export const selectReinstatementProductOfferingByPolicyId = createSelector(
  reinstateablePoliciesById,
  (_: RootState, policyId: IReinstateablePolicy["id"]) => policyId,
  (policiesById, policyId) => policiesById[policyId]?.reinstatementRequest?.productsOffering
);
