import { IButtonAction } from "config";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { useButtonAction } from "containers/DynamicForm/useButtonAction";
import { Layout, useJourney, useTranslation } from "modules";
import { FC } from "react";

const ExitFlowWarningModal: FC<IModalProps> = ({ closeModal, data }) => {
  const { t } = useTranslation();
  const { exitJourney } = useJourney();
  const dynamicFormButtonItemActions = (data?.actions || []) as IButtonAction[];
  const executeButtonItemActions = useButtonAction(dynamicFormButtonItemActions);

  const handleExitFlow = () => {
    exitJourney();
    executeButtonItemActions();

    closeModalWrapper();
  };

  const closeModalWrapper = () => {
    closeModal();
  };

  const content = {
    header: t("fulfillment.modal.title", "Do you really wanna exit mid-application?"),
    subheader: t(
      "fulfillment.modal.body",
      "If you leave now, you might have to answer these questions again."
    ),
    button: [
      {
        text: t("fulfillment.modal.primary", "YEAH, EXIT"),
        onClick: handleExitFlow,
        dataTestId: "journey-exit-modal__confirm",
      },
      {
        text: t("fulfillment.modal.secondary", "NEVERMIND"),
        subType: "text-dark",
        onClick: closeModalWrapper,
        dataTestId: "journey-exit-modal__decline",
      },
    ],
    onClose: closeModalWrapper,
    icon: "BlobAlert",
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default ExitFlowWarningModal;
