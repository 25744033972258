import "./WyshUpdatesCard.scss";

import classNames from "classnames";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { IWyshAllocationHistory } from "config/types";
import { truncate } from "lodash-es";
import { useTranslation } from "modules";
import { ICardProps } from "modules/partials/Card";
import { FC } from "react";
import formatCommas from "utils/formatCommas";

interface IContent extends IWyshAllocationHistory {
  name: string;
  icon: string;
}

interface IWyshUpdatesProps extends ICardProps {
  onClick?(): void;
}

export const WyshUpdatesCard: FC<IWyshUpdatesProps> = props => {
  const { t } = useTranslation();
  const { className, onClick } = props;
  const content = props.content as IContent;
  const { name, amount, updatedAt, icon } = content;

  const amountText = t("dollar.amount", "$%<amount>s", {
    amount: formatCommas(amount),
  });

  const wyshName = truncate(name, { length: 14, omission: " ..." });

  const classes = classNames(className, "card-container wysh-updates");

  return (
    <div className={classes} style={props.style} onClick={onClick}>
      <div className="card-content__header-wrapper">
        <Text tag="l5" text={wyshName} className="card-content__body-text" />
      </div>

      <div className="card-content">
        <div className="card-content__wysh--amount">
          <div className="card-content__icon-wrapper">
            <Icon className="card-content__icon" src={icon} size="xs" />
          </div>
          <Text className="card-content__amount-text" tag="p6" text={amountText} />
        </div>
        <div className="card-content__date-wrapper">
          <Text className="card-content__date-text" tag="p6" text={updatedAt} />
        </div>
      </div>
    </div>
  );
};
