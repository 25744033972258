import { gql } from "config/graphql";
import {
  BusinessSlugTypes,
  IInvoice,
  IPaymentMethod,
  IPlaidAccount,
  PaymentMethodSource,
} from "config/types";
import { PLAID_ACCOUNT_FIELDS } from "graphql/fragments";
import { getApolloClient } from "store/apollo";

interface IPlaidLinkTokenResponse {
  data: {
    plaidGenerateLinkToken: {
      linkToken: string;
    };
  };
}

export const getPlaidLinkToken = (): Promise<IPlaidLinkTokenResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation plaidGenerateLinkToken($input: PlaidGenerateLinkTokenInput!) {
      plaidGenerateLinkToken(input: $input) {
        linkToken
      }
    }
  `;

  return client.mutate({
    mutation,
  });
};

interface IExchangePlaidPublicTokenResponse {
  data: {
    plaidExchangePublicToken: {
      plaidAccount: IPlaidAccount;
    };
  };
}

export const exchangePlaidPublicToken = (
  publicToken: string,
  bankAccountId: string,
  lineOfBusiness: BusinessSlugTypes
): Promise<IExchangePlaidPublicTokenResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    ${PLAID_ACCOUNT_FIELDS}
    mutation plaidExchangePublicToken($input: PlaidExchangePublicTokenInput!) {
      plaidExchangePublicToken(input: $input) {
        plaidAccount {
          ...PlaidAccountFields
        }
      }
    }
  `;

  return client.mutate({
    variables: {
      input: {
        publicToken,
        bankAccountId,
        lineOfBusiness,
      },
    },
    mutation,
  });
};

interface ICreatePaymentMethodResponse {
  data: {
    createPaymentMethod: {
      paymentMethod: IPaymentMethod;
    };
  };
}

export const createPaymentMethodMutation = (
  externalId: string,
  source: PaymentMethodSource,
  metadata = {}
): Promise<ICreatePaymentMethodResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation createPaymentMethod($input: CreatePaymentMethodInput!) {
      createPaymentMethod(input: $input) {
        paymentMethod {
          id
          source
          status
          name
          metadata
          externalId
          createdAt
          plaidAccount {
            id
            externalId
            name
            mask
          }
          updatedAt
        }
      }
    }
  `;

  return client.mutate({
    variables: {
      input: {
        externalId,
        source,
        metadata: metadata,
      },
    },
    mutation,
  });
};

export interface ICreatePaymentInput {
  amount: number;
  invoiceId: string;
}

interface ICreatePaymentResponse {
  data: {
    response: {
      success: boolean;
    };
  };
}

export const createPaymentMutation = (
  input: ICreatePaymentInput
): Promise<ICreatePaymentResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation createPayment($input: CreatePaymentInput!) {
      createPayment(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: { input },
    mutation,
  });
};

export interface ICreateMultiplePaymentsInput {
  invoiceIds: IInvoice["id"][];
}

interface ICreateMultiplePaymentsResponse {
  data: {
    createMultiplePayments: {
      payments: {
        billingAttemptedAmount: number;
        processed: {
          invoiceId: IInvoice["id"];
          transactionResult: "success" | "failed" | "processing";
          reason?: string;
        }[];
      };
    };
  };
}

export const createMultiplePaymentsMutation = (
  input: ICreateMultiplePaymentsInput
): Promise<ICreateMultiplePaymentsResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation createMultiplePayments($input: CreateMultiplePaymentsInput!) {
      createMultiplePayments(input: $input) {
        payments {
          billingAttemptedAmount
          processed {
            invoiceId
            reason
            transactionResult
          }
        }
      }
    }
  `;

  return client.mutate({
    variables: { input },
    mutation,
  });
};

interface IInitiatePlaidUpdatePayload {
  data: {
    linkToken: string;
  };
}

export const initiatePlaidUpdateMutation = (
  plaidItemId: string
): Promise<IInitiatePlaidUpdatePayload> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation InitiatePlaidUpdate($input: InitiatePlaidUpdateInput!) {
      initiatePlaidUpdate(input: $input) {
        linkToken
      }
    }
  `;

  return client.mutate({
    variables: {
      input: { plaidItemId },
    },
    mutation,
  });
};

interface IResponseSuccessPayload {
  data: {
    response: {
      success: boolean;
    };
  };
}

export const confirmPlaidUpdateMutation = (
  plaidItemId: string
): Promise<IResponseSuccessPayload> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation ConfirmPlaidUpdate($input: ConfirmPlaidUpdateInput!) {
      confirmPlaidUpdate(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: {
      input: { plaidItemId },
    },
    mutation,
  });
};

interface IUnlinkPlaidResponsePayload {
  data: {
    unlinkPlaidItem: {
      response: {
        success: boolean;
      };
    };
  };
}

export const unlinkAccountMutation = (
  plaidItemId: string
): Promise<IUnlinkPlaidResponsePayload> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation UnlinkPlaidItem($input: UnlinkPlaidItemInput!) {
      unlinkPlaidItem(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: {
      input: { plaidItemId },
    },
    mutation,
  });
};
