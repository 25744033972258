import { ConditionKey } from "config/conditions";
import { ValueOrArray } from "config/types";
import {
  compact,
  defaultTo,
  every,
  filter,
  first,
  flatten,
  flattenDeep,
  get,
  has,
  includes,
  isEmpty,
  isEqual,
  map,
  mapKeys,
  pick,
  uniq,
} from "lodash-es";
import { useCondition } from "modules";
import { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { fetchJourney, setJourneyStep } from "store/actions/journey";

import JourneyStep from "./JourneyStep";

interface IProps {
  id: string;
  active?: boolean;
  metadata?: Record<string, unknown>;
}

const Journey: FC<IProps> = ({ id, active, metadata }) => {
  const journey = useSelector((state: RootState) => state.journey);
  const dispatch = useDispatch();
  const { steps, collection, activeJourneys } = journey;
  const activeStep = steps.active;
  const currentJourney = get(collection, id);
  const currentJourneySteps = get(currentJourney, "steps", []);
  const rootMetadata = metadata || {};
  const currentJourneyMetadata = { ...rootMetadata, ...(currentJourney?.metadata || {}) };
  const journeySteps = pick(steps.collection, currentJourneySteps);
  const stepsConditions = flattenDeep(
    defaultTo(map(journeySteps, "conditions"), []) as ValueOrArray<ConditionKey>
  );
  const filteredConditions = filter(stepsConditions, v => !isEmpty(v));
  const uniqConditions = uniq(filteredConditions) as ConditionKey[];
  const conditions = useCondition(isEmpty(uniqConditions) ? undefined : uniqConditions);
  const indexedSteps = map(journeySteps, (step, index) => ({ index, ...step }));
  const conditionedStepsList = filter(indexedSteps, step => {
    const stepConditions = compact(flatten([step.conditions]));
    const everyConditionMet = every(stepConditions, condition => get(conditions, condition, true));
    const hasNoConditions = isEmpty(step.conditions);
    return !everyConditionMet || hasNoConditions;
  });
  const conditionedSteps = mapKeys(conditionedStepsList, "index");
  const filteredCurrentJourneySteps = filter(currentJourneySteps, step =>
    has(conditionedSteps, step)
  );

  const retrieveJourney = useCallback(() => {
    const isLoaded = get(collection, [id, "status", "isLoaded"], false);

    if (!isLoaded && includes(activeJourneys, id)) {
      dispatch(fetchJourney({ id }));
    }
  }, [activeJourneys, collection, dispatch, id]);

  const setFirstStepAsActive = useCallback(() => {
    if (conditionedSteps) {
      const firstStep = first(filteredCurrentJourneySteps) as string;
      if ((isEmpty(activeStep) || isEqual(activeStep, id)) && !isEmpty(firstStep)) {
        dispatch(setJourneyStep(firstStep));
      }
    }
  }, [activeStep, conditionedSteps, dispatch, filteredCurrentJourneySteps, id]);

  useEffect(() => {
    retrieveJourney();
    setFirstStepAsActive();
  }, [active, retrieveJourney, setFirstStepAsActive]);

  return (
    <div className="journey__container">
      <div className="journey__wrapper">
        {map(conditionedSteps, (step, key) => {
          return (
            <JourneyStep
              step={step}
              key={key}
              active={key === activeStep}
              metadata={currentJourneyMetadata}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Journey;
