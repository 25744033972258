import { ModalContext } from "config/init/providers/ModalsProvider";
import { ModalKey } from "containers/Modals/modals";
import { useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { ModalMetaInput, setModal } from "store/actions/modal";

type ModalMetaPayload = Partial<ModalMetaInput> & Record<string, unknown>;

export const useModal = (
  cb: Record<string, (...args: any[]) => void> = {},
  meta: ModalMetaPayload = {}
) => {
  const context = useContext(ModalContext);
  const dispatch = useDispatch();

  return useCallback(
    (modalId: ModalKey, mtx?: string, data?: Record<string, unknown>) => {
      const payload = { ...meta, ...data };

      context.callbacks = cb;

      dispatch(setModal(modalId, mtx, payload));
    },
    [context, dispatch, meta, cb]
  );
};
