import { push } from "connected-react-router";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "store/actions/modal";

const LoginAttemptWarningModal: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const closeModalWrapper = () => dispatch(closeModal());
  const redirectToResetPassword = () => dispatch(push("/forgot-password"));

  const content = {
    header: t("auth.third_attempt.modal.title", "Wrong email or password"),
    subheader: t(
      "auth.third_attempt.modal.body",
      "Your call, want to try again? Or would you like to reset your password?"
    ),
    onClose: closeModalWrapper,
    button: [
      {
        text: t("auth.third_attempt.modal.primary_button.title", "RESET PASSWORD"),
        subType: "primary",
        onClick: redirectToResetPassword,
      },
      {
        text: t("auth.third_attempt.modal.secondary_button.title", "TRY AGAIN"),
        subType: "text-dark",
        onClick: closeModalWrapper,
      },
    ],
    onButtonClick: closeModalWrapper,
    icon: "BlobAlert",
  };

  return <Layout as="ModalLayout" className="login-attempt-warning-modal" content={content} />;
};

export default LoginAttemptWarningModal;
