import { IneligibleCoverageReasonsType } from "config";
import { gql } from "config/graphql";
import { getApolloClient } from "store/apollo";

interface IAccountCapabilitiesResponse {
  data: {
    me: {
      activeConstellations: {
        activeInsuranceMembership: {
          capabilities: {
            billing: boolean;
            termLifeExtension: boolean;
          };
        };
      }[];
    };
  };
}

export const fetchAccountCapabilities = (): Promise<IAccountCapabilitiesResponse> => {
  const client = getApolloClient();

  const query = gql`
    query accountCapabilities {
      me {
        activeConstellations {
          activeInsuranceMembership {
            capabilities {
              billing
              termLifeExtension
            }
          }
        }
      }
    }
  `;

  return client.query({ query });
};

export interface IIneligibleForCoverageReasonsResponse {
  data: {
    userIneligibleReasons: {
      ineligibleCoverageReasons: IneligibleCoverageReasonsType[];
    };
  };
}

export const fetchIneligibleForCoverageReasons = (): Promise<IIneligibleForCoverageReasonsResponse> => {
  const client = getApolloClient();

  const query = gql`
    query fetchIneligibleForCoverageReasons {
      userIneligibleReasons {
        ineligibleCoverageReasons
      }
    }
  `;

  return client.query({ query });
};
