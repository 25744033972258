import "./Flow.scss";

import { disclosureAnswerSubmittedEvent } from "analytics/fulfillmentEvents";
import FlowQuestions from "components/FlowQuestions/FlowQuestions";
import ScreenLoader from "components/ScreenLoader/ScreenLoader";
import {
  FlowTerminalBehaviorType,
  FlowTypes,
  IAppError,
  IFlowForm,
  IFormInputs,
  isFormDynamicForm,
} from "config/types";
import FlowReview from "containers/FlowReview/FlowReview";
import { useForm, useTranslation } from "modules";
import { FC, FormEvent, ForwardedRef, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store";
import { createFlowResponse, initFormFlow, nextForm, setFormFlowIndex } from "store/actions/flow";
import { getFeatureFlagByName, selectActiveFlowForm, useParamSelector } from "store/selectors";
import { getThunkError } from "utils/error";
import { scrollToTop } from "utils/scrollToTop";

import { useFlowFieldDisclosures } from "./useFlowFieldDisclosures";
import { useFlowFieldTitles } from "./useFlowFieldTitles";

type TConditionalFlowSlugOverridesData = {
  shouldOverride: boolean;
  slug: FlowTypes;
};
type TConditionalFlowSlugOverrides = Partial<Record<FlowTypes, TConditionalFlowSlugOverridesData>>;
type TFlowSlugOverrides = Partial<Record<FlowTypes, FlowTypes>>;

const flowSlugOverrides: TFlowSlugOverrides = {
  "savings-cdd": "cdd-form",
  "savings-default-application": "savings-default-application-v2",
};

export const getFlowSlug = (
  slug: FlowTypes,
  conditionalOverrides: TConditionalFlowSlugOverrides
) => {
  const overrides = (Object.keys(conditionalOverrides) as FlowTypes[]).reduce(
    (memo, overrideKey) => {
      const override = conditionalOverrides[overrideKey];

      if (override?.shouldOverride) {
        memo[overrideKey] = override.slug;
      }

      return memo;
    },
    { ...flowSlugOverrides }
  );

  return overrides[slug] || slug;
};

interface IProps {
  slug: FlowTypes;
  sessionFromStart?: boolean;
  ctaText?: string;
  allValidInputsRequired?: boolean;
  ref?: ForwardedRef<{ onSubmit(event: FormEvent<HTMLFormElement>): void }>;
  terminalCallback(inputs?: IFormInputs): void;
  nextFormCallback?(nextFormResponse?: IAppError): void;
  submitCallback?(inputs?: IFormInputs): void | null;
}

const Flow: FC<IProps> = props => {
  const { t } = useTranslation();
  const {
    slug,
    sessionFromStart,
    ctaText = t("question_container.form.button", "NEXT"),
    allValidInputsRequired = false,
    terminalCallback,
    nextFormCallback = () => {
      return;
    },
    submitCallback = () => {
      return;
    },
  } = props;
  const dispatch = useAppDispatch();
  const fieldTitles = useFlowFieldTitles(slug);
  const fieldDisclosures = useFlowFieldDisclosures(slug);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const [terminalBehavior, setTerminalBehavior] = useState<FlowTerminalBehaviorType>();
  const [flowSessionId, setFlowSessionId] = useState("");
  const { form, inputs } = useForm([], {
    showInputErrorMessages: true,
    allValidInputsRequired,
  });
  const flowForm = useSelector(selectActiveFlowForm) as IFlowForm;
  const isActiveForm = useSelector((state: RootState) => state.formFlow.id === flowForm?.id);
  const isUnderwritingReviewEnabled = useParamSelector(
    getFeatureFlagByName,
    "wysh-insurance-underwriting-review"
  );

  const initFlow = useCallback(async () => {
    setLoading(true);

    const flowSlug = getFlowSlug(slug, {
      "evidence-underwriting-aura-review": {
        shouldOverride: !isUnderwritingReviewEnabled,
        slug: "evidence-underwriting-aura",
      },
    });
    const { flow, flowSession } = await dispatch(
      initFormFlow({ slug: flowSlug, sessionFromStart })
    ).unwrap();

    setFlowSessionId(flowSession.id);
    setTerminalBehavior(flow.terminalBehavior);
    await fetchNextForm(flowSession.id, flow.terminalBehavior);

    setLoading(false);
  }, [dispatch, slug, sessionFromStart]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    initFlow();
  }, [initFlow]); // eslint-disable-line

  const fetchNextForm = async (
    sessionId = flowSessionId,
    flowTerminalBehavior = terminalBehavior
  ) => {
    try {
      const flowForm = await dispatch(nextForm(sessionId)).unwrap();
      nextFormCallback();

      // Terminal behavior for non-internal flows(e.g. Aura)
      const shouldTerminateNonInternalFlow =
        flowTerminalBehavior === "after_response" && flowForm?.terminal;
      if (shouldTerminateNonInternalFlow) {
        terminalCallback();
      }
    } catch (error) {
      setFormError(error.fullMessage);
      nextFormCallback(error);
    } finally {
      form.setLoading(false);
    }
  };

  const onSubmit = async (event?: FormEvent<HTMLFormElement>, formInputs: IFormInputs = inputs) => {
    event?.preventDefault();

    const callbackResult = submitCallback(formInputs);

    if (callbackResult === null) {
      return;
    }

    if (form.valid) {
      form.setLoading(true);
      const response = await dispatch(
        createFlowResponse({ formInputs: formInputs, flowSessionId })
      );

      const thunkError = getThunkError(response);
      if (thunkError) {
        form.setLoading(false);
        setFormError(thunkError.fullMessage);
        form.processServerValidationErrors(thunkError);
        return;
      }

      setFormError("");

      dispatch(disclosureAnswerSubmittedEvent());

      if (flowForm?.terminal) {
        await terminalCallback(formInputs);
      } else if (!isActiveForm) {
        dispatch(setFormFlowIndex("next"));
      } else {
        await fetchNextForm();
      }
      scrollToTop();
      form.setLoading(false);
    }
  };

  if (loading) {
    return <ScreenLoader />;
  }

  if (isFormDynamicForm(flowForm) || !flowForm?.questions?.length) {
    return null;
  }

  return (
    <div className="flow__wrapper" key={flowForm?.id} data-testid={`flow-form__${slug}`}>
      {flowForm?.layout === "review" ? (
        <FlowReview
          form={form}
          formId={flowForm.id}
          inputs={inputs}
          onSubmit={onSubmit}
          prompt={flowForm?.prompt}
          questions={flowForm.questions}
          title={flowForm?.title}
        />
      ) : (
        <FlowQuestions
          slug={slug}
          form={form}
          formError={formError}
          formId={flowForm.id}
          ctaText={ctaText}
          formErrorMessage={form.formErrorMessage}
          inputs={inputs}
          onSubmit={onSubmit}
          questions={flowForm.questions}
          terminalForm={flowForm.terminal}
          fieldTitles={fieldTitles}
          fieldDisclosures={fieldDisclosures}
        />
      )}
    </div>
  );
};

export default Flow;
