/**
 * Interfaces
 */
export interface ITriviaNextQuestion {
  question: ITriviaQuestion;
  questionNumber: number;
}

export interface ITriviaAnswer {
  correct: boolean;
  id: string;
  name: string;
  slug: string;
}

export interface ITriviaQuestion {
  answers: ITriviaAnswer[];
  explanation: string;
  id: string;
  question: string;
  slug: string;
}

/**
 * Types
 */
export type TriviaIntroType = keyof typeof TriviaIntroTypes;

export type TriviaSectionType = keyof typeof TriviaSectionTypes;

/**
 * Enums
 */
enum TriviaIntroTypes {
  inProgress,
  letsPlay,
  rocketship,
}

enum TriviaSectionTypes {
  intro,
  question,
  answer,
}
