import "./CancelAutoDepositModal.scss";

import { autoDepositCancelEvent } from "analytics/savings";
import FormError from "components/FormError/FormError";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { push } from "connected-react-router";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import React, { FC, MouseEvent, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store";
import { archiveTransferSchedule, cancelOnboardingAutoDeposit } from "store/actions";

const CancelAutoDepositModal: FC<IModalProps> = ({ closeModal }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const modalData = useSelector((state: RootState) => state.modal.data);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState("");

  const transferScheduleId = modalData?.transferScheduleId as string;
  const isOnboarding = modalData?.isOnboarding as string;

  const handleCancelAutoDeposit = async (event: MouseEvent) => {
    try {
      event.preventDefault();
      setPending(true);

      if (isOnboarding) {
        await dispatch(cancelOnboardingAutoDeposit()).unwrap();
        dispatch(autoDepositCancelEvent());
        dispatch(push("/products/savings/application-summary"));
      } else {
        await dispatch(archiveTransferSchedule(transferScheduleId)).unwrap();
        dispatch(autoDepositCancelEvent());
        dispatch(push("/products/savings"));
      }
      closeModal();
    } catch (err) {
      setError(err.message);
    } finally {
      setPending(false);
    }
  };

  const content = {
    alwaysShowIcon: true,
    button: [
      {
        disabled: pending,
        isLoading: pending,
        onClick: handleCancelAutoDeposit,
        subType: "primary",
        text: t(
          "savings.autodeposit.setup.remove.confirm.modal.cta.primary",
          "YES, REMOVE AUTO-DEPOSIT"
        ),
      },
      {
        disabled: pending,
        onClick: () => closeModal(),
        subType: "text-dark-border",
        text: t("savings.autodeposit.setup.remove.confirm.modal.cta.secondary", "NEVER MIND"),
      },
    ],
    icon: "BlobSave",
    header: t("savings.autodeposit.setup.remove.confirm.modal.header", "Are you sure?"),
    onClose: closeModal,
    subheader: t(
      "savings.autodeposit.setup.remove.confirm.modal.subheader",
      "With a monthly auto-deposit you can see consistent growth to your savings account thanks to the compounding interest of your deposits - all without ever needing to think about it!"
    ),
  };

  return (
    <Layout as="ModalLayout" className="cancel-auto-deposit__modal" content={content}>
      <FormError className="flex--justify-center" error={error} />
    </Layout>
  );
};

export default CancelAutoDepositModal;
