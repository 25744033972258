import "./ADOfferCard.scss";

import ADOfferLegalese from "components/ADOfferLegalese/ADOfferLegalese";
import OfferCard from "components/OfferCard/OfferCard";
import { IProductOffering } from "config";
import { DateTime } from "luxon";
import { useTranslation } from "modules";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store";
import formatCommas from "utils/formatCommas";
import { eventFired } from "store/actions/analytics";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { setModal } from "store/actions/modal";

interface IProps {
  loading?: boolean;
  mode?: "info" | "purchase";
  productOffering: IProductOffering;
  showPremiumCalculationModal?: boolean;
  onClickCta(): void;
  onClickEdit(): void;
}

const ADOfferCard: FC<IProps> = props => {
  const { t } = useTranslation();
  const {
    productOffering,
    showPremiumCalculationModal = false,
    mode = "info",
    loading = false,
    onClickCta,
    onClickEdit,
  } = props;
  const dispatch = useAppDispatch();

  const totalWyshAllocations = useSelector(
    (state: RootState) => state.allocationSummary.totalAmount
  );

  const premium =
    productOffering.premiums?.find(premium => {
      return premium.billingFrequency === "monthly";
    })?.amount || 0;

  const validUntilDate = DateTime.fromISO(productOffering.expiresAt).toFormat("MM/dd/yyyy");

  const detailsItems = [
    {
      label: t("offer.card.details.coverage.label", "Total Coverage"),
      value: t("offer.ad.card.details.coverage.value", "Up to\n%<coverage>s", {
        coverage: `$${formatCommas(productOffering.amount)}`,
      }),
    },
    {
      label: t("offer.ad.card.details.age.label", "Guaranteed Coverage"),
      value: t("offer.ad.card.details.age.value", "Until you're\n%<age>s y/o", {
        age: productOffering.insuredsAgeAtTerm,
      }),
    },
  ];

  if (totalWyshAllocations > 0) {
    detailsItems.push({
      label: t("offer.card.details.wyshes.label", "Wyshes"),
      value: t("offer.card.details.wyshes.value", "%<percent>s% covered", {
        percent: productOffering.allocationCoverage,
      }),
    });
  }

  const onClickLearnMore = () => {
    onClickCta();

    if (mode === "info") {
      dispatch(
        eventFired({
          event: "fulfillment_quote_ad_learnmore",
        })
      );
    }
  };

  const ctaText =
    mode === "info" ? t("offer.ad.card.cta", "LEARN MORE") : t("ad.about.card.cta", "GET NOW");

  const openPremiumCalculationModal = () => dispatch(setModal("PremiumCalculationModal"));

  return (
    <div className="offer-card__container">
      <OfferCard
        ctaLoading={loading}
        ctaText={ctaText}
        detailsItems={detailsItems}
        onClickCta={onClickLearnMore}
        onClickEdit={onClickEdit}
        premium={premium}
        secondaryCtaText={t("offer.card.cta.edit", "CUSTOMIZE YOUR COVERAGE AND PRICE")}
        theme="light"
        title={t("offer.ad.card.title", "Accidental death policy")}
        validUntilDate={validUntilDate}
      />
      <ADOfferLegalese payoutDetails />
      {showPremiumCalculationModal && (
        <div className="offer-card__ad-info" onClick={openPremiumCalculationModal}>
          <Icon className="offer-card__ad-info-icon" src="info" size="sm" color="cta" />
          <Text
            className="offer-card__ad-info-text"
            tag="l6"
            text={t(
              "offerings_review.premium_calculation.link",
              "Learn how we calculate monthly premiums"
            )}
          />
        </div>
      )}
    </div>
  );
};

export default ADOfferCard;
