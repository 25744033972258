import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { getLatestPayableInvoice } from "./getLatestPayableInvoice";
import { isValidPolicy } from "utils/policy";

const selectPolicy = (state: RootState) => state.policy;
const selectPayment = (state: RootState) => state.payment;

export const getIsMissingPayment = createSelector(
  selectPolicy,
  selectPayment,
  getLatestPayableInvoice,
  (policy, payment, latestPayableInvoice) => {
    const paymentStatus = payment.status;
    const policyValid = isValidPolicy(policy);
    const paymentMissing = !paymentStatus;
    const isInvoicePaid = latestPayableInvoice && latestPayableInvoice.status === "fully_paid";

    return policyValid && (paymentMissing || !isInvoicePaid);
  }
);
