import { IPlaidAccount } from "./plaidItem.types";

/*
..................
~~~ INTERFACES ~~~
..................
*/

export interface IWysh {
  id: string;
  slug: string;
  description: string;
  prompt: string;
  active: boolean;
  guidance: string;
  monitorable: boolean;
  termable: boolean;
  recipientType: WyshAllocationRecipientType;
  icon100100: IWyshIcon;
  iconSelected6464: IWyshIcon;
  iconUnselected6464: IWyshIcon;
  header950400: IWyshIcon;
  header400368: IWyshIcon;
  order: number;
  defaultAmount: number;
  defaultName: string;
  amountPrompt: string;
  amountGuidance: string;
  amountPlaceholder: string;
  monitoringGuidance: string;
  termPrompt: string;
  termGuidance: string;
  termPlaceholder: string;
  wyshSuggestions: IWyshSuggestion[];
  wyshCategory: IWyshCategory;
  isMonitorable?: boolean;
}

export interface IWyshSuggestion {
  id: string;
  title: string;
  amount: number;
  enabled: boolean;
  order: number;
}

export type WyshLiteType = Pick<
  IWysh,
  "id" | "slug" | "prompt" | "order" | "iconSelected6464" | "iconUnselected6464"
>;

export interface IWyshCategory {
  id: string;
  name: string;
  prompt: string;
  slug: string;
  color: string;
  order: number;
  description: string;
  listDescription: string;
  wyshes?: WyshLiteType[];
}

export interface IWyshAllocation {
  id: string;
  amount: number;
  covered: number; // percent
  amountCovered: number;
  percentCovered: number;
  order: number;
  name: string;
  monitored: boolean;
  terminatesAt: string;
  wysh?: IWysh;
  plaidAccount?: Pick<IPlaidAccount, "id" | "name" | "mask">;
  updatedAt: string;
  allocationHistories: IWyshAllocationHistory[];
  recipient?: IWyshAllocationRecipient | null;
}

export interface IWyshIcon {
  formats: {
    contentType: string;
    url: string;
  }[];
}

export interface IWyshAllocationHistory {
  id: string;
  amount: number;
  change: number;
  direction: AllocationHistoryDirections;
  updatedAt: string;
  wyshAllocation: {
    id: string;
    name: string;
  };
}

export interface IWyshAllocationCompany {
  id: string;
  name: string;
  websiteUrl: string;
  __typename: WyshAllocationRecipientTypename;
}

export interface IWyshAllocationIndividual {
  id: string;
  name: string;
  email: string;
  countryCode: string;
  phoneNumber: string;
  relationship: string;
  __typename: WyshAllocationRecipientTypename;
}

export interface IWyshAllocationInstitution {
  id: string;
  name: string;
  countryCode: string;
  phoneNumber: string;
  accountNumber: string;
  __typename: WyshAllocationRecipientTypename;
}

/*
..................
~~~ TYPES ~~~
..................
*/

export type WyshIconTypes = "application/pdf" | "image/svg+xml";

export type WyshEditFieldTypes =
  | "name"
  | "monitor"
  | "accounts"
  | "amount"
  | "term"
  | "recipient"
  | "order";

export type AllocationHistoryDirections = "increased" | "decreased" | "unchanged";

export const ADD_WYSH_FLOW_DEFAULT = "ADD_WYSH_FLOW_DEFAULT";

// Add one Wysh and navigate back to my wyshes.
// Used in add wysh drawer.
export const ADD_WYSH_FLOW_ONE_AT_A_TIME = "ADD_WYSH_FLOW_ONE_AT_A_TIME";

// After adding a Wysh, navigate to next category list allowing user to add multiple wyshes
// from multiple categories in one flow.
// Used in onboarding journey and when navigating from dashboard wysh card.
// ADD_WYSH_FLOW_DEFAULT uses this logic.
export const ADD_WYSH_FLOW_FROM_EACH_CATEGORY = "ADD_WYSH_FLOW_FROM_EACH_CATEGORY";

export enum AddWyshFlowEnum {
  ADD_WYSH_FLOW_DEFAULT,
  ADD_WYSH_FLOW_ONE_AT_A_TIME,
  ADD_WYSH_FLOW_FROM_EACH_CATEGORY,
}

export type AddWyshFlowType = keyof typeof AddWyshFlowEnum;

// Depending on the previous screen when landing on WyshDetails, the back navigation button should
// return to the same screen (either "dashboard" or "wyshes")
export type BackWyshDetailType = "dashboard" | "wyshes";

export type WyshBuilderFlowType = "dashboard" | "wyshes";

export type WyshesCoveredState = "below" | "covered" | "over";

export type WyshAllocationRecipientType = "company" | "individual" | "institution";

export type WyshAllocationRecipientTypename =
  | "WyshAllocationsInstitution"
  | "WyshAllocationsCompany"
  | "WyshAllocationsIndividual";

export type IWyshAllocationRecipient =
  | IWyshAllocationCompany
  | IWyshAllocationIndividual
  | IWyshAllocationInstitution;
