// ModalContent.tsx
import { motion } from "framer-motion";
import classNames from "classnames";
import { IModalProps, IModalTransitionVariants } from "./Modal";
import Icon from "components/Icon/Icon";
import { FC, isValidElement, useMemo } from "react";
import { ClassName } from "config";
import { isEmpty } from "lodash-es";

interface ModalContentProps {
  variants: IModalTransitionVariants;
  className?: ClassName;
  style?: React.CSSProperties;
  headerProps?: IModalProps["headerProps"];
  confirmExitModal: () => void;
}

interface ModalHeaderProps extends NonNullable<IModalProps["headerProps"]> {
  onClick?: () => void;
}

const ModalHeader: FC<ModalHeaderProps> = ({ icon, className, onClick, closeButton }) => {
  const classes = classNames(className);
  const CloseButton = useMemo(() => {
    if (closeButton) {
      if (isValidElement(closeButton)) {
        return closeButton;
      }
      return (
        <button className="modal-header_close-icon" onClick={onClick}>
          <Icon src={icon} size="sm" />
        </button>
      );
    }
  }, [closeButton, icon, onClick]);

  return <div className={classes}>{CloseButton}</div>;
};

export const ModalContent: React.FC<ModalContentProps> = ({
  className,
  style,
  headerProps,
  confirmExitModal,
  children,
  variants,
}) => {
  const classes = classNames("modal-wrapper", className);
  const variantsProps = {
    initial: "hidden",
    animate: "visible",
    exit: "hidden",
    variants,
  };
  return (
    <motion.div style={style} className={classes} {...variantsProps}>
      {!isEmpty(headerProps) && <ModalHeader {...headerProps} onClick={confirmExitModal} />}
      {children}
    </motion.div>
  );
};
