import { createReducer } from "@reduxjs/toolkit";
import { IFinanceBankTransaction } from "config/types";
import { keyBy } from "lodash-es";
import { logout } from "store/actions/authentication";
import { fetchFinanceBankTransactions } from "store/actions/financeBankAccount";

interface IFinanceBankTransactionsState {
  ids: IFinanceBankTransaction["externalId"][];
  byId: Record<IFinanceBankTransaction["externalId"], IFinanceBankTransaction>;
}

/* ------------    FINANCE_BANK_TRANSACTIONS STATE     ------------------ */
const defaultState: IFinanceBankTransactionsState = {
  ids: [],
  byId: {},
};

/* ------------    REDUCER     ------------------ */
const financeBankTransactions = createReducer(defaultState, builder => {
  builder
    .addCase(fetchFinanceBankTransactions.fulfilled, (state, action) => {
      const financeBankTransactions = action.payload;

      state.ids = financeBankTransactions.map(transaction => transaction.externalId);
      state.byId = keyBy(financeBankTransactions, "externalId");
    })
    .addCase(logout, () => defaultState);
});

export default financeBankTransactions;
