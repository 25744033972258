import "./WyshCategoryList.scss";

import classNames from "classnames";
import { AddWyshCounterCard } from "components/Cards";
import { countBy, map, sortBy } from "lodash-es";
import { FC, MouseEvent, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { WyshCategoryWyshes } from "store/reducers/wyshCategories";

interface IProps {
  wyshes: WyshCategoryWyshes[];
  fadeIn?: boolean;
  showWyshTotal?: boolean;
  onClick?(wyshId: string): void;
}

const WyshCategoryList: FC<IProps> = props => {
  const { wyshes, onClick, fadeIn, showWyshTotal } = props;
  const { allocated } = useSelector((state: RootState) => state.wyshAllocations);

  const wyshAllocationTotal = useMemo(() => countBy(allocated, "wysh.id"), [allocated]);

  const className = classNames("wysh-category__list", {
    "fade-in": fadeIn,
  });

  const orderedWyshes = sortBy(wyshes, "order");

  return (
    <div className={className}>
      {map(orderedWyshes, wysh => {
        const onClickHandler = (event: MouseEvent) => {
          event.preventDefault();
          onClick && onClick(wysh.id);
        };

        const wyshTotal = wyshAllocationTotal[wysh.id] || 0;

        return (
          <AddWyshCounterCard
            className="wysh-category__add-wyshes-item"
            content={{
              prompt: wysh.prompt,
              icons: { selected: wysh.iconSelected, unselected: wysh.iconUnselected },
              slug: wysh.slug,
              onClick: onClickHandler,
              total: showWyshTotal ? wyshTotal : undefined,
            }}
            key={wysh.id}
          />
        );
      })}
    </div>
  );
};

export default WyshCategoryList;
