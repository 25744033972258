import { gql } from "config/graphql";
import { getApolloClient } from "store/apollo";

type Acknowledgement = "InsuranceApplication" | "InsuranceMembership" | "FinanceMembership";

export interface ICreatAcknowledgementInput {
  recordId: string;
  recordType: "disclosure" | "document";
  contextId?: string;
  contextType?: Acknowledgement;
}

interface ICreatAcknowledgementResponse {
  data: {
    createAcknowledgement: {
      acknowledgement: {
        id: string;
      };
    };
  };
}

export const createAcknowledgementMutation = (
  acknowledgement: ICreatAcknowledgementInput
): Promise<ICreatAcknowledgementResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation createAcknowledgement($input: CreateAcknowledgementInput!) {
      createAcknowledgement(input: $input) {
        acknowledgement {
          id
        }
      }
    }
  `;

  return client.mutate({
    variables: { input: acknowledgement },
    mutation,
  });
};
