import { Environment } from "config/types";
import { defaultTo, isEmpty } from "lodash-es";
import { isLocal } from "utils/isLocal";
import isUrl from "utils/isUrl";

import { env, REACT_APP_ENV } from ".";

export const url = (constant: string): string => {
  const isTargetValidUrl = isUrl(constant);
  if (!isTargetValidUrl) return "";

  const targetUrl = new URL(constant);
  return targetUrl.toString();
};

export const local = (constant: string): string => {
  const environment = (isEmpty(env)
    ? defaultTo(process.env.REACT_APP_ENV, REACT_APP_ENV)
    : env) as Environment;
  const localEnvironment = isLocal(window.location.origin, environment);
  if (localEnvironment) return constant;
  else return "";
};
