import "./QuoteHeader.scss";

import classNames from "classnames";
import Text from "components/Text/Text";
import { BillingFrequency, ClassName, IProductOffering } from "config/types";
import { useTranslation } from "modules";
import { FC } from "react";
import { CoverageType } from "router/CoverageEditorRoute/CoverageEditorRoute";
import { IEstimateState } from "store/reducers/estimate";
import { IQuoteState } from "store/reducers/quote";
import formatCommas from "utils/formatCommas";
import { toFixed } from "utils/toFixed";

interface IProps {
  billingFrequency: BillingFrequency;
  quote: IQuoteState | IEstimateState | IProductOffering;
  className?: ClassName;
  quoteType?: CoverageType;
  hideCoverage?: boolean;
  showWishes?: boolean;
}

const QuoteHeader: FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const { quote, className, billingFrequency, hideCoverage, showWishes, quoteType } = props;

  const wyshesCovered = Math.floor(quote.allocationsCoveredPercentage);
  const coverageWithCommas = formatCommas(quote.amount);
  const quotePerMonth =
    quoteType === "estimate" ? Math.floor(+quote.monthlyPremium) : toFixed(quote.monthlyPremium);
  const activeCoverage =
    billingFrequency === "monthly" ? quotePerMonth : toFixed(quote.annualPremium);
  const coveragePerDate =
    billingFrequency === "monthly"
      ? t("quote_review.per_month", "/MO")
      : t("quote_review.per_year", "/YR");

  const classes = classNames("quote-header appear-hidden@large", className, {
    "hide-coverage": hideCoverage,
    "show-wishes": hideCoverage && showWishes,
  });

  return (
    <div className={classes}>
      <div className="quote-header__wrapper">
        <div className="quote-header__coverage-wrapper quote-header__content-wrapper">
          <Text
            className="quote-header__coverage quote-header__content color-primary"
            tag="c5"
            text={t("quote_review.estimate", "$%<estimate>s", { estimate: coverageWithCommas })}
          />
          <Text
            className="color-primary-light"
            tag="c5"
            text={t("quote_review.banner.coverage", "COVERAGE")}
          />
        </div>

        <div className="quote-header__estimate-wrapper quote-header__content-wrapper">
          <Text
            className="quote-header__estimate quote-header__content color-primary"
            tag="c5"
            text={t("quote_review.estimate", "$%<estimate>s", { estimate: activeCoverage })}
          />
          <Text className="color-primary-light" tag="c5" text={coveragePerDate} />
        </div>

        <div className="quote-header__wishes-wrapper quote-header__content-wrapper">
          <Text
            className="quote-header__wishes quote-header__content color-primary"
            tag="c5"
            text={t("percentage.amount", "%<percentage>s%", { percentage: wyshesCovered })}
          />
          <Text
            className="color-primary-light"
            tag="c6"
            text={t("coverage_editor.wyshes.covered", "WYSHES COVERED")}
          />
        </div>
      </div>
    </div>
  );
};

export default QuoteHeader;
