import { gql } from "config/graphql";
import { GQLUserResponse, IWysh, IWyshCategory } from "config/types";
import { RECIPIENT_FIELDS, WYSH_ALLOCATION_FIELDS, WYSH_FIELDS } from "graphql/fragments";
import { getApolloClient } from "store/apollo";

interface IWyshItemsResponse {
  data: {
    wyshes: IWysh[];
  };
}

export const fetchWyshItemsQuery = (): Promise<IWyshItemsResponse> => {
  const client = getApolloClient();

  const query = gql`
    ${WYSH_FIELDS}
    query WyshItems {
      wyshes {
        ...WyshFields
      }
    }
  `;

  return client.query({
    fetchPolicy: "cache-first",
    query,
  });
};

interface IWyshItemResponse {
  data: {
    wysh: IWysh;
  };
}

export const fetchWyshItemQuery = (slug: string): Promise<IWyshItemResponse> => {
  const client = getApolloClient();

  const query = gql`
    ${WYSH_FIELDS}
    query wysh($slug: String!) {
      wysh(slug: $slug) {
        ...WyshFields
      }
    }
  `;

  return client.query({
    fetchPolicy: "cache-first",
    variables: { slug },
    query,
  });
};

interface IWyshCategoriesResponse {
  data: {
    wyshCategories: IWyshCategory[];
  };
}

export const fetchWyshCategoriesQuery = (): Promise<IWyshCategoriesResponse> => {
  const client = getApolloClient();

  const query = gql`
    query WyshCategories {
      wyshCategories {
        id
        name
        prompt
        slug
        color
        order
        description
        listDescription
        wyshes {
          id
          slug
          prompt
          order
          iconSelected6464 {
            formats {
              contentType
              url
            }
          }
          iconUnselected6464 {
            formats {
              contentType
              url
            }
          }
        }
      }
    }
  `;

  return client.query({
    fetchPolicy: "cache-first",
    query,
  });
};

export const fetchWyshAllocationsQuery = (): Promise<GQLUserResponse<"activeConstellations">> => {
  const client = getApolloClient();

  const query = gql`
    ${RECIPIENT_FIELDS}
    ${WYSH_ALLOCATION_FIELDS}
    query WyshAllocations {
      me {
        activeConstellations {
          wyshAllocations {
            ...WyshAllocationFields
            allocationHistories {
              id
              amount
              change
              direction
              createdAt
              updatedAt
              wyshAllocation {
                name
              }
            }
          }
        }
      }
    }
  `;

  return client.query({ query });
};

export const fetchWyshAllocationsSummaryQuery = (): Promise<GQLUserResponse<
  "activeConstellations"
>> => {
  const client = getApolloClient();

  const query = gql`
    query WyshAllocationsSummary {
      me {
        activeConstellations {
          allocationSummary {
            activeCount
            totalAmount
            percentCovered
          }
        }
      }
    }
  `;

  return client.query({ query });
};
