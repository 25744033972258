import { createSelector } from "@reduxjs/toolkit";
import { find } from "lodash-es";
import { RootState } from "store";

const getRequestState = (state: RootState) => state.request;

export const getIsRequestPendingByResource = createSelector(
  [getRequestState, (_state: RootState, resourceName: string) => resourceName],
  (requestState, resourceName) => {
    return !!find(requestState.requests, resource => resource === resourceName);
  }
);
