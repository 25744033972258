import { useEffect } from "react";

import { useFinishFlow } from "../useButtonCommand/useFinishFlow";

export function TerminalLayout() {
  const finishFlowCommand = useFinishFlow();

  useEffect(() => {
    finishFlowCommand(undefined, {});
  }, [finishFlowCommand]);

  return null;
}
