import DateLabel from "components/DateLabel/DateLabel";
import Text from "components/Text/Text";
import { ISO8601Date } from "config";
import { useTranslation } from "modules";
import { FC } from "react";

interface IProps {
  amount: {
    description: string;
    value: string;
    label?: string;
  };
  billing?: {
    description?: string;
    date: ISO8601Date;
  };
  detailLabel?: string;
}

export const HomeCardOwned: FC<IProps> = ({ amount, billing, detailLabel }) => {
  const { t } = useTranslation();

  const billingLabel =
    billing?.description || t("product.owned.insurance.next_billing", "Next Billing Date");

  return (
    <div className="home-product-owned-card">
      <div className="content-column">
        <Text className="color-secondary-text" tag="l5-label" text={amount.description} />
        <Text className="color-primary-dark" tag="l5-label" text={amount.label} />
        <Text className="color-primary-dark" tag="n6-number" text={amount.value} />
        <Text className="color-secondary-text" tag="l5-label" text={detailLabel} />
      </div>
      <div className="content-column">
        {!!billing && (
          <>
            <Text className="color-secondary-text" tag="l5-label" text={billingLabel} />
            <DateLabel textTag="l5-label" date={billing.date} />
          </>
        )}
      </div>
    </div>
  );
};
