import Currency from "components/Currency/Currency";
import FormError from "components/FormError/FormError";
import {
  ProductOfferingColumn,
  ProductOfferingContainer,
  ProductOfferingDetails,
  ProductOfferingDetailsHeader,
  ProductOfferingDetailsItem,
  ProductOfferingHeader,
  ProductOfferingPremiumCalculation,
  ProductOfferingPremiumInfo,
  ProductOfferingSubheader,
  ProductOfferingValueProps,
} from "components/ProductOffering/ProductOffering";
import { IReinstateablePolicy } from "config";
import { defaultTo } from "lodash-es";
import { Layout, useJourney, useTranslation } from "modules";
import { MouseEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { setShowJourneyProgress } from "store/actions/journey";
import { setModal } from "store/actions/modal";
import { acceptProductOffering } from "store/actions/product-offerings";
import { getQuote } from "store/actions/quote";
import { selectReinstatementProductOfferingByPolicyId, useParamSelector } from "store/selectors";
import { openChat } from "utils/customerSupport";

function CaseResultTermReinstatement() {
  const dispatch = useAppDispatch();
  const { nextStepCallback } = useJourney();
  const { t } = useTranslation();

  const policyId = useAppSelector(
    state =>
      state.journey.collection.reinstatementFulfillment?.metadata
        ?.reinstateablePolicyId as IReinstateablePolicy["id"]
  );
  const productOffering = useParamSelector(selectReinstatementProductOfferingByPolicyId, policyId);

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const premiumAmount = productOffering?.premiums?.find(premium => {
    return premium.billingFrequency === "monthly";
  })?.amount;

  const handleReinstatementAcceptance = async () => {
    if (!productOffering) {
      return;
    }

    try {
      setIsLoading(true);

      if (productOffering.status !== "accepted") {
        await dispatch(acceptProductOffering(productOffering.id)).unwrap();
      }

      await dispatch(getQuote()).unwrap();

      nextStepCallback()();
    } catch (err) {
      setError(defaultTo(err.fullMessage, err.code));
    } finally {
      setIsLoading(false);
    }
  };

  const handleChatClick = (event: MouseEvent<HTMLParagraphElement | HTMLHeadingElement>) => {
    event.stopPropagation();

    openChat();
  };

  const handlePremiumCalculationModalOpen = () => dispatch(setModal("PremiumCalculationModal"));

  useEffect(() => {
    dispatch(setShowJourneyProgress(true));
  }, [dispatch]);

  return (
    <Layout as="TLProductOfferingLayout" backNav={{ hasBackNav: true }}>
      <ProductOfferingContainer className="tl-product-offering-layout__product-offering">
        <ProductOfferingColumn className="product-offering__column">
          <ProductOfferingHeader className="product-offering__header">
            {t("review_quotev2.term.header.title.reinstatement", "You're approved...again!")}
          </ProductOfferingHeader>
          <ProductOfferingSubheader className="product-offering__subheader">
            {t(
              "review_quotev2.term.header.subtitle.reinstatement",
              "Congratulations! Your reinstatement for Term Life Insurance has been approved. "
            )}
          </ProductOfferingSubheader>

          <ProductOfferingPremiumInfo
            className="product-offering__premium-info"
            cta={{
              onClick: handleReinstatementAcceptance,
              text: t("review_quotev2.term.offer.cta.reinstatement", "REINSTATE THIS POLICY"),
            }}
            expiresAt={productOffering?.expiresAt}
            isLoading={isLoading}
            premiumAmount={premiumAmount}
          />

          {!!error && <FormError error={error} />}

          <ProductOfferingDetails className="product-offering__details">
            <ProductOfferingDetailsHeader />

            <ProductOfferingDetailsItem
              description={t(
                "review_quotev2.term.offer.info.coverage.description",
                "Total coverage"
              )}
              value={
                productOffering?.amount ? (
                  <Currency amount={productOffering?.amount} fixedPoints={0} />
                ) : (
                  t("dollar.amount", "$%<amount>s", { amount: "-----" })
                )
              }
            />
            <ProductOfferingDetailsItem
              description={t("review_quotev2.term.offer.info.term.description", "Until you're")}
              value={t("review_quotev2.term.offer.info.term.value", "%<number>s y/o", {
                number: productOffering?.insuredsAgeAtTerm || "--",
              })}
            />
            <ProductOfferingDetailsItem
              description={t(
                "review_quotev2.term.offer.info.missed_payments_amount.description",
                "Total missed payments"
              )}
              value={
                productOffering?.totalMissedPayments ? (
                  <Currency amount={productOffering?.totalMissedPayments} fixedPoints={2} />
                ) : (
                  "$--.--"
                )
              }
            />
            <ProductOfferingDetailsItem
              description={t(
                "review_quotev2.term.offer.info.interest_due.description",
                "Interest due"
              )}
              value={
                productOffering?.interestDue ? (
                  <Currency amount={productOffering?.interestDue} fixedPoints={2} />
                ) : (
                  "$--.--"
                )
              }
            />
            <ProductOfferingDetailsItem
              description={t("review_quotev2.term.offer.info.due_today.description", "Due today")}
              value={
                productOffering?.dueToday ? (
                  <Currency amount={productOffering?.dueToday} fixedPoints={2} />
                ) : (
                  "$--.--"
                )
              }
            />
          </ProductOfferingDetails>

          <ProductOfferingPremiumCalculation
            className="product-offering__premium-calculation"
            onClick={handlePremiumCalculationModalOpen}
          />
        </ProductOfferingColumn>

        <ProductOfferingColumn className="product-offering__column product-offering__column--sidebar">
          <ProductOfferingValueProps
            className="product-offering__value-props"
            isReinstateablePolicy
            onChatClick={handleChatClick}
          />
        </ProductOfferingColumn>
      </ProductOfferingContainer>
    </Layout>
  );
}

export default CaseResultTermReinstatement;
