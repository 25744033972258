import "../HonestyPledge/HonestyPledge.scss";

import {
  bifurcatedProductDisplayedEvent,
  bifurcatedProductNextEvent,
} from "analytics/productOfferEvents";
import Button from "components/Button/Button";
import Text from "components/Text/Text";
import { push } from "connected-react-router";
import { Layout, useJourney } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC, useEffect } from "react";
import { useAppDispatch } from "store";

const HonestyPledgeAD: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { nextStepCallback, previousStepCallback } = useJourney();

  const handleBack = async () => {
    const previousAction = previousStepCallback();
    previousAction();
  };

  const handleAccept = () => {
    dispatch(bifurcatedProductNextEvent("ad"));

    nextStepCallback(() => {
      dispatch(push("/flow/application"));
    }, false)();
  };

  useEffect(() => {
    dispatch(bifurcatedProductDisplayedEvent("ad"));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout
      as="MessageLayout"
      content={{}}
      backNav={{
        hasBackNav: false,
        replaceExitInJourneyWithBack: true,
        onClick: handleBack,
      }}>
      <div className="honesty-pledge" data-testId="honesty-pledge-ad">
        <div className="honesty-pledge__content">
          <Text
            tag="h5-header"
            className="honesty-pledge__subheader"
            text={t(
              "honesty_pledge_v2.ad.header",
              "2. You might also get an offer for Accidental Death Insurance"
            )}
          />
          <Text
            tag="p3"
            className="honesty-pledge__agreement-content"
            text={t(
              "honesty_pledge_v2.ad.body",
              "Term Life Insurance is our favorite, but it’s not right for everyone. So we’re excited to offer Accidental Death Insurance for those who may need a different option due to health, lifestyle, or budget restrictions.\n\nYou can apply knowing that Wysh will have a policy for you."
            )}
          />
        </div>

        <Button
          className="full-width"
          text={t("honesty_pledge_v2.ad.cta", "NEXT")}
          onClick={handleAccept}
          dataTestId="honesty-pledge-bifurcated__cta"
        />
      </div>
    </Layout>
  );
};

export default HonestyPledgeAD;
