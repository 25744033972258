export type PollResponse<T = Record<string, unknown>> = {
  resolved: boolean;
  context: T;
};

type PollInput = boolean | PollResponse | void;

const formatResponse = <T>(responseInput: PollInput) => {
  const resolved = typeof responseInput === "object" ? responseInput?.resolved : !!responseInput;
  const context = (typeof responseInput === "object" ? responseInput?.context : {}) as T;

  return { resolved, context };
};

export const poll = async <T>(
  cb: () => Promise<PollInput>,
  maxAttempts = 15,
  intervalInSeconds = 2
) => {
  let count = 1;

  const response = await cb();
  const resolved = typeof response === "object" ? response?.resolved : !!response;

  return new Promise<PollResponse<T>>(resolve => {
    if (resolved) return resolve(formatResponse<T>(response));

    const interval = setInterval(async function() {
      count++;
      const response = await cb();
      const resolved = typeof response === "object" ? response?.resolved : !!response;

      if (resolved || count >= maxAttempts) {
        clearInterval(interval);
        return resolve(formatResponse<T>(response));
      }
    }, intervalInSeconds * 1000);
  });
};
