import { createAction } from "@reduxjs/toolkit";
import { PaymentMethodSource } from "config";
import { EventDefinition } from "redux-beacon";
import { RootState } from "store";
import { IEventFiredPayload } from "store/actions/analytics";
import * as actionType from "store/constants";

import { getDefaultAttrs, getProductOfferingAttr, getProductSelectedAttr } from "./defaults";

// EVENTS added 02/01/2022, updated 09/23/2022
// Analytic events for billing

/* ------------------------    EVENT ACTION     ------------------------------ */

// User selects a payment method for their policy purchase
export const linkPaymentEvent = createAction<PaymentMethodSource>(actionType.BILLING_LINK_PAYMENT);

// User clicks the "Link another account instead" CTA on CC entry screen
export const billingBackEvent = createAction(actionType.BILLING_BACK);

// User successfully links an account via Plaid (API success)
export const billingPlaidLinkEvent = createAction(actionType.BILLING_PLAID_LINK);

// User submits their payment via Stripe
export const billingCCSubmitEvent = createAction(actionType.BILLING_CC_SUBMIT);

// User's submits their payment via stripe, but something fails
export const billingCCFailureEvent = createAction<{ reason?: string }>(
  actionType.BILLING_CC_FAILURE
);

// User edits their payment method on the PaymentReview screen
export const billingEditPaymentEvent = createAction<{
  paymentMethod: PaymentMethodSource;
  hasPaymentMethod: boolean;
}>(actionType.BILLING_EDIT_PAYMENT);

// User submits their payment via Plaid (API success)
export const billingPlaidSubmitEvent = createAction(actionType.BILLING_PLAID_SUBMIT);

// User views the Payment complete screen
export const billingCompleteEvent = createAction(actionType.BILLING_COMPLETE);

// User clicks the CTA on the Payment complete screen
export const billingCompletedConfirmEvent = createAction(actionType.BILLING_COMPLETE_CONFIRMATION);

// User edits the Payment method in Settings
export const settingsEditPaymentEvent = createAction<PaymentMethodSource>(
  actionType.SETTINGS_EDIT_PAYMENT
);

// User unlinks a Plaid account in settings
export const settingsUnlinkAccountEvent = createAction(actionType.SETTINGS_UNLINK_BANK_ACCOUNT);

// User clicks the "Cancel my policy" button in Settings
export const settingCancelPolicyEvent = createAction(actionType.SETTINGS_CANCEL_POLICY);

// User lands on billing page during fulfillment
export const billingDisplayedEvent = createAction(actionType.BILLING_DISPLAYED);

// User looks at their policy document in onboarding
export const policyDocClickEvent = createAction(actionType.POLICY_DOCUMENT_LINK_CLICK);

// User lands on payment-policy-review screen (PurchasePolicy)
export const paymentPolicyDisplayedEvent = createAction(actionType.PAYMENT_POLICY_DISPLAYED);

// User lands on the Dashboard and their policy has past due invoices
export const dashboardBillingIssueDisplayedEvent = createAction(
  actionType.DASHBOARD_BILLING_ISSUE_DISPLAYED
);

// User lands on the Coverage screen and their policy has past due invoices
export const policyBillingIssueDisplayedEvent = createAction(
  actionType.POLICY_BILLING_ISSUE_DISPLAYED
);

// User has past due invoices that they retry and the payment fails
export const paymentFailureModalDisplayedEvent = createAction(
  actionType.PAYMENT_FAILURE_MODAL_DISPLAYED
);

// User has past due invoices that they retry and the payment is processing
export const paymentProcessingModalDisplayedEvent = createAction(
  actionType.PAYMENT_PROCESSING_MODAL_DISPLAYED
);

// User has past due invoices that they retry and the payment suceeds
export const paymentSuccessModalDisplayedEvent = createAction(
  actionType.PAYMENT_SUCCESS_MODAL_DISPLAYED
);

// User lands on the Settings/Billing screen
export const settingsBillingDisplayedEvent = createAction(actionType.SETTINGS_BILLING_DISPLAYED);

// User lands on the Edit CC screen screen
export const settingsBillingEditCCDisplayedEvent = createAction(
  actionType.SETTINGS_BILLING_EDIT_CC_DISPLAYED
);

// User clicks on the Link Bank Account button on the Settings/Billing screen
export const settingsBillingEditBankAccountDisplayedEvent = createAction(
  actionType.SETTINGS_BILLING_EDIT_BANK_ACCOUNT_DISPLAYED
);

/* ------------------------    EVENT DEFINITION     ------------------------------ */

const billingLinkPayment = (
  action: { [key: string]: PaymentMethodSource },
  prevState: RootState,
  nextState: RootState
): IEventFiredPayload => {
  const defaultAttrs = getDefaultAttrs(nextState, "ONBOARDING");
  const paymentMethod = action?.payload;
  const policyPremium = prevState.policy.id
    ? prevState.policy.activePlan.amount
    : prevState.estimate.monthlyPremium;
  const policyAmount = prevState.policy.amount || prevState.estimate.amount;
  const policyTerm = prevState.policy.termDuration || prevState.estimate.termDuration;

  return {
    event: "fulfillment_billing_link_payment",
    attribute: {
      ...defaultAttrs,
      paymentMethod,
      policyPremium,
      policyAmount,
      policyTerm,
    },
  };
};

const billingBack: EventDefinition<IEventFiredPayload, { [key: string]: any }, RootState> = (
  _,
  prevState
): IEventFiredPayload => {
  const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");

  return {
    event: "fulfillment_billing_cc_back",
    attribute: {
      ...defaultAttrs,
    },
  };
};

const billingPlaidSuccess: EventDefinition<
  IEventFiredPayload,
  { [key: string]: PaymentMethodSource },
  RootState
> = (_, prevState): IEventFiredPayload => {
  const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");

  return {
    event: "fulfillment_billing_plaid_success",
    attribute: {
      ...defaultAttrs,
    },
  };
};

const billingCCSubmit: EventDefinition<IEventFiredPayload, { [key: string]: any }, RootState> = (
  _,
  prevState
): IEventFiredPayload => {
  const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");

  return {
    event: "fulfillment_billing_cc_submit",
    attribute: {
      ...defaultAttrs,
    },
  };
};

const billingCCFailureEventDefinition: EventDefinition<
  IEventFiredPayload,
  { [key: string]: { reason?: string } },
  RootState
> = ({ payload }, prevState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");

  return {
    event: "fulfillment_payment_fail",
    attribute: {
      ...defaultAttrs,
      errorReason: payload.reason,
    },
  };
};

const billingEditPayment: EventDefinition<
  IEventFiredPayload,
  { [key: string]: { paymentMethod: PaymentMethodSource; hasPaymentMethod: boolean } },
  RootState
> = (action, prevState): IEventFiredPayload | any => {
  const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");
  const paymentMethod = action?.payload.paymentMethod;
  const hasNoExistingPaymentMethod = !action?.payload.hasPaymentMethod;

  // If user has no existing payment method, skip event trigger
  if (hasNoExistingPaymentMethod) {
    return null;
  }

  return {
    event: "fulfillment_billing_edit_payment",
    attribute: {
      ...defaultAttrs,
      paymentMethod,
    },
  };
};

const billingPlaidSubmit: EventDefinition<IEventFiredPayload, { [key: string]: any }, RootState> = (
  _,
  prevState
): IEventFiredPayload => {
  const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");

  return {
    event: "fulfillment_billing_plaid_submit",
    attribute: {
      ...defaultAttrs,
    },
  };
};

const billingComplete: EventDefinition<IEventFiredPayload, { [key: string]: any }, RootState> = (
  _,
  prevState
): IEventFiredPayload => {
  const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");
  const paymentMethod = prevState.payment.source;
  const policyPremium = prevState.policy.activePlan.amount;
  const policyAmount = prevState.policy.amount;
  const policyTerm = prevState.policy.termDuration;

  return {
    event: "fulfillment_billing_completed_display",
    attribute: {
      ...defaultAttrs,
      paymentMethod,
      policyPremium,
      policyAmount,
      policyTerm,
    },
  };
};

const billingCompletedConfirm: EventDefinition<
  IEventFiredPayload,
  { [key: string]: any },
  RootState
> = (_, prevState): IEventFiredPayload => {
  const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");
  const paymentMethod = prevState.payment.source;
  const policyPremium = prevState.policy.activePlan.amount;
  const policyAmount = prevState.policy.amount;
  const policyTerm = prevState.policy.termDuration;

  return {
    event: "fulfillment_billing_completed_button",
    attribute: {
      ...defaultAttrs,
      paymentMethod,
      policyPremium,
      policyAmount,
      policyTerm,
    },
  };
};

const settingsEditPayment: EventDefinition<
  IEventFiredPayload,
  { [key: string]: PaymentMethodSource },
  RootState
> = (action, prevState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "DASHBOARD");
  const paymentMethod = action?.payload;

  return {
    event: "settings_billing_edit_payment",
    attribute: {
      ...defaultAttrs,
      paymentMethod,
    },
  };
};

const settingsUnlinkAccount: EventDefinition<
  IEventFiredPayload,
  { [key: string]: any },
  RootState
> = (_, prevState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "DASHBOARD");
  const paymentMethod: PaymentMethodSource = "bank_account";

  return {
    event: "settings_linkedaccount_unlink",
    attribute: {
      ...defaultAttrs,
      paymentMethod,
    },
  };
};

const settingCancelPolicy: EventDefinition<
  IEventFiredPayload,
  { [key: string]: any },
  RootState
> = (_, prevState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "DASHBOARD");
  const paymentMethod = prevState.payment.source;
  const policyPremium = prevState.policy.activePlan.amount;
  const policyAmount = prevState.policy.amount;
  const policyTerm = prevState.policy.termDuration;

  return {
    event: "settings_billing_cancel",
    attribute: {
      ...defaultAttrs,
      paymentMethod,
      policyPremium,
      policyAmount,
      policyTerm,
    },
  };
};

const billingDisplayedEventDefinition: EventDefinition<
  IEventFiredPayload,
  { [key: string]: any },
  RootState
> = (_, prevState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");
  const productOfferingAttr = getProductOfferingAttr(prevState);

  return {
    event: "fulfillment_billing_displayed",
    attribute: {
      ...defaultAttrs,
      productOffering: productOfferingAttr,
    },
  };
};

const policyDocClickEventDefinition: EventDefinition<
  IEventFiredPayload,
  { [key: string]: any },
  RootState
> = (_, prevState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");
  const applicationId = prevState.insuranceApplication.id;
  const productSelected = getProductSelectedAttr(prevState);

  return {
    event: "fulfillment_policy_document_click",
    attribute: {
      ...defaultAttrs,
      applicationId,
      productSelected,
    },
  };
};

const paymentPolicyDisplayedEventDefinition: EventDefinition<
  IEventFiredPayload,
  { [key: string]: any },
  RootState
> = (_, prevState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");
  const applicationId = prevState.insuranceApplication.id;
  const productSelected = getProductSelectedAttr(prevState);

  return {
    event: "fulfillment_pay_policy_pageview",
    attribute: {
      ...defaultAttrs,
      applicationId,
      productSelected,
    },
  };
};

const getBillingIssueDisplayedEventDefinition = (eventName: string) => {
  return (_: any, prevState: RootState) => {
    const defaultAttrs = getDefaultAttrs(prevState, "DASHBOARD");
    const missedPaymentNum = prevState.invoice.pastDueIds.length;
    const productSelected = getProductSelectedAttr(prevState);

    return {
      event: eventName,
      attribute: {
        ...defaultAttrs,
        missedPaymentNum,
        productSelected,
      },
    };
  };
};

const getDefaultBillingDisplayedEventDefinition = (eventName: string) => {
  return (_: any, prevState: RootState) => {
    const defaultAttrs = getDefaultAttrs(prevState, "DASHBOARD");
    const productSelected = getProductSelectedAttr(prevState);

    return {
      event: eventName,
      attribute: {
        ...defaultAttrs,
        productSelected,
      },
    };
  };
};

/* ------------------------    EVENT MAP    ------------------------------ */

export const billingEventsMap = {
  [actionType.BILLING_LINK_PAYMENT]: billingLinkPayment,
  [actionType.BILLING_BACK]: billingBack,
  [actionType.BILLING_PLAID_LINK]: billingPlaidSuccess,
  [actionType.BILLING_PLAID_SUBMIT]: billingPlaidSubmit,
  [actionType.BILLING_CC_SUBMIT]: billingCCSubmit,
  [actionType.BILLING_CC_FAILURE]: billingCCFailureEventDefinition,
  [actionType.BILLING_EDIT_PAYMENT]: billingEditPayment,
  [actionType.BILLING_COMPLETE]: billingComplete,
  [actionType.BILLING_COMPLETE_CONFIRMATION]: billingCompletedConfirm,
  [actionType.SETTINGS_EDIT_PAYMENT]: settingsEditPayment,
  [actionType.SETTINGS_UNLINK_BANK_ACCOUNT]: settingsUnlinkAccount,
  [actionType.SETTINGS_CANCEL_POLICY]: settingCancelPolicy,
  [actionType.BILLING_DISPLAYED]: billingDisplayedEventDefinition,
  [actionType.POLICY_DOCUMENT_LINK_CLICK]: policyDocClickEventDefinition,
  [actionType.PAYMENT_POLICY_DISPLAYED]: paymentPolicyDisplayedEventDefinition,
  [actionType.DASHBOARD_BILLING_ISSUE_DISPLAYED]: getBillingIssueDisplayedEventDefinition(
    "dashboard_billing_issue"
  ),
  [actionType.POLICY_BILLING_ISSUE_DISPLAYED]: getBillingIssueDisplayedEventDefinition(
    "policy_billing_issue"
  ),
  [actionType.PAYMENT_FAILURE_MODAL_DISPLAYED]: getDefaultBillingDisplayedEventDefinition(
    "payment_failure_modal"
  ),
  [actionType.PAYMENT_PROCESSING_MODAL_DISPLAYED]: getDefaultBillingDisplayedEventDefinition(
    "payment_pending_modal"
  ),
  [actionType.PAYMENT_SUCCESS_MODAL_DISPLAYED]: getDefaultBillingDisplayedEventDefinition(
    "payment_success_modal"
  ),
  [actionType.SETTINGS_BILLING_DISPLAYED]: getDefaultBillingDisplayedEventDefinition(
    "settings_billing_view"
  ),
  [actionType.SETTINGS_BILLING_EDIT_CC_DISPLAYED]: getDefaultBillingDisplayedEventDefinition(
    "settings_billing_edit_cc"
  ),
  [actionType.SETTINGS_BILLING_EDIT_BANK_ACCOUNT_DISPLAYED]: getDefaultBillingDisplayedEventDefinition(
    "settings_billing_edit_bank"
  ),
};
