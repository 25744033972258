import "./BeneficiaryReviewContact.scss";

import Divider from "components/Divider/Divider";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { IBeneficiary } from "config/types";
import { useNextForm } from "containers/DynamicForm/useButtonCommand/useNextForm";
import { useTranslation } from "modules";
import { FC, MouseEvent, ReactNode } from "react";
import { useAppDispatch } from "store";
import { setModal } from "store/actions/modal";
import { selectBeneficiaryReviewContactInfo, useParamSelector } from "store/selectors";

interface IProps {
  beneficiary: IBeneficiary;
}

export const BeneficiaryReviewContact: FC<IProps> = ({ beneficiary }) => {
  // HOOKS
  const beneficiaryContactInfo = useParamSelector(
    selectBeneficiaryReviewContactInfo,
    beneficiary.id
  );
  const dispatch = useAppDispatch();
  const nextFormAction = useNextForm();

  // METHODS
  const handleEditBeneficiary = async (event: MouseEvent) => {
    event.preventDefault();

    try {
      await nextFormAction([], { formAction: "edit_beneficiary", formScope: beneficiary.id });
    } catch (error) {
      dispatch(setModal("GeneralErrorModal", "", { error }));
    }
  };

  // RENDER
  return (
    <div className="beneficiary-review-contact">
      <div className="beneficiary-review-contact__header">
        <BeneficiaryReviewContactName>{beneficiaryContactInfo.name}</BeneficiaryReviewContactName>
        <div onClick={handleEditBeneficiary}>
          <Icon className="pointer" src="Edit" size="sm" />
        </div>
      </div>
      <div>
        <BeneficiaryReviewContactInfo>
          {beneficiaryContactInfo.dateOfBirth}
        </BeneficiaryReviewContactInfo>
        <BeneficiaryReviewContactInfo>{beneficiaryContactInfo.email}</BeneficiaryReviewContactInfo>
        <BeneficiaryReviewContactInfo>
          {beneficiaryContactInfo.phoneNumber}
        </BeneficiaryReviewContactInfo>
        <BeneficiaryReviewContactInfo>
          {beneficiaryContactInfo.addressLine1}
        </BeneficiaryReviewContactInfo>
        <BeneficiaryReviewContactInfo>
          {beneficiaryContactInfo.addressLine2}
        </BeneficiaryReviewContactInfo>
      </div>

      <BeneficiaryReviewContactAllocation>
        {beneficiary.allocation}
      </BeneficiaryReviewContactAllocation>

      <Divider />
    </div>
  );
};

export function BeneficiaryReviewContactName({ children }: IBaseBeneficiaryReviewContactInfo) {
  return (
    <Text className="beneficiary-review-contact__name" tag="h4">
      {children}
    </Text>
  );
}

export function BeneficiaryReviewContactInfo({ children }: IBaseBeneficiaryReviewContactInfo) {
  return (
    <Text className="beneficiary-review-contact__info" tag="p3">
      {children}
    </Text>
  );
}

export function BeneficiaryReviewContactAllocation({
  children,
}: IBaseBeneficiaryReviewContactInfo) {
  const { t } = useTranslation();

  return (
    <div className="beneficiary-review-contact__allocations">
      <Text tag="l3">{t("beneficiary.main.allocation", "Allocation")}</Text>
      <Text tag="l3">
        {children}
        {t("percentage.sign", "%")}
      </Text>
    </div>
  );
}

/* ------------------    TYPES    ------------------ */
interface IBaseBeneficiaryReviewContactInfo {
  children?: ReactNode;
}
