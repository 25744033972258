import ScreenLoader from "components/ScreenLoader/ScreenLoader";
import Dashboard from "containers/Dashboard/Dashboard";
import { useAccountData, useFetchOffersData } from "modules";
import { FC, useEffect, useState } from "react";
import { useAppDispatch } from "store";
import { fetchMembership } from "store/actions";
import { getDashboardData } from "store/actions/dashboard";

const DashboardRoute: FC = () => {
  const dispatch = useAppDispatch();
  const { userHasEstimate, userHasPolicy } = useAccountData();
  const [dataFetched, setDataFetched] = useState(false);
  const { offersFetched } = useFetchOffersData();

  useEffect(() => {
    dispatch(fetchMembership());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchData = async () => {
      if (userHasEstimate || userHasPolicy) setDataFetched(true);

      await dispatch(getDashboardData());

      setDataFetched(true);
    };

    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>{!dataFetched || !offersFetched ? <ScreenLoader /> : <Dashboard />}</>;
};

export default DashboardRoute;
