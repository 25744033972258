import { gql } from "config/graphql";
import { IDocument, IDocumentCategoryResData } from "config/types";
import { getApolloClient } from "store/apollo";

interface IFetchDocumentCategoriesResponse {
  data: {
    documentCategories: IDocumentCategoryResData[];
  };
}

export const fetchDocumentCategories = (): Promise<IFetchDocumentCategoriesResponse> => {
  const client = getApolloClient();

  const query = gql`
    query documentCategories {
      documentCategories {
        id
        name
        slug
        yearGroupable
        documents {
          id
          name
          slug
          createdAt
          updatedAt
          applicableYear
          html {
            url
            fileName
          }
          pdf {
            url
            fileName
          }
          version
          documentCategory {
            name
            slug
          }
        }
      }
    }
  `;

  return client.query({ query });
};

interface IFetchDocumentById {
  data: {
    document: {
      id: IDocument["id"];
      html: IDocument["html"];
      pdf: IDocument["pdf"];
    };
  };
}

export const fetchDocumentById = (documentId: string): Promise<IFetchDocumentById> => {
  const client = getApolloClient();

  const query = gql`
    query documentById($id: ID!) {
      document(id: $id) {
        id
        html {
          fileName
          url
        }
        pdf {
          fileName
          url
        }
      }
    }
  `;

  return client.query({
    query,
    variables: {
      id: documentId,
    },
  });
};
