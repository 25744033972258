import { createSelector } from "@reduxjs/toolkit";
import { includes } from "lodash-es";
import { RootState } from "store";

export const selectFinanceBankAccount = (state: RootState) => state.financeBankAccount;
export const selectLifeBenefitData = (state: RootState) => state.financeBankAccount.lifeBenefit;

export const selectLifeBenefitPayoutData = createSelector(selectLifeBenefitData, lifeBenefit => {
  const { coverageAmount, projectedCoverageAmount, status } = lifeBenefit;
  const isFirst90Days = includes(["pre_census", "waiting_period"], status);
  const isUpdating = status === "calculating" || status === "error";

  const projectedCoveragePayout =
    status === "waiting_period" && projectedCoverageAmount > 0 ? projectedCoverageAmount : 0;
  const coverageAmountPayout =
    includes(["active", "calculating", "error"], status) && coverageAmount > 0
      ? lifeBenefit.coverageAmount
      : 0;

  const hasLifeInsurancePayout = !!coverageAmountPayout || !!projectedCoveragePayout;

  return {
    ...lifeBenefit,
    hasLifeInsurancePayout,
    isFirst90Days,
    isUpdating,
    coverageAmountPayout,
    projectedCoveragePayout,
  };
});
