import "./PayoutDetailsModal.scss";

import { adQuoteModalDisplayedEvent } from "analytics/productOfferEvents";
import Text from "components/Text/Text";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { getWyshADOffering } from "store/selectors";
import formatCommas from "utils/formatCommas";

const PayoutDetailsModal: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const adProductOffer = useSelector(getWyshADOffering);

  const adPayoutDetails = {
    autoPedestrian: adProductOffer?.payoutDetails?.autoPedestrian || 0,
    generalAccident: adProductOffer?.payoutDetails?.generalAccident || 0,
    publicTransportation: adProductOffer?.payoutDetails?.publicTransportation || 0,
  };

  useEffect(() => {
    dispatch(adQuoteModalDisplayedEvent());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const content = {
    header: t("payout_details.title", "Payout Details"),
    icon: "BlobPolicy",
    alwaysShowIcon: true,
  };

  const publicTransportationAmount = t("dollar.amount", "$%<amount>s", {
    amount: formatCommas(adPayoutDetails.publicTransportation),
  });
  const autoPedestrianAmount = t("dollar.amount", "$%<amount>s", {
    amount: formatCommas(adPayoutDetails.autoPedestrian),
  });
  const generalAccidentAmount = t("dollar.amount", "$%<amount>s", {
    amount: formatCommas(adPayoutDetails.generalAccident),
  });

  const items = [
    {
      id: 1,
      amount: publicTransportationAmount,
      label: t("payout_details.item.1.label", "PUBLIC TRANSPORTATION"),
      description: t(
        "payout_details.item.1.description",
        "Covers an insured person who sustains an injury while riding as a fare-paying passenger on a common carrier which results in death within a certain number of days* following the date of the injury. Does not include a person riding as an operator, pilot, or member of the crew. Subject to the policy’s exclusions."
      ),
    },
    {
      id: 2,
      amount: autoPedestrianAmount,
      label: t("payout_details.item.2.label", "AUTO/PEDESTRIAN"),
      description: t(
        "payout_details.item.2.description",
        "Covers an insured person who sustains an injury that does not fall under the “public transportation” coverage of this policy while driving or riding in any private automobile, when struck by any motor vehicle ordinarily operated on public streets and highways, and such injury results in death within within a certain number of days* following the date of injury. Subject to the policy’s exclusions."
      ),
    },
    {
      id: 3,
      amount: generalAccidentAmount,
      label: t("payout_details.item.3.label", "GENERAL ACCIDENT"),
      description: t(
        "payout_details.item.3.description",
        "Covers an insured person who sustains an injury that does not fall under the “public transportation” or “auto/pedestrian” coverage of this policy exclusions and such accidental bodily injury results in death within within a certain number of days* following the date of the accidental bodily injury. Accidental death insurance benefit amount is not determined by common carrier or auto/pedestrian. Subject to the policy’s exclusions."
      ),
    },
  ];

  return (
    <Layout as="ModalLayout" className="payout-details-modal" content={content}>
      <div className="payout-details-modal__items">
        {items.map(item => {
          return (
            <div className="payout-details-modal__item" key={item.id}>
              <Text className="payout-details-modal__item-amount" tag="l1" text={item.amount} />
              <Text className="payout-details-modal__item-label" tag="c5" text={item.label} />
              <Text
                className="payout-details-modal__item-description"
                tag="p3"
                text={item.description}
              />
            </div>
          );
        })}
        <div>
          <Text
            tag="p6"
            text={t(
              "payout_details.legalese",
              "*The number of days varies by state and it is specified in the customer’s policy."
            )}
            className="payout-details-modal__legalese--text"
          />
        </div>
      </div>
    </Layout>
  );
};

export default PayoutDetailsModal;
