import { gql } from "config/graphql";
import { IUser } from "config/types";
import { getApolloClient } from "store/apollo";

interface IFetchNotificationsResponse {
  data: {
    me: IUser;
  };
}

export const fetchCurrentUserNotifications = (): Promise<IFetchNotificationsResponse> => {
  const client = getApolloClient();

  const query = gql`
    query userNotifications {
      me {
        notifications {
          id
          metadata
        }
      }
    }
  `;

  return client.query({
    query,
  });
};

export const accountNotificationsQuery = (): Promise<IFetchNotificationsResponse> => {
  const client = getApolloClient();

  const query = gql`
    query AccountNotifications {
      me {
        activeConstellations {
          activeInsuranceMembership {
            notifications {
              id
              read
              metadata
            }
          }
        }
      }
    }
  `;

  return client.query({
    query,
  });
};
