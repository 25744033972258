import { push } from "connected-react-router";
import { Layout, useJourney, useTranslation } from "modules";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { logout } from "store/actions/authentication";
import { closeModal } from "store/actions/modal";

const ExitRegistrationModal: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { exitJourney } = useJourney();

  const handleConfirm = async () => {
    await exitJourney();
    dispatch(logout());
    dispatch(push("/login"));
    closeModalWrapper();
  };

  const closeModalWrapper = () => {
    dispatch(closeModal());
  };

  const ctaButtons = [
    {
      text: t("registration.exit.moda.cta.primary", "LOG IN TO MY ACCOUNT"),
      onClick: handleConfirm,
    },
    {
      text: t("registration.exit.moda.cta.secondary", "NEVERMIND"),
      subType: "text-dark",
      onClick: closeModalWrapper,
    },
  ];

  const content = {
    header: t("registration.exit.modal.title", "Just so you know..."),
    subheader: t(
      "registration.exit.modal.subtitle",
      "If you continue, everything you just entered will be replaced with the details in your existing account."
    ),
    onClose: closeModalWrapper,
    button: ctaButtons,
    icon: "BlobAlert",
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default ExitRegistrationModal;
