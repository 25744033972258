import { ISliderTick } from "./../components/SliderInput/SliderInput";

// Returns value from min - max in ticks list
export const tickValueFromSlider = (
  internalValue: number,
  ticksList: ISliderTick[],
  min: number,
  max: number
) => {
  const sliderScaleTick = 1.0 / (ticksList.length - 1);

  // boundary check
  if (internalValue <= 0) {
    return min;
  }

  if (internalValue >= 1) {
    return max;
  }

  // loop control var
  let startTickIdx = 0;

  while (startTickIdx < ticksList.length - 1) {
    // lower and upper range for tick segment
    const lowerSliderRange = sliderScaleTick * startTickIdx;
    const upperSliderRange = lowerSliderRange + sliderScaleTick;

    // is value in range?
    const tickRange = internalValue >= lowerSliderRange && internalValue < upperSliderRange;
    if (tickRange) {
      // how far are we into segment
      const factor = (internalValue - lowerSliderRange) / sliderScaleTick;

      // what's the value of the current tick
      const lowerTickValue = ticksList[startTickIdx].value;
      const upperTickValue = ticksList[startTickIdx + 1].value;
      const tickRange = upperTickValue - lowerTickValue;

      // how much of that tick are we covering
      const tickAmount = tickRange * factor;

      // add that to the base value
      const val = lowerTickValue + tickAmount;

      return val;
    }

    startTickIdx += 1;
  }

  return 0;
};

// Returns value from 0 - 1
export const sliderValueFromTick = (
  tickValue: number,
  ticksList: ISliderTick[],
  min: number,
  max: number
) => {
  const sliderScaleTick = 1.0 / (ticksList.length - 1);

  // boundary check
  if (tickValue < min) {
    return 0;
  }
  if (tickValue >= max) {
    return 1;
  }

  // loop control var
  let startTickIdx = 0;

  while (startTickIdx < ticksList.length - 1) {
    // lower and upper range for tick segment
    const lowerTickRange = ticksList[startTickIdx].value;
    const upperTickRange = ticksList[startTickIdx + 1].value;

    // is value in range?
    const tickRange = tickValue >= lowerTickRange && tickValue < upperTickRange;
    if (tickRange) {
      // Size of tick segment
      const tickDiff = upperTickRange - lowerTickRange;

      // how far are we into segment
      const factor = (tickValue - lowerTickRange) / tickDiff;
      const segmentVal = sliderScaleTick * factor;

      const segmentBaseVal = sliderScaleTick * startTickIdx;
      const val = segmentBaseVal + segmentVal;

      return val;
    }

    startTickIdx += 1;
  }
  // some fallback here
  // but should never happen
  return 0;
};

// Return rounded integer value from tickValueFromSlider
export const outputValue = (tickValue: number, increment: number) => {
  let output = 1;

  if (increment) {
    const remainder = tickValue % increment;
    let diff = 0;

    if (remainder >= increment / 2) {
      diff = increment - remainder;
    } else {
      diff = -remainder;
    }
    const finalVal = tickValue + diff;
    output = finalVal;
  } else {
    output = tickValue;
  }

  return output;
};
