import { createSelector } from "@reduxjs/toolkit";
import { selectFinanceBankAccount } from "./selectFinanceBankAccount";
import { toFixed } from "utils/toFixed";

function formatNumbericFinanceBankAccountInfo(hasLoaded: boolean, value: number, isFixed = true) {
  if (!hasLoaded) {
    return isFixed ? "-.--" : "---";
  }

  if (isFixed) {
    return toFixed(value);
  }

  return value;
}

export const selectFormattedFinanceBankAccountInfo = createSelector(
  selectFinanceBankAccount,
  financeBankAccount => {
    return {
      accountBalance: formatNumbericFinanceBankAccountInfo(
        financeBankAccount.hasLoaded,
        financeBankAccount.accountBalance
      ),
      apy: formatNumbericFinanceBankAccountInfo(
        financeBankAccount.hasLoaded,
        financeBankAccount.apy
      ),
      availableBalance: formatNumbericFinanceBankAccountInfo(
        financeBankAccount.hasLoaded,
        financeBankAccount.availableBalance
      ),
      fundsOnHold: formatNumbericFinanceBankAccountInfo(
        financeBankAccount.hasLoaded,
        financeBankAccount.fundsOnHold
      ),
      lifetimeInterest: formatNumbericFinanceBankAccountInfo(
        financeBankAccount.hasLoaded,
        financeBankAccount.lifetimeInterest,
        false
      ),
      pendingBalance: formatNumbericFinanceBankAccountInfo(
        financeBankAccount.hasLoaded,
        financeBankAccount.pendingBalance
      ),
    };
  }
);
