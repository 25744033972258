import "./MessageLayout.scss";

import classNames from "classnames";
import Button from "components/Button/Button";
import Text from "components/Text/Text";
import { OrString, ValueOrArray } from "config/types";
import Container from "containers/Container/Container";
import { flatten, isString, map } from "lodash-es";
import { ILayoutProps } from "modules";
import { cloneElement, ComponentProps, FC, HTMLAttributes, isValidElement } from "react";

type ContentType = {
  content: ValueOrArray<OrString<FC>>;
  image?: string;
  title: OrString<ComponentProps<typeof Text>>;
  button: OrString<ComponentProps<typeof Button>>;
};

const MessageLayout: FC<ILayoutProps<ContentType>> = props => {
  const { children, className } = props;

  const content = props.content as ContentType;
  const bodyContent = flatten([content.content]);
  const image = content.image;
  const title = content.title;
  const titleProps = isString(title) ? { tag: "h3", text: title } : title;

  const button = content.button;
  const buttonProps = isString(button) ? { subtype: "primary", text: button } : button;

  const classes = classNames("message-layout__container", className);

  return (
    <Container className={classes}>
      <div className="message-layout__wrapper flex-column">
        {image && (
          <div className="message-layout__image-wrapper">
            <img src={image} alt="" className="message-layout__image" />
          </div>
        )}

        <div className="message-layout__content-wrapper">
          {title && (
            <div className="message-layout__content-title-wrapper">
              <Text
                {...(titleProps as ComponentProps<typeof Text>)}
                className="message-layout__content-title"
              />
            </div>
          )}
          <div className="message-layout__content-text-wrapper color-secondary-text">
            {map(bodyContent, (text, key) =>
              isValidElement(text) ? (
                cloneElement(text, {
                  key,
                  className: "p4-tag__text message-layout__content-text",
                } as HTMLAttributes<HTMLParagraphElement>)
              ) : (
                <Text
                  key={key}
                  tag="p4"
                  text={text as string}
                  className="message-layout__content-text"
                  style={{ marginBottom: 20 }}
                />
              )
            )}
          </div>
          {buttonProps && (
            <div className="message-layout__content-button__wrapper flex-column--end">
              <Button
                {...(buttonProps as ComponentProps<typeof Button>)}
                className="message-layout__content-button"
              />
            </div>
          )}
          {children}
        </div>
      </div>
    </Container>
  );
};

export default MessageLayout;
