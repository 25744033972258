import "./TwoColumnLayout.scss";

import classNames from "classnames";
import Button from "components/Button/Button";
import Text, { TextType } from "components/Text/Text";
import { ButtonSubType, ClassName, ValueOrArray } from "config/types";
import Container from "containers/Container/Container";
import { compact, flatten, get, isEmpty, isPlainObject, map } from "lodash-es";
import { FixedWrapper, ILayoutProps } from "modules";
import { ConditionalWrapper } from "modules/partials";
import { ComponentProps, FC, ReactNode } from "react";
import { Link } from "react-router-dom";

type TitleProps = ReactNode | ComponentProps<typeof Text>;

type ContentType = {
  header: string;
  title: { title: TitleProps; subtitle?: TitleProps };
  subheader: ValueOrArray<ReactNode>;
  subheaderElement: ReactNode;
  text: ValueOrArray<string>;
  leftContent: ReactNode;
  linkPath: string;
  linkText: string;
  imgSrc: string;
  button: string;
  buttonType?: ButtonSubType;
  buttonTag?: TextType;
  offsetTopImage?: number;
  onButtonClick?: () => void;
};

const TwoColumnLayout: FC<ILayoutProps<ContentType>> = props => {
  const { children, className } = props;

  const content = props.content as ContentType;
  const header = content.header as string;
  const subheader = content?.subheader;
  const subheaderElement = content?.subheaderElement;
  const title = content?.title;
  const subheaderList = compact(flatten([subheader]));
  const text = content?.text;
  const leftContent = content?.leftContent;
  const textList = compact(flatten([text]));
  const linkPath = content?.linkPath;
  const linkText = content?.linkText;
  const imgSrc = content?.imgSrc;
  const buttonContent = content?.button;
  const onButtonClick = content?.onButtonClick;
  const buttonType = content?.buttonType;
  const buttonTag = content?.buttonTag;
  const offsetTopImage = content?.offsetTopImage || 0;

  const classes = classNames("two-col-layout", className);

  const buildTextFromProps = (
    props: TitleProps,
    defaultTag: TextType = "p1",
    baseClassName?: ClassName
  ) => {
    const tag = get(props, "tag", defaultTag);
    const className = classNames(baseClassName, get(props, "className", ""));
    const textProps = { tag, className };
    return isPlainObject(props) ? <Text {...textProps} /> : <Text {...textProps}>{props}</Text>;
  };

  return (
    <Container className={classes}>
      <div className={classNames("two-col-layout__wrapper", { "text-mode": !imgSrc && !!title })}>
        <ConditionalWrapper condition={!!imgSrc}>
          <div className="two-col-layout__left image-column appear-hidden appear-initial@large">
            <FixedWrapper className="two-col-layout__image-wrapper" offsetFromTop={offsetTopImage}>
              <img alt="" src={imgSrc} className="two-col-layout__image" />
            </FixedWrapper>
          </div>
        </ConditionalWrapper>
        <ConditionalWrapper condition={!imgSrc && !!title}>
          <div className="two-col-layout__left">
            <ConditionalWrapper
              condition={true}
              wrapper={FixedWrapper}
              props={{ className: "appear-hidden appear-block@large" }}>
              <div className="two-col-layout__left--text-wrapper">
                {buildTextFromProps(title?.title, "h4", "two-col-layout__left--text-title")}
                {buildTextFromProps(title?.subtitle, "p3", "two-col-layout__left--text-subtitle")}
                {leftContent && (
                  <div className="two-col-layout__left-content-wrapper">{leftContent}</div>
                )}
              </div>
            </ConditionalWrapper>
            <div className="two-col-layout__left--text-wrapper appear-initial appear-hidden@large mb-50">
              {buildTextFromProps(title?.title, "h4", "two-col-layout__left--text-title")}
              {buildTextFromProps(title?.subtitle, "p3", "two-col-layout__left--text-subtitle")}
              {leftContent && (
                <div className="two-col-layout__left-content-wrapper">{leftContent}</div>
              )}
            </div>
          </div>
        </ConditionalWrapper>
        <div className="two-col-layout__right">
          <ConditionalWrapper condition={!isEmpty(header)}>
            <Text className="two-col-layout__header h4" tag="h1" text={header} />
          </ConditionalWrapper>
          {subheaderElement && (
            <div className="two-col-layout__subheader-el">{subheaderElement}</div>
          )}

          {linkPath && linkText && (
            <Link className="link two-col-layout__link l4-tag__text" to={linkPath}>
              {linkText}
            </Link>
          )}
          <ConditionalWrapper condition={!isEmpty(subheaderList)}>
            <div className="two-col-layout__subheader">
              {map(subheaderList, (item: string, index: number) => (
                <Text tag="p3" key={index}>
                  {item}
                </Text>
              ))}
            </div>
          </ConditionalWrapper>
          {buttonContent && (
            <Button
              className="two-col-layout__header-button"
              subtype={buttonType || "primary"}
              onClick={onButtonClick}
              text={buttonContent}
              tag={buttonTag}
            />
          )}
          {map(textList, (item: string, index: number) => (
            <Text tag="p1" text={item} key={index} />
          ))}
          {children}
        </div>
      </div>
    </Container>
  );
};

export default TwoColumnLayout;
