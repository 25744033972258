import "./IneligibleWarning.scss";

import classnames from "classnames";
import { ButtonSubType, IneligibleCoverageReasonsType } from "config";
import { push } from "connected-react-router";
import { Layout, useAffiliateIntegration, useJourney, useTranslation } from "modules";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { logout } from "store/actions/authentication";
import { clearJourneyActive } from "store/actions/journey";

interface IProps {
  reason: IneligibleCoverageReasonsType | "general";
}

type ButtonType = {
  subType: ButtonSubType;
  text: string;
  onClick: () => void;
  dataTestId?: string;
};

interface ContentType {
  [key: string]: {
    header: string;
    subheader: string;
    buttons?: ButtonType[];
    icon: string;
    iframe?: string;
    metadata?: {
      backNav?: {
        hasBackNav: boolean;
        overrideDefaultBehavior: boolean;
        onClick: () => void;
        icon?: string;
        text?: string;
      };
    };
  };
}

const IneligibleWarning: FC<IProps> = ({ reason = "general" }) => {
  const { iframeHeight, iframeSrc } = useAffiliateIntegration({ ineligibilityReason: reason });
  const dispatch = useDispatch();
  const { nextStepCallback } = useJourney();
  const { t } = useTranslation();

  const hasEstimate = useSelector((state: RootState) => !!state.estimate.id);

  const onTryLater = () => {
    dispatch(logout());
    const targetPath = "/login";
    const destinationUrl = {
      ...location,
      pathname: targetPath,
      state: {
        ignoreAction: true,
      },
    };
    dispatch(push(destinationUrl));
    dispatch(clearJourneyActive());
  };

  const onContinue = () => {
    const nextOnboardingPath = hasEstimate
      ? "/coverage-preview"
      : "/products/insurance/wysh-builder/list";

    const nextStep = nextStepCallback(() => dispatch(push(nextOnboardingPath)), false, true);
    nextStep();
  };

  const content: ContentType = {
    general: {
      header: t("ineligible.general.title", "Sorry, we can’t cover you"),
      subheader: t(
        "ineligible.general.body",
        "Looks like you don’t qualify for a Wysh membership right now. But, you may in the future—If so, we’ll hit you up.\n\nIn the meantime, we can still help you calculate exactly how much coverage you might need (no matter where you get your plan).\n\nWe do it with a little something we like to call the Wysh Builder."
      ),
      buttons: [
        {
          text: t("ineligible.general.cta.continue", "OK, LET'S DO IT"),
          subType: "primary",
          onClick: onContinue,
          dataTestId: "ineligible-warning__continue-button",
        },
        {
          text: t("ineligible.general.cta.exit", "TRY AGAIN LATER"),
          subType: "text-dark-border",
          onClick: onTryLater,
          dataTestId: "ineligible-warning__try-again-button",
        },
      ],
      icon: "BlobSorry",
    },
    state_of_residence: {
      header: t(
        "ineligible.state_of_residence.title.affiliate",
        "Sorry, we can’t insure you but..."
      ),
      subheader: t(
        "ineligible.state_of_residence.body.affiliate",
        "...we can help! Since we’re not in your state yet (though we’ll be there soon) below are coverage options from other great companies."
      ),
      iframe: iframeSrc,
      icon: "BlobSorry",
      metadata: {
        backNav: {
          hasBackNav: true,
          icon: "Exit",
          onClick: onTryLater,
          overrideDefaultBehavior: true,
          text: "Exit",
        },
      },
    },
    age_of_insured: {
      header: t("ineligible.age_of_insured.title.affiliate", "Sorry, we can’t insure you but..."),
      subheader: t(
        "ineligible.age_of_insured.body.affiliate",
        "...we can help! Since we only cover those between 18-50 years old, below are coverage options from other great companies."
      ),
      iframe: iframeSrc,
      icon: "BlobSorry",
      metadata: {
        backNav: {
          hasBackNav: true,
          icon: "Exit",
          onClick: onTryLater,
          overrideDefaultBehavior: true,
          text: "Exit",
        },
      },
    },
    criminal_history: {
      header: t("ineligible.criminal_history.title", "You might have a hard time getting coverage"),
      subheader: t(
        "ineligible.criminal_history.body",
        "It’s our goal to offer life insurance to as many people as we can. Unfortuantely, at this time it is not likely that we would be able to approve your application if you have a recent felony on your record. We don’t want to waste your time, so we thought it was only fair to tell you now.\n\nWith that said, if you would still like to try, or have reason to think this does not apply to you, then we invite you to continue."
      ),
      buttons: [
        {
          text: t("ineligible.criminal_history.cta.continue", "I'D LIKE TO CONTINUE"),
          subType: "primary",
          onClick: onContinue,
          dataTestId: "ineligible-warning__continue-button",
        },
        {
          text: t("ineligible.criminal_history.cta.exit", "NEVERMIND"),
          subType: "text-dark-border",
          onClick: onTryLater,
          dataTestId: "ineligible-warning__try-again-button",
        },
      ],
      icon: "BlobSorry",
    },
  };

  const classes = classnames(
    "ineligible-warning__container",
    `ineligible-warning__container--${reason}`
  );

  const backNavMetadata = content[reason]?.metadata?.backNav;

  return (
    <Layout as="AuthLayout" backNav={backNavMetadata} content={content[reason]} className={classes}>
      {!!content[reason]?.iframe && (
        <iframe
          className="ineligible-warning__iframe"
          src={content[reason]?.iframe}
          style={{ height: iframeHeight }}
        />
      )}
    </Layout>
  );
};

export default IneligibleWarning;
