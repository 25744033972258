import "./NavigationSection.scss";

import classNames from "classnames";
import BackNav from "components/BackNav/BackNav";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { IDynamicForm } from "config";
import { LeadingNavigationButton } from "containers/DynamicForm/DynamicFormItems";
import { NavigationSectionWrapper } from "modules/layouts/NavigationSection/NavigationSectionWrapper";
import { ComponentProps, useCallback } from "react";
import { Link } from "react-router-dom";

import { layouts } from "..";

interface IProps {
  as: keyof typeof layouts;
  backNav?: {
    hasBackNav?: boolean;
    icon?: string;
    leadingNavigationButton?: IDynamicForm["leadingNavigationButton"];
    overrideDefaultBehavior?: boolean;
    pathname?: string;
    replaceExitInJourneyWithBack?: boolean;
    text?: string;
    onClick?(): void;
  };
  nextNav?: {
    hasNextNav?: boolean;
    pathname: string;
    icon?: string;
    text: string;
    onClick?(): void;
  };
}

export function NavigationSection(props: IProps) {
  const { as, backNav, nextNav } = props;

  const hasBackNav = !!backNav?.hasBackNav;
  const hasNextNav = !!nextNav?.hasNextNav;
  const leadingNavigationButton =
    backNav?.leadingNavigationButton && backNav.leadingNavigationButton;

  const renderBack = useCallback(
    (hasPortal: boolean) => {
      if (!hasBackNav) {
        return null;
      }

      if (leadingNavigationButton) {
        return (
          <LeadingNavigationButton
            content={leadingNavigationButton}
            hasPortal={hasPortal}
            tag="l4-label"
          />
        );
      }

      return (
        <BackNav
          {...(backNav as ComponentProps<typeof BackNav>)}
          className={classNames(`backnav--${as}`, { "backnav--portal": hasPortal })}
          hasPortal={hasPortal}
          selfPositionedAboveContent={!hasPortal}
        />
      );
    },
    [as, backNav, hasBackNav, leadingNavigationButton]
  );
  const renderNext = useCallback(() => {
    if (!hasNextNav) {
      return null;
    }

    return (
      <div className={`nextnav--${as} backnav`}>
        <Link onClick={nextNav?.onClick} to={nextNav?.pathname || ""}>
          <div className="backnav__content-wrapper">
            <Text tag="c5" text={nextNav?.text} className="backnav__text" />
            <Icon src="RightArrow" className="backnav__text" />
          </div>
        </Link>
      </div>
    );
  }, [as, hasNextNav, nextNav?.onClick, nextNav?.pathname, nextNav?.text]);

  return (
    <NavigationSectionWrapper
      hasBackNav={hasBackNav}
      hasNextNav={hasNextNav}
      leadingNavigationButton={leadingNavigationButton}
      renderBack={renderBack}
      renderNext={renderNext}
    />
  );
}
