const validatePhoneNumber = (
  value: { phoneNumber: string; countryCode: string },
  isRequired: boolean
): boolean => {
  const isRequiredAndEmpty = isRequired && (!value.countryCode || !value.phoneNumber);
  const isUSNumberAndNotTenDigits =
    value.countryCode === "1" && !!value.phoneNumber && value.phoneNumber.length !== 10;

  if (isRequiredAndEmpty || isUSNumberAndNotTenDigits) {
    return false;
  }

  return true;
};

export default validatePhoneNumber;
