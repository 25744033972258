import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IFinanceTransferSchedule,
  TFinanceScheduledTransfersAction,
  TFinanceTransferSchedulesCycle,
} from "config";
import {
  archiveTransferScheduleMutation,
  createFinanceTransferMutation,
  editTransferScheduleMutation,
} from "graphql/mutations";
import { DateTime } from "luxon";
import { RootState } from "store";
import { selectExternalAccount } from "store/selectors";
import { getAppError } from "utils/error";

import { fetchFinanceScheduledTransfers } from ".";

/* ------------       THUNKS      ------------------ */

type CreateFundsTransferPayload = {
  amount: number;
  type: TFinanceScheduledTransfersAction;
  date: DateTime;
};

export const createFundsTransfer = createAsyncThunk(
  "transfers/createFundsTransfer",
  async (payload: CreateFundsTransferPayload, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const financeCustomer = state.financeCustomer;
      const financeCustomerAccount = financeCustomer.accounts[0];
      const financeCustomerAccountId = financeCustomerAccount?.id;
      const externalAccountId = selectExternalAccount(state)?.externalAccountId;

      if (!payload.date.isValid || !financeCustomerAccountId || !externalAccountId) {
        throw Error("Missing input values. Please try again later");
      }

      const formattedDate = payload.date.toISODate();

      const input = {
        action: payload.type,
        amount: payload.amount,
        transferDate: formattedDate,
        externalAccountId: externalAccountId,
        financeAccountId: financeCustomerAccountId,
      };

      const { data } = await createFinanceTransferMutation(input);

      return data.createFinanceTransfer.response;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const editAutoDepositSchedule = createAsyncThunk(
  "editTransferSchedule",
  async (
    { amount, transferDate }: { amount: number; transferDate: string },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const financeCustomer = state.financeCustomer;
      const financeCustomerAccount = financeCustomer.accounts[0];
      const financeCustomerAccountId = financeCustomerAccount?.id;

      const input = {
        action: "deposit" as TFinanceScheduledTransfersAction,
        amount,
        cycle: "monthly" as TFinanceTransferSchedulesCycle,
        financeAccountId: financeCustomerAccountId,
        transferDate,
      };

      const { data } = await editTransferScheduleMutation(input);
      await dispatch(fetchFinanceScheduledTransfers());

      return data.editTransferSchedule.transferSchedule;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const archiveTransferSchedule = createAsyncThunk(
  "archiveTransferSchedule",
  async (transferScheduleId: IFinanceTransferSchedule["id"], { dispatch, rejectWithValue }) => {
    try {
      const { data } = await archiveTransferScheduleMutation({ transferScheduleId });
      await dispatch(fetchFinanceScheduledTransfers());

      return data.archiveTransferSchedule.response.success;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
