import { IWysh, IWyshIcon } from "config/types";
import { find, isEqual } from "lodash-es";

export const findIconUrl = (icon: IWyshIcon, png = false) => {
  const iconObject = find(icon.formats || [], iconItem =>
    isEqual(iconItem.contentType, png ? "image/png" : "image/svg+xml")
  );

  const iconUrl = iconObject?.url || "";

  return iconUrl;
};

export const findWyshIconUrls = (wysh?: Partial<IWysh>) => {
  const detailsHeader = findIconUrl(wysh?.header400368 || { formats: [] });
  const iconUrl = findIconUrl(wysh?.icon100100 || { formats: [] });
  const infoHeader = findIconUrl(wysh?.header950400 || { formats: [] });
  const iconSelected = findIconUrl(wysh?.iconSelected6464 || { formats: [] });
  const iconUnselected = findIconUrl(wysh?.iconUnselected6464 || { formats: [] });

  return { detailsHeader, infoHeader, icon: iconUrl, iconSelected, iconUnselected };
};
