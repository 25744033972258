import "./JourneyProgress.scss";
import ProgressBar from "components/ProgressBar/ProgressBar";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getJourneyProgress } from "store/selectors";
import { isNumber } from "lodash-es";

const JourneyProgress: FC = () => {
  const [percentage, setPercentage] = useState(0);
  const { shouldDisplayProgress, percentOverride, percentage: calculatedPercentage } = useSelector(
    getJourneyProgress
  );

  useEffect(() => {
    if (calculatedPercentage) {
      setPercentage(calculatedPercentage);
    }
  }, [calculatedPercentage]);

  const displayPercentage = isNumber(percentOverride) ? percentOverride : percentage;

  return (
    <>
      {shouldDisplayProgress ? (
        <ProgressBar
          className="journey-progress"
          percentage={displayPercentage}
          showInitialProgress
        />
      ) : null}
    </>
  );
};

export default JourneyProgress;
