import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "store/actions/modal";

const CantEditMonitoredModal: FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const closeModalWrapper = () => dispatch(closeModal());

  const content = {
    header: t(
      "wysh.modal.unable_to_edit.title",
      "You can’t edit this since it’s tied to your monitored account "
    ),
    subheader: t(
      "wysh.modal.unable_to_edit.body",
      "If you want to edit this info, simply turn off monitoring."
    ),
    onClose: closeModalWrapper,
    button: [
      {
        text: t("wysh.modal.unable_to_edit.button_confirm", "MAKES SENSE"),
        subType: "primary",
        onClick: closeModalWrapper,
      },
    ],
    onButtonClick: closeModal,
    icon: "BlobAlert",
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default CantEditMonitoredModal;
