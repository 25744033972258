import { ISO8601Date } from "config/types";
import { DateTime, Interval } from "luxon";

interface Option {
  value: string;
  label: string;
}

export const createTransactionsFilterOptions = (
  fromDate: ISO8601Date,
  endDate: ISO8601Date
): Option[] => {
  const beginDate = fromDate || new Date().toISOString();
  const currentDate = DateTime.fromISO(endDate);

  const interval = Interval.fromDateTimes(
    DateTime.fromISO(beginDate).startOf("month"),
    currentDate.endOf("month")
  ).splitBy({
    months: 1,
  });

  const filterOptions: Option[] = interval.map(month => {
    return { label: month.start.toFormat("MMMM yyyy"), value: month.start.toISO() };
  });

  return filterOptions.reverse();
};
