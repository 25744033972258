import { createSelector } from "@reduxjs/toolkit";
import { IFinanceScheduledTransfer } from "config";
import { RootState } from "store";

export const selectFinanceAccountScheduledTransferIds = (state: RootState) =>
  state.scheduledTransfers.ids;

const selectFinanceAccountScheduledTransferById = (state: RootState) =>
  state.scheduledTransfers.byId;

export const getFinanceScheduledTransferById = createSelector(
  [
    selectFinanceAccountScheduledTransferById,
    (_state: RootState, transferId: string) => transferId,
  ],
  (transfers, transferId): IFinanceScheduledTransfer | undefined => {
    return transfers[transferId];
  }
);
