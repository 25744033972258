import { FlowTypes } from "config";
import { replace } from "connected-react-router";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { getMembershipMetadata } from "store/selectors";

// Skip flow form if:
// `marketing` flow form returns empty questions
// `savings-cdd` flow form has already been submitted
export const useSkipFlowForm = (slug: FlowTypes) => {
  const dispatch = useAppDispatch();
  const { cdd_submitted = false } = useSelector(getMembershipMetadata);

  const skipFlowForm = () => {
    if (slug === "savings-cdd" && cdd_submitted) {
      return dispatch(replace("/"));
    }
  };

  return { skipFlowForm };
};
