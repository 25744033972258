import "./TriviaAnswer.scss";

import happyTurtleSvg from "assets/images/trivia/happy-turtle.svg";
import sadTurtleSvg from "assets/images/trivia/sad-turtle.svg";
import Button from "components/Button/Button";
import Text from "components/Text/Text";
import TriviaBanner from "components/Trivia/TriviaBanner";
import { useTranslation } from "modules";
import { FC, MouseEvent } from "react";

interface IProps {
  answerScreenDuration: number;
  isQuoteReady: boolean;
  wasAnsweredCorrectly: boolean;
  questionWasTimeout?: boolean;
  correctAnswer?: string;
  explanation?: string;
  onComplete(event?: MouseEvent<HTMLDivElement>): void;
  onNext(): void;
}

const TriviaAnswer: FC<IProps> = props => {
  const {
    answerScreenDuration,
    correctAnswer,
    explanation,
    isQuoteReady,
    questionWasTimeout,
    onComplete,
    onNext,
    wasAnsweredCorrectly,
  } = props;

  const { t } = useTranslation();

  return (
    <div className="trivia-answer__container">
      {isQuoteReady ? <TriviaBanner onComplete={onComplete} /> : null}

      <div className="trivia-answer__content">
        {wasAnsweredCorrectly && !questionWasTimeout ? (
          <>
            <img className="trivia-answer__result-icon" src={happyTurtleSvg} />
            <Text className="trivia-answer__result trivia-answer__result--correct" tag="h4">
              {t("trivia.answer.result.correct", "Correct!")}
            </Text>
          </>
        ) : (
          <>
            <img className="trivia-answer__result-icon" src={sadTurtleSvg} />
            <Text className="trivia-answer__result trivia-answer__result--incorrect" tag="h4">
              {t("trivia.answer.result.incorrect", "Sorry!")}
            </Text>
          </>
        )}

        <Text className="trivia-answer__correct-answer-heading" tag="l2">
          {t("trivia.answer.prompt.correct", "The answer is")}
        </Text>
        <Text className="trivia-answer__correct-answer" tag="h4">
          {correctAnswer}
        </Text>

        <Text className="trivia-answer__explanation" tag="p3">
          {explanation}
        </Text>
      </div>

      <Button className="trivia-answer__cta" onClick={onNext}>
        <div
          className="trivia-answer__cta-background"
          style={{
            animationDuration: `${answerScreenDuration}s`,
          }}
        />
        <span className="trivia-answer__cta-text">{t("trivia.answer.cta", "Next")}</span>
      </Button>
    </div>
  );
};

export default TriviaAnswer;
