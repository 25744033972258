import { Store } from "store";
import { disableFinanceOnboarding, lockAccount } from "store/actions/account";

import { ErrorCodes } from "../types/error.types";

// import { push } from "connected-react-router";
export type GlobalErrorCallback = (store: Store) => void;

export const globalErrors: Partial<Record<ErrorCodes, GlobalErrorCallback>> = {
  // PHONE_NUMBER_MISSING: (store: any) => {
  //   store.dispatch(push("/create-phone"));
  // },
  BLOCKED: store => {
    store.dispatch(lockAccount("BLOCKED"));
  },
  FINANCE_APPLICATION_DISABLED: store => {
    store.dispatch(disableFinanceOnboarding());
  },
};
