import { createReducer } from "@reduxjs/toolkit";
import { IProductOffering } from "config";
import { keyBy } from "lodash-es";
import { logout } from "store/actions/authentication";
import { adjustProductOffering, getProductOfferings } from "store/actions/product-offerings";

interface IProductOfferingState {
  ids: string[];
  byId: Record<string, IProductOffering>;
}

const defaultState: IProductOfferingState = {
  ids: [],
  byId: {},
};

const productOffering = createReducer(defaultState, builder => {
  builder
    .addCase(getProductOfferings.fulfilled, (state, action) => {
      const productOfferings = action.payload.productOfferings;

      state.ids = productOfferings.map(list => list.id);
      state.byId = keyBy(productOfferings, "id");
    })
    .addCase(adjustProductOffering.fulfilled, (state, action) => {
      const offering = state.byId[action.payload.offeringId];
      const { annualPremium, monthlyPremium, allocationsCoveredPercentage } = action.payload;

      state.byId[action.payload.offeringId] = {
        ...offering,
        annualPremium,
        monthlyPremium,
        allocationsCoveredPercentage,
      };
    })
    .addCase(logout, () => defaultState);
});

export default productOffering;
