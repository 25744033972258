import { get, isFunction } from "lodash-es";
import { RootState } from "store";

import { getDefaultAttrs } from "./defaults";

type TrackedJourneyStartIds = "fulfillment";
type TrackedJourneyStepIds = "disclosure-agreement";

const startJourneyMap: Record<TrackedJourneyStartIds, any> = {
  fulfillment: (state: RootState) => {
    return {
      event: "fulfillment_started",
      attribute: {
        ...getDefaultAttrs(state, "ONBOARDING"),
      },
    };
  },
};

export const startJourney = (
  action: { [key: string]: any },
  prevState: RootState,
  nextState: RootState
) => {
  const key: TrackedJourneyStartIds = get(action, "payload.id", "");
  if (key && isFunction(startJourneyMap[key])) {
    return startJourneyMap[key](nextState);
  }
};

const setJourneyStepMap: Record<TrackedJourneyStepIds, any> = {
  "disclosure-agreement": (state: RootState) => {
    return {
      event: "fulfillment_uw_started",
      attribute: {
        ...getDefaultAttrs(state, "ONBOARDING"),
      },
    };
  },
};

export const setJourneyStep = (
  action: { [key: string]: any },
  prevState: RootState,
  nextState: RootState
) => {
  const key: TrackedJourneyStepIds = get(action, "payload.id", "");
  if (key && isFunction(setJourneyStepMap[key])) {
    return setJourneyStepMap[key](nextState);
  }
};
