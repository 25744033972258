import "./CornerBlob.scss";

import WelcomeBlobMd from "assets/icons/WelcomeBlobMd/WelcomeBlobMd.svg";
import WelcomeBlobSm from "assets/icons/WelcomeBlobSm/WelcomeBlobSm.svg";
import { FC } from "react";

const CornerBlob: FC = () => {
  return (
    <div className="corner-blob">
      <img className="appear-hidden@medium corner-blob--sm" src={WelcomeBlobSm} alt="corner blob" />
      <img
        className="appear-hidden appear-initial@medium corner-blob--md"
        src={WelcomeBlobMd}
        alt="corner blob"
      />
    </div>
  );
};

export default CornerBlob;
