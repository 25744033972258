import "./HonestyPledge.scss";

import Button from "components/Button/Button";
import Text from "components/Text/Text";
import { push, replace } from "connected-react-router";
import { Layout, useJourney } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";
import { eventFired } from "store/actions/analytics";
import { createApplicationReferral } from "store/actions/producerManagement";

const HonestyPledge: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { nextStepCallback, exitJourney } = useJourney();
  const utmCampaign = useAppSelector(state => state.analyticsTracker.utm.utm_campaign);

  useEffect(() => {
    dispatch(
      eventFired({
        event: "estimates_honesty_pledge_displayed",
        experienceLocation: "ONBOARDING",
      })
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (utmCampaign) {
      dispatch(createApplicationReferral(utmCampaign));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBack = async () => {
    await exitJourney();

    dispatch(replace("/"));
  };

  const handleAccept = () => {
    dispatch(
      eventFired({
        event: "estimates_honesty_pledge_next",
        experienceLocation: "ONBOARDING",
      })
    );
    nextStepCallback(() => {
      dispatch(push("/flow/application"));
    }, false)();
  };

  return (
    <Layout
      as="MessageLayout"
      backNav={{
        hasBackNav: true,
        replaceExitInJourneyWithBack: true,
        onClick: handleBack,
      }}
      content={{}}>
      <div className="honesty-pledge">
        <Text className="honesty-pledge__company-name" tag="c4-caption">
          {t("honesty_pledge.company.name", "WYSH LIFE AND HEALTH INSURANCE COMPANY")}
        </Text>

        <Text className="honesty-pledge__header" tag="h2-header">
          {t("honesty_pledge_v2.header", "Two things to know before you apply")}
        </Text>

        <div className="honesty-pledge__content">
          <Text
            tag="h5-header"
            className="honesty-pledge__subheader"
            text={t("honesty_pledge_v2.subheader", "1. Honesty makes great policies")}
          />
          <Text
            className="honesty-pledge__agreement-header"
            tag="c4-caption"
            text={t("honesty_pledge_v2.company.header", "WYSH AGREES")}
          />
          <Text
            className="honesty-pledge__agreement-content"
            tag="p5-paragraph"
            text={t(
              "honesty_pledge_v2.company.body",
              "To protect your personal info and keep your data safe and encrypted."
            )}
          />
          <Text
            className="honesty-pledge__agreement-header"
            tag="c4-caption"
            text={t("honesty_pledge_v2.customer.header", "YOU AGREE")}
          />
          <Text
            tag="p5-paragraph"
            className="honesty-pledge__agreement-content"
            text={t(
              "honesty_pledge_v2.customer.body",
              "To answer all the questions honestly. Accurate information means an accurate quote for you. It also helps your loved ones get paid quickly if they need to file a claim."
            )}
          />
        </div>

        <Button
          className="full-width"
          text={t("honesty_pledge_v2.cta", "NEXT")}
          onClick={handleAccept}
          dataTestId="honesty-pledge__cta"
        />
      </div>
    </Layout>
  );
};

export default HonestyPledge;
