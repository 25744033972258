import "./SelectInput.scss";

import classNames from "classnames";
import { ISelectOption, ISelectProps } from "components/SelectInput/SelectInput";
import Text from "components/Text/Text";
import TextInput from "components/TextInput/TextInput";
import { debounce, find, findIndex, flatten, kebabCase, map } from "lodash-es";
import React, {
  ChangeEvent,
  FC,
  MouseEvent,
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";

interface IDropdownListItem {
  text: string;
  highlighted: boolean;
  active: boolean;
  dataTestId: string;
  onMouseDown?(event: MouseEvent): void;
}

const CustomSelect: FC<ISelectProps> = props => {
  const {
    id,
    autoCompleteOverride,
    className,
    disabled = false,
    error,
    errorMessage,
    label,
    options,
    prompt,
    style,
    value,
    dataTestId,
    highlightSearchBuffer = false,
    onChange,
  } = props;

  // useState Hooks
  const [open, setOpen] = useState(false);
  const [activeOption, setActiveOption] = useState<ISelectOption>(() => {
    const foundOption = find(options, option => option.value === value);
    return foundOption || { label: "", value: "" };
  });
  const [selectLabel, setSelectLabel] = useState(() => {
    const foundOption = find(options, option => option.value === value);
    return foundOption?.label || "";
  });
  const [searchBuffer, setSearchBuffer] = useState<string>("");

  // useRef Hook
  const listRef: any = useRef();
  const listItemRefs: { [key: string]: React.RefObject<any> } = useMemo(() => ({}), []);

  const debouncedClearSearchBuffer = debounce(() => {
    setSearchBuffer("");
  }, 750);

  // Functions
  const handleSelectLabel = useCallback(
    (value = "") => {
      const foundOption = find(options, option => option.value === value);
      const optionValue = foundOption?.value || "";
      const optionLabel = foundOption?.label || "";

      setSelectLabel(optionLabel);

      return optionValue;
    },
    [options]
  );

  const toggleMenu = () => {
    setOpen(!open);
  };

  const clearSearch = useCallback(() => {
    resetActiveOption();
    setSearchBuffer("");
  }, []);

  const close = useCallback(() => {
    setOpen(false);
    clearSearch();
  }, [clearSearch]);

  const onBlur = () => {
    close();
  };

  const navigate = (key: string) => {
    if (!open) {
      setOpen(true);
      setActiveOption(options[0]);
    } else if (key === "Enter") {
      const event = {
        target: { value: activeOption.value, id },
      };
      onChange && onChange(event);
      setOpen(false);
      clearSearch();
    } else {
      const currentActiveIndex = findIndex(options, activeOption);
      let newIndex = currentActiveIndex;
      if (key === "ArrowUp" && currentActiveIndex === 0) {
        newIndex = options.length - 1;
      } else if (key === "ArrowUp") {
        newIndex = currentActiveIndex - 1;
      }
      if (key === "ArrowDown" && currentActiveIndex === options.length - 1) {
        newIndex = 0;
      } else if (key === "ArrowDown") {
        newIndex = currentActiveIndex + 1;
      }
      const newActiveIndex = options[newIndex] ? newIndex : 0;
      const newActiveOption = options[newActiveIndex];
      setActiveOption(newActiveOption);
      scrollList(listItemRefs[newActiveOption.value]);
    }
  };

  const resetActiveOption = () => {
    setActiveOption({ label: "", value: "" });
  };

  // useCallback Hooks
  const getOrCreateRef = useCallback(
    (value: string) => {
      if (!listItemRefs.hasOwnProperty(value)) {
        listItemRefs[value] = React.createRef();
      }
      return listItemRefs[value];
    },
    [listItemRefs]
  );

  const scrollList = useCallback((itemRef: React.RefObject<any>) => {
    listRef.current.scrollTo(0, itemRef.current.offsetTop);
  }, []);

  const onSelect = useCallback(
    (event: MouseEvent, option: ISelectOption) => {
      const optionValue = handleSelectLabel(option.value);

      const newEvent = {
        target: { value: optionValue, id },
      };

      onChange && onChange(newEvent);

      close();
    },
    [close, handleSelectLabel, id, onChange]
  );

  const handleOnTextInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const optionValue = handleSelectLabel(event.target.value);

      const newEvent = {
        target: { value: optionValue, id },
      };

      onChange && onChange(newEvent);
    },
    [handleSelectLabel, id, onChange]
  );

  const search = useCallback(
    (searchString: string) => {
      const searchResult = find(options, option =>
        option.label.toLowerCase().startsWith(searchString.toLowerCase())
      );
      if (searchResult) {
        setActiveOption(searchResult);
        scrollList(listItemRefs[searchResult.value]);
      }
    },
    [listItemRefs, options, scrollList]
  );

  // useMemo Hook
  const menuItems: IDropdownListItem[] = useMemo(
    () =>
      options.map(option => ({
        text: option.label.toString(),
        highlighted: option.label === activeOption.label,
        active: option.value === value,
        dataTestId: `select__input-${kebabCase(option.label)}`,
        onMouseDown: (event: MouseEvent) => onSelect(event, option),
      })),
    [options, activeOption, value, onSelect]
  );

  const onKeyDown = (event: React.KeyboardEvent) => {
    const { key } = event;
    const isNavKey = ["ArrowUp", "ArrowDown", "Enter"].includes(key);
    const isSearchKey = key.match(/^[a-z0-9]$/i) !== null;

    if (!isNavKey && !isSearchKey) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (isNavKey) {
      navigate(key);
      return;
    }

    if (isSearchKey) {
      const newSearchBuffer = searchBuffer + key;
      setSearchBuffer(newSearchBuffer);
      search(newSearchBuffer);

      // Reset the debounce timer each time a key is pressed
      debouncedClearSearchBuffer();
    }
  };

  // Highlight matching text
  const highlightText = (text: string, searchBuffer: string) => {
    if (!searchBuffer) return text;
    const index = text.toLowerCase().indexOf(searchBuffer.toLowerCase());
    if (index === -1 || !text.toLowerCase().startsWith(searchBuffer.toLowerCase())) return text;

    return (
      <>
        <strong>{text.substring(index, index + searchBuffer.length)}</strong>
        {text.substring(index + searchBuffer.length)}
      </>
    );
  };

  // useEffect Hook
  useEffect(() => {
    const optionValue = handleSelectLabel(value);
    const noOptionValueExists = options.length && value && !optionValue;

    if (noOptionValueExists) {
      const newEvent = {
        target: { value: optionValue, id },
      };

      onChange && onChange(newEvent);
    }
  }, [value, options, onChange, id, handleSelectLabel]);

  const errorMessages = flatten([errorMessage]);

  const icon = open ? "CaratUp" : "CaratDown";
  const classes = classNames(className, "custom-select-input", {
    "custom-select-input--disabled": disabled,
  });
  const inputClasses = classNames({
    "text-input--active": value,
  });
  const menuClasses = classNames("menu-container", className, {
    active: open,
  });

  return (
    <div
      className={classes}
      style={style}
      data-testid={dataTestId || `custom-select__${kebabCase(label)}`}
      aria-expanded={open ? "true" : "false"}
      role="combobox"
      aria-haspopup="listbox"
      aria-owns={`listbox-${id}`}
      aria-controls={`listbox-${id}`}
      aria-activedescendant={activeOption.value}
      onKeyDown={onKeyDown}>
      {prompt ? (
        <div className="custom-select__prompt">
          <Text tag="l2" text={prompt} />
        </div>
      ) : null}
      <div onClick={toggleMenu} className="custom-select__click-wrapper">
        <TextInput
          autoCompleteOverride={autoCompleteOverride}
          className={inputClasses}
          disableAutoComplete
          disabled={disabled}
          error={error}
          iconRight={icon}
          id={id}
          label={label}
          onBlur={onBlur}
          onChange={handleOnTextInputChange}
          type="text"
          value={selectLabel}
        />
      </div>
      <div className={menuClasses}>
        <div className="menu-wrapper" ref={listRef} id={`listbox-${id}`} role="listbox">
          {map(menuItems, (item: IDropdownListItem, index) => {
            const itemClass = classNames(
              "custom-select__border--bottom custom-select__item flex--center main-menu",
              {
                "custom-select__item--active": item.active,
                "custom-select__item--highlighted": item.highlighted,
              }
            );

            return (
              <div
                key={index}
                className={itemClass}
                onMouseDown={item.onMouseDown}
                data-testid={item.dataTestId}
                ref={getOrCreateRef(options[index].value)}
                role="option"
                aria-selected={item.active}>
                <Text tag="p3">
                  {highlightSearchBuffer ? highlightText(item.text, searchBuffer) : item.text}
                </Text>
              </div>
            );
          })}
        </div>
      </div>
      {error
        ? errorMessages.map(message => (
            <Text
              className="custom-select-input__error-text"
              key={message}
              tag="p6"
              text={message}
            />
          ))
        : null}
    </div>
  );
};

export default CustomSelect;
