import "./QuoteEstimate.scss";

import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import PriceText from "components/PriceText/PriceText";
import Text from "components/Text/Text";
import { IProductOffering } from "config";
import { AnalyticsIds } from "config/analytics";
import { get } from "lodash-es";
import { ConditionalWrapper } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC, MouseEvent, useMemo } from "react";
import { CoverageType } from "router/CoverageEditorRoute/CoverageEditorRoute";
import { IEstimateState } from "store/reducers/estimate";
import { IQuoteState } from "store/reducers/quote";
import { toFixed } from "utils/toFixed";

interface IProps {
  quote: IQuoteState | IEstimateState | IProductOffering;
  ctaText?: string;
  type?: CoverageType;
  disableCta?: boolean;
  isLoading?: boolean;
  onClick?: (event: MouseEvent) => void;
}

const QuoteEstimate: FC<IProps> = props => {
  const { quote, onClick, disableCta, isLoading, ctaText, type } = props;
  const { t } = useTranslation();
  const acceptQuoteAnalyticsId = get(AnalyticsIds, "quote.acceptQuote", "");
  const isEstimateCoverageType = type === "estimate";
  const coveragePremiumFormatted = useMemo(
    () =>
      isEstimateCoverageType ? Math.floor(+quote.monthlyPremium) : toFixed(quote.monthlyPremium),
    [isEstimateCoverageType, quote.monthlyPremium]
  );

  return (
    <div className="quote-estimate flex-column--center">
      <div className="quote-estimate__content-wrapper">
        <div className="flex-column--center">
          <PriceText
            className="quote-estimate__cost"
            amount={coveragePremiumFormatted}
            dataTestId="quote-estimate__cost"
          />

          <Text
            tag="c6"
            text={
              isEstimateCoverageType
                ? t("coverage_editor.per.month", "PER MONTH*")
                : t("quote_review.button.label.per_month", "PER MONTH")
            }
          />
        </div>
      </div>
      <ConditionalWrapper condition={isEstimateCoverageType}>
        <div className="quote-estimate__time">
          <Icon
            className="quote-estimate__time-icon"
            color="secondary-light"
            src="Time"
            size="md"
          />
          <Text
            className="quote-estimate__fulfillment-completion-time"
            tag="p5"
            text={t("fulfillment.intro.time", "5 mins to the finish line")}
          />
        </div>
      </ConditionalWrapper>
      <Button
        className="quote-estimate__btn"
        text={ctaText ? ctaText : t("coverage_editor.cta.finish_application", "FINISH APPLICATION")}
        subtype="primary"
        disabled={disableCta}
        isLoading={isLoading}
        onClick={onClick}
        dataAnalyticsId={acceptQuoteAnalyticsId}
        dataTestId="quote-estimate__cta"
      />
    </div>
  );
};

export default QuoteEstimate;
