import "./ReviewLayout.scss";

import classNames from "classnames";
import Text from "components/Text/Text";
import { DynamicFormInputItems, IDynamicForm } from "config";
import { BeneficiaryReview } from "containers/BeneficiaryReview/BeneficiaryReview";
import { DynamicFormItemComponent } from "containers/DynamicForm/DynamicFormItems";
import { InsuranceProfileReviewInfo } from "containers/DynamicForm/DynamicFormLayouts/ReviewLayout/InsuranceProfileReviewInfo/InsuranceProfileReviewInfo";
import { DynamicFormReviewItemComponent } from "containers/DynamicForm/DynamicFormReviewItems";
import { useEditResponse } from "containers/DynamicForm/useButtonCommand/useEditResponse";
import { useTranslation } from "modules";
import { useEffect } from "react";
import { useAppDispatch } from "store";
import { setJourneyProgressPercentOverride } from "store/actions/journey";

interface IProps {
  items: IDynamicForm["items"];
  slug: IDynamicForm["slug"];
}

type THeaderContent = Record<IDynamicForm["slug"], { subtitle: string; title: string }>;
type TInputContent = DynamicFormInputItems["content"];

export function ReviewLayout({ items, slug }: IProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { editResponse, isRequestingEdit } = useEditResponse();

  const HEADER_CONTENT: THeaderContent = {
    "insurance-profile-review-screen": {
      subtitle: t(
        "uip.review.header.subtitle",
        "Before moving on, please confirm the information you’ve provided is correct."
      ),
      title: t("uip.review.header.title", "Verify & continue"),
    },
  };

  const classes = classNames(
    "dynamic-form-layout",
    "dynamic-form-review-layout",
    `dynamic-form-review-layout--${slug}`,
    {
      "dynamic-form-review-layout--two-col": slug === "insurance-profile-review-screen",
    }
  );

  useEffect(() => {
    dispatch(setJourneyProgressPercentOverride(undefined));
  }, [dispatch]);

  if (slug === "beneficiary-review") {
    return (
      <div className={classes}>
        <BeneficiaryReview items={items} />
      </div>
    );
  }

  return (
    <div className={classes}>
      {(HEADER_CONTENT[slug]?.title || HEADER_CONTENT[slug]?.subtitle) && (
        <div className="dynamic-form-review-layout__header dynamic-form-review-layout-header">
          <Text className="dynamic-form-review-layout-header__title" tag="h4">
            {HEADER_CONTENT[slug]?.title}
          </Text>
          <Text className="dynamic-form-review-layout-header__subtitle" tag="p5">
            {HEADER_CONTENT[slug]?.subtitle}
          </Text>
        </div>
      )}

      {slug === "insurance-profile-review-screen" && (
        <InsuranceProfileReviewInfo className="dynamic-form-review-layout__info" />
      )}

      <div className="dynamic-form-review-layout__form-items">
        {items.map(item => {
          // If on the review screen and the input is disabled, it is a review element
          if (slug === "aura-review" && (item.content as TInputContent).disabled) {
            return (
              <DynamicFormReviewItemComponent
                onEdit={editResponse}
                isRequestingEdit={isRequestingEdit}
                item={item}
                key={item.key}
              />
            );
          }

          return <DynamicFormItemComponent item={item} key={item.key} />;
        })}
      </div>
    </div>
  );
}
