import { createReducer } from "@reduxjs/toolkit";
import { IJourney, IJourneyStep } from "config/types";
import { defaultTo, filter, isEqual, uniq } from "lodash-es";
import { logout } from "store/actions/authentication";
import {
  clearJourneyActive,
  fetchJourney,
  finishJourney,
  restoreJourney,
  setJourneyActive,
  setJourneyActiveSection,
  setJourneyInactive,
  setJourneyProgressPercentOverride,
  setJourneyStep,
  setShowJourneyProgress,
} from "store/actions/journey";
import { local } from "store2";

export interface IJourneyWithActive extends IJourney {
  active: boolean;
}

export type JourneyState = {
  collection: Record<string, IJourneyWithActive>;
  activeJourneys: string[];
  progress: {
    authenticatedAtStart: boolean;
    show: boolean;
    percentOverride?: number;
  };
  steps: {
    active: string;
    collection: Record<string, IJourneyStep>;
    ids: string[];
  };
  sections: {
    active: string;
  };
};

const defaultState: JourneyState = {
  collection: {},
  activeJourneys: [],
  progress: {
    authenticatedAtStart: false,
    show: false,
  },
  steps: {
    active: "",
    collection: {},
    ids: [],
  },
  sections: {
    active: "",
  },
};

const journey = createReducer<JourneyState>(defaultState, {
  [setJourneyStep.type]: (state, { payload }) => {
    state.sections.active = "";
    state.steps.active = payload.id;
  },
  [setJourneyInactive.type]: (state, { payload }) => {
    state.activeJourneys = uniq(filter(state.activeJourneys, i => !isEqual(i, payload.id)));
    state.collection[payload.id] = {
      ...state.collection[payload.id],
    };
  },
  [setJourneyActiveSection.type]: (state, { payload }) => {
    state.sections.active = payload.section;
  },
  [setShowJourneyProgress.type]: (state, { payload }) => {
    state.progress.show = payload.showJourneyProgress;
  },
  [setJourneyProgressPercentOverride.type]: (state, { payload }) => {
    state.progress.percentOverride = payload.percent;
  },
  [finishJourney.fulfilled.type]: () => defaultState,
  [fetchJourney.fulfilled.type]: (state, { payload }) => {
    state.steps = {
      active: defaultTo(payload.steps.active, state.steps.active),
      collection: {
        ...state.steps.collection,
        ...payload.steps.collection,
      },
      ids: [...state.steps.ids, ...payload.steps.ids],
    };
    state.collection = {
      ...state.collection,
      [payload.journey.id]: {
        ...state.collection[payload.journey.id],
        ...payload.journey,
        active: true,
        status: {
          isLoaded: true,
        },
      },
    };
  },
  [setJourneyActive.fulfilled.type]: (state, { payload }) => {
    state.activeJourneys = uniq([...state.activeJourneys, payload.id]);
    state.collection[payload.id] = {
      ...state.collection[payload.id],
    };
    state.progress.authenticatedAtStart = payload.authenticated;
  },
  [restoreJourney.type]: (state, { payload }) => ({
    ...defaultState,
    ...state,
    ...payload.state,
  }),
  [clearJourneyActive.type]: () => {
    return defaultState;
  },
  [logout.type]: () => {
    local.remove("completedJourneys");
    return defaultState;
  },
});

export default journey;
