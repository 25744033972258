import { DynamicFormItemType, FlowInputTypes, IFlow, IFlowField, IFlowSession } from "config";
import { gql } from "config/graphql";
import { getApolloClient } from "store/apollo";

export const createFlowSessionMutation = (
  flowId: IFlow["id"],
  fromStart: boolean
): Promise<ICreateFlowSessionResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation createFlowSession($input: CreateFlowSessionInput!) {
      createFlowSession(input: $input) {
        flowSession {
          id
        }
      }
    }
  `;

  return client.mutate({
    variables: { input: { flowId, fromStart } },
    mutation,
  });
};

export const createFlowResponseMutation = (
  flowSessionId: IFlowSession["id"],
  fieldResponses: {
    fieldId: IFlowField["id"];
    response: { inputType: FlowInputTypes | DynamicFormItemType; value: any };
  }[]
): Promise<ICreateFlowResponseResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation createResponse($input: CreateResponseInput!) {
      createResponse(input: $input) {
        flowSession {
          id
        }
        field {
          id
          key
        }
        response
      }
    }
  `;

  return client.mutate({
    variables: {
      input: { flowSessionId, fieldResponses },
    },
    mutation,
  });
};

/* ------------       INTERFACE      ------------------ */

interface ICreateFlowSessionResponse {
  data: {
    createFlowSession: {
      flowSession: {
        id: IFlowSession["id"];
      };
    };
  };
}

interface ICreateFlowResponseResponse {
  data: {
    createResponse: ICreateResponse[];
  };
}

interface ICreateResponse {
  field: {
    id: IFlowField["id"];
    key: IFlowField["key"];
  };
  flowSession: {
    id: IFlowSession["id"];
  };
  response: {
    value: any;
    inputType: FlowInputTypes;
  };
}
