import { getReinstateablePolicies } from "store/actions/policy";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store";
import {
  getInsuranceMembershipId,
  getIsSessionVerified,
  selectReinstateablePolicies,
} from "store/selectors";

export const usePolicyReinstatementData = () => {
  const dispatch = useAppDispatch();

  const hasInsuranceMembership = !!useSelector(getInsuranceMembershipId);
  const isSessionVerified = useSelector(getIsSessionVerified);
  const isStartingReinstatement = useSelector(
    (state: RootState) => state.reinstateablePolicies.isRequestingToStartReinstatement
  );
  const { hasLoaded, isLoading, reinstateablePolicies } = useSelector(selectReinstateablePolicies);

  const fetchData = useCallback(async () => {
    if (hasInsuranceMembership && isSessionVerified) {
      return await dispatch(getReinstateablePolicies()).unwrap();
    }
  }, [dispatch, hasInsuranceMembership, isSessionVerified]);

  useEffect(() => {
    if (!hasLoaded && !isLoading) {
      fetchData();
    }
  }, [fetchData, hasLoaded, isLoading]);

  return {
    hasLoaded,
    isLoading,
    isStartingReinstatement,
    reinstateablePolicies,
    hasReinstatablePolicies: hasLoaded && reinstateablePolicies.length > 0,
    refetch: fetchData,
  };
};
