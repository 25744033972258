import { RouteDestination } from "config/types";
import { isString, startsWith } from "lodash-es";
import { parsePath } from "utils/parsePath";

const trimTrailingSlashes = (path: string) => path.replace(/\/+$/, "");
const normalizeSlashes = (path: string) => path.replace(/\/\/+/g, "/");
const joinPaths = (paths: string[]) => normalizeSlashes(paths.join("/"));
const splitPath = (path: string) => normalizeSlashes(path).split("/");

export const resolvePathname = (toPathname: string, fromPathname: string): string => {
  const segments = splitPath(trimTrailingSlashes(fromPathname));
  const relativeSegments = splitPath(toPathname);

  relativeSegments.forEach(segment => {
    if (segment === "..") {
      // Keep the root "" segment so the pathname starts at /
      if (segments.length > 1) segments.pop();
    } else if (segment !== ".") {
      segments.push(segment);
    }
  });

  return segments.length > 1 ? joinPaths(segments) : "/";
};

export const resolvePath = (to: RouteDestination, fromPathname = "/") => {
  const { pathname: toPathname, search = "", hash = "" } = isString(to) ? parsePath(to) : to;

  const pathname = toPathname
    ? resolvePathname(toPathname, startsWith(toPathname, "/") ? "/" : fromPathname)
    : fromPathname;

  return { pathname, search, hash };
};
