import "./OnboardingCarouselCard.scss";

import Text from "components/Text/Text";
import { FC, ReactNode } from "react";

interface ICarouselContent {
  title: string;
  subtitle: string | ReactNode;
  image: string;
}

interface IProps {
  content: ICarouselContent;
}

export const OnboardingCarouselCard: FC<IProps> = ({ content }) => {
  return (
    <div className="onboarding-carousel-card">
      <div className="onboarding-carousel-card__wrapper">
        <div className="onboarding-carousel-card__content">
          <Text tag="h3" text={content.title} className="onboarding-carousel-card__text--title" />
          <Text tag="p3" className="onboarding-carousel-card__text--subtitle">
            {content.subtitle}
          </Text>
        </div>
        <div className="onboarding-carousel-card__img">
          <img alt={content.image} src={content.image} />
        </div>
      </div>
    </div>
  );
};
