import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchPreVerifiedInitialData, fetchVerifiedInitialData } from "graphql/queries/appLoad";
import { get } from "lodash-es";
import { endRequest, startRequest } from "store/actions/request";
import { getActiveAccount } from "utils/account";
import { getAppError } from "utils/error";

/* ------------       THUNKS      ------------------ */

export const getPreVerifiedInitialData = createAsyncThunk(
  "appLoad/getPreVerifiedInitialData",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await fetchPreVerifiedInitialData();
      const me = get(data, "me");

      return { me };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const getVerifiedInitialData = createAsyncThunk(
  "appLoad/getVerifiedInitialData",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      await dispatch(startRequest("appLoad"));

      const { data } = await fetchVerifiedInitialData();
      const me = get(data, "me");

      const account = getActiveAccount(me);

      return { account, me };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    } finally {
      await dispatch(endRequest("appLoad"));
    }
  }
);
