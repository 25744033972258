import { RootState } from "store";
import { IEventFiredPayload } from "store/actions/analytics";

import { getDefaultAttrs } from "./defaults";

export const eventFired = (
  action: { [key: string]: IEventFiredPayload } = {},
  state: RootState
) => {
  const { event, attribute, experienceLocation } = action.payload;

  const attributes = {
    ...attribute,
    ...getDefaultAttrs(state, experienceLocation),
  };

  return {
    event,
    attribute: attributes,
  };
};
