import { motion } from "framer-motion";
import { ComponentPropsWithRef, forwardRef } from "react";
import { IModalTransitionVariants } from "./Modal";

interface IProps extends ComponentPropsWithRef<typeof motion.div> {
  variants: IModalTransitionVariants;
}

export const ModalOverlay = forwardRef<HTMLDivElement, IProps>(
  ({ variants, children, ...props }, ref) => {
    const variantsProps = {
      initial: "hidden",
      animate: "visible",
      exit: "hidden",
      variants,
    };

    return (
      <motion.div ref={ref} {...props} {...variantsProps}>
        {children}
      </motion.div>
    );
  }
);

ModalOverlay.displayName = "ModalOverlay";
