import { gql } from "config/graphql";
import { GQLUserResponse, ICoverageEditorPremium } from "config/types";
import { INSURANCE_APPLICATION_FIELDS } from "graphql/fragments";
import { CLASSIFICATION_FIELDS } from "graphql/fragments/classification";
import { PAYOUT_DETAILS_FIELDS } from "graphql/fragments/payoutDetails";
import { PRODUCT_DETAIL_FIELDS } from "graphql/fragments/product";
import { getApolloClient } from "store/apollo";

import { IAdjustCoverageInput } from "./estimate";

// GET QUOTE

export const getQuoteQuery = (): Promise<GQLUserResponse<"activeConstellations">> => {
  const client = getApolloClient();

  const query = gql`
    ${CLASSIFICATION_FIELDS}
    ${INSURANCE_APPLICATION_FIELDS}
    ${PRODUCT_DETAIL_FIELDS}
    ${PAYOUT_DETAILS_FIELDS}
    query pendingQuote {
      me {
        activeConstellations {
          activeInsuranceMembership {
            latestInsuranceApplication {
              ...InsuranceApplicationFields
            }
            pendingQuote {
              id
              amount
              termUnit
              termDuration
              allocationCoverage
              status
              insuredsAgeAtTerm
              extensionAvailable
              expiresAt
              insuranceApplication {
                id
                result
                version
              }
              product {
                ...ProductFields
              }
              payoutDetails {
                ...PayoutDetailsFields
              }
              classification {
                ...ClassificationFields
              }
              premiums {
                amount
                billingFrequency
              }
              documents {
                id
                name
                description
                slug
                html {
                  url
                  fileName
                }
                pdf {
                  url
                  fileName
                }
                version
              }
            }
          }
        }
      }
    }
  `;

  return client.query({ query });
};

// GET QUOTE PREMIUMS
interface IAdjustQuoteResponse {
  data: {
    adjustQuote: ICoverageEditorPremium;
  };
}

export const adjustQuoteQuery = (quote: IAdjustCoverageInput): Promise<IAdjustQuoteResponse> => {
  const client = getApolloClient();

  const query = gql`
    query adjustQuote($id: ID!, $termUnit: SharedTermUnit!, $termDuration: Int, $amount: Int) {
      adjustQuote(id: $id, termUnit: $termUnit, termDuration: $termDuration, amount: $amount) {
        annualPremium
        monthlyPremium
        allocationsCoveredPercentage
      }
    }
  `;

  return client.query({
    query,
    variables: quote,
  });
};
