import "./ToggleInput.scss";

import c from "classnames";
import { ClassName } from "config/types";
import { CSSProperties, FC } from "react";

interface IProps {
  checked: boolean;
  id?: string;
  style?: CSSProperties;
  className?: ClassName;
  dataTestId?: string;
  onChange?(event: IToggleInputEvent): void;
}

export interface IToggleInputEvent {
  target: {
    value: boolean;
    id: string;
  };
}

const ToggleInput: FC<IProps> = props => {
  const { checked, id, onChange, style, className, dataTestId } = props;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const changeEvent = {
      target: { value: event.target.checked, id: id || "" },
    };
    onChange && onChange(changeEvent);
  };

  const classes = c("toggle__container", className);

  return (
    <label className={classes} style={style} data-testid={dataTestId}>
      <input
        className="toggle__input"
        type="checkbox"
        checked={checked}
        onChange={handleOnChange}
        data-testid="toggle__input"
      />
      <span className="toggle__slider" />
    </label>
  );
};

export default ToggleInput;
