import "./SavingsApplicationTrustCTA.scss";

import { AdItem } from "components/Cards/Ad/MultipleAd/MultipleAdCard";
import { Card } from "modules/partials";
import { ICardProps } from "modules/partials/Card";
import { FC } from "react";

type IFlowAdContent = ICardProps["content"] & {
  items: AdItem[];
};

interface IProps extends ICardProps {
  content?: IFlowAdContent;
}

const SavingsApplicationTrustCTA: FC<IProps> = props => {
  const { content, ...rest } = props;

  return (
    <div className="savings-application-flow-ad-content">
      <Card as="MultipleAdCard" content={content} {...rest} />
    </div>
  );
};

export default SavingsApplicationTrustCTA;
