import { createReducer } from "@reduxjs/toolkit";
import { fetchTranslations } from "store/actions/translations";

export interface ITranslations {
  [key: string]: string;
}

const defaultState: ITranslations = {};

const translations = createReducer(defaultState, {
  [fetchTranslations.fulfilled.type]: (state, action) => {
    return action.payload;
  },
});

export default translations;
