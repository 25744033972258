import { replace } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";

export const useQueryParams = () => {
  const dispatch = useDispatch();
  const search = useSelector((state: RootState) => state.router.location.search);

  const queryParams = new URLSearchParams(search);

  const setParam = (param: string, value: string) => {
    queryParams.set(param, value);
    dispatch(replace({ search: queryParams.toString() }));
  };

  const deleteParam = (param: string) => {
    queryParams.delete(param);
    dispatch(replace({ search: queryParams.toString() }));
  };

  return { queryParams, deleteParam, setParam };
};
