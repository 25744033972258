import {
  ArchiveTransferScheduleInput,
  ArchiveTransferScheduleResponse,
  CreateFinanceTransferMutationInput,
  CreateFinanceTransferMutationResponse,
  EditTransferScheduleInput,
  EditTransferScheduleResponse,
  IFinanceScheduledTransfer,
} from "config";
import { gql } from "config/graphql";
import { getApolloClient } from "store/apollo";

export const createFinanceTransferMutation = (
  input: CreateFinanceTransferMutationInput
): Promise<CreateFinanceTransferMutationResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation CreateFinanceTransfer($input: CreateFinanceTransferInput!) {
      createFinanceTransfer(input: $input) {
        response {
          status
          success
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables: { input },
  });
};

export const editTransferScheduleMutation = (
  input: EditTransferScheduleInput
): Promise<EditTransferScheduleResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation EditTransferSchedule($input: EditTransferScheduleInput!) {
      editTransferSchedule(input: $input) {
        transferSchedule {
          action
          amount
          createdAt
          cycle
          externalAccountId
          id
          status
          transferDate
          updatedAt
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables: { input },
  });
};

export const archiveTransferScheduleMutation = (
  input: ArchiveTransferScheduleInput
): Promise<ArchiveTransferScheduleResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation ArchiveTransferSchedule($input: ArchiveTransferScheduleInput!) {
      archiveTransferSchedule(input: $input) {
        response {
          status
          success
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables: { input },
  });
};

type ICancelScheduledTransferResponse = {
  data: {
    createFinanceTransfer: {
      response: {
        status: string;
        success: boolean;
      };
    };
  };
};

export const cancelScheduledTransferMutation = (
  scheduledTransferId: IFinanceScheduledTransfer["id"]
): Promise<ICancelScheduledTransferResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation CancelScheduledTransfer($input: CancelScheduledTransferInput!) {
      cancelScheduledTransfer(input: $input) {
        response {
          status
          success
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables: { input: { scheduledTransferId } },
  });
};
