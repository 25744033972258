import FormError from "components/FormError/FormError";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC, useState } from "react";
import { useAppDispatch } from "store";
import { removeExternalAccount } from "store/actions/externalAccounts";
import { closeModal } from "store/actions/modal";

const RemoveLinkedAccountModal: FC<IModalProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const closeModalWrapper = () => dispatch(closeModal());

  const onClickConfirm = async (event: MouseEvent) => {
    event.preventDefault();
    try {
      setError("");
      setLoading(true);
      await dispatch(removeExternalAccount()).unwrap();

      closeModalWrapper();
    } catch (error) {
      setError(t("errors.graphql_error", "Oops, it’s us not you. There was an error."));
    } finally {
      setLoading(false);
    }
  };

  const onClickDecline = (event: MouseEvent) => {
    event.preventDefault();

    closeModalWrapper();
  };

  const content = {
    header: t("savings.transfer.external_account.remove.warning.title", "Are you sure?"),
    subheader: t(
      "savings.transfer.external_account.remove.warning.message",
      "You will need to link a new account before making future transfers in Wysh. In addition, any currently setup auto-deposits will be cancelled. We recommend replacing your linked account instead."
    ),
    button: [
      {
        isLoading: loading,
        disabled: loading,
        onClick: onClickConfirm,
        subType: "primary",
        text: t(
          "savings.transfer.external_account.remove.warning.cta.yes",
          "REMOVE LINKED ACCOUNT"
        ),
      },
      {
        disabled: loading,
        onClick: onClickDecline,
        subType: "text-dark-border",
        text: t("savings.transfer.external_account.remove.warning.cta.no", "NEVERMIND"),
      },
    ],
    onButtonClick: closeModal,
    onClose: closeModalWrapper,
    icon: "BlobAlert",
    alwaysShowIcon: true,
  };

  return (
    <Layout as="ModalLayout" className="remove-linked-account__modal" content={content}>
      <FormError error={error} />
    </Layout>
  );
};

export default RemoveLinkedAccountModal;
