import "./HonestyPledgeTerm.scss";

import {
  bifurcatedProductDisplayedEvent,
  bifurcatedProductNextEvent,
} from "analytics/productOfferEvents";
import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { push } from "connected-react-router";
import { Layout, useJourney } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC, useEffect } from "react";
import { useAppDispatch } from "store";

const HonestyPledgeTerm: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { nextStepCallback, previousStepCallback } = useJourney();

  const handleBack = async () => {
    const previousAction = previousStepCallback();
    previousAction();
  };

  const handleAccept = () => {
    dispatch(bifurcatedProductNextEvent("term"));

    nextStepCallback(() => {
      dispatch(push("/flow/application"));
    }, false)();
  };

  useEffect(() => {
    dispatch(bifurcatedProductDisplayedEvent("term"));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  interface approvalItem {
    text: string;
  }
  const approvalItemsList: approvalItem[] = [
    {
      text: t("honesty_pledge_v2.term.item.1", "No current drug and/or alcohol abuse"),
    },
    {
      text: t("honesty_pledge_v2.term.item.2", "No felony convictions"),
    },
    {
      text: t("honesty_pledge_v2.term.item.3", "Low body mass index (43 BMI and lower)"),
    },
    {
      text: t(
        "honesty_pledge_v2.term.item.4",
        "No recent history of cancer or serious heart problems"
      ),
    },
    {
      text: t("honesty_pledge_v2.term.item.5", "No current disabilities (or in the past 2 years)"),
    },
    {
      text: t(
        "honesty_pledge_v2.term.item.6",
        "No history of suicide attempts or recent hospitalizations from mental health conditions"
      ),
    },
  ];
  return (
    <Layout
      as="MessageLayout"
      content={{}}
      backNav={{
        hasBackNav: false,
        replaceExitInJourneyWithBack: true,
        onClick: handleBack,
      }}>
      <div className="honesty-pledge" data-testId="honesty-pledge-term">
        <div className="honesty-pledge__content">
          <Text
            tag="h5-header"
            className="honesty-pledge__subheader"
            text={t(
              "honesty_pledge_v2.term.header",
              "2. Sadly, not everyone is a good fit for our term life policy"
            )}
          />
          <Text
            tag="p3"
            className="honesty-pledge__agreement-content"
            text={t(
              "honesty_pledge_v2.term.body",
              "Because we offer best-in-class life insurance with strict eligibility requirements, not all applicants will be approved.\n\nApproval chances will be highest if all the following apply:"
            )}
          />
          <div className="honesty-pledge__approval-items">
            {approvalItemsList.map((item, index) => (
              <div className="honesty-pledge__approval-item" key={index}>
                <Icon
                  src="Check"
                  size="sm"
                  color="success-dark"
                  className="honesty-pledge__check"
                />
                <Text tag="l5" className="honesty-pledge__approval-item-text" text={item.text} />
              </div>
            ))}
          </div>
        </div>

        <Button
          className="full-width"
          text={t("honesty_pledge_v2.term.cta", "NEXT")}
          onClick={handleAccept}
          dataTestId="honesty-pledge-bifurcated__cta"
        />
      </div>
    </Layout>
  );
};

export default HonestyPledgeTerm;
