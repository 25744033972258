import { isEmpty } from "lodash-es";

export const isUUIDRegex = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

export const isUUID = (comparator: string) => {
  if (isEmpty(comparator)) return false;

  return isUUIDRegex.test(comparator);
};

export default isUUID;
