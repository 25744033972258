import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout, useTranslation } from "modules";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "store/actions/modal";

const BillingChargeFrequencyModal: FC<IModalProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Mock data
  const amount = "43.25";
  const date = "12/23/21";

  const handleSaveBilling = async () => {
    // TODO - integrate billing charge mutation
    closeModalWrapper();
  };

  const closeModalWrapper = () => {
    dispatch(closeModal());
  };

  const content = {
    header: t("settings.billing.charge_frequency.modal.title", "Switch to monthly payments?"),
    subheader: t(
      "settings.billing.charge_frequency.modal.body",
      "By confirming, your plan will be charged $%<amount>s on a monthly basis, starting from %<date>s. Cool?",
      { amount, date }
    ),
    onClose: closeModalWrapper,
    button: [
      {
        text: t("settings.billing.charge_frequency.modal.button_confirm", "YEP, COOL"),
        subType: "primary",
        onClick: handleSaveBilling,
      },
      {
        text: t("settings.billing.charge_frequency.modal.button_cancel", "CANCEL"),
        subType: "text-dark",
        onClick: closeModalWrapper,
      },
    ],
    icon: "BlobUpdate",
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default BillingChargeFrequencyModal;
