import { gql } from "config/graphql";
import {
  ICreateFinanceCustomerPayload,
  IInitiateFinanceVerificationInput,
  IInitiateFinanceVerificationPayload,
  IMembership,
  IUploadDocumentInput,
  IUploadDocumentPayload,
  ICloseFinanceAccountInput,
  ICloseFinanceAccountPayload,
} from "config/types";
import { FINANCE_CUSTOMER_FIELDS } from "graphql/fragments";
import { getApolloClient } from "store/apollo";

export const createFinanceCustomerMutation = (
  membershipId: IMembership["id"]
): Promise<ICreateFinanceCustomerPayload> => {
  const client = getApolloClient();

  const mutation = gql`
    ${FINANCE_CUSTOMER_FIELDS}
    mutation CreateFinanceCustomer($input: CreateFinanceCustomerInput!) {
      createFinanceCustomer(input: $input) {
        customer {
          ...FinanceCustomerFields
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables: { input: { membershipId } },
  });
};

export const initiateFinanceVerificationMutation = (
  input: IInitiateFinanceVerificationInput
): Promise<IInitiateFinanceVerificationPayload> => {
  const client = getApolloClient();

  const mutation = gql`
    ${FINANCE_CUSTOMER_FIELDS}
    mutation InitiateFinanceVerification($input: InitiateFinanceVerificationInput!) {
      initiateFinanceVerification(input: $input) {
        customer {
          ...FinanceCustomerFields
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables: { input },
  });
};

export const uploadDocumentMutation = (
  input: IUploadDocumentInput
): Promise<IUploadDocumentPayload> => {
  const client = getApolloClient();

  const mutation = gql`
    ${FINANCE_CUSTOMER_FIELDS}
    mutation UploadDocument($input: UploadDocumentInput!) {
      uploadDocument(input: $input) {
        customer {
          ...FinanceCustomerFields
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables: { input },
  });
};

export const closeFinanceAccountMutation = (
  input: ICloseFinanceAccountInput
): Promise<ICloseFinanceAccountPayload> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation CloseFinanceAccount($input: CloseFinanceAccountInput!) {
      closeFinanceAccount(input: $input) {
        clientMutationId
        response {
          status
          success
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables: { input },
  });
};
