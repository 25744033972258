import FormError from "components/FormError/FormError";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { push } from "connected-react-router";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC, MouseEvent, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store";
import { cancelScheduledTransfer } from "store/actions";
import { cancelBankTransaction } from "store/actions/financeBankAccount";
import { closeModal } from "store/actions/modal";

const CancelFinancialTransactionModal: FC<IModalProps> = props => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { externalId = "", scheduledTransferId = "" } = useSelector(
    (state: RootState) => state.modal.data
  );
  const [pending, setPending] = useState(false);
  const [error, setError] = useState("");

  const closeModalWrapper = () => dispatch(closeModal());

  const continueOnClick = async (event: MouseEvent) => {
    event.preventDefault();

    try {
      setPending(true);

      if (externalId) {
        await dispatch(cancelBankTransaction(externalId as string));
      } else if (scheduledTransferId) {
        await dispatch(cancelScheduledTransfer(scheduledTransferId as string));
      }

      dispatch(push("/products/savings"));

      closeModalWrapper();
    } catch (err) {
      setError(err.message);
    } finally {
      setPending(false);
    }
  };

  const cancelOnClick = () => {
    props.context?.callbacks?.closeDrawer && props.context.callbacks.closeDrawer();
    closeModalWrapper();
  };

  const content = {
    header: t("savings.activity.details.cancel_transfer.modal.header", "Cancel transfer?"),
    subheader: t(
      "savings.activity.details.cancel_transfer.modal.body",
      "Are you sure you want to cancel your transfer? The cancelation will go into effect immediately."
    ),
    onClose: closeModalWrapper,
    button: [
      {
        disabled: pending,
        isLoading: pending,
        onClick: continueOnClick,
        subType: "primary",
        text: t("savings.activity.details.cancel_transfer.modal.cta.primary", "CANCEL TRANSFER"),
      },
      {
        disabled: pending,
        onClick: cancelOnClick,
        subType: "text-dark",
        text: t("savings.activity.details.cancel_transfer.cta.secondary", "NEVERMIND"),
      },
    ],
    onButtonClick: closeModal,
    icon: "Refresh",
    alwaysShowIcon: true,
  };

  return (
    <Layout as="ModalLayout" content={content} className="left-align-sm">
      <FormError className="flex--justify-center" error={error} />
    </Layout>
  );
};

export default CancelFinancialTransactionModal;
