import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CreateOrInitiateExternalAccountInput,
  ExternalAccountType,
} from "config/types/externalAccount.type";
import {
  createOrInitiateExternalAccountMutation,
  fetchExternalAccountsQuery,
  removeExternalAccountMutation,
  verifyExternalAccountMutation,
} from "graphql/mutations/externalAccount";
import { upperFirst } from "lodash-es";
import { RootState } from "store";
import {
  getFinanceMembershipId,
  hasOpenedFinanceAccount,
  selectExternalAccount,
  selectFinanceBankAccount,
  selectFinanceCustomer,
} from "store/selectors";
import { getAppError } from "utils/error";

/* ------------       THUNKS      ------------------ */

export const verifyExternalAccount = createAsyncThunk(
  "externalAccounts/verifyExternalAccount",
  async (
    { amount1, amount2 }: { amount1: string; amount2: string },
    { getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const externalAccountId = selectExternalAccount(state).externalAccountId;
      const membershipId = getFinanceMembershipId(state);

      if (!externalAccountId || !membershipId) {
        throw Error("Missing input values. Please try again later");
      }

      const input = {
        amount1: parseFloat(amount1),
        amount2: parseFloat(amount2),
        externalAccountId: externalAccountId,
        membershipId,
      };

      const { data } = await verifyExternalAccountMutation(input);
      return data.verifyExternalAccount.response.success;
    } catch (err) {
      const errorCode = getAppError(err);
      return rejectWithValue(errorCode);
    }
  }
);

export const fetchExternalAccounts = createAsyncThunk(
  "externalAccounts/fetchExternalAccounts",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const membershipId = getFinanceMembershipId(state);

      if (!membershipId) {
        throw Error("Missing input values. Please try again later");
      }

      const { data } = await fetchExternalAccountsQuery(membershipId);
      return data.externalAccounts;
    } catch (err) {
      const errorCode = getAppError(err);
      return rejectWithValue(errorCode);
    }
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState;
      const hasFinanceAccount = hasOpenedFinanceAccount(state);

      if (!hasFinanceAccount) {
        return false;
      }
    },
  }
);

export const initiateExternalAccount = createAsyncThunk(
  "externalAccounts/initiateExternalAccount",
  async (
    {
      accountNumber = "",
      accountType,
      plaidAccountId = null,
      routingNumber = null,
    }: CreateOrInitiateExternalAccountInput,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const membershipId = getFinanceMembershipId(state);
      const financeBankAccount = selectFinanceBankAccount(state);
      const accountTypeInput = upperFirst(accountType) as ExternalAccountType;

      if (!financeBankAccount || !membershipId) {
        throw Error("Missing input values. Please try again later");
      }

      const input = {
        accountNumber: accountNumber || financeBankAccount.accountNumber,
        accountType: accountTypeInput || null,
        routingNumber: routingNumber || financeBankAccount.routingNumber,
        membershipId,
        plaidAccountId,
      };

      const { data } = await createOrInitiateExternalAccountMutation(input);
      await dispatch(fetchExternalAccounts());
      return data.externalAccounts;
    } catch (err) {
      const errorCode = getAppError(err);
      return rejectWithValue(errorCode);
    }
  }
);

export const removeExternalAccount = createAsyncThunk(
  "externalAccounts/removeExternalAccount",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const financeCustomer = selectFinanceCustomer(state);
      const externalAccount = selectExternalAccount(state);

      const input = {
        customerId: financeCustomer.id,
        externalAccountId: externalAccount.externalAccountId,
      };

      const { data } = await removeExternalAccountMutation(input);
      await dispatch(fetchExternalAccounts());

      return data.removeExternalAccount.response;
    } catch (err) {
      const errorCode = getAppError(err);
      return rejectWithValue(errorCode);
    }
  }
);
