import { reduce } from "lodash-es";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store";
import { getWyshesCategories } from "store/actions/wyshes";
import { IWyshCategoryState } from "store/reducers/wyshCategories";

export const useAddWyshCategories = () => {
  const dispatch = useAppDispatch();
  const categories = useSelector((state: RootState) => state.wyshCategories.categories);

  useEffect(() => {
    if (!categories.length) {
      dispatch(getWyshesCategories());
    }
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return reduce(
    categories,
    (hashCategory: Record<string, IWyshCategoryState>, category) => {
      const newCategory = { ...hashCategory, [category.slug]: category };
      return newCategory;
    },
    {}
  );
};
