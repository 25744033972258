import { getProductOfferingAttr } from "analytics/defaults";
import { fulfillmentQuoteAcceptedEvent } from "analytics/fulfillmentEvents";
import { productOfferEditEvent } from "analytics/productOfferEvents";
import Currency from "components/Currency/Currency";
import FormError from "components/FormError/FormError";
import {
  ProductOfferingColumn,
  ProductOfferingContainer,
  ProductOfferingDetails,
  ProductOfferingDetailsHeader,
  ProductOfferingDetailsItem,
  ProductOfferingHeader,
  ProductOfferingPremiumCalculation,
  ProductOfferingPremiumInfo,
  ProductOfferingSubheader,
  ProductOfferingValueProps,
} from "components/ProductOffering/ProductOffering";
import TLFAQ from "components/TLFAQ/TLFAQ";
import { defaultTo, find, get } from "lodash-es";
import { Layout, useJourney, useTranslation } from "modules";
import { MouseEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { eventFired } from "store/actions/analytics";
import { setModal } from "store/actions/modal";
import { acceptProductOffering } from "store/actions/product-offerings";
import { getQuote } from "store/actions/quote";
import { getWyshTermLifeOffering } from "store/selectors";
import { openChat } from "utils/customerSupport";
import { poll } from "utils/poll";

interface IProps {
  onClickEdit(offeringType: "termLifeOffering" | "adOffering"): void;
}

function CaseResultTerm({ onClickEdit }: IProps) {
  const dispatch = useAppDispatch();
  const { nextStepCallback } = useJourney();
  const { t } = useTranslation();

  const productOffering = useSelector(getWyshTermLifeOffering);
  const productOfferingAttr = useSelector(getProductOfferingAttr);

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const premiumAmount = productOffering?.premiums?.find(premium => {
    return premium.billingFrequency === "monthly";
  })?.amount;

  const getQuotePolling = async () => {
    const payload = await dispatch(getQuote()).unwrap();
    const quoteDocuments = get(payload, "documents", []);

    return !!find(quoteDocuments, ({ slug }) => slug === "quote")?.id;
  };

  const handleEdit = async () => {
    dispatch(productOfferEditEvent("term"));
    onClickEdit("termLifeOffering");
  };

  const handleChatClick = (event: MouseEvent<HTMLParagraphElement | HTMLHeadingElement>) => {
    event.stopPropagation();

    dispatch(
      eventFired({
        event: "fulfillment_quote_displayed_help",
        experienceLocation: "ONBOARDING",
        attribute: {
          faqname: "Chat with us",
        },
      })
    );

    openChat();
  };

  const handlePremiumCalculationModalOpen = () => dispatch(setModal("PremiumCalculationModal"));

  const handlePurchase = async () => {
    if (!productOffering) {
      return;
    }

    try {
      setIsLoading(true);

      await dispatch(acceptProductOffering(productOffering.id)).unwrap();

      await poll(getQuotePolling);

      dispatch(fulfillmentQuoteAcceptedEvent());

      nextStepCallback()();
    } catch (err) {
      setError(defaultTo(err.fullMessage, err.code));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(
      eventFired({
        event: "fulfillment_quote_displayed_pageview",
        attribute: {
          productOffering: productOfferingAttr,
        },
      })
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout as="TLProductOfferingLayout" backNav={{ hasBackNav: true }}>
      <ProductOfferingContainer className="tl-product-offering-layout__product-offering">
        <ProductOfferingColumn className="product-offering__column">
          <ProductOfferingHeader className="product-offering__header">
            {t("review_quotev2.term.header.title", "Your quote is ready for review")}
          </ProductOfferingHeader>
          <ProductOfferingSubheader className="product-offering__subheader">
            {t(
              "review_quotev2.term.header.subtitle",
              "Congratulations! Your application for Term Life Insurance has been approved."
            )}
          </ProductOfferingSubheader>

          <ProductOfferingPremiumInfo
            className="product-offering__premium-info"
            cta={{
              onClick: handlePurchase,
              text: t("review_quotev2.term.offer.cta", "PURCHASE TERM LIFE"),
            }}
            expiresAt={productOffering?.expiresAt}
            isLoading={isLoading}
            premiumAmount={premiumAmount}
          />

          {!!error && <FormError error={error} />}

          <ProductOfferingDetails className="product-offering__details">
            <ProductOfferingDetailsHeader onEditClick={handleEdit} />

            <ProductOfferingDetailsItem
              description={t(
                "review_quotev2.term.offer.info.coverage.description",
                "Total coverage"
              )}
              value={
                productOffering?.amount ? (
                  <Currency amount={productOffering?.amount} fixedPoints={0} />
                ) : (
                  t("dollar.amount", "$%<amount>s", { amount: "-----" })
                )
              }
            />
            <ProductOfferingDetailsItem
              description={t("review_quotev2.term.offer.info.term.description", "Until you're")}
              value={t("review_quotev2.term.offer.info.term.value", "%<number>s y/o", {
                number: productOffering?.insuredsAgeAtTerm || "--",
              })}
            />
          </ProductOfferingDetails>

          <ProductOfferingPremiumCalculation
            className="product-offering__premium-calculation"
            onClick={handlePremiumCalculationModalOpen}
          />
        </ProductOfferingColumn>

        <ProductOfferingColumn className="product-offering__column product-offering__column--sidebar">
          <ProductOfferingValueProps
            className="product-offering__value-props"
            onChatClick={handleChatClick}
          />
        </ProductOfferingColumn>
      </ProductOfferingContainer>

      <TLFAQ />
    </Layout>
  );
}

export default CaseResultTerm;
