import "./Header.scss";

import { welcomeLoginCtaEvent } from "analytics/onboardingEvents";
import classNames from "classnames";
import Text from "components/Text/Text";
import HeaderAccountButton from "containers/Header/HeaderAccountButton";
import HeaderHelpButton from "containers/Header/HeaderHelpButton";
import HeaderLogo from "containers/Header/HeaderLogo";
import HeaderMenu from "containers/Header/HeaderMenu";
import HeaderNavigation from "containers/Header/HeaderNavigation";
import WyshBuilderLiteCta from "containers/WyshBuilderLite/WyshBuilderLiteCta";
import { useCoBranding, useImmersiveMode, useTranslation } from "modules";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getIsLoggedIn } from "store/selectors";
import HeaderExitButton from "./HeaderExitButton";

function Header() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const headerRef = useRef<HTMLDivElement | null>(null);

  const { isCoBranded } = useCoBranding();
  const isImmersiveMode = useImmersiveMode();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const isOnboardingMode = ["/products/select", "/products/insurance/welcome"].includes(
    location.pathname
  );
  const isWyshBuilder = ["/products/insurance/wysh-builder/list", "/wysh-builder/list"].includes(
    location.pathname
  );
  const [isTransparent, setIsTransparent] = useState(true);

  const handleTransparency = () => {
    if (headerRef.current) {
      const currentYPos = window.scrollY;
      const headerPos = headerRef.current?.getBoundingClientRect().bottom / 2;

      setIsTransparent(currentYPos <= headerPos);
    }
  };

  const onClickLogin = () => {
    dispatch(welcomeLoginCtaEvent());
  };

  useEffect(() => {
    window.addEventListener("scroll", handleTransparency);

    return () => window.removeEventListener("scroll", handleTransparency);
  }, []);

  return (
    <header
      className={classNames("header", {
        "header--authenticated": isLoggedIn,
        "header--immersive": isImmersiveMode,
        "header--transparent": isTransparent,
      })}
      ref={headerRef}>
      <div className="header__section header__section--left">
        <div className="header__portal" id="header__portal" />
        {(!isImmersiveMode || isCoBranded) && <HeaderLogo className="header__logo" />}
      </div>

      <div className="header__section header__section--center">
        {!isImmersiveMode && !isCoBranded && <HeaderNavigation className="header__navigation" />}
        {isImmersiveMode && !isCoBranded && <HeaderLogo />}
      </div>

      <div className="header__section header__section--right">
        {isLoggedIn && (
          <>
            {/* Only shows on desktop via CSS */}
            <HeaderHelpButton
              className="header__help"
              ctaText={t("tab_nav.item.6.title", "Support")}
            />

            {!isImmersiveMode && (
              <>
                {/* Only shows on desktop via CSS */}
                <HeaderAccountButton className="header__account" />

                {/* Only shows on mobile and tablet via CSS */}
                <HeaderMenu className="header__menu" />
              </>
            )}
          </>
        )}

        {!isLoggedIn && (
          <>
            {isWyshBuilder ? (
              <WyshBuilderLiteCta
                className="header__wysh-builder-cta appear-hidden appear-initial@large"
                dataTestId="header__wysh-builder-cta"
                type="header"
              />
            ) : (
              <>
                {isOnboardingMode && (
                  <Link
                    className="header__login"
                    data-testid="header__login"
                    onClick={onClickLogin}
                    to="/login">
                    <Text tag="l4-label" text={t("welcome.secondary", "Login")?.toLowerCase()} />
                  </Link>
                )}
                <HeaderHelpButton
                  className="header__help"
                  ctaText={t("tab_nav.item.6.title", "Support")}
                />
                {!isImmersiveMode && (
                  <>
                    <HeaderExitButton className="header__exit" />
                    <HeaderMenu className="header__menu" />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </header>
  );
}

export default Header;
