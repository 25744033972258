import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { LockTypeStrings } from "config/types";
import { exchangeBlackboxMutation } from "graphql/mutations/account";
import { fetchAccountCapabilities } from "graphql/queries/account";
import { fetchEligibleForUnderwriting } from "graphql/queries/eligibleForUnderwriting";
import { first } from "lodash-es";
import { FINANCE_APPLICATION_DISABLED, LOCK_ACCOUNT, UNLOCK_ACCOUNT } from "store/constants";
import { getActiveAccount } from "utils/account";
import { getAppError } from "utils/error";
import { getBlackbox } from "utils/fraud";

/* ------------    ACTIONS     ------------------ */

export const lockAccount = createAction<LockTypeStrings>(LOCK_ACCOUNT);

export const unlockAccount = createAction(UNLOCK_ACCOUNT);

export const disableFinanceOnboarding = createAction(FINANCE_APPLICATION_DISABLED);

export const exchangeBlackbox = createAsyncThunk(
  "fraud/exchangeBlackbox",
  async (_, { rejectWithValue }) => {
    try {
      const blackboxData = getBlackbox();

      if (!blackboxData) {
        throw new Error("Could not retreive blackbox");
      }

      const { data } = await exchangeBlackboxMutation({ blackbox: blackboxData.blackbox });

      return data.exchangeBlackbox;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const getAccountCapabilities = createAsyncThunk(
  "account/getAccountCapailities",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await fetchAccountCapabilities();

      const activeConstellation = first(data.me.activeConstellations);
      const activeInsuranceMembership = activeConstellation?.activeInsuranceMembership;

      return activeInsuranceMembership?.capabilities;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const getEligibleForCoverageUnderwriting = createAsyncThunk(
  "account/getEligibleForCoverageUnderwriting",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchEligibleForUnderwriting();
      const activeAccount = getActiveAccount(response.data.me);

      const eligibleForCoverage = activeAccount?.eligibleForCoverage;
      const eligibleForUnderwriting = activeAccount?.eligibleForUnderwriting;
      const hasPolicy = !!activeAccount?.activePolicy?.id;

      return { eligibleForCoverage, eligibleForUnderwriting, hasPolicy };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
