import "./BeneficiaryReview.scss";

import ScreenLoader from "components/ScreenLoader/ScreenLoader";
import Text from "components/Text/Text";
import { ButtonFormItem, DynamicFormCriteriaFlowScope, IDynamicForm } from "config";
import { BeneficiaryReviewContact } from "containers/BeneficiaryReview/BeneficiaryReviewContact/BeneficiaryReviewContact";
import { ButtonItem } from "containers/DynamicForm/DynamicFormItems";
import { ConditionalWrapper, useBeneficiariesData, useTranslation } from "modules";
import { useMemo } from "react";
import { useAppSelector } from "store";
import { BeneficiaryReviewClaimsLink } from "./BeneficiaryReviewClaimsLink/BeneficiaryReviewClaimsLink";

export function BeneficiaryReview({ items }: IBeneficiaryReview) {
  // HOOKS
  const { t } = useTranslation();
  const flowScope = useAppSelector(state => state.formFlow.flowScope);
  const { backup, beneficiaries, beneficiariesPending } = useBeneficiariesData(true, flowScope);

  const isApplicationScope = useMemo(() => {
    const targetScope: DynamicFormCriteriaFlowScope = "application";
    return targetScope === flowScope;
  }, [flowScope]);

  const addPrimaryButton = items.find(
    item => item.key === ADD_PRIMARY_BUTTON_KEY
  ) as ButtonFormItem;
  const editAllocationsButton = items.find(
    item => item.key === EDIT_ALLOCATIONS_BUTTON_KEY
  ) as ButtonFormItem;
  const addBackupButton = items.find(item => item.key === ADD_BACKUP_BUTTON_KEY) as ButtonFormItem;
  const primaryButton = items.find(item => item.key === PRIMARY_BUTTON) as ButtonFormItem;

  // RENDER
  if (beneficiariesPending) {
    return <ScreenLoader />;
  }

  return (
    <div className="beneficiary-review">
      <ConditionalWrapper condition={isApplicationScope}>
        <section className="beneficiary-review__section">
          <div className="beneficiary-review__header">
            <Text className="dynamic-form-review-layout-header__title" tag="h4">
              {t("beneficiary.review.header", "Please review your beneficiaries")}
            </Text>
            <Text className="dynamic-form-review-layout-header__subtitle" tag="p4">
              {t(
                "beneficiary.review.subheader",
                "You will be able to add and edit your beneficiaries at any time after your policy is activated."
              )}
            </Text>
          </div>
        </section>
      </ConditionalWrapper>

      <section className="beneficiary-review__section">
        <div className="beneficiary-review__beneficiaries-list">
          {beneficiaries.map(beneficiary => {
            return <BeneficiaryReviewContact beneficiary={beneficiary} key={beneficiary.id} />;
          })}
        </div>
        <ButtonItem className="beneficiary-review__add-beneficiary-btn" item={addPrimaryButton} />
      </section>

      <section className="beneficiary-review__section">
        <ButtonItem item={editAllocationsButton} />
      </section>

      <section className="beneficiary-review__section beneficiary-review__section-backup">
        <div className="beneficiary-review__section-backup-wrapper">
          <div className="beneficiary-review__section-backup__title flex--center-space-between">
            <Text tag="c5" className="uppercase">
              {t("beneficiary.review.backup.header", "CONTINGENT BENEFICIARY")}
            </Text>
          </div>
          {backup && <BeneficiaryReviewContact beneficiary={{ ...backup, allocation: 100 }} />}
          <ConditionalWrapper condition={!backup && !!addBackupButton}>
            <Text tag="p3">
              {t(
                "beneficiary.review.backup.body",
                "Your contingent beneficiary will receive your death benefit if your primary beneficiaries are also deceased at the time of claim."
              )}
            </Text>
            <ButtonItem
              className="beneficiary-review__add-beneficiary-btn"
              item={addBackupButton}
            />
          </ConditionalWrapper>
        </div>
      </section>

      <ConditionalWrapper condition={!!primaryButton}>
        <section className="beneficiary-review__section">
          <ButtonItem item={primaryButton} />
        </section>
      </ConditionalWrapper>

      <ConditionalWrapper condition={!isApplicationScope}>
        <section className="beneficiary-review__section">
          <BeneficiaryReviewClaimsLink />
        </section>
      </ConditionalWrapper>
    </div>
  );
}

/* ------------------    TYPES    ------------------ */
interface IBeneficiaryReview {
  items: IDynamicForm["items"];
}

const ADD_PRIMARY_BUTTON_KEY = "add-primary-button";
const EDIT_ALLOCATIONS_BUTTON_KEY = "edit-allocations-button";
const ADD_BACKUP_BUTTON_KEY = "add-backup-button";
const PRIMARY_BUTTON = "primary-button";
