import Text from "components/Text/Text";
import { ClassName } from "config/types";
import { toString } from "lodash-es";
import { useTranslation } from "modules";
import { CSSProperties, FC } from "react";

interface IProps {
  amount: number | string;
  className?: ClassName;
  style?: CSSProperties;
  dataTestId?: string;
  dynamicSize?: boolean;
}

const PriceText: FC<IProps> = props => {
  const { amount = 0.0, className, style, dataTestId, dynamicSize = true } = props;
  const { t } = useTranslation();

  const splitPrice = toString(amount).split(".");

  const dollarPrice = splitPrice[0] ? `${splitPrice[0]}` : "0";
  const centsPrice = splitPrice[1] ? `.${splitPrice[1]}` : "";

  const dollarText = t("dollar.amount", "$%<amount>s", { amount: dollarPrice });

  const dollarTextTag = dollarPrice.length <= 2 && dynamicSize ? "n2" : "n3";
  const centsTextTag = dollarPrice.length <= 2 ? "l2" : "l3";

  return (
    <div className="flex--baseline" style={style} data-testid={dataTestId}>
      <Text className={className} tag={dollarTextTag} text={dollarText} />
      <Text className={className} tag={centsTextTag} text={centsPrice} />
    </div>
  );
};

export default PriceText;
