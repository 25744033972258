import "./ImageBackgroundLayout.scss";

import classNames from "classnames";
import Button from "components/Button/Button";
import Text, { TextType } from "components/Text/Text";
import { ButtonSubType, ClassName, ValueOrArray } from "config/types";
import Container from "containers/Container/Container";
import { flatten, map } from "lodash-es";
import { ILayoutProps } from "modules";
import { FC } from "react";
import { Link } from "react-router-dom";

type ContentType = {
  header: string;
  subheader: ValueOrArray<string>;
  text: ValueOrArray<string>;
  linkPath: string;
  linkText: string;
  imgSrc: string;
  button: string;
  buttonTestId?: string;
  buttonType?: ButtonSubType;
  buttonTag?: TextType;
  backNavPath?: string;
  backNavIconType?: string;
  backNavText?: string;
  backNav?: boolean;
  reverseCol?: boolean;
  headerClassName?: ClassName;
  onButtonClick?: () => void;
};

const ImageBackgroundLayout: FC<ILayoutProps<ContentType>> = props => {
  const { children, className } = props;

  const content = props.content as ContentType;
  const header = content.header as string;
  const headerClassName = content?.headerClassName;
  const subheader = content?.subheader;
  const subheaderList = flatten([subheader]);
  const text = content?.text;
  const textList = flatten([text]);
  const linkPath = content?.linkPath;
  const linkText = content?.linkText;
  const imgSrc = content?.imgSrc;
  const buttonContent = content?.button;
  const onButtonClick = content?.onButtonClick;
  const buttonTestId = content?.buttonTestId;
  const buttonType = content?.buttonType;
  const buttonTag = content?.buttonTag;

  const classes = classNames("image-background-layout", className);
  const headerClasses = classNames("image-background-layout__header h2", headerClassName);

  return (
    <Container className={classes}>
      <div className="image-background-layout__wrapper">
        {/* ///-----------  CONTENT -----------///  */}
        <div className="image-background-layout__left">
          <Text className={headerClasses} tag="h1" text={header} />
          {linkPath && linkText && (
            <Link className="link image-background-layout__link l4-tag__text" to={linkPath}>
              {linkText || "Next"}
            </Link>
          )}
          {map(subheaderList, (item: string, index: number) => (
            <Text className="image-background-layout__subheader" tag="p2" text={item} key={index} />
          ))}
          {buttonContent && (
            <Button
              className="image-background-layout__header-button"
              subtype={buttonType || "primary"}
              onClick={onButtonClick}
              text={buttonContent}
              tag={buttonTag}
              dataTestId={buttonTestId}
            />
          )}
          {map(textList, (item: string, index: number) => (
            <Text tag="p1" text={item} key={index} />
          ))}
          {children}
        </div>

        {/* ///-----------  IMAGE BACKGROUND  -----------/// */}
        <div className="image-background-layout__right">
          <div className="image-background-layout__image-wrapper">
            {imgSrc && <img alt="" src={imgSrc} className="image-background-layout__image" />}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ImageBackgroundLayout;
