import { productAvailableInStateQuery } from "graphql/queries/productOffering";
import { useEffect, useState } from "react";
import { useAppDispatch } from "store";
import { getPreVerifiedInitialData } from "store/actions/appLoad";

export const useIsADAvailableInState = () => {
  const dispatch = useAppDispatch();
  const [adEligible, setADEligible] = useState<boolean | null | undefined>(null);

  useEffect(() => {
    const isUserADEligible = async () => {
      try {
        const userId = await (await dispatch(getPreVerifiedInitialData()).unwrap()).me.state.id;

        const isADAvailableInStateResponse = await productAvailableInStateQuery({
          businessSlug: "wysh-insurance",
          productSlug: "wysh-accidental-death",
          stateId: userId,
        });

        const isADAvailableInState =
          isADAvailableInStateResponse.data.productAvailableInState === true;

        setADEligible(isADAvailableInState);
      } catch (err) {
        setADEligible(undefined);
      }
    };

    isUserADEligible();
  }, [dispatch]);

  return adEligible;
};
