import { GQLUserResponse } from "config";
import { gql } from "config/graphql";
import { INVOICE_FIELDS } from "graphql/fragments";
import { getApolloClient } from "store/apollo";

export const fetchCurrentUserLatestPayableInvoice = (): Promise<GQLUserResponse<
  "activeConstellations"
>> => {
  const client = getApolloClient();

  const query = gql`
    ${INVOICE_FIELDS}
    query userInvoice {
      me {
        activeConstellations {
          activeInsuranceMembership {
            latestPayableInvoice {
              ...InvoiceFields
            }
          }
        }
      }
    }
  `;

  return client.query({
    query,
  });
};

export const fetchCurrentUserInvoices = (): Promise<GQLUserResponse<"activeConstellations">> => {
  const client = getApolloClient();

  const query = gql`
    ${INVOICE_FIELDS}
    query userInvoices {
      me {
        activeConstellations {
          activeInsuranceMembership {
            activePolicy {
              lapsesAt
            }
            invoicesPastDueBalance
            invoicesProcessingAmount
            pastDueInvoices: invoices(filterBy: "eligibleUnpaid") {
              ...InvoiceFields
            }
            processingInvoices: invoices(filterBy: "processing") {
              ...InvoiceFields
            }
          }
        }
      }
    }
  `;

  return client.query({ query });
};
