import { Condition, IJourneyStepNavigation, IReinstateablePolicy, ValueOrArray } from "config";
import { ConditionKey } from "config/conditions";
import { push, replace } from "connected-react-router";
import {
  compact,
  every,
  filter,
  find,
  findIndex,
  first,
  flatten,
  get,
  isEmpty,
  isEqual,
  isFunction,
  last,
  some,
} from "lodash-es";
import { useCondition } from "modules";
import { JourneySectionContext } from "modules/managers/JourneySectionProvider";
import { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { getEligibleForCoverageUnderwriting } from "store/actions/account";
import {
  clearJourneyActive,
  finishJourney,
  setJourneyInactive,
  setJourneyStep,
  setShowJourneyProgress,
  startJourney as startJourneyAction,
} from "store/actions/journey";
import { getProductOfferings } from "store/actions/product-offerings";
import { local } from "store2";
import { scrollToTop } from "utils/scrollToTop";

const parseBackNavMetadata = (
  backNavMetadata: boolean | ValueOrArray<IJourneyStepNavigation> | undefined,
  conditions: Condition<boolean>
) => {
  const flattenedBackNav = compact(flatten([backNavMetadata]));
  const filteredBackNavMetadata = find(flattenedBackNav, meta => {
    if (typeof meta === "boolean") {
      return true;
    }

    const stepConditions = compact(flatten([meta.conditions]));
    if (isEmpty(stepConditions)) {
      return true;
    }

    const allConditionsMet = every(stepConditions, condition =>
      get(conditions, condition as ConditionKey, false)
    );
    return allConditionsMet;
  });

  return filteredBackNavMetadata;
};

export const useJourney = <TData = Record<string, unknown>>() => {
  const dispatch = useDispatch();
  const journey = useSelector((state: RootState) => state.journey);
  const userState = useSelector((state: RootState) => state.user);
  const anyJourneysActive = !isEmpty(journey.activeJourneys);
  const currentStepKey = journey.steps.active;
  const currentStep = get(journey.steps.collection, currentStepKey);
  const { activateNextSection, isLastSectionActive } = useContext(JourneySectionContext);
  const conditions = useCondition({});

  const userId = userState.id;

  const startJourney = useCallback(
    (
      journeyId: string,
      context: {
        startingStep?: string;
        data?: Record<string, unknown>;
        metadata?: Record<string, unknown>;
      } = {}
    ) => {
      const isJourneyActive =
        some(journey.activeJourneys, journeyId) ||
        isEqual(get(journey.collection, [journeyId, "active"]), true);
      if (isJourneyActive) return;

      const { startingStep, data, metadata } = context;
      const contextData = data || {};
      scrollToTop({ behavior: "auto" });
      dispatch(startJourneyAction(journeyId, false, { data: contextData, metadata }));
      if (startingStep && !isEmpty(startingStep)) dispatch(setJourneyStep(startingStep));
    },
    [dispatch, journey.activeJourneys, journey.collection]
  );

  const startFulfillmentJourney = useCallback(async () => {
    await dispatch(getProductOfferings());
    const response = await dispatch(getEligibleForCoverageUnderwriting());

    const eligibleForCoverage = get(response, "payload.eligibleForCoverage", false);
    const eligibleForUnderwriting = get(response, "payload.eligibleForUnderwriting", true);
    // users coming from affiliate-estimate will not have a registered state and can ignore the eligibleForCoverage flag returning false
    const isAffiliateUserWithoutStateData = !eligibleForCoverage && !userState.state.id;
    const hasPolicy = get(response, "payload.hasPolicy", false);

    if (hasPolicy) {
      dispatch(replace("/"));
    } else if (
      eligibleForUnderwriting &&
      (eligibleForCoverage || isAffiliateUserWithoutStateData)
    ) {
      startJourney("fulfillment");
      dispatch(setShowJourneyProgress(true));
    } else if (eligibleForCoverage === false) {
      await dispatch(push("/coverage-denied/state_ineligible"));
    } else if (eligibleForUnderwriting === false) {
      await dispatch(push("/coverage-denied/rejected"));
    }
    // TODO: Check for latestPayableInvoice if the user already has a policy and are missing an active payment method.
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const startReinstatementFulfillmentJourney = useCallback(
    async (reinstateablePolicyId: IReinstateablePolicy["id"]) => {
      startJourney("reinstatementFulfillment", { metadata: { reinstateablePolicyId } });
      dispatch(setShowJourneyProgress(true));
    },
    [] //eslint-disable-line react-hooks/exhaustive-deps
  );

  const previousStepCallback = useCallback(
    (fc?: (...args: any[]) => unknown) => {
      return (...args: any[]) => {
        scrollToTop({ behavior: "auto" });

        if (!anyJourneysActive) {
          if (isFunction(fc)) fc(args);
        } else {
          const latestJourneyId = last(journey.activeJourneys) || "";
          const latestJourney = get(journey.collection, latestJourneyId);

          const currentStepIndex = findIndex(latestJourney.steps, val =>
            isEqual(val, currentStepKey)
          );

          const backNavMetadata = currentStep?.navigation?.back;
          const targetBackNavMetadata = parseBackNavMetadata(backNavMetadata, conditions);
          const targetStepKey =
            typeof targetBackNavMetadata === "boolean" ? undefined : targetBackNavMetadata?.key;
          const previousStepKey = targetStepKey || latestJourney.steps[currentStepIndex - 1];
          const previousStep = find(journey.steps.collection, step => {
            return isEqual(previousStepKey, step.id) || isEqual(previousStepKey, step.key);
          });

          const previousStepType = get(previousStep, "type");
          const isFirstStepInJourney = isEqual(currentStepKey, first(latestJourney.steps));

          if (previousStepType === "journey" && !isFirstStepInJourney) {
            dispatch(startJourneyAction(previousStepKey, true));
          } else {
            if (isFirstStepInJourney) {
              const isJourneyNested = !isEmpty(latestJourney.parent);
              const parentJourney = get(journey.collection, latestJourney?.parent || "");
              const parentJourneyIndex = findIndex(get(parentJourney, "steps"), item =>
                isEqual(item, latestJourneyId)
              );
              const nextParentJourneyStep = parentJourney?.steps[parentJourneyIndex - 1];

              if (isJourneyNested) {
                dispatch(setJourneyInactive(latestJourneyId));
                dispatch(setJourneyStep(nextParentJourneyStep));
              } else {
                return;
              }
            } else {
              dispatch(setJourneyStep(previousStepKey));
            }
          }
        }
      };
    },
    [
      anyJourneysActive,
      journey.activeJourneys,
      journey.collection,
      journey.steps.collection,
      currentStep,
      currentStepKey,
      conditions,
      dispatch,
    ]
  );

  const nextStepCallback = useCallback(
    (
      fc: ((...args: any[]) => unknown) | undefined = undefined,
      concurrent = true,
      skipSection = false
    ) => {
      return async (...args: any[]) => {
        scrollToTop({ behavior: "auto" });

        if (concurrent || !anyJourneysActive) {
          if (isFunction(fc)) await fc(args);
        }
        if (anyJourneysActive) {
          const latestJourneyId = last(journey.activeJourneys) as string;
          const latestJourney = get(journey.collection, latestJourneyId);
          const latestJourneySteps = filter(latestJourney.steps, stepKey => {
            const step = get(journey.steps.collection, stepKey);
            const stepConditions = flatten([step.conditions]);
            if (isEmpty(stepConditions)) return true;
            const allConditionsMet = every(stepConditions, condition =>
              get(conditions, condition as ConditionKey, false)
            );
            return !allConditionsMet;
          });

          const currentStepIndex = findIndex(latestJourneySteps, val =>
            isEqual(val, currentStepKey)
          );
          const nextStepKey = latestJourneySteps[currentStepIndex + 1];
          const nextStep = find(journey.steps.collection, step => {
            return isEqual(nextStepKey, step.id) || isEqual(nextStepKey, step.key);
          });

          const nextStepType = get(nextStep, "type");

          const stepIsTerminal = get(currentStep, "terminal", false);
          const isLastStepInJourney =
            isEqual(currentStepKey, last(latestJourneySteps)) || isEqual(stepIsTerminal, true);

          if (nextStepType === "journey" && !isLastStepInJourney) {
            dispatch(startJourneyAction(nextStepKey, true));
          } else {
            if (isLastStepInJourney) {
              const isJourneyNested = !isEmpty(latestJourney.parent);
              const parentJourney = get(journey.collection, latestJourney.parent as string);
              const parentJourneyIndex = findIndex(get(parentJourney, "steps"), item =>
                isEqual(item, latestJourneyId)
              );

              const nextParentJourneyStep = parentJourney?.steps[parentJourneyIndex + 1];

              if (isJourneyNested) {
                dispatch(setJourneyInactive(latestJourneyId));
                dispatch(setJourneyStep(nextParentJourneyStep));
              } else {
                if (isEqual(currentStep.type, "section")) {
                  const { success } = activateNextSection();
                  if (!success) dispatch(finishJourney(latestJourneyId));
                } else {
                  dispatch(finishJourney(latestJourneyId));
                }
              }
            } else {
              if (isEqual(currentStep.type, "section") && !isLastSectionActive && !skipSection) {
                const { success } = activateNextSection();
                if (!success) {
                  dispatch(setJourneyStep(nextStepKey));
                }
              } else {
                dispatch(setJourneyStep(nextStepKey));
              }
            }
          }
        }
      };
    },
    [
      activateNextSection,
      anyJourneysActive,
      conditions,
      currentStep,
      currentStepKey,
      dispatch,
      isLastSectionActive,
      journey.activeJourneys,
      journey.collection,
      journey.steps.collection,
    ]
  );

  const buildNavigationMetadata = () => {
    const navigation = currentStep?.navigation;
    const shouldExit = navigation?.exit === true;
    const backNavMetadata = navigation?.back;
    const targetBackNavMetadata = parseBackNavMetadata(backNavMetadata, conditions);

    const backNavProps = {
      replaceExitInJourneyWithBack:
        !shouldExit && (backNavMetadata === true || !!targetBackNavMetadata),
    };

    return backNavProps;
  };
  const navigationMetadata = buildNavigationMetadata();

  const rootJourney = find(
    journey.collection,
    item => item?.parent === undefined && item.active === true
  );

  const exitJourney = useCallback(async () => {
    const storage = isEmpty(userId) ? local : local.namespace(userId as string);

    dispatch(clearJourneyActive());
    await storage.remove("journeyProgress");
  }, [dispatch, userId]);

  const rootJourneyData = (rootJourney?.data || {}) as TData;

  return {
    navigationMetadata,
    anyJourneysActive,
    currentStep,
    startJourney,
    startFulfillmentJourney,
    startReinstatementFulfillmentJourney,
    nextStepCallback,
    previousStepCallback,
    exitJourney,
    rootJourney,
    data: rootJourneyData,
  };
};
