import * as cards from "components/Cards";
import { ClassName, Condition, OrString, ValueOrArray } from "config/types";
import { ComponentProps, CSSProperties, FC } from "react";
import { Link } from "react-router-dom";

export type CardActions = {
  onClick?: (event: any) => void;
};

type Content = OrString<ComponentProps<any>>;

export interface ICardProps {
  content?: Condition<ValueOrArray<Content | Condition<Content>>>;
  className?: ClassName;
  conditions?: Condition;
  to?: ComponentProps<Link>["to"];
  actions?: CardActions;
  style?: CSSProperties;
}

interface IProps extends ICardProps {
  as: keyof typeof cards;
}

export const Card: FC<IProps> = props => {
  const { as, children, ...rest } = props;
  const CardBody = cards[as];

  return (
    <div className="card--container">
      <div className="card--wrapper">
        <CardBody {...rest}>{children}</CardBody>
      </div>
    </div>
  );
};

Card.defaultProps = {
  content: {},
  conditions: {},
};
