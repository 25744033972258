import { paymentProcessingModalDisplayedEvent } from "analytics/billingEvents";
import Text from "components/Text/Text";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout, useTranslation } from "modules";
import { FC, useEffect } from "react";
import { useAppDispatch } from "store";
import { openChat } from "utils/customerSupport";

const PolicyBillingPaymentRetryProcessingModal: FC<IModalProps> = ({ closeModal }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(paymentProcessingModalDisplayedEvent());
  }, [dispatch]);

  const content = {
    icon: "BlobUnlinkedMonitored",
    header: t(
      "policy.modal.payment_retry.processing.title",
      "Hang tight, your payment is processing..."
    ),
    subheader: (
      <>
        {t(
          "policy.modal.payment_retry.processing.text_1",
          "Payments may take up to 5 business days to process. Please check back later to ensure your payment was successful, or contact"
        )}{" "}
        <Text
          as="span"
          className="modal__subheader-inline-cta"
          onClick={() => {
            openChat();
            closeModal();
          }}
          tag="p3"
          text={t("policy.modal.payment_retry.processing.text_cs", "Customer Service")}
        />{" "}
        {t("policy.modal.payment_retry.processing.text_2", "for help with billing.")}
      </>
    ),
    button: [
      {
        onClick: () => closeModal(),
        text: t("policy.modal.payment_retry.processing.cta_1", "OKAY, GOT IT"),
      },
      {
        onClick: () => {
          openChat();
          closeModal();
        },
        subType: "text-dark",
        text: t("policy.modal.payment_retry.processing.cta_2", "GET HELP"),
      },
    ],
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default PolicyBillingPaymentRetryProcessingModal;
