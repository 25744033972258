import { ISelectOption } from "components/SelectInput/SelectInput";
import { DynamicFormItemType, IFlowQuestion } from "config";
import { IAppError } from "config/types/error.types";
import { FC } from "react";
import { IResetValues } from "utils/form";

import { FlowInputTypes } from ".";

/*
..................
<<< INTERFACES >>>
..................
*/

// Start Form component state types
export interface IValidationData {
  inputId: IFormInput["id"];
  inputs: IFormInputs;
  validation: { [key: string]: any };
}

export interface IForm {
  loading: boolean;
  allRequiredFieldsFilled: boolean;
  formErrorMessage: string;
  isFormDirtied: boolean;
  isFormInitialized: boolean;
  valid: boolean;
  onChange(event: any): void;
  onBlur(event: any): void;
  getFormInput(id: IFormInput["id"]): Partial<IFormInput>;
  getInputError(id: IFormInput["id"]): boolean;
  getInputErrorMessage(id: IFormInput["id"]): string[];
  getSubmitBtnDisabled(): boolean;
  validateAllFields(): boolean;
  setLoading(value: boolean): void;
  setFormErrorMessage(message: string): void;
  reset(resetValues: IResetValues): void;
  processServerValidationErrors(thunkError: IAppError | null): IFormInputs;
  setInputs(inputs: IFormInputs): void;
  initializeFormState(inputs: IFormInputs | Partial<IFormInput>[]): void;
}

export interface IFormValidation {
  errorCode: string;
  defaultError: string;
  validation(value: any, data?: any): any;
}

export interface IFormValidations {
  [key: string]: IFormValidation;
}

export interface IFormInputError {
  code: string;
  message: string;
}

export interface IFormInput {
  id: string;
  key: string;
  pristineValue: any;
  value: any;
  touched: boolean;
  dirty: boolean;
  error: IFormInputError[];
  validations: (IFormValidation | string | { [key: string]: unknown; name: string })[];
  required: boolean;
  metadata: {
    flowQuestionId?: IFlowQuestion["id"];
    flowInputType?: FlowInputTypes | DynamicFormItemType;
  };
  inputType: FormInputTypes;
  encryptOutput?: boolean;
  label?: string;
  placeholder?: string;
  precision?: number;
}

export interface IFormInputs {
  [key: string]: IFormInput;
}

export interface IFormSectionField extends Partial<IFormInput> {
  id: string;
  component: FC<any>;
  autoCompleteOverride?: string;
  label?: string;
  placeholder?: string;
  options?: ISelectOption[];
  validations?: (IFormValidation | string | { [key: string]: unknown; name: string })[];
  existingValue?: boolean;
  required?: boolean;
}

// End Form component state types

/*
..................
~~~~~ ENUMS ~~~~~
..................
*/

export enum EnumFormTypes {
  login,
  createNewPassword,
  resetPassword,
  createAccount,
}

export enum EnumFormInputTypes {
  "radio-input-group",
}

/*
..................
~~~~~ TYPES ~~~~~~
..................
*/

type FormInputTypes =
  | FlowInputTypes
  | Exclude<DynamicFormItemType, "button" | "decoration" | "space" | "text">
  | "";

export type FormSectionKey =
  | "legalName"
  | "contactInfo"
  | "personalDetails"
  | "address"
  | "organizationDetails"
  | "trustInformation"
  | "trusteePersonalDetails";

export type FormSectionConfig = {
  fields: IFormSectionField[];
  title?: string;
};
export type FormSectionsConfig = Record<FormSectionKey, FormSectionConfig>;
