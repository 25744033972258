import { gql } from "config/graphql";
import { BusinessSlugTypes, IConstellation, IMembership, IPhoneNumber, IUser } from "config/types";
import { FEATURE_FLAG_FIELDS } from "graphql/fragments";
import { getApolloClient } from "store/apollo";

// Register user
export interface IRegisterUserInput {
  attributes: {
    emailAddress: string;
    firstName: string;
    lastName: string;
    password: string;
    passwordConfirmation: string;
    newsletterOptIn: boolean;
    insuranceMembershipId?: IMembership["id"];
  };
}

interface IRegisterUserResponse {
  data: {
    registerUser: {
      user: {
        id: string;
      };
    };
  };
}

export const registerUserMutation = (user: IRegisterUserInput): Promise<IRegisterUserResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation registerUser($input: RegisterUserInput!) {
      registerUser(input: $input) {
        clientMutationId
        user {
          id
        }
      }
    }
  `;

  return client.mutate({
    variables: { input: user },
    mutation,
  });
};

// Create phone number

export interface ICreatePhoneNumerInput {
  phoneNumber: string;
  countryCode: string;
}

interface ICreatePhoneNumerResponse {
  data: {
    createPhoneNumber: {
      phoneNumber: IPhoneNumber;
    };
  };
}

export const createPhoneNumberMutation = (
  phoneNumber: ICreatePhoneNumerInput
): Promise<ICreatePhoneNumerResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation createPhoneNumber($input: CreatePhoneNumberInput!) {
      createPhoneNumber(input: $input) {
        phoneNumber {
          id
          mask
          status
          createdAt
        }
      }
    }
  `;

  return client.mutate({
    variables: { input: phoneNumber },
    mutation,
  });
};

export const decommissionUserMutation = (): Promise<any> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation decommissionUser($input: DecommissionUserInput!) {
      decommissionUser(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    mutation,
  });
};

export interface ISetupUserForNewLineOfBusinessInput {
  businessSlug: BusinessSlugTypes;
  constellationId?: IConstellation["id"];
  userId?: IUser["id"];
}

interface SetupUserForNewLineOfBusinessPayload {
  data: {
    setupUserForNewLineOfBusiness: {
      user: Pick<IUser, "activeConstellations" | "id" | "status">;
    };
  };
}

export const setUpUserForNewLineOfBusinessMutation = (
  input: ISetupUserForNewLineOfBusinessInput
): Promise<SetupUserForNewLineOfBusinessPayload> => {
  const client = getApolloClient();

  const mutation = gql`
    ${FEATURE_FLAG_FIELDS}
    mutation SetupUserForNewLineOfBusiness($input: SetupUserForNewLineOfBusinessInput!) {
      setupUserForNewLineOfBusiness(input: $input) {
        user {
          activeConstellations {
            activeFinanceMembership {
              id
            }
            activeInsuranceMembership {
              id
            }
            id
            featureFlags {
              ...FeatureFlagFields
            }
          }
          id
          status
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables: { input },
  });
};
