import "./WyshesByCategoryList.scss";

import Currency from "components/Currency/Currency";
import List from "components/List/List";
import WishesListItem from "components/WishesListItem/WishesListItem";
import WyshCategoryTitle from "components/WyshCategoryTitle/WyshCategoryTitle";
import { get, groupBy, isFunction, keyBy, map } from "lodash-es";
import { Link } from "modules";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { setBackWyshDetailType } from "store/actions/wyshesFlow";
import { IWyshesAllocationState } from "store/reducers/wyshes";

interface IProps {
  allocations: IWyshesAllocationState[];
  flowTabIndicesStart?: number;
  onWyshRemove?(wyshId: string): void;
}

const WyshesByCategoryList: FC<IProps> = props => {
  const { allocations, onWyshRemove, flowTabIndicesStart } = props;
  const dispatch = useDispatch();

  const groupedAllocations = groupBy(allocations, allocation => {
    return allocation.wysh?.wyshCategory?.id;
  });

  const categories = keyBy(
    map(allocations, a => a.wysh?.wyshCategory),
    "id"
  );

  const buildRemoveClick = (wyshId: string) => {
    if (isFunction(onWyshRemove)) {
      return () => handleRemoveClick(wyshId);
    }
  };

  const handleRemoveClick = (wyshId: string) => {
    if (isFunction(onWyshRemove)) {
      onWyshRemove(wyshId);
    }
  };

  const setBackNavToWyshes = () => {
    dispatch(setBackWyshDetailType("wyshes"));
  };

  let tabIndex = flowTabIndicesStart ?? 1;

  return (
    <div className="wyshes-by-category__list-container">
      {map(groupedAllocations, (list, key) => {
        const category = get(categories, key);
        return (
          <div key={key} className="wyshes-by-category__list-wrapper">
            <WyshCategoryTitle text={category?.name} color={category?.color} />
            <List className="wyshes-by-category__list">
              {map(list, listItem => {
                const WyshListItem = onWyshRemove ? (
                  <WishesListItem
                    className="wyshes-by-category__list-item"
                    key={listItem.id}
                    icon={listItem.icon}
                    color={category?.color}
                    text={listItem.name}
                    tabIndex={flowTabIndicesStart ? tabIndex : 0}
                    secondaryText={<Currency amount={listItem.amount} fixedPoints={0} />}
                    onRemoveClick={buildRemoveClick(listItem.id)}
                  />
                ) : (
                  <Link
                    to={`/wyshes/${listItem?.id}/details`}
                    className="wysh-list__item"
                    key={listItem?.id}
                    onClick={setBackNavToWyshes}>
                    <WishesListItem
                      className="wyshes-by-category__list-item"
                      key={listItem.id}
                      icon={listItem.icon}
                      color={category?.color}
                      text={listItem.name}
                      secondaryText={<Currency amount={listItem.amount} fixedPoints={0} />}
                    />
                  </Link>
                );
                tabIndex++;
                return WyshListItem;
              })}
            </List>
          </div>
        );
      })}
    </div>
  );
};

export default WyshesByCategoryList;
