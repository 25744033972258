import { gql } from "config/graphql";

export const CUSTOMER_FIELDS = gql`
  fragment CustomerFields on Customer {
    createdAt
    externalId
    id
    metadata
    paymentMethods {
      id
      plaidAccount {
        id
      }
    }
    processor
    updatedAt
  }
`;
