import { gql } from "config/graphql";

export const PLAN_FIELDS = gql`
  fragment PlanFields on Plan {
    id
    amount
    billingFrequency
    nextPayment {
      date
      invoiceId
      estimated
    }
  }
`;
