import { IModalProps } from "config/init/providers/ModalsProvider";
import { push } from "connected-react-router";
import { Layout, useTranslation } from "modules";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "store/actions/modal";

const UnlinkBankPaymentErrorModal: FC<IModalProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const closeModalWrapper = () => dispatch(closeModal());

  const unlinkBankOnClick = async () => {
    dispatch(push("/products/insurance/billing"));
  };

  const ctaButtons = [
    {
      text: t(
        "settings.linked.accounts.unlink.payment.method.error.modal.primary.cta",
        "GO TO BILLING"
      ),
      onClick: unlinkBankOnClick,
    },
    {
      text: t(
        "settings.linked.accounts.unlink.payment.method.error.modal.secondary.cta",
        "NEVERMIND"
      ),
      subType: "text-dark",
      onClick: closeModalWrapper,
    },
  ];

  const content = {
    header: t(
      "settings.linked.accounts.unlink.payment.method.error.modal.title",
      "Hmm, we can’t unlink your bank just yet..."
    ),
    subheader: t(
      "settings.linked.accounts.unlink.payment.method.error.modal.body",
      "You currently have a payment method tied to this bank. To unlink, replace your payment method & try again."
    ),
    onClose: closeModalWrapper,
    button: ctaButtons,
    icon: "BlobBank",
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default UnlinkBankPaymentErrorModal;
