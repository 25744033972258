import "./TextInput.scss";

import classNames from "classnames";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { ClassName, ValueOrArray } from "config/types";
import { defaultTo, flatten, isNil, kebabCase, toString, trimStart, truncate } from "lodash-es";
import { useTranslation } from "modules";
import {
  ChangeEvent,
  ClipboardEvent,
  CSSProperties,
  FC,
  FocusEvent,
  HTMLAttributes,
  KeyboardEvent,
  useMemo,
  useState,
} from "react";

interface IProps {
  id: string;
  activeLabel?: string;
  autoCompleteOverride?: string;
  className?: ClassName;
  clearInputOption?: boolean;
  dataTestId?: string;
  disableAutoComplete?: boolean;
  disableFloatingLabel?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: ValueOrArray<string>;
  iconLeft?: string;
  iconRight?: string;
  inputMode?: HTMLAttributes<HTMLInputElement>["inputMode"];
  inputRef?: React.Ref<HTMLInputElement> | null;
  label?: string;
  max?: string | number;
  maxCharacterLength?: number;
  min?: string | number;
  placeholder?: string;
  preventDisabledStyles?: boolean;
  required?: boolean;
  showHide?: boolean;
  style?: CSSProperties;
  type?: string;
  unit?: string;
  validations?: any[];
  value?: string | number;
  onBlur?(event: FocusEvent<HTMLInputElement>): void;
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
  onFocus?(event: FocusEvent<HTMLInputElement>): void;
  onIconLeftClick?(): void;
  onIconRightClick?(): void;
  onKeyDown?(event: KeyboardEvent<HTMLInputElement>): void;
  onKeyUp?(event: KeyboardEvent<HTMLInputElement>): void;
  onPaste?(event: ClipboardEvent<HTMLInputElement>): void;
}

const TextInput: FC<IProps> = props => {
  const { t } = useTranslation();
  const {
    activeLabel,
    autoCompleteOverride,
    className,
    clearInputOption,
    dataTestId,
    disableAutoComplete,
    disableFloatingLabel,
    disabled,
    error,
    errorMessage,
    iconLeft,
    iconRight,
    id,
    inputMode,
    inputRef,
    label,
    max,
    maxCharacterLength,
    min,
    onBlur,
    onChange,
    onFocus,
    onIconLeftClick,
    onIconRightClick,
    onKeyDown,
    onKeyUp,
    onPaste,
    placeholder,
    preventDisabledStyles,
    style,
    type = "text",
    unit,
    value,
  } = props;
  const [focused, setFocused] = useState(false);

  const handleOnInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!isNil(maxCharacterLength)) {
      const value = event.target.value;
      const trimmed = truncate(trimStart(value), { length: maxCharacterLength, omission: "" });
      event.target.value = trimmed;
    }
    onChange && onChange(event);
  };

  const handleOnFocus = (event: FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    onFocus && onFocus(event);
  };

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    onBlur && onBlur(event);
  };

  const handleIconLeftClick = () => {
    onIconLeftClick && onIconLeftClick();
  };

  const handleIconRightClick = () => {
    clearInputOption && clearTextInput();
    onIconRightClick && onIconRightClick();
  };

  const clearTextInput = () => {
    const eventTarget = { target: { id, value: "" } } as ChangeEvent<HTMLInputElement>;
    onChange && onChange(eventTarget);
  };

  const active = focused || value === 0 || value;

  const activeLabelText = defaultTo(activeLabel, label);
  const labelText = active ? activeLabelText : label;

  const errorMessages = flatten([errorMessage]);

  const conditionalClassNames = {
    "text-input--active": active,
    "text-input--disable-floating-label": disableFloatingLabel,
    "text-input--complete": value || value === 0,
    "text-input--error": error,
    "text-input--with-icon-left": iconLeft,
    "text-input--with-icon-right": iconRight,
    "text-input--disabled": disabled && !preventDisabledStyles,
    "text-input--has-label": labelText,
  };

  const classes = classNames(className, "text-input form__input", conditionalClassNames);
  const iconRightClasses = classNames("text-input__icon text-input__icon--right", {
    pointer: type === "password" || clearInputOption,
    "clear-text-icon": clearInputOption,
  });

  const labelTextClasses = classNames("text-input__label-text", {
    "p5-tag__text": active,
  });

  const autoCompleteValue = useMemo(() => {
    if (autoCompleteOverride) {
      return autoCompleteOverride;
    } else if (disableAutoComplete) {
      return "off";
    }

    return undefined;
  }, [autoCompleteOverride, disableAutoComplete]);

  return (
    <div className={classes} style={style}>
      <div className="text-input__wrapper">
        {iconLeft && (
          <div>
            <Icon
              onClick={handleIconLeftClick}
              className="text-input__icon text-input__icon--left"
              src={iconLeft}
            />
          </div>
        )}
        {(iconRight || (clearInputOption && value)) && (
          <Icon
            size="sm"
            onClick={handleIconRightClick}
            className={iconRightClasses}
            dataTestId="text-input__icon--right"
            src={iconRight || "CloseBorder"}
          />
        )}
        <input
          autoComplete={autoCompleteValue}
          className="text-input_input"
          data-testid={dataTestId || `text-input__${kebabCase(label)}`}
          disabled={disabled}
          id={id}
          inputMode={inputMode || "text"}
          max={max}
          min={min}
          onBlur={handleOnBlur}
          onChange={handleOnInputChange}
          onFocus={handleOnFocus}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onPaste={onPaste}
          placeholder={placeholder}
          ref={inputRef}
          type={type}
          value={value}
        />
        {unit && <Text className="text-input__unit" text={unit} tag="p2" />}
        {labelText && (
          <label htmlFor={id}>
            <Text className={labelTextClasses} text={labelText} tag="p2" />
          </label>
        )}
      </div>

      {error &&
        errorMessages.map((message, index) => {
          return <Text className="text-input__error-text" text={message} tag="p6" key={index} />;
        })}
      {!error && !isNil(maxCharacterLength) && (
        <Text
          className="text-input__max-chars"
          tag="c5"
          text={t("wysh.setup.name.input_limit", "%<length>s/%<max>s characters", {
            length: toString(value).length,
            max: maxCharacterLength,
          })}
        />
      )}
    </div>
  );
};

export default TextInput;
