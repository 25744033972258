import { gql } from "config/graphql";
import {
  IInitiateFinanceOnboardingInput,
  IInitiateFinanceOnboardingPayload,
  IRemoveOnboardingAutoDepositInput,
  IRemoveOnboardingAutoDepositPayload,
  IUpdateDepositInput,
  IUpdateDepositPayload,
} from "config/types";
import { FINANCE_MEMBERSHIP_FIELDS } from "graphql/fragments";
import { getApolloClient } from "store/apollo";

export const initiateFinanceOnboardingMutation = (
  input: IInitiateFinanceOnboardingInput
): Promise<IInitiateFinanceOnboardingPayload> => {
  const client = getApolloClient();

  const mutation = gql`
    ${FINANCE_MEMBERSHIP_FIELDS}
    mutation InitiateFinanceOnboarding($input: InitiateFinanceOnboardingInput!) {
      initiateFinanceOnboarding(input: $input) {
        membership {
          ...MembershipFields
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables: { input },
  });
};

export const updateDepositMutation = (
  input: IUpdateDepositInput
): Promise<IUpdateDepositPayload> => {
  const client = getApolloClient();

  const mutation = gql`
    ${FINANCE_MEMBERSHIP_FIELDS}
    mutation UpdateDeposit($input: UpdateDepositInput!) {
      updateDeposit(input: $input) {
        membership {
          ...MembershipFields
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables: { input },
  });
};

export const removeOnboardingAutoDepositMutation = (
  input: IRemoveOnboardingAutoDepositInput
): Promise<IRemoveOnboardingAutoDepositPayload> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation RemoveOnboardingAutoDeposit($input: RemoveOnboardingAutoDepositInput!) {
      removeOnboardingAutoDeposit(input: $input) {
        response {
          status
          success
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables: { input },
  });
};
