import classNames from "classnames";
import { OrString } from "config/types";
import { defaultTo } from "lodash-es";
import { FC, ReactNode } from "react";

import replaceString from "../../utils/replaceString";

interface IProps {
  text: string;
  target: OrString<RegExp>;
  className?: string;
  replacementValue?: ReactNode;
  replacementElement?: keyof JSX.IntrinsicElements;
}

const VariableText: FC<IProps> = props => {
  const { text, target, className, replacementValue, replacementElement } = props;
  const targetMatch = defaultTo(target, /@(\w+)@/g);

  const classes = classNames("variable-text_tag", className);

  const Element = replacementElement || "span";

  const content = replaceString(text, targetMatch, (match, i) => {
    return (
      <Element key={i} className={classes}>
        {replacementValue || match}
      </Element>
    );
  });

  return <>{content}</>;
};

export default VariableText;
