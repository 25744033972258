import { useCallback, useEffect, useRef, useState } from "react";

export type PollingStatus = "polling" | "done" | "timed-out";

// cb (callback function) expects a returned truthy value to end polling
export const usePolling = (
  cb: () => Promise<boolean | undefined>,
  maxLimit = 15, // Limits the # of callbacks until polling ends
  intervalTime = 2000
) => {
  const count = useRef(0);
  const [status, setStatus] = useState<PollingStatus>("polling");
  const interval = useRef<NodeJS.Timeout>();

  const callback = useCallback(async () => {
    count.current = count.current + 1;

    const resolved = await cb();

    if (resolved || count.current >= maxLimit) {
      interval.current && clearInterval(interval.current);
    }

    if (resolved) {
      setStatus("done");
    }

    if (count.current >= maxLimit) {
      setStatus("timed-out");
    }
  }, [cb, maxLimit]);

  useEffect(() => {
    callback();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    interval.current = setInterval(callback, intervalTime);

    return () => {
      interval.current && clearInterval(interval.current);
    };
  }, [callback, intervalTime]);

  return status;
};
