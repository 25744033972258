// ONLY SUPPORTED FOR SCREEN SIZES > md
import "./SidebarTabsLayout.scss";

import classNames from "classnames";
import List from "components/List/List";
import ListItem from "components/ListItem/ListItem";
import Text from "components/Text/Text";
import { throttle } from "lodash-es";
import { ILayoutProps } from "modules";
import { FC, useEffect, useState } from "react";

import { ITab } from "../tabs-layouts.types";
import { getActiveIdOnScroll, getOrCreateRef, scrollTo, SectionRefs } from "../tabs-utils";

type ContentType = {
  header: string;
  subheader: string;
  tabs: ITab[];
  onTabClick?(id: string): void;
};

const sectionRefs: SectionRefs = {};

const SidebarTabsLayout: FC<ILayoutProps<ContentType>> = props => {
  const { className, children } = props;
  const [activeId, setActiveId] = useState("");

  const tabsContent = props.content as ContentType;
  const header = tabsContent?.header;
  const subheader = tabsContent?.subheader;
  const tabs = tabsContent?.tabs;
  const onTabClick = tabsContent?.onTabClick;

  const classes = classNames("sidebar-tabs__container", className);

  const handleScroll = throttle(() => {
    const bottomOfPage =
      Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
    const id = getActiveIdOnScroll(sectionRefs, -25, bottomOfPage);
    if (id) {
      setActiveId(id);
    }
  }, 10);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabClick = (id: string) => {
    onTabClick && onTabClick(id);
    scrollTo(sectionRefs, id);
  };

  return (
    <div className={classes}>
      <div className="sidebar-tabs__left-col">
        <div className="sidebar-tabs__header-container">
          <Text tag="h3" text={header} className="sidebar-tabs__header" />
          <Text tag="p3" text={subheader} className="sidebar-tabs__subheader" />
        </div>
        <div className="sidebar-tabs__tabs-container">
          <div className="sidebar-tabs__tabs">
            <List>
              {tabs.map(({ id, list }, index) => {
                const active = (index === 0 && !activeId) || activeId === id;
                const tabClasses = classNames("sidebar-tabs__tab", {
                  "sidebar-tabs__tab--active": active,
                });
                return (
                  <div key={id} className={tabClasses} onClick={() => handleTabClick(id)}>
                    <ListItem
                      text={list.label}
                      secondaryText={active ? list.description : ""}
                      active={active}
                      icon={{ right: "RightArrow" }}
                    />
                  </div>
                );
              })}
            </List>
          </div>
        </div>
      </div>
      <div className="sidebar-tabs__right-col">
        <div className="sidebar-tabs__content-container">
          {tabs.map(({ id, content }) => (
            <section
              className="sidebar-tabs__content"
              key={id}
              id={id}
              ref={getOrCreateRef(sectionRefs, id)}>
              <Text tag="h4" className="sidebar-tabs__content-header">
                {content.header}
              </Text>
              {content.component}
            </section>
          ))}
          {children}
        </div>
      </div>
    </div>
  );
};

export default SidebarTabsLayout;
