import { createSelector } from "@reduxjs/toolkit";

import { selectConstellation } from "./selectConstellation";
import { getUser } from "./selectUser";

export const getCanSeeDashboard = createSelector(
  getUser,
  selectConstellation,
  (user, constellation) => {
    const isRegisteredUser = user.status && user.status !== "pending";
    const hasActiveConstellation = !!constellation.id;

    return isRegisteredUser && hasActiveConstellation;
  }
);
