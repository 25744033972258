import "./NotFound.scss";

import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { push } from "connected-react-router";
import Container from "containers/Container/Container";
import { useTranslation } from "modules";
import { FC } from "react";
import { useAppDispatch } from "store";

const NotFound: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <Container className="not-found__container">
      <div className="not-found__wrapper">
        <Icon size="xl" src="BlobAlert" />
        <Text
          className="not-found__header"
          tag="h4"
          text={t("blocked.system_error.title", "Oops! We must've moved whatever was here")}
        />
        <Text
          className="not-found__subheader"
          tag="p3"
          text={t(
            "blocked.system_error.body",
            "Let's get you back on track and pretend this never happened."
          )}
        />

        <Button
          className="not-found__cta"
          onClick={() => dispatch(push("/"))}
          text={t("blocked.system_error.button", "FORGET YOU SAW THIS")}
        />
      </div>
    </Container>
  );
};

export default NotFound;
