import { IJourney, RouteAction, RouteLocation, SyncJourneyLocally } from "config";
import { push } from "connected-react-router";
import Journey from "containers/Journey/Journey";
import JourneyProgress from "containers/JourneyProgress/JourneyProgress";
import { find, get, includes, isEmpty, isEqual, isUndefined } from "lodash-es";
import { usePrompt, useTranslation } from "modules";
import { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { RootState, useAppDispatch } from "store";
import { finishJourney } from "store/actions/journey";
import { setModal } from "store/actions/modal";
import { deprecatedJourneys } from "store/mock/journey.mock";
import { local } from "store2";

import JourneySectionProvider from "./JourneySectionProvider";

const JourneyLocalSync: FC<{ active?: boolean }> = props => {
  const { active = true } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = useMemo(() => {
    return location.pathname;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const journey = useSelector((state: RootState) => state.journey);
  const user = useSelector((state: RootState) => state.user);

  const anyJourneysActive = !isEmpty(journey.activeJourneys);
  const rootJourney = find(journey.collection, item => isUndefined(item?.parent));
  const rootJourneyId = get(rootJourney, "id", "");

  const buildModalContent = (jny?: IJourney) => {
    const contentBasedOnJourney = {
      onboarding: {
        condition: isEqual(jny?.context, "onboarding"),
        content: {
          header: t("onboarding.modal.title", "Do you really wanna exit?"),
          subheader: t(
            "onboarding.modal.body",
            "If you leave now, you will have to answer these questions again."
          ),
        },
      },
      fulfillment: {
        condition: isEqual(jny?.slug, "fulfillment"),
        content: {
          header: t("fulfillment.modal.title", "Do you really wanna exit mid-application?"),
          subheader: t(
            "fulfillment.modal.body",
            "If you leave now, you might have to answer these questions again."
          ),
          buttons: {
            primary: t("fulfillment.modal.primary", "YEAH, EXIT"),
            secondary: t("fulfillment.modal.secondary", "NEVERMIND"),
          },
        },
      },
      // This content will only display when on a testing journey
      testing: {
        condition: isEqual(jny?.slug, "default-journey"),
        content: {
          header: "Do you want to exit this testing journey?",
          subheader: "If you leave now, you will have to answer these questions again.",
        },
      },
    };

    const activeItem = find(contentBasedOnJourney, item => item.condition === true);
    const content = get(activeItem, "content", {});
    return content;
  };

  const setJourneyExitModal = (location: RouteLocation, action: RouteAction) => {
    if (action === "POP") {
      dispatch(push(currentPath));
    } else {
      const content = buildModalContent(rootJourney);
      const modalData = { content, location };

      setTimeout(() => {
        dispatch(setModal("JourneyExit", rootJourneyId, modalData));
      }, 100);
    }
  };

  usePrompt(anyJourneysActive, setJourneyExitModal);

  useEffect(() => {
    if (anyJourneysActive) {
      window.onbeforeunload = (e: BeforeUnloadEvent) => {
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = "";
      };
    } else {
      window.onbeforeunload = null;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [anyJourneysActive]);

  useEffect(() => {
    if (SyncJourneyLocally && active) {
      const userId = user.id;
      const storage = isEmpty(userId) ? local : local.namespace(userId as string);
      storage.set("journeyProgress", journey);
    }
  }, [active, journey, user.id]);

  return null;
};

export const JourneyManager: FC = () => {
  const { collection } = useSelector((state: RootState) => state.journey);
  const rootJourney = find(collection, item => isUndefined(item?.parent));
  const rootJourneyId = get(rootJourney, "id", "");
  const rootJourneyMetadata = rootJourney?.metadata;
  const dispatch = useAppDispatch();

  // End journeys that are no longer supported with `finishJourney`
  useEffect(() => {
    const endDeprecatedJourneys = async () => {
      if (includes(deprecatedJourneys, rootJourneyId)) {
        return await dispatch(finishJourney(rootJourneyId));
      }
    };

    endDeprecatedJourneys();
  }, [dispatch, rootJourneyId]);

  if (isEmpty(rootJourneyId)) return null;

  return (
    <JourneySectionProvider>
      <JourneyLocalSync />
      <div className="journey-experience__container">
        <div className="journey-experience__wrapper">
          <JourneyProgress />
          <div className="journey-experience__content-wrapper">
            <Journey id={rootJourneyId} metadata={rootJourneyMetadata} />
          </div>
        </div>
      </div>
    </JourneySectionProvider>
  );
};
