import { ApolloLink, RequestHandler } from "@apollo/client";
import { defaultTo, get } from "lodash-es";
import { Store } from "store";

import { LinkContext } from ".";

type CallbackWrapper = (state: Store) => RequestHandler;

const callback: CallbackWrapper = store => {
  return (operation, forward) => {
    const state = store.getState();
    const { authentication } = state;
    const authToken = get(authentication, "accessToken", null);
    const accessToken = get(authentication, ["accessToken", "access_token"], authToken);
    const context = operation.getContext();
    const contextHeaders = defaultTo(context.headers, {});
    const baseHeaders = { authorization: !!accessToken ? `Bearer ${accessToken}` : "" };
    const headers = { ...baseHeaders, ...contextHeaders };
    operation.setContext({ headers });
    return forward(operation);
  };
};

const callbackWrapper = (context: LinkContext) => callback(context.store);

export const authenticationLink = (context: LinkContext) =>
  new ApolloLink(callbackWrapper(context));
