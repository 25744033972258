import {
  HomeCardMarketingRecapture,
  HomeCardNotice,
  HomeCardOwned,
  HomeCardQuote,
  HomeProductCardBody,
  HomeProductCardContainer,
  HomeProductCardDivider,
  HomeProductCardHeader,
  HomeProductCardTextLink,
  IHomeProductCard,
} from "components/Cards/HomeProduct";
import { useTranslation } from "modules/hooks";
import { FC } from "react";
import { useAppSelector } from "store";
import { getActivePolicy, getInsuranceProductState, selectQuote } from "store/selectors";
import formatCommas from "utils/formatCommas";

export const InsuranceProductBlock: FC = () => {
  // HOOKS
  const { t } = useTranslation();
  const insuranceProductState = useAppSelector(getInsuranceProductState);
  const activePolicy = useAppSelector(getActivePolicy);
  const quote = useAppSelector(selectQuote);

  // LOCAL DEFINED VARIABLES
  const insuranceProductType = activePolicy.productDetail.slug || quote.productDetail.slug;
  const LINK_URL = "/products/insurance";

  // CONTENT
  const cardHeader = {
    default: t("product.header.insurance.tl", "Term Life Insurance"),
    "wysh-term-life": t("product.header.insurance.tl", "Term Life Insurance"),
    "wysh-accidental-death": t("product.header.insurance.ad", "Accidental Death Insurance"),
  };

  const cardBodyComponents = {
    recapture: InsuranceRecaptureBlockBody,
    marketing: InsuranceMarketingBlockBody,
    notice: InsuranceNoticeBlockBody,
    quote: InsuranceQuoteBlockBody,
    owned: InsuranceOwnedBlockBody,
  };

  const CardBody = cardBodyComponents[insuranceProductState];

  return (
    <HomeProductCardContainer link={LINK_URL}>
      <HomeProductCardHeader
        icon="Insurance"
        title={cardHeader[insuranceProductType || "default"]}
      />
      <HomeProductCardDivider />
      <HomeProductCardBody>
        <CardBody link={LINK_URL} />
      </HomeProductCardBody>
    </HomeProductCardContainer>
  );
};

function InsuranceMarketingBlockBody({ link }: Pick<IHomeProductCard, "link">) {
  const { t } = useTranslation();

  return (
    <>
      <HomeCardMarketingRecapture
        bodyText={t(
          "product.marketing.body.insurance",
          "Ensure financial protection for your loved ones with a customizable plan anytime."
        )}
      />
      <HomeProductCardTextLink link={link} text={t("product.link.learn_more", "Learn More")} />
    </>
  );
}

function InsuranceRecaptureBlockBody({ link }: Pick<IHomeProductCard, "link">) {
  const { t } = useTranslation();

  return (
    <>
      <HomeCardMarketingRecapture
        bodyText={t(
          "product.recapture.body.insurance",
          "Your application's incomplete. Finish it today for a fast and easy quote."
        )}
      />
      <HomeProductCardTextLink link={link} text={t("product.link.learn_more", "Learn More")} />
    </>
  );
}

function InsuranceNoticeBlockBody({ link }: Pick<IHomeProductCard, "link">) {
  const { t } = useTranslation();

  return (
    <>
      <HomeCardNotice text={t("product.notice.insurance.ruw", "PENDING MANUAL UNDERWRITING")} />
      <HomeProductCardTextLink link={link} text={t("product.link.learn_more", "Learn More")} />
    </>
  );
}

function InsuranceQuoteBlockBody({ link }: Pick<IHomeProductCard, "link">) {
  const { t } = useTranslation();
  const quote = useAppSelector(selectQuote);

  return (
    <>
      <HomeCardQuote expDate={quote.expiresAt} />
      <HomeProductCardTextLink link={link} text={t("product.link.learn_more", "Learn More")} />
    </>
  );
}

function InsuranceOwnedBlockBody() {
  const { t } = useTranslation();
  const activePolicy = useAppSelector(getActivePolicy);

  const isAccidentalDeathProduct = activePolicy.productDetail.slug === "wysh-accidental-death";

  return (
    <HomeCardOwned
      amount={{
        description: t("product.owned.insurance.label", "Coverage Amount"),
        label: isAccidentalDeathProduct
          ? t("product.owned.insurance.ad.label", "Up To")
          : undefined,
        value: t("dollar.amount", "$%<amount>s", {
          amount: formatCommas(activePolicy.amount),
        }),
      }}
      billing={{ date: activePolicy.activePlan.nextPayment.date }}
    />
  );
}
