import "./FlowQuestions.scss";

import classNames from "classnames";
import BinarySelectInput from "components/BinarySelectInput/BinarySelectInput";
import CardButtonInput from "components/CardButtonInput/CardButtonInput";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";
import DateInput from "components/DateInput/DateInput";
import MultipleCheckboxInput from "components/MultipleCheckboxInput/MultipleCheckboxInput";
import NumberInput from "components/NumberInput/NumberInput";
import USPhoneNumberInput from "components/PhoneNumberInput/USPhoneNumberInput";
import RadioInputGroup from "components/RadioInputGroup/RadioInputGroup";
import SelectInput from "components/SelectInput/SelectInput";
import SliderNumberInput from "components/SliderNumberInput/SliderNumberInput";
import Text from "components/Text/Text";
import TextInput from "components/TextInput/TextInput";
import TINInput from "components/TINInput/TINInput";
import UnitizedInput from "components/UnitizedInput/UnitizedInput";
import {
  EstimateFormSlug,
  FlowInputTypes,
  FlowTypes,
  IFlowField,
  IForm,
  IFormInput,
  IFormInputs,
} from "config";
import { every, includes } from "lodash-es";
import { useTranslation } from "modules";
import { ChangeEvent, ComponentProps, FC, FocusEvent } from "react";

import { useFlowFieldAnalyticEvents } from "./useFlowFieldAnalyticEvents";
import TextAreaInput from "components/TextAreaInput";

const inputTypes: Record<FlowInputTypes, (metadata: any) => FC<any>> = {
  dropdown: () => SelectInput,
  text: (metadata: any) => (metadata?.phoneNumber ? USPhoneNumberInput : TextInput),
  multi_select: () => MultipleCheckboxInput,
  numeric: (metadata: any) => {
    return metadata.currency ? CurrencyInput : NumberInput;
  },
  long_text: () => TextAreaInput,
  radio_group: () => RadioInputGroup,
  date: () => DateInput,
  unitized: () => UnitizedInput,
  blobs: metadata => (metadata.binary ? BinarySelectInput : CardButtonInput),
  slider: () => SliderNumberInput,
  tax_id: () => TINInput,
};

type InputTypeDependentProps = {
  label: string;
  options: any[];
  disabled: boolean;
  textCentered?: boolean;
  binary?: boolean;
  unit?: string;
  metadata?: any;
  borderBottom?: boolean;
  vertical?: boolean;
  dividers?: boolean;
  prompt?: string;
  placeholder?: string;
  phoneNumber?: boolean;
  resize?: ComponentProps<typeof TextAreaInput>["resize"];
  limit?: ComponentProps<typeof TextAreaInput>["limit"];
};

interface IProps {
  form: IForm;
  field: IFlowField;
  inputs: IFormInputs;
  terminalForm: boolean;
  fieldTitles: Record<string, string | undefined>;
  fieldDisclosures: Record<string, string | undefined>;
  slug?: FlowTypes;
}

interface IWrapperProps {
  inputType: any;
  terminalForm: boolean;
  fieldTitle?: string;
  fieldDisclosure?: string;
}

export const FlowQuestionFieldWrapper: FC<IWrapperProps> = ({
  children,
  fieldTitle,
  fieldDisclosure,
  inputType,
  terminalForm,
}) => {
  return (
    <div className="flow__field-container" data-inputtype={inputType} data-terminal={terminalForm}>
      {!!fieldTitle && <Text tag="l6" text={fieldTitle} className="flow__field-title_text" />}
      {children}
      {!!fieldDisclosure && (
        <Text tag="b6" text={fieldDisclosure} className="flow__field-disclosure_text" />
      )}
    </div>
  );
};

export const FlowQuestionField: FC<IProps> = props => {
  const { t } = useTranslation();

  const buildFieldLabel = (field: IFlowField, inputs: IFormInputs) => {
    const { label, required } = field;
    const allFieldsRequired = every(inputs, { required: true });
    const requiredLabel = t("input.required.label", "%<text>s *", { text: label });

    return required && !allFieldsRequired ? requiredLabel : label;
  };

  const { form, field, inputs, slug, terminalForm } = props;
  const { inputType, metadata, key, label } = field;
  const inputData: IFormInput = inputs[field.id];
  const inputTypeDependentProps: InputTypeDependentProps = {
    label: buildFieldLabel(field, inputs),
    options: metadata.options,
    disabled: metadata?.disabled || form.loading,
  };

  const { flowFieldTextTypeEvent, flowFieldSelectTypeEvent } = useFlowFieldAnalyticEvents();

  if (inputType === "blobs") {
    inputTypeDependentProps.textCentered = true;

    if (key === `${EstimateFormSlug}-gender` || slug === "savings-default-application") {
      inputTypeDependentProps.binary = true;
    }
  }

  if (inputType === "numeric") {
    inputTypeDependentProps.unit = metadata.unit;
  }

  if (inputType === "slider") {
    const metaData = { ...metadata, value: inputData?.value || 0 };
    inputTypeDependentProps.metadata = metaData;
  }

  if (inputType === "radio_group") {
    inputTypeDependentProps.borderBottom = true;
    inputTypeDependentProps.vertical = true;
  }

  if (inputType === "multi_select") {
    inputTypeDependentProps.dividers = true;
  }

  if (inputType === "dropdown" && field.key === "savings-cdd") {
    inputTypeDependentProps.prompt = label;
    inputTypeDependentProps.label = inputData.value
      ? ""
      : t("savings.cdd_form.select_input.placeholder", "Select");
  }

  if (inputType === "long_text") {
    inputTypeDependentProps.placeholder = inputTypeDependentProps.label;
    // TODO: This label will be provided by the API once available.
    inputTypeDependentProps.label = "";
    inputTypeDependentProps.resize = "none";

    const maxCharacterLength = metadata?.characterMax;
    if (maxCharacterLength) {
      inputTypeDependentProps.limit = {
        character: { max: maxCharacterLength },
      };
    }
  }

  if (inputType === "text") {
    if (
      includes(
        [
          "savings-default-application-number",
          "phone-number",
          "savings-default-application-phone-number",
          "savings-default-application-v2-number",
        ],
        key
      )
    ) {
      inputTypeDependentProps.phoneNumber = true;
    }
  }

  const Input = inputTypes[inputType]({ ...inputTypeDependentProps, ...metadata });
  const inputClasses = classNames("flow__field", key);

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    form.onBlur(event);

    const errorMessages = form.getInputErrorMessage(inputData.id);

    flowFieldTextTypeEvent({ event, errorMessages, field });
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    form.onChange(event);

    flowFieldSelectTypeEvent({ event, field });
  };

  const fieldTitle = props.fieldTitles[key];
  const fieldDisclosure = props.fieldDisclosures[key];

  if (!inputData) {
    return null;
  }

  return (
    <FlowQuestionFieldWrapper
      inputType={inputType}
      terminalForm={terminalForm}
      fieldTitle={fieldTitle}
      fieldDisclosure={fieldDisclosure}>
      <Input
        id={inputData.id}
        className={inputClasses}
        value={inputData.value}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        error={form.getInputError(inputData.id)}
        errorMessage={form.getInputErrorMessage(inputData.id)}
        metadata={inputData.metadata}
        dataTestId={key}
        {...inputTypeDependentProps}
      />
    </FlowQuestionFieldWrapper>
  );
};
