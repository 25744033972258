import "./Carousel.scss";

import c from "classnames";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { ClassName } from "config/types";
import { get } from "lodash-es";
import { FC, MouseEvent, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";

interface IProps extends Pick<Settings, "speed" | "swipe"> {
  arrows?: DisplayAtBreakpoint;
  dots?: DisplayAtBreakpoint;
  header?: string;
  offset?: SlidesAtBreakpoint;
  slidesToShow?: SlidesAtBreakpoint;
  onBeforeChange?(): void;
  onAfterChange?(): void;
}

type Breakpoints = "small" | "medium" | "large";
type SlidesAtBreakpoint = Record<Breakpoints, number>;
type DisplayAtBreakpoint = Record<Breakpoints, boolean>;

interface ICarouselArrowProps {
  className?: ClassName;
  inactive?: boolean;
  onClick?: (event: MouseEvent) => void;
}

type ActiveArrowTypes = "prev" | "next" | "";

///----- CAROUSEL ITEM STYLING -----///
//In order to overrite react-slick's width style, items rendered in carousel must have !important
//ie. width: 300px !important

export const Carousel: FC<IProps> = props => {
  const {
    arrows,
    children,
    dots,
    header,
    slidesToShow,
    speed = 500,
    swipe = true,
    onBeforeChange,
    onAfterChange,
    offset = { small: 0, medium: 0, large: 0 },
  } = props;
  const sliderRef = useRef<Slider>(null);
  const [inactiveArrow, setInactiveArrow] = useState<ActiveArrowTypes>("prev");
  const slidesSettings = { small: 1, medium: 2, large: 3, ...slidesToShow };

  const next = () => {
    sliderRef.current && sliderRef.current.slickNext();
  };

  const prev = () => {
    sliderRef.current && sliderRef.current.slickPrev();
  };

  const disablePrevOrNext = (index: number, offset: number) => {
    const childrenLength = get(children, "length", 1);
    const maxIndex = childrenLength - offset;

    if (index <= 0) {
      setInactiveArrow("prev");
    } else if (index >= maxIndex) {
      setInactiveArrow("next");
    } else {
      setInactiveArrow("");
    }
  };

  const sliderClass = c("slider", {
    "mx-4": slidesSettings.small > 1,
  });

  const settings = {
    className: sliderClass,
    speed,
    infinite: false,
    dots: !!dots?.large,
    arrows: false,
    swipe,
    slidesToShow: slidesSettings.large,
    swipeToSlide: true,
    variableWidth: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: slidesSettings.medium,
          dots: !!dots?.medium,
          swipe,
          beforeChange: (_oldInex: number, newIndex: number) => {
            disablePrevOrNext(newIndex, offset.medium);
            onBeforeChange && onBeforeChange();
          },
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: slidesSettings.small,
          dots: !!dots?.small,
          swipe,
          beforeChange: (_oldInex: number, newIndex: number) => {
            disablePrevOrNext(newIndex, offset.small);
            onBeforeChange && onBeforeChange();
          },
        },
      },
    ],
    beforeChange: (_oldInex: number, newIndex: number) => {
      disablePrevOrNext(newIndex, offset.large);
      onBeforeChange && onBeforeChange();
    },
    afterChange: () => {
      onAfterChange && onAfterChange();
    },
  };

  const arrowClasses = c("carousel__arrow--wrapper flex--center", {
    "carousel__arrow--small": arrows?.small,
    "carousel__arrow--medium": arrows?.medium,
    "carousel__arrow--large": arrows?.large,
  });

  return (
    <div className="carousel-container">
      <div className="carousel__header--wrapper flex--center-space-between">
        <div className="carousel__header--text">
          <Text tag="c5" text={header} />
        </div>
        <div className={arrowClasses}>
          <ArrowButton inactive={inactiveArrow === "prev"} className="prev--arrow" onClick={prev} />
          <ArrowButton inactive={inactiveArrow === "next"} className="next--arrow" onClick={next} />
        </div>
      </div>
      <Slider {...settings} ref={sliderRef}>
        {children}
      </Slider>
    </div>
  );
};

const ArrowButton: FC<ICarouselArrowProps> = props => {
  const { inactive, className, onClick } = props;
  const classes = c("carousel__arrow pointer flex--justify-center", className, {
    inactive: inactive,
  });

  return (
    <div className={classes} onClick={onClick}>
      <Icon className="slider-arrow__icon" src="BackArrow" size="xs" />
    </div>
  );
};
