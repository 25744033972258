import { IModalProps } from "config/init/providers/ModalsProvider";
import { push } from "connected-react-router";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "store";
import { closeModal } from "store/actions/modal";

const ReplaceLinkedAccountModal: FC<IModalProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const closeModalWrapper = () => dispatch(closeModal());

  const content = {
    header: t("savings.replace_linked_account.modal.header", "Are you sure?"),
    subheader: t(
      "savings.replace_linked_account.modal.subheader",
      "Replacing your linked account will update the funding source for future and pending transactions."
    ),
    button: [
      {
        onClick: () => dispatch(push("/products/savings/linked-account/edit", { from: pathname })),
        text: t("savings.replace_linked_account.modal.cta.primary", "LINK A NEW ACCOUNT"),
      },
      {
        onClick: closeModalWrapper,
        subType: "text-dark",
        text: t("savings.replace_linked_account.modal.cta.secondary", "NEVERMIND"),
      },
    ],
    onClose: closeModalWrapper,
    onButtonClick: closeModal,
    icon: "BlobAlert",
    alwaysShowIcon: true,
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default ReplaceLinkedAccountModal;
