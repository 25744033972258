import "./TruncateText.scss";

import Text, { TextType } from "components/Text/Text";
import { ClassName } from "config/types";
import { truncate } from "lodash-es";
import { useTranslation } from "modules";
import { FC, useState } from "react";

interface IProps {
  text?: string;
  tag?: TextType;
  className?: ClassName;
  truncateLimit?: number;
}

const TruncateText: FC<IProps> = props => {
  const { text = "", tag = "p3", className, truncateLimit = 50 } = props;
  const { t } = useTranslation();

  const [truncated, setTruncated] = useState(true);

  const truncateText = truncate(text, { length: truncateLimit, separator: " " });
  const toggleText = truncated ? t("see.all", "See All") : t("see.less", "See Less");

  const toggleTruncate = () => {
    setTruncated(!truncated);
  };

  return (
    <div className="truncate-text_wrapper">
      <Text
        className={`truncate-text__text ${className}`}
        tag={tag}
        text={truncated ? truncateText : text}
      />

      {text.length > truncateLimit && (
        <button className="truncate-text__toggle-text-wrapper" onClick={toggleTruncate}>
          <Text tag={tag} text={` ${toggleText}`} className="truncate-text__toggle-text" />
        </button>
      )}
    </div>
  );
};

export default TruncateText;
