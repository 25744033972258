import c from "classnames";
import Button from "components/Button/Button";
import { TextLink } from "components/Buttons";
import { textTypes } from "components/Text/Text";
import { ButtonFormItem } from "config";
import { defaultTo } from "lodash-es";
import { useModal, useTranslation } from "modules";
import { ComponentProps, useContext, useRef, useState } from "react";

import { DynamicFormContext } from "../DynamicFormContext";
import { useButtonAction } from "../useButtonAction";

import { IDynamicFormItemProps } from ".";

interface TButtonItem extends IDynamicFormItemProps<"button"> {
  buttonTextByStyle?: Partial<Record<DynamicFormItemButtonStyle, string>>;
  icon?: string;
  tag?: keyof typeof textTypes;
}

/**
 * A DynamicForm.Item button component
 * @param {string} key - reserved ButtonItem.key = 'primary-button' functions as "submit" form button
 * @param {array} actions - IButtonAction[] - actions to be executed synchronously in order in the array
 * @param {boolean} primary - a button where primary=true is disabled if any required inputs are empty/incomplete
 * @param {string} style - ButtonStyle - corresponds to a subset of buttons with distinctive UI
 * @param {string} text - text to display in button as is
 * @return {React.ReactElement} - Button component using ButtonFormItem data
 */
export function ButtonItem({ buttonTextByStyle = {}, className, icon, item, tag }: TButtonItem) {
  const isPrimaryButton = item?.key === "primary-button";
  const { actions, primary, style, text } = item?.content || {};

  // HOOKS
  const { t } = useTranslation();
  const { form } = useContext(DynamicFormContext);
  const actionCommands = useButtonAction(actions);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const openModal = useModal();
  const [isDisabled, setIsDisabled] = useState(false);

  // LOCAL DERIVED VARIABLES
  const classes = c("dynamic-form-item dynamic-form-item--button", className, {
    "appear-hidden": style === "hidden",
  });
  const buttonComponentType = buttonStyleTypeMapper[style];
  const buttonIcon = icon || buttonStyleIconMapper[style];
  const isPrimaryButtonDisabled =
    primary && (!form.allRequiredFieldsFilled || form.loading || !form.valid);
  const buttonTextMap: Record<DynamicFormItemButtonStyle, string> = {
    back: t("back", "BACK").toLowerCase(),
    destructive: text,
    exit: t("exit", "EXIT").toLowerCase(),
    hidden: text,
    primary: text,
    secondary: text,
    textLink: text,
    ...buttonTextByStyle,
  };
  const buttonText = buttonTextMap[style];

  // LOCAL METHODS
  const handleOnClick = async () => {
    try {
      setIsDisabled(true);
      form.setLoading(true);
      form.setFormErrorMessage("");

      await actionCommands({ setButtonItemIsDisabled: setIsDisabled });

      await buttonRef?.current?.form?.requestSubmit();
    } catch (error) {
      const appErrorMessage = defaultTo(error?.fullMessage, error?.code);

      if (primary) {
        form.processServerValidationErrors(error);
        form.setFormErrorMessage(
          appErrorMessage || t("errors.graphql_error", "Oops. There was an error.")
        );
      } else {
        openModal("GeneralErrorModal", "", { error });
      }
    } finally {
      setIsDisabled(false);
      form.setLoading(false);
    }
  };

  // RENDER
  if (style === "textLink") {
    return (
      <TextLink
        className={classes}
        isDisabled={isDisabled || isPrimaryButtonDisabled}
        onClick={handleOnClick}
        size="md"
        tag={tag}
        text={text}
      />
    );
  }

  return (
    <Button
      align="left"
      buttonRef={buttonRef}
      className={classes}
      disabled={isDisabled || isPrimaryButtonDisabled}
      icon={buttonIcon}
      isLoading={isPrimaryButton && form.loading}
      onClick={handleOnClick}
      subtype={buttonComponentType}
      tag={tag}
      text={buttonText}
      type={isPrimaryButton ? "submit" : "button"}
    />
  );
}

/* ------------------    TYPES    ------------------ */
type DynamicFormItemButtonStyle = ButtonFormItem["content"]["style"];
type ButtonComponentTypes = ComponentProps<typeof Button>["subtype"];

const buttonStyleTypeMapper: Record<DynamicFormItemButtonStyle, ButtonComponentTypes> = {
  back: "dark",
  destructive: "text-error-dark",
  exit: "dark",
  hidden: "text-dark",
  primary: "primary",
  secondary: "text-dark-border",
  textLink: "text-dark",
};

const buttonStyleIconMapper: Record<DynamicFormItemButtonStyle, string> = {
  back: "BackCarat",
  destructive: "",
  exit: "Close",
  hidden: "",
  primary: "",
  secondary: "",
  textLink: "",
};
