import "./CardButtonInput.scss";

import classNames from "classnames";
import CardButton from "components/CardButton/CardButton";
import { ClassName } from "config/types";
import { CSSProperties, FC } from "react";

interface IBlobOption {
  label: string;
  value: string;
  iconType?: string;
  header?: string;
  subtext?: string;
}

interface IProps {
  id: string;
  style?: CSSProperties;
  className?: ClassName;
  disabled?: boolean;
  options: IBlobOption[];
  value?: string;
  textCentered?: boolean;
  dataTestId?: string;
  leftDataTestId?: string;
  rightDataTestId?: string;
  onChange?(event: ICardButtonInputEvent): void;
}

export interface ICardButtonInputEvent {
  target: { value: string; id: string };
}

const CardButtonInput: FC<IProps> = props => {
  const {
    className,
    disabled = false,
    style,
    value,
    onChange,
    id,
    options,
    textCentered,
    dataTestId,
    leftDataTestId,
    rightDataTestId,
  } = props;

  const classes = classNames("card-btn-input", className, {
    "card-btn-input--disabled": disabled,
  });

  const leftOption = options[0];
  const rightOption = options[1];

  const handleOnClick = (event: React.FormEvent, newValue: string) => {
    event.preventDefault();
    const changeEvent = {
      target: { value: newValue, id },
    };
    onChange && onChange(changeEvent);
  };

  return (
    <div style={style} className={classes} data-testid={dataTestId || "card-button__input"}>
      <CardButton
        side="left"
        filled={leftOption.value === value}
        textCentered={textCentered}
        {...leftOption}
        onClick={(event: React.FormEvent) => handleOnClick(event, leftOption.value)}
        dataTestId={leftDataTestId || `card-button__input:${leftOption.value}`}
      />
      <CardButton
        side="right"
        filled={rightOption.value === value}
        textCentered={textCentered}
        {...rightOption}
        onClick={(event: React.FormEvent) => handleOnClick(event, rightOption.value)}
        dataTestId={rightDataTestId || `card-button__input:${rightOption.value}`}
      />
    </div>
  );
};

export default CardButtonInput;
