import { createAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import * as actionType from "store/constants";

import { getDefaultAttrs, getProductSelectedAttr } from "../defaults";
import { selectFinanceAccount, selectFinanceBankAccount } from "store/selectors";

// Analytic events for Savings+ Management

/* ------------------------    EVENT ACTION     ------------------------------ */

export const savingsDashboardPageEvent = createAction(actionType.MS_DASHBOARD_PAGEVIEW);

export const savingsDashboardTransferClickEvent = createAction(
  actionType.MS_DASHBOARD_TRANSFER_CLICK
);

export const savingsDashboardAutoDepositClickEvent = createAction(
  actionType.MS_DASHBOARD_AUTODEPOSIT_CLICK
);

export const savingsDashboardBeneficiariesClickEvent = createAction(
  actionType.MS_DASHBOARD_BENEFICIARIES_CLICK
);

export const savingsDashboardRecentActivityClickEvent = createAction(
  actionType.MS_DASHBOARD_RECENTACTIVITY_CLICK
);

/* ------------------------    EVENT DEFINITION     ------------------------------ */

const getSavingsManagementEventDefinition = (eventName: string) => {
  return (action: { [key: string]: any }, prevState: RootState, nextState: RootState) => {
    const defaultAttrs = getDefaultAttrs(prevState, "SAVINGS");
    const productSelected = getProductSelectedAttr(prevState);
    let additionalAttrs = {};

    if (action.type === actionType.MS_DASHBOARD_PAGEVIEW) {
      const { accountBalance, pendingBalance } = selectFinanceBankAccount(nextState);

      additionalAttrs = {
        depositAmount: accountBalance + pendingBalance,
        financeMembershipId: nextState.financeMembership.id,
        savingsStatus: selectFinanceAccount(nextState).status,
      };
    }

    return {
      event: eventName,
      attribute: {
        ...defaultAttrs,
        ...additionalAttrs,
        productSelected,
      },
    };
  };
};

/* ------------------------    EVENT MAP    ------------------------------ */

export const savingsManagementEventsMap = {
  [actionType.MS_DASHBOARD_PAGEVIEW]: getSavingsManagementEventDefinition("ms_dashboard_pageview"),
  [actionType.MS_DASHBOARD_TRANSFER_CLICK]: getSavingsManagementEventDefinition(
    "ms_dashboard_transfer_click"
  ),
  [actionType.MS_DASHBOARD_AUTODEPOSIT_CLICK]: getSavingsManagementEventDefinition(
    "ms_dashboard_autodeposit_click"
  ),
  [actionType.MS_DASHBOARD_BENEFICIARIES_CLICK]: getSavingsManagementEventDefinition(
    "ms_dashboard_beneficiaries_click"
  ),
  [actionType.MS_DASHBOARD_RECENTACTIVITY_CLICK]: getSavingsManagementEventDefinition(
    "ms_dashboard_recentactivity_click"
  ),
};
