import "./Dashboard.scss";

import { dashboardDisplayedEvent } from "analytics/productOfferEvents";
import { useModal } from "modules";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHasMUWResult } from "store/selectors";

import { ContactService } from "./ContactService/ContactService";
import { Disclaimers } from "./Disclaimers/Disclaimers";
import HomeHeader from "./HomeHeader/HomeHeader";
import { HomeProducts } from "./HomeProducts/HomeProducts";
import { logCustomEvent } from "@braze/web-sdk";

const Dashboard: FC = () => {
  const dispatch = useDispatch();
  const hasMUWResultNotification = useSelector(getHasMUWResult);
  const openModal = useModal();

  useEffect(() => {
    dispatch(dashboardDisplayedEvent());
    logCustomEvent("home_view");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasMUWResultNotification) {
      openModal("UnderwritingDecisionModal");
    }
  }, [hasMUWResultNotification, openModal]);

  return (
    <div className="dashboard-container" data-testid="dashboard__container">
      <HomeHeader />
      <HomeProducts />
      <ContactService />
      <Disclaimers />
    </div>
  );
};

export default Dashboard;
