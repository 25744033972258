import "./ContentWrapper.scss";

import c from "classnames";
import { FC } from "react";

interface IProps {
  className?: string;
  container?: boolean;
}

export const ContentWrapper: FC<IProps> = ({ className, children = {}, container = false }) => {
  const classNames = c("content-wrapper", className, {
    "content-container": container,
  });

  return <div className={classNames}>{children}</div>;
};
