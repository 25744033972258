import "../AdCard.scss";

import Text from "components/Text/Text";
import { ICardProps } from "modules/partials/Card";
import { FC } from "react";
import CustomLink from "components/CustomLink/CustomLink";

type Content = {
  title: string;
  headline: string;
  description: string;
  linkText: string;
  imgSrc: string;
};

export const SingleAdCard: FC<ICardProps> = props => {
  const content = props.content as Content;
  const { title, headline, description, linkText, imgSrc } = content;
  const to = props.to as string;

  return (
    <div className="card-content__ad-container card-content__single-ad-container">
      <div className="card-content__text-wrapper">
        <Text className="card-content__ad-title" tag="c5" text={title} />
        <Text className="card-content__ad-headline" tag="h4" text={headline} />
        <Text className="card-content__ad-description" tag="p4" text={description} />
        <CustomLink
          className="card-content__single-ad-link"
          type="primary"
          to={to}
          text={linkText}
        />
      </div>
      <img className="card-content__single-ad-img" alt="" src={imgSrc} />
    </div>
  );
};
