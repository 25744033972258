import { ITriviaQuestion } from "config/types";
import { createReducer } from "@reduxjs/toolkit";
import { logout } from "store/actions/authentication";
import { fetchTrivia } from "store/actions/trivia";

interface ITriviaState {
  questions: {
    [key: string]: ITriviaQuestion;
  };
  slugs: string[];
}

const defaultState: ITriviaState = {
  questions: {},
  slugs: [],
};

const trivia = createReducer(defaultState, builder => {
  builder
    .addCase(fetchTrivia.fulfilled, (state, action) => {
      const { slugs, questions } = action.payload.reduce(
        (memo: ITriviaState, question: ITriviaQuestion) => {
          memo.questions[question.slug] = question;
          memo.slugs.push(question.slug);

          return memo;
        },
        { questions: {}, slugs: [] }
      );

      state.slugs = slugs;
      state.questions = questions;
    })
    .addCase(logout, () => defaultState);
});

export default trivia;
