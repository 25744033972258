import VerifyPhoneImage from "assets/images/registration/VerifyPhone.svg";
import Loader from "components/Loader/Loader";
import Pin from "components/PinInput/PinInput";
import { IPhoneNumber, PhoneVerificationMode } from "config";
import { push } from "connected-react-router";
import { get } from "lodash-es";
import { Layout, useJourney, useModal, useTranslation } from "modules";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch, useAppSelector } from "store";
import { setRedirectPath, verifySession } from "store/actions/authentication";
import { formatPhoneNumber, getVerificationPhoneNumber } from "utils/phoneNumber";

interface IProps {
  changeMode: (mode: PhoneVerificationMode) => void;
  mode: PhoneVerificationMode;
  backNav?: {
    hasBackNav?: boolean;
    pathname?: string;
    icon?: string;
    text?: string;
    replaceExitInJourneyWithBack?: boolean;
    overrideDefaultBehavior?: boolean;
    onClick?(): void;
  };
  phoneNumber?: IPhoneNumber;
  shouldRedirect?: boolean;
  onSuccess?(): void;
}

const VerifySessionOTP: FC<IProps> = props => {
  const { mode, changeMode, onSuccess, phoneNumber: phone, shouldRedirect } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [pending, setPending] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const handleError = (code: string, message: string) => {
    setErrorCode(code);
    setErrorMessage(message);
  };

  const { nextStepCallback } = useJourney();
  const redirectPath = useAppSelector(state => state.authentication.redirectPath);
  const phoneNumbers = useSelector((state: RootState) => state.profile.phoneNumbers);
  const openModal = useModal({ onModeChange: changeMode, onError: handleError });
  const phoneNumber = phone || getVerificationPhoneNumber(phoneNumbers);
  const formattedPhoneNumber = phoneNumber && formatPhoneNumber(phoneNumber);

  const phoneNumberText = formattedPhoneNumber || phoneNumber?.mask || "your phone.";

  const handleSubmit = async (value: string) => {
    try {
      setPending(true);
      const sessionPayload =
        phoneNumber?.status === "pending"
          ? { code: value, phoneNumberId: phoneNumber.id }
          : { code: value };
      const response = await dispatch(verifySession(sessionPayload)).unwrap();

      if (response?.status !== "verified") {
        throw new Error();
      }

      onSuccess && onSuccess();

      if (!shouldRedirect) {
        return;
      }

      const path = redirectPath || "/";
      dispatch(setRedirectPath(null));

      const nextStep = nextStepCallback(() => dispatch(push(path)), false, true);
      nextStep();
    } catch (error) {
      handleError(get(error, "code", ""), get(error, "fullMessage", ""));
    } finally {
      setPending(false);
    }
  };

  const handleChange = () => {
    handleError("", "");
  };

  const openResendCodeModal = () => {
    openModal("ResendCodeModal", "registration", { errorCode, mode });
  };

  const content = {
    header: t("session_verify.code_input.title", "Your code is on the way"),
    subheader: t(
      "session_verify.code_input.subtitle",
      "We texted or called %<phone_number>s. Please enter your verification code below.",
      {
        phone_number: phoneNumberText,
      }
    ),
    button: t(
      "session_verify.code_input.did_not_receive_link_title",
      "Didn't receive verification code?"
    ),
    onButtonClick: openResendCodeModal,
    buttonType: "text-dark",
    buttonTag: "l4",
    imgSrc: VerifyPhoneImage,
  };

  const message =
    !!errorMessage && !!errorCode
      ? t(`session_verify.code_input.error.${errorCode.toLowerCase()}`, errorMessage)
      : "";

  return (
    <Layout as="TwoColumnLayout" content={content} backNav={props.backNav}>
      <form className="flex-column">
        <div className="new-device__input-wrapper" data-testid="verify-phone__pin">
          <Pin
            length={6}
            onChange={handleChange}
            onComplete={handleSubmit}
            message={message}
            error={!!errorMessage}
            disabled={pending}
          />
        </div>

        {pending ? <Loader /> : null}
      </form>
    </Layout>
  );
};

export default VerifySessionOTP;
