import { get } from "lodash-es";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { closeModal } from "store/actions/modal";

const SliderErrorMessageModal: FC = () => {
  const { t } = useTranslation();
  const modal = useSelector((state: RootState) => state.modal);

  const dispatch = useDispatch();
  const closeModalWrapper = () => dispatch(closeModal());

  const content = {
    header: get(modal, "data.header"),
    subheader: get(modal, "data.text"),
    onClose: closeModal,
    button: [
      {
        text: t("cta.try_again", "TRY AGAIN"),
        subType: "primary",
        onClick: closeModalWrapper,
      },
    ],
    onButtonClick: closeModal,
    icon: "BlobAlert",
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default SliderErrorMessageModal;
