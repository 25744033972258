import { createRef, RefObject } from "react";

export type SectionRefs = { [key: string]: RefObject<any> };

export const getActiveIdOnScroll = (
  sectionRefs: SectionRefs,
  offsetTop: number,
  bottomOfPage?: boolean
) => {
  const scrollPositions: { id: string; top: number }[] = [];
  for (const [id, ref] of Object.entries(sectionRefs)) {
    if (ref?.current) {
      scrollPositions.push({ id, top: ref.current.getBoundingClientRect().top });
    }
  }

  const closestToZero = scrollPositions
    .filter(pos => {
      return Math.round(pos.top) >= offsetTop;
    })
    .sort(function(a, b) {
      return a.top - b.top;
    })[0];

  if (closestToZero) {
    return bottomOfPage ? scrollPositions[scrollPositions.length - 1].id : closestToZero.id;
  } else {
    return "";
  }
};

export const scrollTo = (sectionRefs: SectionRefs, id: string) => {
  const ref = sectionRefs[id];
  if (ref?.current) {
    ref.current?.scrollIntoView({ block: "start", inline: "nearest", behavior: "smooth" });
  }
};

export const getOrCreateRef = (sectionRefs: SectionRefs, value: string) => {
  if (!sectionRefs.hasOwnProperty(value)) {
    sectionRefs[value] = createRef();
  }
  return sectionRefs[value];
};
