import { manualUWModalDisplayEvent } from "analytics/underwritingEvents";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { push } from "connected-react-router";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { closeModal } from "store/actions/modal";
import { updateNotification } from "store/actions/notifications";
import {
  getHasValidPolicy,
  getLatestInsuranceApplication,
  getMUWNotificationId,
} from "store/selectors";

const UnderwritingDecisionModal: FC<IModalProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const hasValidPolicy = useSelector(getHasValidPolicy);
  const notificationId = useSelector(getMUWNotificationId);
  const latestApplication = useSelector(getLatestInsuranceApplication);

  const isCaseAccepted = latestApplication.result === "accepted";
  const isCaseRejected = latestApplication.result === "rejected";

  const closeModalWrapper = () => {
    if (notificationId) {
      dispatch(updateNotification(notificationId));
    }

    dispatch(closeModal());
  };

  const caseAcceptedOnClick = () => {
    if (hasValidPolicy) {
      dispatch(push("/products/insurance/confirm-update"));
    } else {
      dispatch(push("/fulfillment/start"));
    }
    closeModalWrapper();
  };

  const caseDeniedOnClick = () => {
    dispatch(push("/coverage-denied/rejected"));
    closeModalWrapper();
  };

  useEffect(() => {
    if (!!latestApplication.id && (isCaseAccepted || isCaseRejected)) {
      const uwEventPayload = isCaseAccepted ? "policy-approved" : "coverage-rejection";

      dispatch(manualUWModalDisplayEvent(uwEventPayload));
    }
  }, [dispatch, isCaseAccepted, isCaseRejected, latestApplication.id]);

  const modalHeader = isCaseAccepted
    ? t("modal.ruw_decision.approval.title", "You’ve been approved!")
    : t("modal.ruw_decision.rejected.title", "Your wait is over");

  const modalSubheader = isCaseAccepted
    ? t(
        "modal.ruw_decision.approval.body",
        "Congrats, your application was given the green light after our team did a manual review. Click below to see your quote and to purchase a policy."
      )
    : t(
        "modal.ruw_decision.rejected.body",
        "Our underwriting team’s manual review for your insurance application is complete."
      );

  const primaryCtaText = isCaseAccepted
    ? t("modal.ruw_decision.approval.cta", "SEE MY QUOTE")
    : t("modal.ruw_decision.rejected.cta", "SEE MY RESULTS");

  const content = {
    header: modalHeader,
    subheader: modalSubheader,
    onClose: closeModalWrapper,
    button: [
      {
        text: primaryCtaText,
        subType: "primary",
        onClick: isCaseAccepted ? caseAcceptedOnClick : caseDeniedOnClick,
      },
    ],
    onButtonClick: closeModal,
    icon: "BlobCheck",
    alwaysShowIcon: true,
  };

  return <Layout as="ModalLayout" content={content} className="left-align-sm" />;
};

export default UnderwritingDecisionModal;
