import TriviaAnswer from "./TriviaAnswer";
import TriviaIntroContainer from "containers/Trivia/TriviaIntro";
import TriviaQuestion from "./TriviaQuestion";
import { ITriviaNextQuestion, TriviaSectionType } from "config/types";
import { Layout } from "modules";
import { FC, MouseEvent } from "react";

interface IProps {
  answerScreenDuration: number;
  isQuoteReady: boolean;
  section: TriviaSectionType;
  questionWasTimeout?: boolean;
  questionScreenDuration: number;
  questionInfo?: ITriviaNextQuestion;
  selectedAnswerSlug?: string;
  onComplete(event?: MouseEvent<HTMLDivElement>): void;
  onNext(): void;
  onSelectAnswer(slug: string): void;
}

const Trivia: FC<IProps> = props => {
  const {
    answerScreenDuration,
    isQuoteReady,
    onComplete,
    onNext,
    onSelectAnswer,
    questionInfo,
    questionWasTimeout,
    section,
    selectedAnswerSlug,
    questionScreenDuration,
  } = props;

  const correctAnswer = questionInfo?.question.answers.find(({ correct }) => correct);

  return (
    <Layout as="TriviaLayout">
      {section === "intro" ? <TriviaIntroContainer /> : null}

      {section === "question" ? (
        <TriviaQuestion
          answers={questionInfo?.question.answers}
          isQuoteReady={isQuoteReady}
          onComplete={onComplete}
          onSelectAnswer={onSelectAnswer}
          question={questionInfo?.question.question}
          questionNumber={questionInfo?.questionNumber}
          questionScreenDuration={questionScreenDuration}
          selectedAnswerSlug={selectedAnswerSlug}
        />
      ) : null}

      {section === "answer" ? (
        <TriviaAnswer
          answerScreenDuration={answerScreenDuration}
          correctAnswer={correctAnswer?.name}
          explanation={questionInfo?.question.explanation}
          isQuoteReady={isQuoteReady}
          onComplete={onComplete}
          onNext={onNext}
          wasAnsweredCorrectly={correctAnswer?.slug === selectedAnswerSlug}
          questionWasTimeout={questionWasTimeout}
        />
      ) : null}
    </Layout>
  );
};

export default Trivia;
