import { IButtonAction } from "config";
import { push } from "connected-react-router";
import { useGetButtonCommand } from "containers/DynamicForm/useButtonCommand";
import { TShowInsuranceApplicationCommand } from "containers/DynamicForm/useButtonCommand/useShowInsuranceApplication";
import { useAppDispatch } from "store";

export const BREAK_ACTIONS = "BREAK_ACTIONS";

/**
 * Handles generating ButtonFormItem actions
 * @param {Object} actions - IButtonAction, determines what method to generate by type and command
 * @return {Function} - executes each generated action method synchronously*
 *  @return {String} - return value of BREAK_ACTIONS breaks actions loop, used for certain action APIs
 */

export const useButtonAction = (actions: IButtonAction[]) => {
  const dispatch = useAppDispatch();
  const { getApiButtonCommand, getLinkButtonCommand } = useGetButtonCommand();

  const createActionCommand = (buttonAction: IButtonAction) => {
    if (buttonAction.type === "api") {
      const apiAction = getApiButtonCommand(buttonAction.command);

      return (buttonOptions: IButtonActionOptions) =>
        apiAction(buttonAction.command.arguments, buttonOptions);
    } else if (buttonAction.type === "link") {
      const linkActionURL = getLinkButtonCommand(buttonAction.command);
      const linkNavigationAction = () => dispatch(push(linkActionURL));

      return linkNavigationAction;
    }
  };

  const handleButtonActions = async (options: Partial<IButtonActionOptions> = {}) => {
    const buttonActionInput: IButtonActionOptions = {
      ...options,
      actions,
      formAction: undefined,
    };

    for (const buttonAction of actions) {
      const actionCommand = createActionCommand(buttonAction);

      const response = actionCommand && (await actionCommand(buttonActionInput));

      if (isActionResponseFormAction(response)) {
        buttonActionInput.formAction = response?.formAction;
      }

      if (response === BREAK_ACTIONS) {
        break;
      }
    }
  };

  return handleButtonActions;
};

/* ------------------    TYPES, PREDICATES    ------------------ */
export type IButtonActionOptions = TShowInsuranceApplicationCommand & TFormAction;
type TFormAction = { actions?: IButtonAction[]; formAction?: string; formScope?: string };
const isActionResponseFormAction = (response: any): response is TFormAction => {
  return typeof response === "object" && (response?.formAction as TFormAction) !== undefined;
};
