import { ISliderMetaData } from "components/SliderInput/SliderInput";
import { gql } from "config/graphql";
import { getApolloClient } from "store/apollo";

export interface ICoverageEditorStateInput {
  estimateId?: string;
  quoteId?: string;
  policyId?: string;
  productsOfferingId?: string;
}

interface IMetadata {
  metadata: ISliderMetaData;
}

export interface ICoverageEditorStateResponse {
  data: {
    coverageEditorState: IMetadata[];
  };
}

export const getCoverageEditorStateQuery = (
  coverageInput: ICoverageEditorStateInput
): Promise<ICoverageEditorStateResponse> => {
  const client = getApolloClient();

  const query = gql`
    query coverageEditorState(
      $estimateId: ID
      $quoteId: ID
      $productsOfferingId: ID
      $policyId: ID
    ) {
      coverageEditorState(
        estimateDatumId: $estimateId
        quoteId: $quoteId
        productsOfferingId: $productsOfferingId
        policyId: $policyId
      ) {
        metadata
      }
    }
  `;

  return client.query({
    variables: coverageInput,
    query,
  });
};
