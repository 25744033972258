import classNames from "classnames";
import TextInput from "components/TextInput/TextInput";
import { ValueOrArray } from "config/types";
import { useTranslation } from "modules";
import { ClipboardEvent, ComponentProps, FC, KeyboardEvent, useState } from "react";
import { formatTIN } from "utils/tin";

type ITextInputProps = Pick<ComponentProps<typeof TextInput>, "onBlur" | "onFocus">;

interface IProps extends ITextInputProps {
  value: string;
  id: string;
  className?: string;
  label?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: ValueOrArray<string>;
  required?: boolean;
  dataTestId?: string;
  existingValue?: boolean;
  onChange?(event: ITINInputEvent): void;
}

type InputEventListenersTypes = KeyboardEvent<HTMLInputElement> | ClipboardEvent<HTMLInputElement>;

export interface ITINInputEvent extends Partial<Omit<InputEventListenersTypes, "target">> {
  target: {
    value: string;
    id: string;
  };
}

const TINInput: FC<IProps> = props => {
  const {
    className,
    label,
    disabled,
    error,
    errorMessage,
    id,
    value,
    onBlur,
    onChange,
    onFocus,
    dataTestId,
    existingValue,
  } = props;

  const { t } = useTranslation();

  const [hidden, setHidden] = useState(false);
  const [existingValueUnchanged, setExistingValueUnchanged] = useState(existingValue);

  const classes = classNames("form__input form__input--ssn", className);

  const onRightIconClick = () => {
    if (existingValueUnchanged) {
      onChange && onChange({ target: { value: "", id } });
      setExistingValueUnchanged(false);
      return;
    }

    setHidden(!hidden);
  };

  const showHideIcon = hidden ? "Show" : "Hide";

  const onKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    setExistingValueUnchanged(false);
    const { key } = event;
    const newValue = key === "Backspace" ? value.slice(0, -1) : value + key;
    const formatted = formatTIN(newValue);
    const changedEvent = {
      ...event,
      target: { ...event.target, id: event.currentTarget.id, value: formatted },
    };

    onChange && onChange(changedEvent);
  };

  const onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedValue = event.clipboardData.getData("text");
    const concatValue = value + pastedValue;
    const formatted = formatTIN(concatValue);
    const changedEvent = {
      ...event,
      target: { ...event.target, id: event.currentTarget.id, value: formatted },
    };

    onChange && onChange(changedEvent);
  };

  const displayValue = hidden ? value.replace(/[0-9]/g, "*") : value;
  const mask = "***-**-****";

  return (
    <TextInput
      id={id || "ssn"}
      className={classes}
      inputMode="numeric"
      preventDisabledStyles={!disabled}
      disableAutoComplete
      label={label ? label : t("beneficiary.form.placeholder.ssnTin", "SSN/TIN")}
      value={existingValueUnchanged ? mask : displayValue}
      error={error}
      errorMessage={errorMessage}
      onKeyUp={onKeyUp}
      onBlur={onBlur}
      onFocus={onFocus}
      onPaste={onPaste}
      disabled={disabled || existingValueUnchanged}
      dataTestId={dataTestId || "form__input--ssn"}
      clearInputOption={existingValueUnchanged}
      onIconRightClick={onRightIconClick}
      iconRight={!!value && !existingValueUnchanged ? showHideIcon : ""}
    />
  );
};

export default TINInput;
