import classNames from "classnames";
import { ClassName } from "config/types";
import { CSSProperties, FC, useState } from "react";
import { Waypoint } from "react-waypoint";

type FixedState = {
  fixed: boolean;
  topOffset: number;
};

interface IProps extends Waypoint.WaypointProps {
  className?: ClassName;
  offsetFromTop?: number;
}

export const FixedWrapper: FC<IProps> = ({ className, offsetFromTop, children, ...rest }) => {
  const classes = classNames(className);
  const [fixedState, setFixedState] = useState<FixedState>({ fixed: false, topOffset: 0 });
  const { fixed, topOffset } = fixedState;
  const fixedStyle: CSSProperties = { position: "fixed", top: topOffset };

  const offsetTopValue = offsetFromTop || 0;

  const handleEnter = (event: Waypoint.CallbackArgs) => {
    const state: FixedState = { fixed: true, topOffset: event.waypointTop + offsetTopValue };
    setFixedState(state);
  };

  return (
    <Waypoint {...rest} onEnter={handleEnter} topOffset={fixedState.topOffset}>
      <div className={classes} style={fixed ? fixedStyle : {}}>
        {children}
      </div>
    </Waypoint>
  );
};
