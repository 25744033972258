import { gql } from "config/graphql";
import { IFinanceBankTransaction } from "config/types";
import { getApolloClient } from "store/apollo";

interface ICancelBankTransactionResponse {
  data: {
    cancelBankTransaction: {
      response: {
        status: string;
        success: boolean;
      };
    };
  };
}

export const cancelBankTransactionMutation = (
  bankTransactionId: IFinanceBankTransaction["externalId"]
): Promise<ICancelBankTransactionResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation CancelBankTransaction($input: CancelBankTransactionInput!) {
      cancelBankTransaction(input: $input) {
        response {
          status
          success
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables: { input: { bankTransactionId } },
  });
};
