import classNames from "classnames";
import TextInput from "components/TextInput/TextInput";
import { ValueOrArray } from "config/types";
import { useTranslation } from "modules/hooks/useTranslation";
import { ChangeEvent, FC, FocusEvent, useState } from "react";

interface IProps {
  value: string;
  id: string;
  className?: string;
  label?: string;
  activeLabel?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: ValueOrArray<string>;
  required?: boolean;
  validations?: any[];
  dataTestId?: string;
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
  onFocus?(event: FocusEvent<HTMLInputElement>): void;
  onBlur?(event: FocusEvent<HTMLInputElement>): void;
}

const PasswordInput: FC<IProps> = (props: IProps) => {
  const {
    activeLabel,
    className,
    disabled,
    error,
    errorMessage,
    id,
    label,
    value,
    onBlur,
    onChange,
    onFocus,
    dataTestId,
  } = props;
  const [inputType, setInputType] = useState("password");
  const { t } = useTranslation();

  const toggleInputType = () => {
    return inputType === "password" ? setInputType("text") : setInputType("password");
  };

  const showHideIcon = inputType === "password" ? "Show" : "Hide";

  const classes = classNames("form__input", className);

  return (
    <TextInput
      className={classes}
      type={inputType}
      iconRight={value && showHideIcon}
      id={id || "password"}
      label={label ? label : t("inputs.password.label", "Password")}
      activeLabel={activeLabel ? activeLabel : t("inputs.password.activeLabel", "Password")}
      value={value}
      error={error}
      errorMessage={errorMessage}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onIconRightClick={() => toggleInputType()}
      disabled={disabled}
      dataTestId={dataTestId}
    />
  );
};

export default PasswordInput;
