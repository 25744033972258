import { gql } from "config/graphql";
import { IUser } from "config/types";
import { getApolloClient } from "store/apollo";

interface IAllWyshAllocationHistoriesResponse {
  data: {
    me: Pick<IUser, "activeConstellations">;
  };
}

export const fetchAllWyshAllocationHistories = (): Promise<IAllWyshAllocationHistoriesResponse> => {
  const client = getApolloClient();

  const query = gql`
    query AllWyshAllocationsHistories {
      me {
        activeConstellations {
          allocationHistories {
            id
            amount
            change
            direction
            createdAt
            updatedAt
            wyshAllocation {
              id
              name
            }
          }
        }
      }
    }
  `;

  return client.query({ query });
};
