import { useElements, useStripe } from "@stripe/react-stripe-js";
import { CreatePaymentMethodCardData, PaymentMethod } from "@stripe/stripe-js";
import { useAppDispatch } from "store";
import { createPaymentMethod } from "store/actions/payment";

export const useCreateCCPayment = () => {
  const dispatch = useAppDispatch();
  const elements = useElements();
  const stripe = useStripe();

  const clearCCPaymentFields = () => {
    const cardElement = elements?.getElement("card");
    if (!cardElement) {
      throw new Error("Payment Card is undefined");
    }

    cardElement.clear();
  };

  const createPaymentWithStripe = async () => {
    const cardElement = elements?.getElement("card");
    if (!cardElement) {
      throw new Error("Payment Card is undefined");
    }

    const cardPayload: CreatePaymentMethodCardData = {
      card: cardElement,
      type: "card",
    };

    const cardResponse = await stripe?.createPaymentMethod(cardPayload);
    const cardError = cardResponse?.error;
    if (!!cardError) {
      throw new Error(cardError.message);
    }

    const cardPayment = cardResponse?.paymentMethod as NonNullable<PaymentMethod>;

    const paymentResponse = await dispatch(
      createPaymentMethod({
        externalId: cardPayment.id,
        source: "credit_card",
        metadata: {
          customer: cardPayment.customer,
          type: cardPayment.type,
          card: {
            brand: cardPayment.card?.brand,
            country: cardPayment.card?.country,
            funding: cardPayment.card?.funding,
            last4: cardPayment.card?.last4,
          },
        },
      })
    ).unwrap();

    return paymentResponse;
  };

  return {
    clearCCPaymentFields,
    createCCPayment: createPaymentWithStripe,
  };
};
