import { gql } from "config/graphql";

export const FINANCE_SCHEDULED_TRANSFERS_FIELDS = gql`
  fragment FinanceScheduledTransferFields on FinanceScheduledTransfer {
    action
    amount
    cancellable
    createdAt
    creationMechanism
    errorMessage
    id
    status
    transferDate
    updatedAt
  }
`;

export const FINANCE_TRANSFER_SCHEDULES_FIELDS = gql`
  fragment FinanceTransferScheduleFields on FinanceTransferSchedule {
    action
    amount
    createdAt
    cycle
    externalAccountId
    id
    status
    transferDate
    updatedAt
  }
`;

export const FINANCE_ACCOUNT_FIELDS = gql`
  ${FINANCE_SCHEDULED_TRANSFERS_FIELDS}
  ${FINANCE_TRANSFER_SCHEDULES_FIELDS}
  fragment FinanceAccountFields on FinanceAccount {
    createdAt
    id
    openedAt
    scheduledTransfers {
      ...FinanceScheduledTransferFields
    }
    status
    transferSchedules {
      ...FinanceTransferScheduleFields
    }
    updatedAt
  }
`;

export const FINANCE_CUSTOMER_FIELDS = gql`
  ${FINANCE_ACCOUNT_FIELDS}
  fragment FinanceCustomerFields on FinanceCustomer {
    accounts {
      ...FinanceAccountFields
    }
    createdAt
    id
    status
  }
`;
