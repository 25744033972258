import { ModalKey } from "containers/Modals/modals";
import * as Braze from "@braze/web-sdk";
import { isEmpty, noop } from "lodash-es";
import { createContext, FC, useCallback, useEffect, useMemo, useState } from "react";
import { matchPath, useLocation } from "react-router";
import { useAppSelector } from "store";
import replaceWyshwareUri from "utils/replaceWyshwareUri";

type DisabledPath = {
  path: string;
  exact?: boolean;
};

interface IBrazeIAMContext {
  disabled: boolean;
  deferIAM: boolean;
  setDeferIAM: (defer: boolean) => void;
  setDisabledPaths: (paths: DisabledPath[]) => void;
  triggerCustomEvent: (eventName: string, data?: Record<string, unknown>) => void;
}

export const BrazeIAMContext = createContext<IBrazeIAMContext>({
  disabled: false,
  deferIAM: false,
  setDeferIAM: noop,
  setDisabledPaths: noop,
  triggerCustomEvent: noop,
});

const defaultDisabledPaths: DisabledPath[] = [
  { path: "/login", exact: true },
  { path: "/fulfillment" },
];

export const BrazeIAMProvider: FC = ({ children }) => {
  const location = useLocation();
  const modal = useAppSelector(state => (state.modal.currentModal as ModalKey) ?? null);

  const pathname = useMemo(() => location.pathname, [location]);
  const [deferIAM, setDeferIAM] = useState<boolean>(false);
  const [disabledPaths, setDisabledPaths] = useState<DisabledPath[]>(defaultDisabledPaths);

  const handleDismissMessage = () => {
    Braze.logCustomEvent("home_view");
  };

  const replaceDeeplinkActions = useCallback(
    (inAppMessage: Braze.InAppMessage | Braze.ControlMessage, callback?: () => void) => {
      if (
        inAppMessage instanceof Braze.SlideUpMessage ||
        inAppMessage instanceof Braze.ModalMessage
      ) {
        const action = inAppMessage.clickAction;
        if (action === "URI" && inAppMessage.uri) {
          inAppMessage.uri = replaceWyshwareUri(inAppMessage.uri);
        }

        if (inAppMessage instanceof Braze.ModalMessage) {
          inAppMessage.buttons.forEach(button => {
            if (button.clickAction === "URI" && button.uri) {
              button.uri = replaceWyshwareUri(button.uri);
              if (callback) {
                button.subscribeToClickedEvent(callback);
              }
            }
          });
        }
      }

      return inAppMessage as Braze.InAppMessage;
    },
    []
  );

  const disabledPath = useMemo(() => {
    return disabledPaths.find(path => matchPath(pathname, path));
  }, [disabledPaths, pathname]);

  // Determine if IAM should be disabled based on path or active modal
  const isDisabled = useMemo(() => {
    return !isEmpty(disabledPath) || !isEmpty(modal);
  }, [disabledPath, modal]);

  const inAppMessageListener = useCallback(
    (inAppMessage: Braze.InAppMessage | Braze.ControlMessage) => {
      const message = replaceDeeplinkActions(inAppMessage);
      if (isDisabled) {
        Braze.deferInAppMessage(message);
      }

      // Re-fetch any remaining messages after dismissing the current message
      message.subscribeToDismissedEvent(handleDismissMessage);

      Braze.showInAppMessage(message);
    },
    [isDisabled, replaceDeeplinkActions]
  );

  useEffect(() => {
    if (isDisabled) return;

    Braze.logCustomEvent("page_view", { path: pathname });
  }, [isDisabled, pathname]);

  useEffect(() => {
    if (isDisabled) return;

    try {
      Braze.subscribeToInAppMessage(inAppMessageListener);
    } catch (error) {
      return;
    }
  }, [inAppMessageListener, isDisabled]);

  const contextValue = useMemo<IBrazeIAMContext>(
    () => ({
      disabled: isDisabled,
      deferIAM,
      setDeferIAM,
      setDisabledPaths,
      triggerCustomEvent: Braze.logCustomEvent,
    }),
    [deferIAM, isDisabled]
  );

  return <BrazeIAMContext.Provider value={contextValue}>{children}</BrazeIAMContext.Provider>;
};
