import { IFeatureFlags } from "config";
import { gql } from "config/graphql";
import { FEATURE_FLAG_FIELDS } from "graphql/fragments";
import { getApolloClient } from "store/apollo";

export const globalFeatureFlagQuery = (): Promise<{
  data: { globalFeatureFlags: IFeatureFlags[] };
}> => {
  const client = getApolloClient();

  const query = gql`
    ${FEATURE_FLAG_FIELDS}
    query GlobalFeatureFlags {
      globalFeatureFlags {
        ...FeatureFlagFields
      }
    }
  `;

  return client.query({ query });
};
