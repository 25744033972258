import { ClassName } from "config/types";
import React, { FC, ReactElement } from "react";
import { Link } from "react-router-dom";

import { ConditionalWrapper } from "./ConditionalWrapper";

interface IProps {
  condition: boolean;
  to: string;
  className?: ClassName;
}

export const ConditionalLink: FC<IProps> = ({
  children,
  condition,
  to,
  className,
  ...props
}): ReactElement => {
  return (
    <ConditionalWrapper condition={condition} props={{ to, className, ...props }} wrapper={Link}>
      {children}
    </ConditionalWrapper>
  );
};
