import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  confirmPlaidUpdateMutation,
  initiatePlaidUpdateMutation,
  unlinkAccountMutation,
} from "graphql/mutations/payment";
import { plaidItemsQuery } from "graphql/queries/payment";
import { getActiveConstellation } from "utils/constellation";
import { getAppError } from "utils/error";

export const getPlaidItems = createAsyncThunk("getPlaidItems", async (_, { rejectWithValue }) => {
  try {
    const { data } = await plaidItemsQuery();
    const constellation = getActiveConstellation(data.me.activeConstellations);
    const financePlaidItems = constellation?.financePlaidItems || [];
    const insurancePlaidItems = constellation?.plaidItems || [];

    return [...financePlaidItems, ...insurancePlaidItems];
  } catch (error) {
    const errorCode = getAppError(error);

    return rejectWithValue(errorCode);
  }
});

export const initiatePlaidUpdate = createAsyncThunk(
  "bank/initiatePlaidUpdate",
  async (plaidItemId: string, { rejectWithValue }) => {
    try {
      const { data } = await initiatePlaidUpdateMutation(plaidItemId);

      return data.linkToken;
    } catch (error) {
      const errorCode = getAppError(error);

      return rejectWithValue(errorCode);
    }
  }
);

export const confirmPlaidUpdate = createAsyncThunk(
  "bank/confirmPlaidUpdate",
  async (plaidItemId: string, { rejectWithValue }) => {
    try {
      const { data } = await confirmPlaidUpdateMutation(plaidItemId);

      return data.response.success;
    } catch (error) {
      const errorCode = getAppError(error);

      return rejectWithValue(errorCode);
    }
  }
);

export const unlinkAccount = createAsyncThunk(
  "bank/unlinkAccount",
  async (plaidItemId: string, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await unlinkAccountMutation(plaidItemId);
      await dispatch(getPlaidItems());

      return data.unlinkPlaidItem.response.success;
    } catch (error) {
      const errorCode = getAppError(error);

      return rejectWithValue(errorCode);
    }
  }
);
