import { createAsyncThunk } from "@reduxjs/toolkit";
import { translationsQuery } from "graphql/queries/translations";
import { getAppError } from "utils/error";

import { ITranslations } from "store/reducers/translations";

export const fetchTranslations = createAsyncThunk(
  "fetchTranslations",
  async (_, { rejectWithValue }) => {
    try {
      const res = await translationsQuery();

      const translations = res.data.translations.reduce((map: ITranslations, term) => {
        map[term.key] = term.value;
        return map;
      }, {});

      return translations;
    } catch (err) {
      console.log("Could not fetch translations");
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
