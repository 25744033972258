import "./TLFAQ.scss";

import FAQ from "components/FAQ/FAQ";
import { useTranslation } from "modules";

function TLFAQ() {
  const { t } = useTranslation();

  const questions = [
    {
      id: "1",
      question: t(
        "review_quotev2.term.faq.item1.question",
        "Why might my quote be higher than expected?"
      ),
      answer: t(
        "review_quotev2.term.faq.item1.answer",
        "Rates are determined by a number of factors, so your final life insurance quote may be higher than your initial estimate. Most often, age, gender, medical history and your lifestyle can all affect your final quote."
      ),
    },
    {
      id: "2",
      question: t("review_quotev2.term.faq.item2.question", "Can I cancel my policy at any time?"),
      answer: t(
        "review_quotev2.term.faq.item2.answer",
        "Yes! Your term life policy can be canceled at any time, with no additional cost or fees. And, if you cancel within the first 10 days, you get a full refund."
      ),
    },
    {
      id: "3",
      question: t(
        "review_quotev2.term.faq.item3.question",
        "Will my price change over the years? Any hidden fees?"
      ),
      answer: t(
        "review_quotev2.term.faq.item3.answer",
        "We value complete transparency when we share your quote, which means we’ll never charge you more or less than the price you have agreed to accept. There are no hidden fees or surprise costs on top of that monthly premium. However, your quote will expire in a month if you don’t start your policy today, and your price may increase after that so it’s best to act fast!"
      ),
    },
    {
      id: "4",
      question: t(
        "review_quotev2.term.faq.item4.question",
        "What if my coverage needs change in the future?"
      ),
      answer: t(
        "review_quotev2.term.faq.item4.answer",
        "As long as you’re still insurable, we offer you the flexibility to increase or decrease your coverage throughout the life of your policy."
      ),
    },
  ];

  return (
    <div className="tl-faq__container">
      <FAQ
        fireAnalyticsEvent
        title={t("review_quotev2.term.faq.title", "Common questions")}
        questions={questions}
      />
    </div>
  );
}

export default TLFAQ;
