import { IFinanceBankAccount, IFinanceCustomer, IMembership, IPlaidAccount } from "config/types";

//------  EXTERNAL ACCOUNT TYPES  ------//
export interface ExternalAccount {
  accountNumber: string;
  accountType: ExternalAccountType;
  balance: number;
  customerExternalId: string;
  externalAccountId: string;
  institutionLogo: string;
  institutionName: string;
  mask: string;
  routingNumber: string;
  status: ExternalAccountStatus;
}

export enum EnumExternalAccountTypes {
  "Checking",
  "Savings",
}
export type ExternalAccountType = keyof typeof EnumExternalAccountTypes;
type ExternalAccountStatus = "Unverified" | "Verified" | "VerifyLocked";

//------  GRAPHQL TYPES  ------//
export interface VerifyExternalAccountInput {
  amount1: number;
  amount2: number;
  externalAccountId: ExternalAccount["externalAccountId"];
  membershipId: IMembership["id"];
}

export interface CreateOrInitiateExternalAccountInput {
  accountType: ExternalAccountType | string;
  accountNumber?: IFinanceBankAccount["accountNumber"] | null;
  membershipId?: IMembership["id"];
  plaidAccountId?: IPlaidAccount["id"] | null;
  routingNumber?: IFinanceBankAccount["routingNumber"] | null;
}

export interface RemoveExternalAccountInput {
  customerId: IFinanceCustomer["id"];
  externalAccountId: ExternalAccount["externalAccountId"];
}

export interface RemoveExternalAccountResponse {
  data: {
    removeExternalAccount: {
      response: {
        status?: string;
        success: boolean;
      };
    };
  };
}
