import { gql } from "config/graphql";
import { IUser } from "config/types";
import { getApolloClient } from "store/apollo";

interface IFetchPendingPolicyResponse {
  data: {
    me: IUser;
  };
}

export const fetchPendingPolicy = (): Promise<IFetchPendingPolicyResponse> => {
  const client = getApolloClient();

  const query = gql`
    query pendingPolicy {
      me {
        activeConstellations {
          activeInsuranceMembership {
            id
            pendingPolicy {
              id
              amount
              status
              termDuration
              termUnit
              effectiveAt
              pausedAt
              terminatesAt
              product {
                pendingPlan {
                  amount
                  billingFrequency
                }
              }
              documents {
                id
                name
                description
                slug
                html {
                  url
                  fileName
                }
                pdf {
                  url
                  fileName
                }
                version
              }
            }
          }
        }
      }
    }
  `;

  return client.query({ query });
};
