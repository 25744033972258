import { gql } from "config/graphql";
import {
  IWyshAllocation,
  IWyshAllocationRecipient,
  WyshAllocationRecipientType,
} from "config/types";
import { RECIPIENT_FIELDS, WYSH_FIELDS } from "graphql/fragments";
import { getApolloClient } from "store/apollo";

export interface ICreateWyshAllocationInput {
  wyshId: string;
  name?: string;
}

interface ICreateWyshAllocationResponse {
  data: {
    createWyshAllocation: {
      wyshAllocation: IWyshAllocation;
    };
  };
}

export const createWyshAllocationMutation = (
  input: ICreateWyshAllocationInput
): Promise<ICreateWyshAllocationResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    ${RECIPIENT_FIELDS}
    ${WYSH_FIELDS}
    mutation CreateWyshAllocation($input: CreateWyshAllocationInput!) {
      createWyshAllocation(input: $input) {
        wyshAllocation {
          id
          name
          amount
          covered
          amountCovered
          percentCovered
          monitored
          status
          order
          terminatesAt
          recipient {
            ...RecipientFields
          }
          wysh {
            ...WyshFields
          }
        }
      }
    }
  `;

  return client.mutate({
    variables: { input },
    mutation,
  });
};

export interface IUpdateWyshAllocationInput {
  wyshAllocationId: string;
  name?: string;
  amount?: number;
  monitored?: boolean;
  plaidAccountId?: string;
  order?: number;
  terminatesAt?: string;
}

interface IUpdateWyshAllocationResponse {
  data: {
    updateWyshAllocation: {
      wyshAllocation: IWyshAllocation;
    };
  };
}

export const updateWyshAllocationMutation = (
  input: IUpdateWyshAllocationInput
): Promise<IUpdateWyshAllocationResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    ${RECIPIENT_FIELDS}
    ${WYSH_FIELDS}
    mutation UpdateWyshAllocation($input: UpdateWyshAllocationInput!) {
      updateWyshAllocation(input: $input) {
        wyshAllocation {
          id
          name
          amount
          covered
          amountCovered
          percentCovered
          monitored
          status
          order
          terminatesAt
          recipient {
            ...RecipientFields
          }
          wysh {
            ...WyshFields
          }
          plaidAccount {
            id
            name
            mask
          }
        }
      }
    }
  `;

  return client.mutate({
    variables: { input },
    mutation,
  });
};

interface IDestroyWyshAllocationResponse {
  data: {
    destroyWyshAllocation: {
      response: {
        success: boolean;
      };
    };
  };
}

export const destroyWyshAllocationMutation = (
  wyshAllocationId: string
): Promise<IDestroyWyshAllocationResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation DecommissionWyshAllocation($input: DecommissionWyshAllocationInput!) {
      decommissionWyshAllocation(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: {
      input: { wyshAllocationId },
    },
    mutation,
  });
};

export interface ICreateAllocationRecipientInput {
  wyshAllocationId: string;
  recipient: Omit<IWyshAllocationRecipient, "id" | "__typename">;
  type: WyshAllocationRecipientType;
}

interface ICreateWyshAllocationRecipientResponse {
  data: {
    createAllocationRecipient: {
      wyshAllocation: {
        recipient: IWyshAllocationRecipient;
      };
    };
  };
}

// Recipient
export const createAllocationRecipientMutation = ({
  wyshAllocationId,
  recipient,
  type,
}: ICreateAllocationRecipientInput): Promise<ICreateWyshAllocationRecipientResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    ${RECIPIENT_FIELDS}
    mutation CreateAllocationRecipient($input: CreateAllocationRecipientInput!) {
      createAllocationRecipient(input: $input) {
        wyshAllocation {
          recipient {
            ...RecipientFields
          }
        }
      }
    }
  `;

  return client.mutate({
    variables: {
      input: {
        wyshAllocationId,
        recipient: {
          [type]: recipient,
        },
      },
    },
    mutation,
  });
};

export interface IUpdateAllocationRecipientInput {
  wyshAllocationId: string;
  recipient: Omit<IWyshAllocationRecipient, "id" | "__typename">;
  type: WyshAllocationRecipientType;
}

interface IUpdateWyshAllocationRecipientResponse {
  data: {
    updateAllocationRecipient: {
      wyshAllocation: {
        recipient: IWyshAllocationRecipient;
      };
    };
  };
}

export const updateAllocationRecipientMutation = ({
  wyshAllocationId,
  recipient,
  type,
}: IUpdateAllocationRecipientInput): Promise<IUpdateWyshAllocationRecipientResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    ${RECIPIENT_FIELDS}
    mutation UpdateAllocationRecipient($input: UpdateAllocationRecipientInput!) {
      updateAllocationRecipient(input: $input) {
        wyshAllocation {
          recipient {
            ...RecipientFields
          }
        }
      }
    }
  `;

  return client.mutate({
    variables: {
      input: {
        wyshAllocationId,
        recipient: {
          [type]: recipient,
        },
      },
    },
    mutation,
  });
};

interface IRemoveAllocationRecipientInput {
  wyshAllocationId: string;
}
interface IRemoveAllocationRecipientResponse {
  data: {
    destroyAllocationRecipient: {
      success: boolean;
    };
  };
}

export const removeAllocationRecipientMutation = ({
  wyshAllocationId,
}: IRemoveAllocationRecipientInput): Promise<IRemoveAllocationRecipientResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation DestroyAllocationRecipient($input: DestroyAllocationRecipientInput!) {
      destroyAllocationRecipient(input: $input) {
        success
      }
    }
  `;

  return client.mutate({
    variables: { input: { wyshAllocationId } },
    mutation,
  });
};
