import FAQ from "components/FAQ/FAQ";
import { useTranslation } from "modules";
import { FC } from "react";

const ADFAQ: FC = () => {
  const { t } = useTranslation();

  const adQuestions = [
    {
      id: "1",
      question: t("faq.ad.item.1.question", "What does accidental death insurance cover?"),
      answer: t(
        "faq.ad.item.1.answer",
        "Accidental death insurance typically offers coverage for non-medical death, but this may vary by state. This includes being killed while riding in, driving a, or being struck by a motor vehicle. You'd also be covered if you died as a ticketed passenger on a plane, boat, or bus—or as a passenger in a cab or app-based car service.\n\nFlexible coverage starts at $50,000 and goes up to $1,000,000.\n\nSubject to the policy's exclusions."
      ),
    },
    {
      id: "2",
      question: t(
        "faq.ad.item.2.question",
        "What’s the difference between term life and accidental death insurance?"
      ),
      answer: t(
        "faq.ad.item.2.answer",
        "Term policies cover policyholders for most causes of death. Accidental death insurance is more limited and focuses only on fatalities resulting from accidental bodily injury as define in the customer’s policy."
      ),
    },
    {
      id: "3",
      question: t(
        "faq.ad.item.3.question",
        "Can I keep my Wyshes if I have accidental death Insurance and not a term policy?"
      ),
      answer: t(
        "faq.ad.item.3.answer",
        "Yep! Your Wyshes are a great way to know how much coverage you need."
      ),
    },
    {
      id: "4",
      question: t("faq.ad.item.4.question", "What is not covered?"),
      answer: t(
        "faq.ad.item.4.answer",
        "While we can't list everything, the most common deaths an accidental death policy doesn't cover include medically-related deaths such as cancer, heart disease, or other terminal illnesses. In addition, it doesn’t cover suicide and accidents from extreme sports."
      ),
    },
    {
      id: "5",
      question: t("faq.ad.item.5.question", "Can I change my coverage?"),
      answer: t(
        "faq.ad.item.5.answer",
        "As long as you’re still insurable, we offer you the flexibility to increase or decrease your coverage as you see fit.\n\nAccidental death insurance offers up to $1M in coverage up to age 65."
      ),
    },
  ];

  return (
    <div className="ad-faq__container">
      <FAQ
        fireAnalyticsEvent
        title={t("faq.ad.title", "Common questions")}
        questions={adQuestions}
      />
    </div>
  );
};

export default ADFAQ;
