import "./TransferFundSuccess.scss";

import c from "classnames";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { delay } from "lodash-es";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { closeModal } from "store/actions/modal";

const TransferFundSuccess: FC<IModalProps> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modalData = useSelector((state: RootState) => state.modal.data);

  const onInitialRender = () => {
    const callback = () => {
      dispatch(closeModal());
      props.context?.callbacks?.onClose && props.context?.callbacks?.onClose();
    };
    const closeAfterTimer = delay(callback, 2000);
    return () => clearTimeout(closeAfterTimer);
  };

  const isAutoDepositTransfer = modalData?.transferType === "auto-deposit";

  const content = {
    header: isAutoDepositTransfer
      ? t("savings.transfer.review.autodeopsit.success", "SUCCESS! AUTO-DEPOSIT SCHEDULED")
      : t("savings.transfer.success", "TRANSFER SUBMITTED"),
    icon: isAutoDepositTransfer ? "BlobSave" : "BlobCheck",
    onInitialRender,
  };

  const classes = c("transfer-fund-success-modal", {
    "auto-deposit-type": isAutoDepositTransfer,
  });

  return <Layout as="ModalStatusLayout" className={classes} content={content} />;
};

export default TransferFundSuccess;
