import "./WyshCategoryTitle.scss";

import { FC, ReactNode } from "react";

interface IProps {
  text?: ReactNode;
  color?: string;
}

const WyshCategoryTitle: FC<IProps> = ({ text = "", color }) => {
  return (
    <div className="wysh-category-title__wrapper">
      <span className="wysh-category-title__title">{text}</span>
      <span className="wysh-category-title__flair" style={{ backgroundColor: color }} />
    </div>
  );
};

export default WyshCategoryTitle;
