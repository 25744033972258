import formatCommas from "./formatCommas";
import { toFixed } from "./toFixed";

export const formatCurrencyAmount = (amount: number | string) => {
  return formatCommas(toFixed(amount));
};

// Outputs large currency values in compacted notation
// eg. $5.3M, $250k, $5400
export const formatCurrencyCompactAmount = (amount: number) => {
  const amountAsInt = amount;

  let numberFormatOptions = {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  } as Intl.NumberFormatOptions;

  switch (true) {
    case amountAsInt >= 10000 && amountAsInt < 1000000:
      numberFormatOptions = {
        ...numberFormatOptions,
        notation: "compact",
        roundingMode: "trunc",
      } as Intl.NumberFormatOptions;
      break;
    case amountAsInt >= 1000000:
      numberFormatOptions = {
        ...numberFormatOptions,
        maximumFractionDigits: 1,
        notation: "compact",
        roundingMode: "trunc",
        trailingZeroDisplay: "stripIfInteger",
      } as Intl.NumberFormatOptions;
      break;
    default:
      break;
  }

  const formattedCurrency = new Intl.NumberFormat("en-US", numberFormatOptions).format(amountAsInt);

  return formattedCurrency;
};
