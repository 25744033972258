import { RouteDestination } from "config/types";
import { useNavigate } from "modules";
import { ComponentProps, forwardRef } from "react";
import { Link as BaseLink } from "react-router-dom";

interface IProps extends ComponentProps<BaseLink> {
  to: RouteDestination;
  state?: Record<string, unknown>;
}

export const Link = forwardRef<HTMLAnchorElement, IProps>(function LinkWithRef(
  { onClick, replace = false, state, target, to, ...rest },
  ref
) {
  const navigate = useNavigate();

  function handleClick(event: React.MouseEvent<HTMLAnchorElement>) {
    if (onClick) onClick(event);
    if (!event.defaultPrevented && event.button === 0 && (!target || target === "_self")) {
      event.preventDefault();
      navigate(to, { replace, state });
    }
  }

  return <BaseLink {...rest} to={to} onClick={handleClick} ref={ref} target={target} />;
});
