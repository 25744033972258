import { gql } from "config/graphql";
import { ApiFormGroup } from "config/types";
import { getApolloClient } from "store/apollo";

type DynamicFormGroupApiMutationBodyInput = {
  inputPayload: Record<string, unknown>;
  mutationBody: ApiFormGroup["detail"]["mutationBody"];
};

export const dynamicFormGroupMutationBodyMutation = ({
  inputPayload,
  mutationBody,
}: DynamicFormGroupApiMutationBodyInput) => {
  const client = getApolloClient();

  const mutation = gql`
    ${mutationBody}
  `;

  return client.mutate({
    variables: { ...inputPayload },
    mutation,
  });
};
