import c from "classnames";
import SliderNumberInput from "components/SliderNumberInput/SliderNumberInput";
import { ComponentProps, useContext } from "react";

import { DynamicFormContext } from "../DynamicFormContext";

import { IDynamicFormItemProps } from ".";

type SliderInputProps = ComponentProps<typeof SliderNumberInput>;

export function SliderInputItem({ item }: IDynamicFormItemProps<"inputSlider">) {
  const { form } = useContext(DynamicFormContext);

  const { content, key } = item;
  const {
    characterMax,
    increment,
    leftTextLabel,
    maxErrorMessage,
    minErrorMessage,
    rightTextLabel,
    textEditable,
    ticks,
    valueBase,
  } = content;
  const { label, value } = form.getFormInput(key);

  const metadata: SliderInputProps["metadata"] = {
    characterMax: characterMax,
    increment: increment,
    leftTextLabel: leftTextLabel,
    maxErrorMessage: maxErrorMessage || "",
    minErrorMessage: minErrorMessage || "",
    rightTextLabel: rightTextLabel,
    textEditable: textEditable,
    ticks: ticks,
    value: value,
    valueBase: valueBase,
  };

  const classes = c("dynamic-form-item form-item--input-slider");

  return (
    <SliderNumberInput
      className={classes}
      id={key}
      idText={`${key}-text`}
      label={label}
      metadata={metadata}
      onBlur={form.onBlur}
      onChange={form.onChange}
      value={value || 0}
    />
  );
}
