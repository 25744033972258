import { IConstellation } from "config/types";
import { first } from "lodash-es";

export const getActiveConstellation = <T = IConstellation>(constellations: T[] = []) => {
  const constellation = first(constellations);
  return constellation;
};

type ConstellationMembershipByBusiness = {
  "wysh-financial": "activeFinanceMembership";
  "wysh-insurance": "activeInsuranceMembership";
};

interface MembershipReturnTypeByBusiness<T extends IConstellation> {
  "wysh-financial": T["activeFinanceMembership"];
  "wysh-insurance": T["activeInsuranceMembership"];
}

export const getConstellationMembership = <
  K extends keyof ConstellationMembershipByBusiness,
  T extends IConstellation
>(
  constellations: T[],
  businessType: K
): MembershipReturnTypeByBusiness<T>[K] | undefined => {
  const businessField: ConstellationMembershipByBusiness = {
    "wysh-financial": "activeFinanceMembership",
    "wysh-insurance": "activeInsuranceMembership",
  };

  const activeConstellation = getActiveConstellation(constellations);
  const membershipField = businessField[businessType];

  const activeMembership = activeConstellation
    ? (activeConstellation?.[membershipField] as MembershipReturnTypeByBusiness<T>[K])
    : undefined;

  return activeMembership;
};
